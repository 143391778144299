import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[terminals] requestGetDataPage')
export const getDataPageSuccess = createAction('[terminals] getDataPageSuccess')
export const getDataPageError = createAction('[terminals] getDataPageError')
// get AvailableTerminals
export const requestGetAvailableTerminals = createAction('[terminals] requestGetAvailableTerminals')
export const finalGetAvailableTerminals  = createAction('[terminals] finalGetAvailableTerminals')
// get one
export const requestGetOne = createAction('[terminals] requestGetOne')
export const successGetOne  = createAction('[terminals] successGetOne')
export const clearGetOne  = createAction('[terminals] clearGetOne')
//  create
export const requestCreate = createAction('[terminals] requestCreate')
export const finalCreate  = createAction('[terminals] finalCreate')
//  update
export const requestUpdate = createAction('[terminals] requestUpdate')
export const finalUpdate  = createAction('[terminals] finalUpdate')