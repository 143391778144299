import React from "react"
import { useHistory } from "react-router-dom"
import { Button, Tooltip } from "antd"
import { EyeTwoTone, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux"


const btnStyle = {
    width: 50,
}


const Receipt = ({ record }) => {
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.transactions.downloadReceipt)
    const permissions = useSelector((state) => state.auth.user.userToken.adminRoles)
    
    const hasMerchantInvociesReceiptPermission = permissions["merchantInvoices:invoices:downloadReceipt"]

    const handleReceipt = () => {
        //dispatch(fetchGetReceiptPdf(record.checkNumber))
    }

    return (
        <Tooltip
            title="Скачать чек транзакции"
            placement="bottomLeft"
            arrowPointAtCenter
        >
            <Button
                style={ btnStyle }
                icon={ <DownloadOutlined /> }
                onClick={ handleReceipt }
                type="primary"
                ghost
                block
                loading={ loading }
                disabled={ !hasMerchantInvociesReceiptPermission }
            />
        </Tooltip>
    )
}

const ViewDetails = ({ record }) => {
    const history = useHistory()
    const handleEdit = () => {
        const { pathname } = history.location
        if (record.id) {
            history.push(`${pathname}/update/${record.id}`)
        }
    }
    return <Button type="text" icon={<EyeTwoTone />} onClick={handleEdit} />
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: "110px",
    },
    {
        title: "Название",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => {
            if (a.enabled > b.enabled) {
                return 1
            }
            if (a.enabled < b.enabled) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Сумма",
        dataIndex: "totalAmount",
        key: "totalAmount",
        sorter: (a, b) => {
            if (a.enabled > b.enabled) {
                return 1
            }
            if (a.enabled < b.enabled) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Чек",
        dataIndex: "receipt",
        key: "receipt",
        render: (_, record) => <Receipt record={ record } />,
    },
    {
        title: "Действия",
        key: "actions",
        align: "center",
        render: (_, record) => <ViewDetails record={record} />,
    },
]

export default columns