import { call, put, takeLatest } from 'redux-saga/effects'; 
import * as actions from './actions'  
import API from '../../../api/core/dict';  

export default [ 
	takeLatest(actions.requestGetBanks, requestGetBanks),  
	takeLatest(actions.requestGetPayments, requestGetPayments),  
	takeLatest(actions.requestGetPartners, requestGetPartners),  
	takeLatest(actions.requestGetMerchants, requestGetMerchants),     
	takeLatest(actions.requestGetMerchantPoints, requestGetMerchantPoints),  
	takeLatest(actions.requestGetMerchantPointsByParams, requestGetMerchantPointsByParams),  
	takeLatest(actions.requestUsersForApp, requestUsersForApp),
	takeLatest(actions.requestGetPinpads, requestGetPinpads),
	takeLatest(actions.requestGetAllDataForTmsConfigs, requestGetAllDataForTmsConfigs), 
]

//
function* requestGetBanks(action) {
	try {   
		const data = yield call(API.getBanks, action.payload);  
		yield put(actions.successGetBanks(data)) 
	} catch (error) {   
		yield put(actions.successGetBanks({}))
	}
}

//
function* requestGetPayments(action) {
	try {   
		const data = yield call(API.getPayments, action.payload);  
		yield put(actions.successGetPayments(data)) 
	} catch (error) {   
		yield put(actions.successGetPayments({}))
	}
}

//
function* requestGetPartners(action) {
	try {   
		const data = yield call(API.getPartners, action.payload);  
		yield put(actions.finalGetPartners(data)) 
	} catch (error) {   
		yield put(actions.finalGetPartners([]))
	}
}
// 
function* requestGetMerchants(action) {
	try {  
		const data = yield call(API.getMerchantsWithBanks, action.payload);  
		yield put(actions.finalGetMerchants(data))
	} catch (error) {   
		yield put(actions.finalGetMerchants({}))
	}
} 

// 
function* requestGetMerchantPoints(action) {
	try {  
		const data = yield call(API.getMerchantPoints, action.payload);  
		yield put(actions.finalGetMerchantsPoints(data))
	} catch (error) {   
		yield put(actions.finalGetMerchantsPoints({}))
	}
} 

// 
function* requestGetMerchantPointsByParams(action) {
	try {  
		const data = yield call(API.getMerchantPointsByParams, action.payload);  
		yield put(actions.finallGetMerchantPointsByParams(data))
	} catch (error) {   
		yield put(actions.finallGetMerchantPointsByParams({}))
	}
} 

// 
function* requestUsersForApp(action) {
	try {  
		const data = yield call(API.getUsersForApp, action.payload);  
		yield put(actions.finalUsersForApp(data.data))
	} catch (error) {   
		yield put(actions.finalUsersForApp({}))
	}
}

function* requestGetPinpads(action) {
	try {
		const data = yield call(API.getPinpads, action.payload);  
		yield put(actions.successGetPinpads(data))
	} catch (error) {   
		yield put(actions.successGetPinpads({}))
	}
}

function* requestGetAllDataForTmsConfigs(action) {
	try {
		const data = yield call(API.getAllDataForTmsConfigs, action.payload);  
		yield put(actions.successGetAllDataForTmsConfigs(data))
	} catch (error) {   
		yield put(actions.successGetAllDataForTmsConfigs({}))
	}
} 