import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import customersAPI from "../../api/v2/customersAPI"
import posesAPI from "../../api/v2/posesAPI"
import dictsAPI from "../../api/v2/dictsAPI"
import {
    setCurrentPosForTerminalCreate,
    setCurrentCustomerForTerminalCreate,
    setCurrentTerminalNameForTerminalCreate,
	setCurrentCustomMccForTerminalCreate,
} from "../terminal-create/terminalCreateSlice"

export const fetchCreatePos = createAsyncThunk(
    "pos-create/create/fetch",
    async (payload, thunkAPI) => {
        const {
            installationAddress,
            contactPerson,
        } = thunkAPI.getState().posCreate
        const requestData = { ...payload, installationAddress, contactPerson }
        const response = await posesAPI.createOne(requestData)
        return response
    }
)

export const getCustomersOptions = createAsyncThunk(
    "pos-create/customers",
    async () => {
        const response = await customersAPI.getData()
        return response
    }
)

export const getMccOptions = createAsyncThunk(
    "pos-create/mcc",
    async (bankId) => {
        const response = await dictsAPI.getActivitiesKinds(bankId)
        return response
    }
)

export const getCurrentCustomerData = createAsyncThunk(
    "pos-create/customer-fetch",
    async (customerId) => {
        const response = await customersAPI.getOneForRecord(customerId)
        return response
    }
)

export const setCurrentDataForTerminalCreate = createAsyncThunk(
    "customer-create/for-pos-create",
    async ({ customerId, MCC, posId, posNameRu,customMcc }, thunkAPI) => {
        customerId && thunkAPI.dispatch(setCurrentCustomerForTerminalCreate(customerId))
        posId && thunkAPI.dispatch(setCurrentPosForTerminalCreate(posId))
        posNameRu && thunkAPI.dispatch(setCurrentTerminalNameForTerminalCreate(posNameRu))
        customMcc && thunkAPI.dispatch(setCurrentCustomMccForTerminalCreate(customMcc))
    }
)

const initialState = {
    withNextStep: false,
    installationAddress: {},
    contactPerson: {},
    currentCustomer: null,
    currentCustomerData: {},
    currentCustomerDataFetched: false,
    currentCustomerAddress: {},
    options: {
        customers: [],
        mcc: []
    },
}

const posCreateSlice = createSlice({
    name: "pos-create",
    initialState,
    reducers: {
        setCountryFromToken: (state, { payload }) => {
            state.installationAddress = { ...state.installationAddress, country: payload }
        },
        addPosAddress: (state, { payload }) => {
            state.installationAddress =
                payload || initialState.installationAddress
        },
        addPosContactPerson: (state, { payload }) => {
            state.contactPerson = payload || initialState.contactPerson
        },
        setWithNextStep: (state) => {
            state.withNextStep = true
        },
        setCurrentCustomer: (state, { payload }) => {
            state.currentCustomer = payload
        },
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        [fetchCreatePos.pending]: (state) => {
            state.loading = true
        },
        [fetchCreatePos.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.withNextStep = false
        },
        [fetchCreatePos.rejected]: (state) => {
            state.loading = false
            state.withNextStep = false
        },
        [getCustomersOptions.fulfilled]: (state, { payload }) => {
            state.customers = payload.clients
            state.options.customers = payload.clients.map((customer) => ({
                key: customer.id,
                value: customer.id,
                label: `${customer.businessName} (${customer.TIN})`,
            }))
        },
        [getMccOptions.fulfilled]: (state, { payload }) => {
            state.options.mcc = Object.entries(payload.data).map(item => ({value: item[0], label: item[1]}))
        },
        [getCurrentCustomerData.fulfilled]: (state, { payload }) => {
            state.currentCustomerData = payload
            state.currentCustomerDataFetched = true
			if(payload.businessName && payload.TIN && state.currentCustomer){
				state.options.customers = [{label: `${payload.businessName} (${payload.TIN})`, value: state.currentCustomer}]
			}
			
        },
    },
})

export const {
    setCountryFromToken,
    addPosAddress,
    addPosContactPerson,
    setWithNextStep,
    setCurrentCustomer,
    clearFormData,
} = posCreateSlice.actions
export default posCreateSlice.reducer
