import axios from "axios"

const ROOT_URL = "/api/v2"

const usersAPI = {
    // read
    getData: (pagination) => axios.get(`${ROOT_URL}/users?page=${pagination.current}&elementsPerPage=${pagination.elementsPerPage}`).then((r) => r.data),
    getDataByAdvancedSearch: (data) => axios.post(`${ROOT_URL}/users/advanced-search`, data).then((r) => r.data),
    getOne: (id) => axios.get(`${ROOT_URL}/user/${id}`).then((r) => r.data),
    validateLoginExist: (login) =>
        axios.get(`${ROOT_URL}/user/check/${login}`).then((r) => r.data),
    // update
    update: (data) => axios.put(`${ROOT_URL}/user`, data).then(r => r.data),
    resetUpdateUserPassword: (data) => axios.post(`${ROOT_URL}/user/resetUserPassword`, data).then(r => r.data),
    logInOutNewUser: (data) => axios.post(`${ROOT_URL}/user/changeUserToken`, data).then(r => r.data),
    // create
    create: (data) => axios.post(`${ROOT_URL}/user`, data).then((r) => r.data),
}

export default usersAPI