import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/crm/leads';  
import dictsAPI from '../../../../api/v2/dictsAPI'
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestCreate, requestCreate),   
	takeLatest(actions.requestUpdate, requestUpdate),   
	takeLatest(actions.requestCreateNoLogin, requestCreateNoLogin),   
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestGetLeadTypes, requestGetLeadTypes),   
	takeLatest(actions.requestGetGoodsServices, requestGetGoodsServices),   
	takeLatest(actions.requestCovertLeadToApp, requestCovertLeadToApp),   
	takeLatest(actions.requestGetTopWidgets, requestGetTopWidgets),
	takeLatest(actions.requestPersonalData, requestPersonalData),
	takeLatest(actions.requestCloseLead, requestCloseLead),
	takeLatest(actions.requestSendNewComment, requestSendNewComment),
	// --- files --->
	takeLatest(actions.requestGetLeadFiles, requestGetLeadFiles),
	takeLatest(actions.requestUploadLeadFile, requestUploadLeadFile),
	takeLatest(actions.requestDownloadFile, requestDownloadFile),
	takeLatest(actions.requestDownloadCashBoxLeadFile, requestDownloadCashBoxLeadFile),
	// --- widgets --->
	takeLatest(actions.requestGetTotalLeads, requestGetTotalLeads),
	takeLatest(actions.requestGetNewLeads, requestGetNewLeads),
	takeLatest(actions.requestGetInProgressLeads, requestGetInProgressLeads),
	takeLatest(actions.requestGetConvertedLeads, requestGetConvertedLeads),
	takeLatest(actions.updateResponsibleUser, updateResponsibleUser)
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestCreate(action) {
	try {  
		const data = yield call(API.create, action.payload.request);  
		action.payload.callback(true)  
		yield put(actions.finalCreate(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCreate(false))
	}
} 

// 
function* requestUpdate(action) {
	try {  
		const data = yield call(API.update, action.payload.request);  
		action.payload.callback(true)  
		yield put(actions.finalGetUpdate(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalGetUpdate(false))
	}
} 

// 
function* requestCreateNoLogin(action) {
	try {  
		const data = yield call(API.createNoLogin, action.payload.request);  
		action.payload.callback(true)  
		yield put(actions.finalCreateNoLogin(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCreateNoLogin({}))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetOne(data))
	} catch (error) {   
		action.payload.callback({})  
		yield put(actions.finalGetOne({}))
	}
} 

// 
function* requestGetLeadTypes(action) {
	try {  
		const data = yield call(dictsAPI.getLeadTypes, action.payload);
		yield put(actions.finalGetLeadTypes(data))
	} catch (error) {   
		yield put(actions.finalGetLeadTypes({}))
	}
} 

// 
function* requestGetGoodsServices(action) {
	try {  
		const data = yield call(API.getGoodsServices, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetGoodsServices(data))
	} catch (error) {   
		action.payload.callback({})  
		yield put(actions.finalGetGoodsServices([]))
	}
} 



// 
function* requestSendNewComment(action) {
	try {  
		const data = yield call(API.sendNewComment, action.payload.request);
		yield put(actions.finalSendNewComment(data))
		action.payload.callback()
	} catch (error) {    
		yield put(actions.finalSendNewComment({}))
	}
}

//
function* requestGetTopWidgets(action) {
	try {
		const data = yield call(API.getTopWidgets, action.payload);
		yield put(actions.finalGetTopWidgets(data))
	} catch (error) {
		yield put(actions.finalGetTopWidgets({}))
	}
}

// --- widgets --->
function* requestGetTotalLeads(action) {
	try {
		const data = yield call(API.getTotalLeads, action.payload);
		yield put(actions.finalGetTotalLead(data))
	} catch (error) {
		yield put(actions.finalGetTotalLead(false))
	}
}
function* requestGetNewLeads(action) {
	try {
		const data = yield call(API.getNewLeads, action.payload);
		yield put(actions.finalGetNewLeads(data))
	} catch (error) {
		yield put(actions.finalGetNewLeads(false))
	}
}
function* requestGetInProgressLeads(action) {
	try {
		const data = yield call(API.getInProgressLeads, action.payload);
		yield put(actions.finalGetInProgressLeads(data))
	} catch (error) {
		yield put(actions.finalGetInProgressLeads(false))
	}
}
function* requestGetConvertedLeads(action) {
	try {
		const data = yield call(API.getConvertedLeads, action.payload);
		yield put(actions.finalGetConvertedLeads(data))
	} catch (error) {
		yield put(actions.finalGetConvertedLeads(false))
	}
}

// --- files --->

function* requestGetLeadFiles(action) {
	try {
		const data = yield call(API.getLeadFiles, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalGetLeadFiles(data))
	} catch (error) {
		yield put(actions.finalGetLeadFiles(false))
	}
}

function* requestUploadLeadFile(action) {
	try {
		const data = yield call(API.uploadLeadFile, action.payload.formData);  
		action.payload.callback(data.fileIds[0])    
	} catch (e) { 
		action.payload.callback(false) 
	}
}

function* requestDownloadFile(action) {
	try {
		const data = yield call(API.downloadFile, action.payload);
		yield put(actions.finalDownloadFile(data))
	} catch (error) {
		yield put(actions.finalDownloadFile(false))
	}
}

function* requestDownloadCashBoxLeadFile(action) {
	console.log(action)
	try {
		const data = yield call(API.downloadCashBoxFile, action.payload);
		yield put(actions.finalDownloadCashBoxLeadFile(data))
	} catch (error) {
		yield put(actions.finalDownloadCashBoxLeadFile(false))
	}
}

// -- request transform update

// 
function* requestCovertLeadToApp(action) {
	try {  
		const data = yield call(API.covertLeadToAppOld, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalCovertLeadToApp(data))
	} catch (error) {   
		action.payload.callback(error)  
		yield put(actions.finalCovertLeadToApp({}))
	}
}


// 
function* requestPersonalData(action) {
	try {  
		const data = yield call(API.personalData, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalPersonalData(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalPersonalData({}))
	}
}

// 
function* requestCloseLead(action) {
	try {  
		const data = yield call(API.closeLead, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalCloseLead(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCloseLead({}))
	}
}

// 
function* updateResponsibleUser(action) {
	try {  
		
		const data = yield call(API.updateResponsibleUser, action.payload.request);  
		action.payload.callback({result: data, request: action.payload.request});  

	} catch (error) {   
		action.payload.callback(false)  
	}
}