import axios from 'axios'

const ROOT_URL = "/api/v2/serviceTickets"

const serviceAPI = {
    // read
    getData: () => axios.get(ROOT_URL).then(r => r.data),
    getOne: (id) => axios.get(`${ROOT_URL}/${id}`).then(r => r.data),
    // update

    // create
    createOneServiceTickets: (payload) => axios.post(`${ROOT_URL}/create`, payload)
        .then(r => r.data),
    createOneServiceTicketsComment: ({ticketId, comment}) => axios.post(`${ROOT_URL}/addComment`, {ticketId, comment})
        .then(r => r.data),
}

export default serviceAPI