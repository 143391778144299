import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = { 
  success: '',
  errorsServer: {},
	error: false,
  loading: false,
  codeAgain: {
    data: {},
    loading: false,
  },
  changePass: {
    success: false,
    error: false,
    errorsServer: {},
    loading: false,
  },
  recoveryPass: {
    success: false,
    error: false,
    errorsServer: {},
    loading: false,
  },
  savedPathname: null,
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- *  Login
    [actions.requestLogin]: (state, action) => ({
      ...initialState,  loading: true
    }), 
    [actions.successLogin]: (state, action) => ({
      ...state,  loading: false, success: true, error: false,
    }),  
    [actions.errorLogin]: (state, action) => ({
      ...state,  loading: false, success: false, error: true, errorsServer: action
    }),  

    // --- * ChangePass 
    [actions.requestChangePass]: (state, action) => ({
      ...initialState,  changePass: {...initialState.changePass, loading: true }
    }), 
    [actions.successChangePass]: (state, action) => ({
      ...state, changePass: {...state.changePass,  loading: false, success: true, error: false, errorsServer: {} }
    }),  
    [actions.errorChangePass]: (state, action) => ({
      ...state, changePass: {...state.changePass,  loading: false, success: false, error: true, errorsServer: action}
    }),  

    // --- * recoveryPass 
    [actions.requestRecoveryPass]: (state, action) => ({
      ...initialState,  recoveryPass: {...initialState.recoveryPass, loading: true }
    }), 
    [actions.successRecoveryPass]: (state, action) => ({
      ...state, recoveryPass: {...state.recoveryPass,  loading: false, success: true, error: false, errorsServer: {} }
    }),  
    [actions.errorRecoveryPass]: (state, action) => ({
      ...state, recoveryPass: {...state.recoveryPass,  loading: false, success: false, error: true, errorsServer: action}
    }),

    // --- *  GetCodeAgain
    [actions.requestGetCodeAgain]: (state, action) => ({
      ...state,  codeAgain: {...state.codeAgain, loading: true}
    }), 
    [actions.finalGetCodeAgain]: (state, action) => ({
      ...state,  codeAgain: {...state.codeAgain, loading: false, data: action}
    }),

    [actions.savePathname]: (state, action) => {
     return {...state,  savedPathname: action}
    },   
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
