import axios from "axios"

const ROOT_URL = "/api/v2"

const merchantInvoicesAPI = {
    // read
    getMerchantPayments: (data) => axios.post(`${ROOT_URL}/payments/advanced-search`,data).then((r) => r.data),
    getPayment: (id) => axios.get(`${ROOT_URL}/payments/${id}`).then((r) => r.data),
    getCurrencies: (data) => axios.get(`/dict/currencies`, data).then((r) => r.data),
    // update
   
    // create
	createPayment: (data) => axios.post(`${ROOT_URL}/createPayment`, data).then((r) => r.data),
}

export default merchantInvoicesAPI