export const selectUserToken = (state) => state.auth.user.userToken
export const selectUserTokenBankBean = (state) => state.auth.user.userToken.bankBean
export const selectPermissions = (state) => state.auth.user.userToken.adminRoles
export const selectUserTokenAdminId = (state) => state.auth.user.userToken.adminId
export const selectUserLeadRole = (state) => state.auth.user.userToken.leadRole
export const selectLeadTypes = (state) => state.pages.crm.leads.types.data

/** Списки банков */
export const selectBanks = (state) => state.core.dict.banks

/** Список банков для заявок */
//без изменений
export const selectBanksData = (state) => state.core.dict.banks.data
//в виде опций
export const selectDictBanks = (state) => state.core.dict.banks.dictBanks

/** Список всех банков */
//в виде опций
export const selectAllBanksList = (state) => state.core.dict.banks.allBanksList
//без изменений
export const selectAllBanksData = (state) => state.core.dict.banks.allBanksData
// {id: {data}}
export const selectAllBanksMap = (state) => state.core.dict.banks.allBanksMap
