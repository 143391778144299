import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import LoadingAnimation from '../features/layout/LoadingAnimation'
import { savePathname } from '../store/auth/login/actions'
import jwt_decode from 'jwt-decode'
import { useQuery } from 'react-query'
import leadAPI from '../api/other/newLeadV2'
import { useEffect } from 'react'
import { selectPermissions } from '../features/shares/helpers/commonReduxSelectors'

const getUserData = () => {
    let userDataRow = localStorage.getItem('user')
    let userDataJson = JSON.parse(userDataRow);

    return { userDataRow, userDataJson }
}

const checkUserData = () => {
    const { userDataRow, userDataJson } = getUserData()
    let needPasswordReset = true
    let token = null

    if (userDataRow) {
        needPasswordReset = userDataJson.needPasswordReset
        token = userDataJson.token
    }

    if (token) {
        if (!needPasswordReset) {
            // токен есть и не нужно менять пароль
            return null

            // первый раз заходит, нужно обновить пароль
        } else {
            return process.env.REACT_CHANGE_PASSWORD
        }

        // токена нет, надо авторизоваться
    } else if (!token) {
        return '/login'
    }


}

const useRedirectForMerchants = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const savedPathname = useSelector((state) => state.auth.login.savedPathname);
	const permissions = useSelector(selectPermissions)
	const { merchant_id, app_id, kep_id = null } = useSelector(state => state.auth.user.userToken);
    const isMerchantPermission = merchant_id || app_id  || kep_id

    const history = useHistory()

	useEffect(() => {
		if (!savedPathname) {
			if(location.pathname.replace('/','') || location.hash){
				return 	dispatch(savePathname(location.pathname + location.hash));
			}
			if(permissions['merchant applications'] && !kep_id){
				return 	dispatch(savePathname('/merchant_applications'));
			}
			if(kep_id){
				return 	dispatch(savePathname('/kep-list'));
			}
		}

	}, [savedPathname, location, dispatch, permissions, kep_id])

	useEffect(() => {
		if (isMerchantPermission && savedPathname) {
			history.push(savedPathname)
		}
	}, [savedPathname])

};

const Private = ({ path, children }) => {
    const location = useLocation()
   
    const { userDataJson } = getUserData()
    const userLeadID = userDataJson ? jwt_decode(userDataJson.token)?.['lead_id'] : null;

    const pathToRedirect = checkUserData()
    const { push } = useHistory()

	useRedirectForMerchants();

    useQuery(['new_lead', +userLeadID], () => leadAPI.checkHashNewLead(userLeadID), {
        enabled: !!userLeadID && !location.pathname.includes(`/new_lead#${userLeadID}`),
        onSuccess: () => {
            const newPath = `/leads#${userLeadID}`;
            push(newPath)
        },

    });

    // если пользователю нужно что-то изменить в токене и он стучится на главную
    if (pathToRedirect) {
        return (
            <Redirect
                to={{
                    pathname: pathToRedirect,
                    state: { from: location },
                }}
            />
        )
    }

    return (
        <LoadingAnimation>
            <Route path={path}>{children}</Route>
        </LoadingAnimation>
    )
}

export default Private
