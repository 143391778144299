export const reportTypes = {
    BUSINESS_SALE: "БИЗНЕС_ПРОДАЖИ",
    BUSINESS_TERMINAL: "БИЗНЕС_ТЕРМИНАЛЫ",
    BUSINESS_PARTNER_TICKET: "БИЗНЕС_ЗАЯВКИ_ПАРТНЕРА",
    FINANCE_OPERATION: "ФИНАНСЫ_ОПЕРАЦИИ",
    FINANCE_PARTNER_COMPENSATION: "ФИНАНСЫ_ВОЗНАГРАЖДЕНИЯ_ПАРТНЕРА",
    TECH_ACTIVE_TERMINALS: "ТЕХНИЧЕСКИЙ_АКТИВНЫЕ_ТЕРМИНАЛЫ",
    TECH_ADDRESS_TERMINALS: "ТЕХНИЧЕСКИЙ_АДРЕС_УСТАНОВКИ",
    KUB_TOTAL_REPORT: "КРЕДИТ_УРАЛ_БАНК_ОБЩИЙ",
	PARTNER_FINANCE: "ФИНАНСОВЫЙ_ОТЧЕТ_ПАРТНЕРА",
	COMMON_TRANSACTION_REPORT: "ОТЧЕТ_ПО_ТРАНЗАКЦИЯМ",
}

export const reportActiveTypes = [
    { value: true, label: "Активные" },
    { value: false, label: "Не активные" },
]

export const reportFinanceTypes = [
    { value: true, label: "Финансовые" },
    { value: false, label: "Все" },
]

export const reportVendingTypes = [
    { value: true, label: "Вендинг" },
    { value: false, label: "Ритейл" },
]

export const formatTypes = [
    { value: "PDF", label: "PDF" },
    { value: "EXCEL", label: "EXCEL" },
    { value: "CSV", label: "CSV" },
]