import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"
import moment from "moment"
import transactions from "../../api/statistics/transactions"
import dictsAPI from "../../api/v2/dictsAPI"
import reportsAPI from "../../api/v2/reportsAPI"
import transactionsAPI from "../../api/v2/transactionsAPI"
import customHistory from "../../history"
import { reportTypes } from "../shares/dicts/reports"
import openNotificationWithIcon from "../shares/openNotificationWithIcon"
import {calculateClientTimeForFilter} from "../shares/helpers/calculateClientTimeForFilter"
import { changeFalsyValuesToNull } from "../shares/helpers/changeFalsyValues"

export const fetchTransactions = createAsyncThunk(
    "transactions/list",
    async (searchData, thunkAPI) => {
        try {
            const rangeDateTime = searchData.rangeDateTime?.map((el) =>
				 el.format()
            )
            const response = await transactionsAPI.getDataByAdvancedSearch({
                ...changeFalsyValuesToNull(searchData),
                rangeDateTime,
            })
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const requestCreateReport = createAsyncThunk(
    "transactions/create-report",
    async (data, thunkAPI) => {
        try {
			const reportType = "COMMON_TRANSACTION_REPORT"
		
            const response = await reportsAPI.createOne({
				...data, 
				reportType,
				name:`${reportTypes[reportType]}`,
				rangeDateTime:[moment(data.rangeDateTime[0], "YYYY-MM-DD HH:mm Z").utc().format(),moment(data.rangeDateTime[1], "YYYY-MM-DD HH:mm Z").utc().format() ],
				//rangeDateTime:[dayjs.utc(data.rangeDateTime[0]).format('YYYY-MM-DDTHH:mm:ss[Z]'),dayjs.utc(data.rangeDateTime[1]).format('YYYY-MM-DDTHH:mm:ss[Z]') ],

            })
			if (response.errorType === null) {
				openNotificationWithIcon({
					type: "success",
					message: `Запрос на создание очета отправлен`,
					duration:1,
				})
				setTimeout(()=>{
					customHistory.push("/reports")
				},1000)
			} else {
			
				openNotificationWithIcon({
					type: "error",
					message: `Произошла ошибка`,
					duration:2,
				})
			}
		
            return response
        } catch (error) {
			return thunkAPI.rejectWithValue(error)
		}
    }
)

export const getMerchantsOptions = createAsyncThunk(
    "transactions/get-merchants",
    async (data) => {
        const response = await dictsAPI.getMerchantsDict(data)
        return response
    }
)
export const getTerminalsOptions = createAsyncThunk(
    "transactions/get-terminals",
    async (data) => {
        const response = await dictsAPI.getTerminalsDict(data)
        return response
    }
)
export const getTerminalsGroupOptions = createAsyncThunk(
    "transactions/get-terminals-group",
    async () => {
        const response = await dictsAPI.getTerminalsGroupDict()
        return response
    }
)
export const getPartnerssOptions = createAsyncThunk(
    "transactions/get-partners",
    async () => {
        const response = await dictsAPI.getPartners()
        return response
    }
)
export const fetchGetReceiptPdf = createAsyncThunk(
    "transactions/download-receipt",
    async (transactionId) => {
        await transactions.downloadCheck({ transactionId })
    }
)

export const fetchInitReverseTransaction = createAsyncThunk(
    "transactions/reverse-init",
    async ({ payload, index, cancelType }, { dispatch, getState, rejectWithValue}) => {
        try {
            let response = await transactionsAPI.cancelTransaction(payload)
			response = { ...response, cancelType }
            if(response && response.errorType) {
                throw response;
            }
            if (response) {
                const advancedSearchData = getState().transactions
                    .advancedSearchData
                dispatch(fetchTransactions(advancedSearchData))
            }
            return { response, index }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchConfirmReverseTransaction = createAsyncThunk(
    "transactions/reverse-confirm",
    async ({ payload, cancelType }, { dispatch, getState, rejectWithValue }) => {
        try {
            let response = await transactionsAPI.confirmCancelTransaction(payload)
			response = { ...response, cancelType }
            // wrong confirmation code
            if(response && response.errorType) {
                throw response
            }
            // success
            if (response) {
                const advancedSearchData = getState().transactions.advancedSearchData
                // update rangeDateTime to show cancelled transaction
                const updatedAdvancedSearchData = {
                    ...advancedSearchData,
                    rangeDateTime: [
                        advancedSearchData.rangeDateTime[0], // from selected time
                        moment().endOf('day') // конец дня
                    ]
                }
                dispatch(setTransactionsLoading(true))
                setTimeout(() => {
                    dispatch(setTransactionsLoading(false))
                    dispatch(advancedSearchTransactionsData(updatedAdvancedSearchData))
                    dispatch(fetchTransactions({
						...updatedAdvancedSearchData, 
						...(response?.id ? {transactionId: response.id } : {})
					}))
                }, 1000)
            }
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const fetchAbortReverseTransaction = createAsyncThunk(
    "transactions/reverse-abort",
    async ({payload, cancelType }, { dispatch, getState, rejectWithValue }) => {
        try {
            let response = await transactionsAPI.denyCancelTransaction(payload)
			response = { ...response, cancelType }
            // wrong cancel code
            if(response && response?.errorType) {
                throw response
            }
            if (response) {
                const advancedSearchData = getState().transactions
                    .advancedSearchData
                dispatch(fetchTransactions(advancedSearchData))
            }
            return response
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const initialState = {
    listData: [],
    searchDataString: "",
    selectedPreset: "",
    advancedSearchData: {
        rangeDateTime: calculateClientTimeForFilter('THIS_DAY',true),
        details: true,
        RRN: "",
        terminal: "",
        client: "",
        formatType: "EXCEL",
    },
    downloadReceipt: {
        loading: false,
    },
    reversalTransaction: {
        loading: false,
        invalidCode: false,
        status: "",
    },
    options: {
        merchants: [],
        terminals: [],
        terminalsGroup: [],
        partners: [],

		merchantsSuccess: false,
		terminalsSuccess: false,
    },
    loading: false,
    error: null,
}

const transactionsSlice = createSlice({
    name: "transactions",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.selectedColumns = initialState.selectedColumns
        },

        advancedSearchTransactionsData: (state, { payload }) => {
            state.advancedSearchData = {...state.advancedSearchData, ...payload}
        },

        setTransactionReverseStatus: (state, { payload }) => {
            state.reversalTransaction.status = payload
        },

        setTransactionsLoading: (state, { payload }) => {
            state.loading = payload
        },

        refreshInvalidCode: (state) => {
            state.reversalTransaction.invalidCode = false
        },

        clearTransactionsData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key]
                }
            }
        },
    },
    extraReducers: {
        [fetchTransactions.pending]: (state) => {
            state.error = initialState.error
            state.loading = true
        },
        [fetchTransactions.fulfilled]: (state, { payload }) => {
            if (payload.errorType) {
                state.error = payload.errorType
                state.listData = initialState.listData
            } else {
                state.listData = payload?.transactions?.map((transaction) => {
                    const formatedTransaction = Object.entries(transaction).reduce((acc, [key, value]) => {
                        acc[key] = value ?? "-"
                        return acc
                    },{})
                    return { ...formatedTransaction, key: nanoid() }
                })
            }
            state.loading = false
        },
        [fetchTransactions.rejected]: (state, { payload }) => {
            state.error = payload?.errorType
            state.listData = initialState.listData
            state.loading = false
        },
        [fetchGetReceiptPdf.pending]: (state) => {
            state.downloadReceipt.loading = true
        },
        [fetchGetReceiptPdf.fulfilled]: (state) => {
            state.downloadReceipt.loading = false
        },
        [fetchGetReceiptPdf.rejected]: (state) => {
            state.downloadReceipt.loading = false
        },
        [fetchInitReverseTransaction.pending]: (state) => {
            state.reversalTransaction.loading = true
        },
        [fetchInitReverseTransaction.fulfilled]: (state, { payload }) => {
            if (payload.response) {
                state.listData[payload.index].cancelRequested = true
            }
            state.reversalTransaction.loading = false
        },
        [fetchInitReverseTransaction.rejected]: (state, { payload }) => {
			const { cancelType = 'Отмена'} = payload

            state.reversalTransaction.loading = false;
            if(payload?.errorType === 'USER_NOT_FOUND') {
                openNotificationWithIcon({
                    type: 'error',
                    message: `Не найден пользователь с правом на подтверждение ${cancelType === 'Возврат' ? 'возврата' : 'отмены'} транзакции.`
                })
                return;
            }
            openNotificationWithIcon({
                type: 'error',
                message: 'Что-то пошло не так, попробуйте позже!'
            })
        },
        [fetchConfirmReverseTransaction.pending]: (state) => {
            state.reversalTransaction.loading = true
        },
        [fetchConfirmReverseTransaction.fulfilled]: (state, { payload }) => {
			const { cancelType = 'Отмена' } = payload
			
            state.reversalTransaction.loading = false
            state.reversalTransaction.invalidCode = false
            openNotificationWithIcon({
                type: "success",
                message: `Транзакция ${cancelType === 'Возврат' ? 'возвращена' : 'отменена'}!`,
            })
        },
        [fetchConfirmReverseTransaction.rejected]: (state, { payload }) => {
			const { cancelType = 'Отмена' } = payload

			state.reversalTransaction.loading = false
            state.reversalTransaction.status = payload?.errorType
            if(payload?.errorType) {
                switch(payload?.errorType) {
                    case 'OTP_NOT_VALID':
                        state.reversalTransaction.invalidCode = true;
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Код неверный!'
                        });
                        break;
                    case 'TOO_MUCH_REQUESTS':
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Лимит транзакций превышен!'
                        });
                        break;
                    case 'USER_NOT_FOUND':
                        openNotificationWithIcon({
                            type: 'error',
                            message: `Не найден пользователь с правом на подтверждение ${cancelType === 'Возврат' ? 'возврата' : 'отмены'} транзакции.`
                        });
                        break;
                    default:
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Что-то пошло не так, попробуйте позже!'
                        });
                }
            }
        },
        [fetchAbortReverseTransaction.pending]: (state) => {
            state.reversalTransaction.loading = true
        },
        [fetchAbortReverseTransaction.fulfilled]: (state, { payload }) => {
			const { cancelType = 'Отмена' } = payload
			const isReturnType = cancelType === 'Возврат'

            state.reversalTransaction.loading = false
            state.reversalTransaction.invalidCode = false;
            openNotificationWithIcon({
                type: 'success',
                message: `${isReturnType ? 'Возврат' : 'Отмена'} транзакции  успешно отклонен${isReturnType ? '' : 'a'}!`
            })
        },
        [fetchAbortReverseTransaction.rejected]: (state, { payload }) => {
			const { cancelType = 'Отмена' } = payload

            state.reversalTransaction.loading = false
            state.reversalTransaction.status = payload?.errorType
            if(payload?.errorType) {
                switch(payload?.errorType) {
                    case 'OTP_NOT_VALID':
                        state.reversalTransaction.invalidCode = true;
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Код неверный!'
                        });
                        break;
                    case 'TOO_MUCH_REQUESTS':
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Лимит транзакций превышен!'
                        });
                        break;
                    case 'USER_NOT_FOUND':
                        openNotificationWithIcon({
                            type: 'error',
                            message: `Не найден пользователь с правом на подтверждение ${cancelType === 'Возврат' ? 'возврата' : 'отмены'} транзакции.`
                        });
                        break;
                    default:
                        openNotificationWithIcon({
                            type: 'error',
                            message: 'Что-то пошло не так, попробуйте позже!'
                        });
                }
            }
        },
		[getMerchantsOptions.pending]: (state, { payload }) => {
			state.options.merchantsSuccess = false
        },
		[getMerchantsOptions.pending]: (state, { payload }) => {
			state.options.merchantsSuccess = false
        },
        [getMerchantsOptions.fulfilled]: (state, { payload }) => {
            state.options.merchants = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.merchantsSuccess = true
        }, 
        [getTerminalsOptions.pending]: (state, { payload }) => {          
			state.options.terminalsSuccess = false
        },
        [getTerminalsOptions.fulfilled]: (state, { payload }) => {
            state.options.terminals = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.terminalsSuccess = true
        },
        [getTerminalsGroupOptions.fulfilled]: (state, { payload }) => {
			 state.options.terminalsGroup = Object.entries(payload.data).map((el) => ({
                value: el[0],
                label: el[1],
            }))
        },
        [getPartnerssOptions.fulfilled]: (state, { payload }) => {
            state.options.partners = Object.entries(payload).map((el) => ({
                value: el[0],
                label: el[1],
            }))
        },
    },
})

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    advancedSearchTransactionsData,
    setTransactionReverseStatus,
    refreshInvalidCode,
    clearTransactionsData,
    setTransactionsLoading
} = transactionsSlice.actions
export default transactionsSlice.reducer
