import { call, takeLatest, put } from 'redux-saga/effects';
import API from '../../../api/auth/auth'; 
import * as actions from './actions'   

 
export default [     
	takeLatest(actions.requestGetSettings, requestGetSettings),   
	takeLatest(actions.requestGetBankSettings, requestGetBankSettings),   
	takeLatest(actions.requestUpdOneBankSettings, requestUpdOneBankSettings),   
	takeLatest(actions.requestUpdateSettings, requestUpdateSettings),   
	takeLatest(actions.requestGetAllRoles, requestGetAllRoles),   
]
  
// 
function* requestGetSettings(action) {
	try {   
		const data = yield call(API.getSettings, action.payload);  
		yield put(actions.finalGetSettings(data));   
	} catch (error) { 
		yield put(actions.finalGetSettings({}));  
	} 
} 
  
// 
function* requestGetBankSettings(action) {
	try {   
		const data = yield call(API.getBankSettings, action.payload);  
		yield put(actions.finalGetBankSettings(data));   
	} catch (error) { 
		yield put(actions.finalGetBankSettings({}));  
	} 
} 
  
// 
function* requestUpdOneBankSettings(action) {
	try {   
		const data = yield call(API.updOneBankSettings, action.payload.request);  
		action.payload.callback(true)
		yield put(actions.finalUpdOneBankSettings(data));   
	} catch (error) { 
		action.payload.callback(false)
		yield put(actions.finalUpdOneBankSettings({}));  
	} 
}
  
//  
function* requestUpdateSettings(action) {
	try {   
		const data = yield call(API.updateSettings, action.payload.request);  
		action.payload.callback(true)
		yield put(actions.finalUpdateSettings(data));   
	} catch (error) { 
		action.payload.callback(false)
		yield put(actions.finalUpdateSettings({}));  
	} 
} 

// 
function* requestGetAllRoles() {
	try {   
		const data = yield call(API.getAllRoles);  
		// action.payload.callback(data)
		yield put(actions.finalrequestGetAllRoles(data));   
	} catch (error) { 
		// action.payload.callback([])
		yield put(actions.finalrequestGetAllRoles([]));  
	} 
}