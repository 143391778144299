import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UserSwitchOutlined } from '@ant-design/icons'
import { Button, Form, Row, Tooltip } from 'antd'
import NumberInput from '../shares/inputs/NumberInput'
import { requestNewAuthToken, logoutFromNewUser } from './loginAsSlice'
import OtpModalForm from './OtpModalForm'
import openNotificationWithIcon from '../shares/openNotificationWithIcon'
import { unwrapResult } from '@reduxjs/toolkit'
import { gettext } from 'ttag'
import { selectPermissions } from '../shares/helpers/commonReduxSelectors'

const LoginAs = () => {
    const [show, setShow] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const permissions = useSelector(selectPermissions)
    const hasPermission = permissions['users_changeLogin']

    const hasOriginalAdminId = useSelector(
        (state) => state.auth.user.userToken.originalAdminId
    )
    const { loading } = useSelector((state) => state.loginAs)

    const handleUnloginAs = () => {
        dispatch(logoutFromNewUser())
    }

    const onFinish = (values) => {
        if (values) {
            dispatch(requestNewAuthToken(values))
                .then(unwrapResult)
                .then(() => {
                    setShow(true)
                })
                .catch((err) => {
                    openNotificationWithIcon({
                        type: 'error',
                        message: `При попытке входа на портал под другим пользователем произошла ошибка!`,
                        description: `${gettext(err.errorType)}`,
                    })
                })
        }
    }

    return hasPermission ? (
        <>
            <div style={{ width: 222 }}>
                {hasOriginalAdminId ? (
                    <Button
                        type="primary"
                        icon={<UserSwitchOutlined />}
                        block
                        danger
                        onClick={handleUnloginAs}
                        loading={loading}
                    >
                        Вернуться к себе
                    </Button>
                ) : (
                    <Form form={form} onFinish={onFinish}>
                        <Row wrap={false}>
                            <Form.Item noStyle name="toUserId">
                                <NumberInput
                                    className="rounded-r-none border-r-0"
                                    allowClear
                                    placeholder={gettext('Partner ID')}
                                />
                            </Form.Item>
                            <Form.Item dependencies={['toUserId']} noStyle>
                                {() => (
                                    <Tooltip title="Войти, как партнер.">
                                        <Button
                                            style={{
                                                borderBottomLeftRadius: 0,
                                                borderTopLeftRadius: 0,
                                            }}
                                            htmlType="submit"
                                            type="primary"
                                            ghost
                                            icon={<UserSwitchOutlined />}
                                            loading={loading}
                                            disabled={
                                                !form.getFieldValue('toUserId')
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </Form.Item>
                        </Row>
                    </Form>
                )}
            </div>
            <OtpModalForm
                show={show}
                setShow={setShow}
                toUserId={form.getFieldValue('toUserId')}
            />
        </>
    ) : null
}

export default LoginAs
