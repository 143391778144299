import axios from 'axios'

export default {  
	getData: (data) => axios.post(`/waiters`, data).then(r => r.data),  
	create: (data) => axios.post(`/waiters/create`, data).then(r => r.data),   
	update: (data) => axios.post(`/waiters/update`, data).then(r => r.data),  
	getOne: (data) => axios.get(`/waiter/${data.id}`).then(r => r.data),  
	// cards
	getCreditCards: (data) => axios.post(`/waiters/cards`, data).then(r => r.data),   
	addCreditCards: (data) => axios.post(`/waiters/card/create`, data).then(r => r.data),   
	delCreditCard: (data) => axios.post(`/waiters/card/delete`, data).then(r => r.data),   
}