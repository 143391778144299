import moment from 'moment'

const isDate = (arrayDateKey = [], key = '') => {
    return arrayDateKey.includes(key)
}

/**
 * Функция форматирования объекта для отображения в компоненту
 *
 * @param {Object} payload - Основной объект который надо изменить
 * @param {String[]} dateFieldNameList - Список имен ключей полей объекта значения которых надо отобразить, как дата
 * @param {String[]} dateWithTimeFieldNameList - Список имен ключей полей объекта значения которых надо отобразить, как дата со временем
 */

const formatingObjectToView = (payload = {}, dateFieldNameList = [], dateWithTimeFieldNameList = []) => {
    // подготавливаем данные для отображения
    const formatedPayload = {}
    for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
            // если значение нулл или пустая строка заменяем его на прочерк
            if (payload[key] === null || payload[key] === '') {
                formatedPayload[key] = "-"
                continue
            }
            //если значение - дата форматируем его моментом
            if (isDate(dateFieldNameList, key)) {
                formatedPayload[key] = moment(payload[key]).format("ll")
                continue
            }
            //если значение - дата со временем форматируем его моментом
            if (isDate(dateWithTimeFieldNameList, key)) {
                formatedPayload[key] = moment(payload[key]).format("lll")
                continue
            }
            formatedPayload[key] = payload[key]
        }
    }
    return formatedPayload
}

export default formatingObjectToView