import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customersAPI from '../../api/v2/customersAPI';
// import isVoidObject from '../shares/helpers/isVoidObject'
import generalAPI from '../../api/v2/generalAPI';
import { setCurrentCustomer } from '../pos-create/posCreateSlice';

export const fetchCreateCustomer = createAsyncThunk(
    "customer-create/fetch-create",
    async (payload, thunkAPI) => {
        try {
            const { executive, contactPerson, businessAddress, address, accountUser } = thunkAPI.getState().customerCreate
            const requestData = {
                ...payload,
                executive,
                contactPerson,
                businessAddress,
                address,
                accountUser,
            }
            const response = await customersAPI.createOne(requestData)
            await thunkAPI.dispatch(updateReportingPeriods(response.id))
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const updateReportingPeriods = createAsyncThunk(
    "customer-create/merchantReportConfigs/put",
    async (id, thunkAPI) => {
        const { merchantReportConfigs } = thunkAPI.getState().customerCreate
        const response = await customersAPI.updateReportingPeriods({id, payload: {merchantReportConfigs}})
        return response
    }
)

export const fetchCompanyDataFromINN = createAsyncThunk(
    "customer-create/tin/fetch",
    async (TIN, { rejectWithValue }) => {
        try {
            const response = await generalAPI.getCompanyDataFromTIN(TIN)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const setCurrentCustomerForPosCreate = createAsyncThunk(
    "customer-create/for-pos-create",
    async (customerId, thunkAPI) => {
        thunkAPI.dispatch(setCurrentCustomer(customerId))
    }
)

const initialState = {
    withNextStep: false,
    resolveTIN: {
        data: {},
        loading: false,
        error: null,
    },
    executive: {},
    contactPerson: {},
    businessAddress: {},
    address: {},
    accountUser: {},
    merchantReportConfigs: [],
    loading: false,
    saveDataError: null,
    spb: false,
    vppSbpAgent : null,
}

const customerCreateSlice = createSlice({
    name: "customer-create",
    initialState,
    reducers: {
        setCountryFromToken: (state, { payload }) => {
            state.businessAddress = { ...state.businessAddress, country: payload }
            state.address = { ...state.address, country: payload }
        },
        addDirectorData: (state, { payload }) => {
            state.executive = payload
        },
        addContactPersonData: (state, { payload }) => {
            state.contactPerson = payload
        },
        addLegalAddress: (state, { payload }) => {
            state.businessAddress = payload
        },
        addActualAddress: (state, { payload }) => {
            state.address = payload
        },
        setSbpCustomerCreate: (state, { payload }) => {
            state.spb = payload
        },
        setSbpAgentCustomerCreate: (state, { payload }) => {
            state.vppSbpAgent = payload
        },
        addReportsPeriods: (state, { payload }) => {
            state.merchantReportConfigs = payload.merchantReportConfigs
        },
        addAccountUser: (state, { payload }) => {
            state.accountUser = payload
        },
        setWithNextStep: (state) => {
            state.withNextStep = true
        },
        clearTinError: (state) => {
            state.resolveTIN.error = initialState.resolveTIN.error
        },
        clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        [fetchCreateCustomer.pending]: (state) => {
            state.saveDataError = initialState.saveDataError
            state.loading = true
        },
        [fetchCreateCustomer.fulfilled]: (state, { payload }) => {
            //state.loading = false
            state.withNextStep = false
        },
        [fetchCreateCustomer.rejected]: (state, { payload }) => {
            state.saveDataError = payload?.errorType || "UNKNOWN_ERROR"
            state.loading = false
            state.withNextStep = false
        },
        [updateReportingPeriods.pending]: (state) => {
            state.loading = true
        },
        [updateReportingPeriods.fulfilled]: (state) => {
            state.loading = false
        },
        [updateReportingPeriods.rejected]: (state) => {
            state.loading = false
        },
        [fetchCompanyDataFromINN.pending]: (state) => {
            state.resolveTIN.loading = true
        },
        [fetchCompanyDataFromINN.fulfilled]: (state, { payload }) => {
            state.businessAddress = payload?.businessAddress || {}
            state.executive = payload?.executive || {}
            state.resolveTIN.loading = false
        },
        [fetchCompanyDataFromINN.rejected]: (state, { payload }) => {
            state.resolveTIN.error = payload?.errorType || "UNKNOWN_ERROR"
            state.resolveTIN.loading = false
        },
    }
});

export const {
    setCountryFromToken,
    addDirectorData,
    addContactPersonData,
    addLegalAddress,
    addActualAddress,
    addReportsPeriods,
    addAccountUser,
    setWithNextStep,
    clearTinError,
    clearData,
	setSbpCustomerCreate,
	setSbpAgentCustomerCreate,
} = customerCreateSlice.actions
export default customerCreateSlice.reducer