export const getDocumentTypeOptions = (allDocs = [], forbiddenDel = false) => {
    
        return documentTypeOptions.map(item => ({value: item.value, label: item.label}))


    // const documentTypesToDelete = allDocs.reduce((docTypesArr, item) => {
    //     if (item.documentType !== 'OTHER' && item.forbiddenDel) {
    //         return [...docTypesArr, item.documentType]
    //     }
    //     return docTypesArr
    // }, [])

    // const documentTypeOptionsWithoutExistTypes = documentTypeOptions.filter(type => {
    //     return !documentTypesToDelete.some(item => item === type.value)
    // })
    
    
    // return documentTypeOptionsWithoutExistTypes.map(item => ({value: item.value, label: item.label}))
}
export const getExtraDocumentTypeOptions = (allDocs = []) => {  
	return documentTypeOptions.reduce((prev, curr) => {
		if(allDocs.some(el => el.documentType.includes(curr.value) && el.documentType !== 'OTHER')){
			return prev
		}
		return [...prev, {value: curr.value, label: curr.label}]
	},[])
}

export const getKktDocumentTypeOptions = (allDocs = [], forbiddenDel = false) => {
    if (forbiddenDel) {
        return kktDocumentTypeOptions.map(item => ({value: item.value, label: item.label}))
    }

    const documentTypesToDelete = allDocs.reduce((docTypesArr, item) => {
        if (item.documentType !== 'OTHER' && item.forbiddenDel) {
            return [...docTypesArr, item.documentType]
        }
        return docTypesArr
    }, [])

    const documentTypeOptionsWithoutExistTypes = kktDocumentTypeOptions.filter(type => {
        return !documentTypesToDelete.some(item => item === type.value)
    })
    
    return documentTypeOptionsWithoutExistTypes.map(item => ({value: item.value, label: item.label}))
}


export const getExemplarOptions = (documentType) => {
    const docType = documentTypeOptions.find(({ value }) => value === documentType)
    return docType ? docType.exemplarType.reduce((options, item) => {
        return [...options, exemplarType.find(({ value }) => value === item)]
    }, []) :
    null
}

export const checkFileTypeForDisableByBank = (documentType, bankBeanName) => {
    const bankFileTypes = fileTypeForDisable.find(({ bank }) => bank === bankBeanName)
    return bankFileTypes ? bankFileTypes.fileTypes.includes(documentType) : false
}

export const getPositionsByDocumentType = (documentType) => {
    if (documentType === 'PDN_BENEFICIARY') {
        return positions.filter(item => item.value === 'BENEFICIARY')
    } else {
        return positions
    }
}

export const documentTypeOptions = [
    { value: 'OGRN', label: 'ОГРН', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'PASSPORT', label: 'Паспорт', exemplarType: ['ALL_PAGES', 'PASSPORT_HISTORY_SPREAD', 'MAIN_SPREAD_AND_REGISTRATION', 'MAIN_SPREAD_PAGE', 'ELECTRONIC_DOCUMENT', 'REGISTRATION_PAGE'] },
    { value: 'INN', label: 'ИНН', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'USTAV', label: 'Устав', exemplarType: ['ALL_PAGES', 'FIRST_PAGE', 'SECOND_PAGE', 'CEO_OFFICE_TERM', 'TAX_STAMP', 'TITLE_PAGE'] },
    { value: 'LICENSE', label: 'Лицензия', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'FOUNDERS_DECISION_PROTOCOL', label: 'Протокол,Решение собр-я учред', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'JOB_ORDER', label: 'Приказ на должность', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'POWER_OF_ATTORNEY_SIGNING', label: 'Доверенность на подписание', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'CUSTOMER_PHOTO', label: 'Фотография клиента', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'JOINING_APPLICATION', label: 'Заявка о присоединении', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'OTHER', label: 'Другое', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'LEASE_CONTRACT', label: 'Договор аренды', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'OFFICE', label: 'Офис', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'MIGRATION_CARD_VISA', label: 'Миграционная карта или виза', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'SNILS', label: 'СНИЛС', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'FINANCIAL_STATEMENTS', label: 'Годовая бух.отчетность', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'USTAV_CHANGE_LIST', label: 'Лист изменений к уставу', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'EGRUL_CHANGE_LIST', label: 'Лист записи ЕГРЮЛ (или ГРН)', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'ACCOUNT_PROFILE_DATA', label: 'Анкета клиента ЮЛ или ИП', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'OFFER', label: 'Оферта', exemplarType: ['ALL_PAGES', 'PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'ANKETA', label: 'Анкета', exemplarType: ['ALL_PAGES', 'PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'REQUEST', label: 'Заявка', exemplarType: ['ALL_PAGES'] },
    { value: 'PDN_DIR', label: 'Персональные данные директора', exemplarType: ['ALL_PAGES'] },
    { value: 'PDN_ACC', label: 'Персональные данные бухгалтера', exemplarType: ['ALL_PAGES'] },
    { value: 'DECLARATION', label: 'Заявление', exemplarType: ['ALL_PAGES'] },
    { value: 'REPORT', label: 'Отчет', exemplarType: ['ALL_PAGES'] },
    { value: 'PASSPORT_ACC', label: 'Паспорт бухгалтера', exemplarType: ['ALL_PAGES'] },
    { value: 'PHOTO_INSIDE', label: 'Фото внутри', exemplarType: ['PHOTO'] },
    { value: 'PHOTO_OUTSIDE', label: 'Фото фасада', exemplarType: ['PHOTO'] },
    { value: 'EGRUL_STATEMENT', label: 'Отчетность из ЕГРЮЛ', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'GUARANTEE_LETTER', label: 'Гарантийное письмо', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'SHAREHOLDERS_EXCERPT', label: 'Выписка из реестра акционеров', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT', 'EDS', 'ELECTRONIC_WITH_EDS'] },
    { value: 'BO_ACCEPT', label: 'Подтверждение бенефициарных владельцев', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT', 'ALL_PAGES', 'EDS'] },
    { value: 'FULL_PD_BO', label: 'Список паспортных данных участников или бенефициарных владельцев', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'ACT_CONFIRMATION_STRUCTURE_KOU', label: 'Акт подтверждающий состав КОУ', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT', 'ALL_PAGES'] },
    { value: 'CONFIRMATION_HMB_COMPOSITION', label: 'Подтв. акт. сост. ВОУ', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'APPOINTMENT_NOTARY', label: 'Назначение нотариуса', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT'] },
    { value: 'TAX_RETURN', label: 'Налоговая декларация', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT', 'ALL_PAGES', 'EDS', 'ELECTRONIC_WITH_EDS'] },
    { value: 'ARBITRATION_CERTIFICATE', label: 'Свидетельство арбитражных упр.', exemplarType: ['ELECTRONIC_DOCUMENT'] },
    { value: 'NOTICE_OF_ESTABLISHMENT_LAWYER_OFFICE', label: 'Уведом/св-во об учреждении адвокатского кабинета', exemplarType: ['PHOTO', 'ELECTRONIC_DOCUMENT', 'ALL_PAGES'] },
    { value: 'LAWYER_CERTIFICATE', label: 'Удостоверение адвоката', exemplarType: ['ALL_PAGES'] },
    { value: 'PDN_SIGNER', label: 'Персональные данные подписанта', exemplarType: ['ALL_PAGES'] },
    { value: 'BENEFICIARIES_INDIVIDUAL', label: 'Бенефициары физ лица', exemplarType: ['ALL_PAGES'] },
    { value: 'DIRECTOR', label: 'Документ директора', exemplarType: ['ALL_PAGES'] },
    { value: 'SIGNER', label: 'Документ подписанта', exemplarType: ['ALL_PAGES'] },
    { value: 'CHANGE_POINT_NAME', label: 'Заявление на смену названия торговой точки', exemplarType: ['ALL_PAGES'] },
    { value: 'CHANGE_POINT_ADDRESS', label: 'Заявление на смену адреса торговой точки', exemplarType: ['ALL_PAGES','PHOTO'] },
    { value: 'CHANGE_MERCHANT_BANK', label: 'Заявление на смену реквизитов', exemplarType: ['ALL_PAGES'] },
    { value: 'PDN_BENEFICIARY', label: 'Персональные данные бенефициара', exemplarType: ['ALL_PAGES'] },
    { value: 'MAILING', label: 'Согласие на получение рекламных и информационных сообщений', exemplarType: ['ALL_PAGES'] },
	
	{ value: 'MARRIAGE_CERTIFICATE', label: 'Свидетельство о заключении/расторжении брака', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'TEMPORARY_REGISTRATION', label: 'Временная регистрация', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'LOCATION_CONFIRMATION', label: 'Подтверждение местонахождения', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'DOCUMENT_FOR_STAY_IN_RF', label: 'Документ для пребывания в РФ', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'INFORMATION', label: 'Информирование', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'BUSINESS_MODEL', label: 'Бизнес-модель', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'ANOTHER_BANK_ACCOUNT_STATEMENT', label: 'Выписка со счета в др. банке', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'PASSPORT_VALIDITY_CERTIFICATE', label: 'Справка о действ. паспорта', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'CHECKS', label: 'Чеки', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'FOREIGN_PUBLIC_OFFICIAL_CERTIFICATE', label: 'Подтверждение ИПДЛ', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'PDN_CONT', label: 'Персональные данные контактного лица', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'CHANGE_ACQUIRING_LIMIT', label: 'Заявление на изменение лимита', exemplarType: ['ELECTRONIC_DOCUMENT','ALL_PAGES'] },
	{ value: 'OFFER_SBP', label: 'Оферта СБП', exemplarType: ['ALL_PAGES'] },
	{ value: 'ADD_AGREEMENT', label: 'Дополнительное соглашение', exemplarType: ['ALL_PAGES'] },

	{ value: 'POWER_OF_ATTORNEY_SIGNING_SIGNER', label: 'Доверенность на подписание для представителя', exemplarType: ['PHOTO','ELECTRONIC_DOCUMENT'] },
	{ value: 'DOCUMENT_FOR_STAY_IN_RF_SIGNER', label: 'Документ для пребывания в РФ для представителя', exemplarType: ['ELECTRONIC_DOCUMENT'] },
	{ value: 'PASSPORT_SIGNER', label: 'Документ, подтверждающий личность представителя (два разворота: с фото и пропиской)', exemplarType: ['ALL_PAGES'] },
	{ value: 'POINT', label: 'Заявление на регистрацию торговой точки', exemplarType: ['ALL_PAGES'] },
]

export const kktDocumentTypeOptions = [
    { value: 'DECLARATION', label: 'Заявление', exemplarType: ['ALL_PAGES'] },
    { value: 'REPORT', label: 'Отчет', exemplarType: ['ALL_PAGES'] },
    { value: 'CARD', label: 'Карточка регистрации ККТ', exemplarType: ['ALL_PAGES'] },
    { value: 'RNM', label: 'Присвоенный номер РНМ', exemplarType: ['ALL_PAGES'] },
    { value: 'OFFER', label: 'Оферта', exemplarType: ['ALL_PAGES'] },
]

export const exemplarType = [
    { value: 'ALL_PAGES', label: 'Все страницы' },
    { value: 'PHOTO', label: 'Фотография' },
    { value: 'TITLE_PAGE', label: 'Титульная страница' },
    { value: 'FIRST_PAGE', label: '1-ая страница' },
    { value: 'SECOND_PAGE', label: '2-ая страница' },
    { value: 'CEO_OFFICE_TERM', label: 'Срок полномочий ген.дира' },
    { value: 'TAX_STAMP', label: 'Печать налоговой' },
    { value: 'MAIN_SPREAD_PAGE', label: 'Основной разворот' },
    { value: 'REGISTRATION_PAGE', label: 'Регистрация' },
    { value: 'PASSPORT_HISTORY_SPREAD', label: 'Ранее выданные паспорта' },
    { value: 'MAIN_SPREAD_AND_REGISTRATION', label: 'Основ. разворот и регистрация' },
    { value: 'ELECTRONIC_DOCUMENT', label: 'Электронный' },
    { value: 'ORIGINAL', label: 'Оригинал' },
    { value: 'ELECTRONIC_WITH_EDS', label: 'Электронный с ЭЦП' },
    { value: 'EDS', label: 'ЭЦП' },
    { value: 'OTHER', label: 'Другое' },
]

export const positions = [
    { value: 'DIRECTOR', label: 'Директор' },
    { value: 'ACCOUNTANT', label: 'Бухгалтер' },
    { value: 'CONTACT', label: 'Контактное лицо' },
    { value: 'FOUNDER', label: 'Основатель' },
    { value: 'BENEFICIARY', label: 'Бенифициар' },
    { value: 'SIGNER', label: 'Подписант' },
]

export const fileTypeForDisable = [
    { bank: 'TINKOFF', fileTypes: ['PASSPORT', 'POWER_OF_ATTORNEY_SIGNING', 'CUSTOMER_PHOTO', 'MIGRATION_CARD_VISA', 'SNILS']},
    { bank: 'PSB', fileTypes: ['PDN_BENEFICIARY']}
]