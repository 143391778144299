/**
 * Проверяет являются ли все значения в объекте пустыми
 *
 * @param {Object} obj - Объект который надо проверить
 */

const isVoidObject = (obj) => {
    return !Object
        .values(obj)
        .filter(value => {
            if (value === "" || value === [] || value === null || value === undefined) {
                return false
            }
            return true
        })
        .length
}

export default isVoidObject