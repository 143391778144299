import React from "react"
import useBanners from "./useBanners"
import Banner from './Banner'


const BannersModal = () => {
    const [banners, unsubscribeBanner] = useBanners()
    
    return (
        <>
            {banners && banners?.map((item, index) => {
                return <Banner key={index} text={item.text} id={item.id} index={index} unsubscribeBanner={unsubscribeBanner}/>
            })}
        </>
    )
}

export default BannersModal