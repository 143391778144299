import {t} from 'ttag';

const createPureOptionItem = (item) => {
	return {value: item.value, label: item.label}
}

export const _periodsSelect = [
	{value: "DAILY", label: t`Ежедневно`},
	{value: "WEEKLY", label: t`Еженедельно`},
	{value: "MONTHLY", label: t`Ежемесячно`},
	{value: "ALL", label: t`Все`, banksAllowed: ['PSB']},
	{value: "NOTGET", label: t`Не получать`},
];

export const getPeriodsOptions = (bankBeanName) => {
	return _periodsSelect.filter(item => !item.banksAllowed || item?.banksAllowed.find(bank => bank === bankBeanName)).map(item => createPureOptionItem(item))
}

export const getPeriodsOptionsNew = ({bankBeanName = '', banks = []}) => {
	if (banks.length) {
		return _periodsSelect.filter(item => !item.banksAllowed || item?.banksAllowed.some(allowedBank => banks.some(bank => bank.beanName === allowedBank))).map(item => createPureOptionItem(item))
	}
	return _periodsSelect.filter(item => !item.banksAllowed || item?.banksAllowed.find(bank => bank === bankBeanName)).map(item => createPureOptionItem(item))
}

export const _requestTypeSelect = [
	{value: "NEWCONTRACT", label: t`Новый контакт`},
	{value: "NOTNEWCONTRACT", label: t`Контакт для существующего клиента`},
	{value: "NEWTCT", label: t`Новая ТСТ`},
	{value: "NEWEQUIPMENT", label: t`Новое оборудование`},
	{value: "CHANGETCT", label: t`Изменение данных ТСТ`},
	{value: "CHANGEEQUIPMENT", label: t`Смена оборудования`},
	{value: "CHANGEDATA", label: t`Изменение данных`},
];


export const _legalTypeClassificatorsSelect = [
	{value: 'IP', label: t`Индивидуальный предприниматель`},
	{value: 'LEGAL', label: t`Юридическое лицо`},
	{value: 'CREDIT', label: t`Кредитная организация`}
];

export const paymentFrequencyOptions = [
	{value: 'DAILY', label:'Ежедневно'},
	{value: 'MONTHLY', label: 'Ежемесячно'},
];

export const _documentType = [ 
	{value: 'MIGRATION_CARD', label: t`Миграционная карта`},
	{value: 'RESIDENCE', label: t`Вид на жительство`},
	{value: 'RESIDENCE_PERMIT', label: t`Разрешение на временное проживание`},
	{value: 'VISA', label: t`Виза`},
	{value: 'OTHER', label: t`Другое`},
];

export const _documentTypeRF = [
	{value: 'PASSPORT', label: t`Паспорт гражданина РФ`}, 
	{value: 'FOREIGN_PASSPORT', label: t`Паспорт иностранного гражданина`}, 
];

export const _legalTypesSelect = [
	{value: 'OOO', label: t`OOO`},
	{value: 'IP', label: t`ИП`},
	{value: 'ZAO', label: t`ЗАО`},
	{value: 'ODO', label: t`ОДО`},
	{value: 'DXO', label: t`ДХО`},
	{value: 'ZXO', label: t`ЗХО`},
	{value: 'OAO', label: t`ОАО`},
	{value: 'PBOUL', label: t`ПБОЮЛ`},
	{value: 'FGBY', label: t`ФГБУ`},
	{value: 'ANO', label: t`АНО`},
	{value: 'NP', label: t`НП`},
	{value: 'YP', label: t`УП`},
	{value: 'PAO', label: t`ПАО`},
	{value: 'AO', label: t`АО`},
	{value: 'NOTARY', label: t`Нотариус`},
	{value: 'GKFH', label: `ГКФХ`},
	{value: 'OTHER', label: t`Другое`}
];

export const _legalTypesSelectMap = _legalTypesSelect.reduce((prev, curr) => {
	prev[curr.value] = curr.label
	return prev
},{})

export const refundTypeSelect = [
	{value: "CONTRACT", label: "по договору"},
	{value: "TST", label: "По каждой торговой точке"},
	{value: "TERMINAL", label: "По каждому терминалу"},

];

export const sexSelect = [
	{value: "MALE", label: t`Мужской`},
	{value: "FEMALE", label: t`Женский`}
];


export const accountantType = [
	{value: "ACCOUNTANT", label: t`Бухгалтер`},
	{value: "MAIN_ACCOUNTANT", label: t`Главный бухгалтер`},
	{value: 'OTHER', label: t`Другое`}
];

export const businessReputation = [
	{value: "GOOD", label: t`Позитивная`},
	{value: "BAD", label: t`Негативная`}
];

export const positionTypesSelect = [
	{value: 'GEN', label: `Генеральный директор`},
	{value: 'IP', label: `Индивидуальный предприниматель`},
	{value: 'DIRECTOR', label: t`Директор`},
	{value: 'OTHER', label: t`Другое`}
];
export const pdlTypesSelect = [
	{value: 'FOREIGN', label: `Иностранные публичные должностные лица`},
	{value: 'FAMOUS', label: `Должностные лица публичных международных организаций, а также лица, занимающие государственные должности РФ`},
	{value: 'FAMILY', label: 'Cупруги или родственники по прямой восходящей и нисходящей линии'},
];
export const positionTypesSelectSigner = [
	{value: 'GEN', label: `Генеральный директор`},
	{value: 'DIRECTOR', label: t`Директор`},
	{value: 'OTHER', label: t`Другое`}
];

export const getPositionTypesOptions = (bank) => {
	return positionTypesSelect.filter(item => !(item.value === 'IP' && bank === 'PSB'))
}

export const foundationTypesSelect = [
	{value: 'CERTIFICATE', label: t`Свидетельство`},
	{value: 'STATUTE', label: t`Устав`},
	{value: 'PROXY', label: t`Доверенность`},
	{value: "OTHER", label: t`Другое`}, 
];
export const foundationTypesSelectVTBNewIP = [
	{value: 'CERTIFICATE', label: t`Свидетельство`},
	{value: 'PROXY', label: t`Доверенность`},
];
export const foundationTypesSelectVTBNewLegal = [
	{value: 'STATUTE', label: t`Устав`},
	{value: 'PROXY', label: t`Доверенность`},
	{value: "ORDER", label: 'Приказ'}, 
];
export const foundationTypesSelectWalletOneLegal = [
	{value: 'PROXY', label: `Доверенность`},
	{value: "PROTOCOL", label: 'Протокол'}, 
	{value: "SOLUTION", label: 'Решение'}, 
	{value: "ORDER", label: 'Приказ'}, 
	{value: "OTHER", label: `Другое`},
];

export const foundationTypesSelectSigner = [
	{value: 'PROXY', label: t`Доверенность`},
	{value: "OTHER", label: t`Другое`}, 
	// {value: 'CERTIFICATE', label: t`Certificate`},  
];

export const foundationTypesSelectSignerPSBLegal = [
	{value: 'PROXY', label: t`Доверенность`},
	{value: "OTHER", label: t`Другое`}, 
	{value: 'STATUTE', label: t`Устав`}, 
];

export const foundationTypesSelectSignerVTBNew = [
	{value: 'PROXY', label: t`Доверенность`},
];

export const countryCitizenship = [
	{value: 'RUSSIA', label: t`Российская Федерация`},
	{value: 'UKRAINE', label: t`Украина`}, 
	{value: 'BELARUS', label: t`Беларусь`}, 
	{value: 'ARMENIA', label: t`Армения`},
	{value: 'KAZAKHSTAN', label: t`Казахстан`},
	{value: 'AZERBAIJAN', label: t`Азербайджан`},
	{value: 'KYRGYZSTAN', label: t`Киргизия`}, 
	{value: 'MOLDOVA', label: t`Молдавия`}, 
	{value: 'TAJIKISTAN', label: t`Таджикистан`}, 
	{value: 'TURKMENISTAN', label: t`Туркмения`}, 
	{value: 'UZBEKISTAN', label: t`Узбекистан`}, 
	{value: "OTHER", label: t`Другое`},  
];

export const directorType = [
	{value: 'PHYSICAL', label: t`Физ лицо`}, 
	{value: "LEGAL", label: t`Юр.лицо`},
	{value: "INDIVIDUAL", label: t`ИП`}, 
];
export const representativeType = [
	{value: 'PHYSICAL', label: t`Физ лицо`}, 
	{value: "LEGAL", label: t`Юр.лицо`},
];
export const representativePositionType = [
	{value: 'GEN', label: `Генеральный директор`},
	{value: 'OTHER', label: t`Другое`},
];

export const governingBodiesPosition = [
	{value: 'COMMON', label: t`Общее собрание участников`},
	{value: 'GENERAL_MEETING_OF_SHAREHOLDERS', label: t`Общее собрание акционеров`},
	{value: 'ADVICE', label: t`Совет директоров (СД)`},
	{value: 'GOVERNING', label: t`Правление`},
	{value: 'CHAIRMAN', label: t`Председатель Правления`},
	{value: 'DIRECTOR', label: t`Генеральный директор`},
	{value: 'OTHER', label: t`Другое`},
];
export const legalAddressOwnerTypes = [
	{value: 'RENT', label: 'Аренда'},
	{value: 'OWN', label: 'Собственность'},
	{value: 'OTHER', label: 'Иное'},
];
export const tstPlacementTypePSB = [
	{value: 'RENT', label: 'Аренда'},
	{value: 'OWN', label: 'Собственность'},
];