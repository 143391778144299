import axios from 'axios' 
import {downloadBlob} from '../apiTools';

export default {   
	sendNewMerch: (data) => axios.post(`/new_app/${data.id}/send`, data).then(r => r.data),  

	checkHashNewMerch: (data) => axios.post(`/new_app/${data}`, data).then(r => r.data) ,   
	deleteFileNewMerch: (data) => axios.post(`/new_app/${data.id}/file/${data.fileId}/del`, data).then(r => r.data), 
	downloadFileNewMerch: (data) => axios.post(`/new_app/${data.id}/file/${data.fileId}`, {}, {responseType: 'blob'})
	.then(r => downloadBlob(r, data.fileName)), 
	downloadPersonalDocNewMerch: (data) => axios.post(`/new_app/${data.hash}/generate_file`, data, {responseType: 'blob'})
	.then(r => downloadBlob(r, data.fileName)), 
	uploadFile: (data) => axios.post(`/new_app/${data.id}/upload${data.appKey ? "/"+data.appKey : ''}`, 
		data.payload).then(r => r.data),
	uploadFile2: (data) => axios.post(`/api/v2/new_app/upload`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),
}
  