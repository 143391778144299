import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dictsAPI from '../../api/v2/dictsAPI'
import usersAPI from '../../api/v2/usersAPI'
import rolesAPI from '../../api/v2/rolesAPI'
import { gettext } from 'ttag'


export const fetchUpdateUser = createAsyncThunk(
    "user-update/user/post",
    async (payload, thunkAPI) => {
        try {
            const response = await usersAPI.update(payload)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getUser = createAsyncThunk(
    "user-update/user/get",
    async (userId) => {
        const response = await usersAPI.getOne(userId)
        return response
    }
)

export const getCustomersOptions = createAsyncThunk(
    "user-update/customersByPartners/get",
    async (partnerId) => {
        if (partnerId) {
            const response = await dictsAPI.getMerchantsForPartner(partnerId)
            return response
        }
        const response = await dictsAPI.getMerchantsDict()
        return response
    }
)

export const getRolesOptions = createAsyncThunk(
    "user-update/roles/get",
    async ({merchantId = null, partnerId = null}) => {
        if (merchantId) {
            const response = await rolesAPI.getMerchantRoles(merchantId)
            return response
        } else if (partnerId) {
            const response = await rolesAPI.getPartnerRoles(partnerId)
            return response
        } else {
            const response = await rolesAPI.getUserRoles()
            return response
        }
    }
)

export const getRolesListOptions = createAsyncThunk(
    "user-update/rolesList/get",
    async () => {
        const response = await rolesAPI.getUserRolesList()
        return response
    }
)

export const getPartnersOptions = createAsyncThunk(
    "user-update/partners/get",
    async () => {
        const response = await dictsAPI.getPartners()
        return response
    }
)

export const getUsersOptions = createAsyncThunk(
    "user-update/users/get",
    async (userRole) => {
        const response = await dictsAPI.getUsers(userRole)
        return response
    }
)

const initialState = {
    options: {
        customers: [],
        roles: [],
        rolesList: [],
        partners: [],
        users: []
    },
    userData: {},
    loading: false
}

const userUpdateSlice = createSlice({
    name: "user-update",
    initialState,
    reducers: {
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
        setUserData: (state, {payload}) => {
            state.userData = payload
        },
    },
    extraReducers: {
        [fetchUpdateUser.pending]: (state) => {
            state.loading = true
        },
        [fetchUpdateUser.fulfilled]: (state) => {
            state.loading = false
        },
        [fetchUpdateUser.rejected]: (state, {payload}) => {
            state.loading = false
        },
        [getCustomersOptions.fulfilled]: (state, { payload }) => {
            state.options.customers = Object.entries(payload).map(el => ({ value: +el[0], label: el[1] }))
        },
        [getCustomersOptions.reject]: (state) => {
            state.options.customers = []
        },
        [getRolesOptions.fulfilled]: (state, { payload }) => {
            state.options.roles = Object.entries(payload).map(el => ({ value: +el[0], label: el[1] }))
        },
        [getRolesOptions.reject]: (state) => {
            state.options.roles = []
        },
        [getRolesListOptions.fulfilled]: (state, { payload }) => {
            state.options.rolesList = Object.entries(payload).map(el => ({ value: el[1], label: gettext(el[1]) }))
        },
        [getRolesListOptions.reject]: (state) => {
            state.options.rolesList = []
        },
        [getPartnersOptions.fulfilled]: (state, { payload }) => {
            state.options.partners = Object.entries(payload).map(el => ({ value: Number(el[0]), label: el[1] }))
        },
        [getPartnersOptions.reject]: (state) => {
            state.options.partners = []
        },
        [getUsersOptions.fulfilled]: (state, { payload }) => {
            state.options.users = Object.entries(payload.data).map(el => ({ value: +el[0], label: el[1] }))
        },
        [getUsersOptions.reject]: (state) => {
            state.options.users = []
        },
        [getUser.pending]: (state) => {
            state.loading = true
        },
        [getUser.fulfilled]: (state, { payload }) => {
			state.userData = payload
            state.loading = false
        },
        [getUser.rejected]: (state) => {
            state.loading = false
        },
    }
});

export const {
    clearFormData,
    setUserData,
} = userUpdateSlice.actions
export default userUpdateSlice.reducer