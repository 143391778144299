import React, { useEffect, lazy, Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import classnames from 'classnames';
import ruRU from 'antd/es/locale/ru_RU'

import Favicon_ATOL from './core/assets/atol-favicon.ico'

import './core/scss/App.scss'
//переопределение переменных в  анта
import './Antd.less'
//для кастомных стилей в зависимости от партнера
import './App.less'
//переменные для динамического переопределения переменных в анте
import './variables.css'

import Private from './core/Private'
// import GuestRouter  from './core/Guest';
import { i18n } from './core/utils/i18n/i18n'
import customHistory from './history'
import DefaultLayout from './features/layout/DefaultLayout'
import useCheckDomainName from './features/shares/helpers/useCheckDomainName';
// import NeedChangePassword from './core/Pages/Login/NeedChangePassword';
const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

// Pages
const Login = lazy(() => import('./core/Pages/Login/Login'))
const RecoveryPass = lazy(() => import('./core/Pages/Login/RecoveryPassword'))
const NewMerch = lazy(() => import('./core/Pages/NewMerch'))
const NewKkt = lazy(() => import('./core/Pages/NewKkt'))
const NewLead_old = lazy(() => import('./core/Pages/NewLead_old')) // !KUB ONLY!
const LeadLinkWrapper = lazy(() => import('./core/Pages/NewLead/LeadLinkWrapper/LeadLinkWrapper.jsx'))
const ChangePassword = lazy(() => import('./core/Pages/Login/ChangePassword'))
const Page404 = lazy(() => import('./core/Pages/Page404/Page404'))
const Page500 = lazy(() => import('./core/Pages/Page500/Page500'))
const Page403 = lazy(() => import('./core/Pages/Page403/Page403'))

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
        },
        mutations: {
            retry: 0
        }
    },
})

const validateMessages = {
    required: 'Пожалуйста заполните поле',
    whitespace: 'Поле не может быть пустым',
    // string: {
    //     len: 'Поле должно содержать ${len} символов',
    //     min: 'Поле не должно содержать меньше ${min} символов',
    //     max: 'Поле не должно быть больше ${max} символов',
    //     range: "'${name}' must be between ${min} and ${max} characters",
    // },
    types: {
        email: 'Не является email',
    },
    pattern: {
        mismatch: "Недопустимые символы",
      }
    // ...
}

const useInitializeApp = () => {
	const { isAtol } = useCheckDomainName();

	useEffect(() => {
		i18n.init()
	}, [])

	useEffect(() => {
		const body = document.body;
		if(isAtol){
			body.classList.add("atol");
			const favicon = document.getElementById('favicon')
            favicon.href = Favicon_ATOL
		} 
	}, [isAtol])

	return { isAtol }
};

const App = () => {

	const  { isAtol } = useInitializeApp();

	return (
        <Suspense fallback={loading()}>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider
                    form={{ validateMessages }}
                    locale={i18n.getLocale() === 'ru' ? ruRU : undefined}
                >
                    <div 
						className={ classnames('w-full h-screen app-background-gradient', {
							'atol-background-gradient': isAtol,
						})}
					>
                        <Router history={customHistory}>
                            <Switch>
                                {/* <GuestRouter exact path="/login" name="Login Page" component={Login} /> */}

                                <Route
                                    exact
                                    path="/new_lead/:id?"
                                    name="New Lead"
                                    component={NewLead_old}
                                />
                                <Route
                                    exact
                                    path="/new_lead/v2/:id?"
                                    name="New Lead"
                                    component={LeadLinkWrapper}
                                />

                                <Route
                                    exact
                                    path="/new_app/v2/:id?"
                                    name="New Lead"
                                    component={LeadLinkWrapper}
                                />
                                <Route
                                    exact
                                    path="/new_app/:id?"
                                    name="New Merch"
                                    component={NewMerch}
                                />
                                <Route
                                    exact
                                    path="/ofd/new_ofd/:id?"
                                    name="New Kkt"
                                    component={NewKkt}
                                />
                                <Route
                                    exact
                                    path="/login"
                                    name="Login Page"
                                    component={Login}
                                />
                                <Route
                                    exact
                                    path="/recoveryPassword"
                                    name="Recovery pass"
                                    component={RecoveryPass}
                                />
                                <Route
                                    exact
                                    path="/changePassword"
                                    name="Recovery pass"
                                    render={() => (
                                        <ChangePassword firstLogin={true} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/reset_password/:id?"
                                    name="Recovery pass"
                                    component={ChangePassword}
                                />
                                <Route
                                    exact
                                    path="/403"
                                    name="Page 403"
                                    component={Page403}
                                />
                                <Route
                                    exact
                                    path="/404"
                                    name="Page 404"
                                    component={Page404}
                                />
                                <Route
                                    exact
                                    path="/500"
                                    name="Page 500"
                                    component={Page500}
                                />
                                <Private path="/" name="Home">
                                    <Suspense fallback={loading()}>
                                        <DefaultLayout />
                                    </Suspense>
                                </Private>
                            </Switch>
                        </Router>
                    </div>
                </ConfigProvider>
            </QueryClientProvider>
        </Suspense>
    )
}

export default App
