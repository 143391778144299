import axios from 'axios'
import { downloadBlob, optionsForAxiosBlob } from '../apiTools';


export const leadAPI = {
	sendNewLead: (data) => axios.post(`/api/v2/new_lead/send`, data).then(r => r.data),
	downloadPersonalDocNewMerch: (data) => axios.post(`/new_lead/${data.hash}/generate_file`, data, {responseType: 'blob'}).then(resp => downloadBlob(resp, data.fileName)),
	getTokenByHash: ({ appType, hash }) => axios.get(`/api/v2/${appType}/${hash}/getToken`).then((r) => r.data),
	checkHashNewLead: ({commonId,superLeadId}) => axios.get(`/api/v2/new_lead${superLeadId? `?id=${superLeadId}`:''}`).then(r => r.data),
	deleteFileNewLead: (data) => axios.post(`/api/v2/new_lead/deleteFile`, data).then(r => r.data),
	downloadFileNewLead: (data) => axios.get(`/api/v2/new_lead/file/${data.id}`, { responseType: 'blob' })
		.then(r => downloadBlob(r, data.fileName)),
	uploadFile: (data) => axios.post(`/api/v2/new_lead/upload`, data, { headers: { "content-type": "multipart/form-data" } }).then(r => r.data),
	sendLead: (data) => axios.post(`/api/v2/new_lead/send`, data).then(r => r.data),
	getComissions: (applicationId) => axios.get(`/api/v2/lead/commission/details${applicationId ? `?id=${applicationId}`:''}`).then(r => r.data),
	generateFile:(file)=> axios.post('/api/v2/new_lead/generate_file',file,{responseType: 'blob'}).then(resp => downloadBlob(resp, file.docName)),
	downloadSerialNumbersTemplateFile:()=> axios.get('/api/v2/lead/downloadSerialNumbersTemplateFile', optionsForAxiosBlob()).then(resp => downloadBlob(resp, 'Шаблон.xlsx')),
}

export const requestIdForNewMerchantPoint = (data) => axios.post(`/api/v2/new_lead/addMerchantPoint`, { id: data.id }).then(r => r)
export const requestDeleteMerchantPoint = (data) => axios.post(`/api/v2/new_lead/deleteMerchantPoint`, { merchantPointId: data.merchantId, id: data?.id  || null}).then(r => r)

export default leadAPI