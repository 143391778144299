import * as actions from "./actions";
import { createReducer } from "redux-act"; 

const checkKubBeanName = (bankBean) => {
	if(bankBean === 'KUBNew') return 'KUB'
	return bankBean
};

const initialState = {  
  sendNewLead: {
    data: {},
    loading: false,
  },
  dataLead: {
    data: {},
    loading: false,
  }
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
   

    // --- *  send NewLead
    [actions.requestSendNewLead]: (state, action) => ({
      ...state,  sendNewLead: {...initialState.sendNewLead, loading: true}
    }), 
    [actions.finalSendNewLead]: (state, action) => ({
      ...state,  sendNewLead: {...state.sendNewLead, loading: false, data: action}
    }),   
    // --- *  CheckHashNewLead
    [actions.requestCheckHashNewLead]: (state, action) => ({
      ...state,  dataLead: {...initialState.dataLead, loading: true}
    }), 
    [actions.finalCheckHashNewLead]: (state, action) => ({
      ...state,  dataLead: {...state.dataLead, loading: false, data: {...action, bankBeanName: checkKubBeanName(action?.bankBeanName)}}
    }),   
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
