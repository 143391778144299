import { EyeTwoTone } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import React from 'react';
import customHistory from '../../history';
import ColPosConnectSbp from './ColPosConnectSbp';
import { ColumnSwitchBankSelect } from './ColumnSwitchBankSelect';

const ViewDetails = ({ record }) => {
    const handleEdit = () => {
        if (record.id) {
            customHistory.push(`/customers/terminal/${record.id}`)
        }
    }

    return <Button type="text" icon={ <EyeTwoTone /> } onClick={ handleEdit } />
}

const columns = [
    {
        title: 'Статус',
        key: 'enabled',
        align: 'center',
        sorter: (a, b) => {
            if (a.businessName > b.businessName) {
                return 1;
            }
            if (a.businessName < b.businessName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        render: (_, render) => {
            return <span>
                <Tag color={ render.enabled ? "green" : "red" }>{ render.enabled ? "Активен" : "Неактивен" }</Tag>
            </span>
        }
    },
	{
        title: 'Банк',
        key: 'bank',
		width: 180,
		render: (value, record) => {           
			return <ColumnSwitchBankSelect record={record} />
        },
    },
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '80px',
        sorter: (a, b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        },
    },
    {
        title: 'TID',
        dataIndex: 'TID',
        key: 'TID',
    },
    {
        title: 'Серийный номер',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'Клиент',
        dataIndex: 'merchantName',
        key: 'merchantName',
        sorter: (a, b) => {
            if (a.merchantName > b.merchantName) {
                return 1;
            }
            if (a.merchantName < b.merchantName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
    },
    {
        title: 'Точка',
        dataIndex: 'merchantPointName',
        key: 'merchantPointName',
        sorter: (a, b) => {
            if (a.merchantPointName > b.merchantPointName) {
                return 1;
            }
            if (a.merchantPointName < b.merchantPointName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        optional: true
    },
    {
        title: 'Модель',
        dataIndex: 'modelName',
        key: 'modelName',
        sorter: (a, b) => {
            if (a.modelName > b.modelName) {
                return 1;
            }
            if (a.modelName < b.modelName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        
    },
    {
        title: 'Банк',
        dataIndex: 'bankName',
        key: 'bankName',
        sorter: (a, b) => {
            if (a.bankName > b.bankName) {
                return 1;
            }
            if (a.bankName < b.bankName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        optional: true
    },
    {
        title: 'Дата установки',
        dataIndex: 'installationDate',
        key: 'installationDate',
    },
    {
        title: 'Дата изменения',
        dataIndex: 'changeDate',
        key: 'changeDate',
        optional: true
    },
    {
        title:'Подключить QR',
		key: 'sbp',
        align: 'center',
		hidden: true,
        render: (text, record) => (
            <ColPosConnectSbp record={ record } />
        )
    },
    {
        title: 'Действия',
        key: 'actions',
        align: 'center',
        render: (text, record) => (
            <ViewDetails record={ record } />
        )
    },
];

export default columns

