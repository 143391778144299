import * as actions from "./actions";
import { createReducer } from "redux-act";


const initialState = {
  one: {
    loading: false,
    data: {},
  },
  terminals: {
    loading: false,
    data: [],
  },
  loyaltyProgram: {
    loading: false,
    data: [],
  },
  tstList: {
    loading: false,
    data: [],
  },
  dynamicTerminals: {
    loading: false,
    data: [],
  },
  createUpdate: {
    loading: false,
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.successGetOne]: (state, action) => {
      return { ...state, one: {...initialState.one, loading: false, data: action}}
    },
    [actions.clearGetOne]: (state, action) => ({
      ...state, one: initialState.one
    }),
    
    // --- get terminals ->>
    [actions.requestGetTerminals]: (state, action) => ({
      ...state, terminals: {...initialState.terminals, loading: true}
    }),
    [actions.finalGetTerminals]: (state, action) => {
      return { ...state, terminals: {...state.terminals, loading: false, data: action}}
    },
    [actions.clearGetTerminals]: (state, action) => {
      return { ...state, terminals: initialState.terminals}
    },
    
    // --- get LoyaltyProgram ->>
    [actions.requestGetLoyaltyProgram]: (state, action) => ({
      ...state, loyaltyProgram: {...initialState.loyaltyProgram, loading: true}
    }),
    [actions.finalGetLoyaltyProgram]: (state, action) => {
      return { ...state, loyaltyProgram: {...state.loyaltyProgram, loading: false, data: action}}
    },
    
    // --- get tstList ->>
    [actions.requestGetTstList]: (state, action) => ({
      ...state, tstList: {...initialState.tstList, loading: true}
    }),
    [actions.finalGetTstList]: (state, action) => {
      return { ...state, tstList: {...state.tstList, loading: false, data: action}}
    },
    
    // --- get DynamicTerminals ->>
    [actions.requestGetDynamicTerminals]: (state, action) => ({
      ...state, dynamicTerminals: {...initialState.dynamicTerminals, loading: true}
    }),
    [actions.finalGetDynamicTerminals]: (state, action) => {
      return { ...state, dynamicTerminals: {...state.dynamicTerminals, loading: false, data: action}}
    },
    
    // --- Create Update ->>
    [actions.requestCreateUpdate]: (state, action) => ({
      ...state, createUpdate: {...initialState.createUpdate, loading: true}
    }),
    [actions.finalCreateUpdate]: (state, action) => {
      return { ...state, createUpdate: {...state.createUpdate, loading: false}}
    },
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
