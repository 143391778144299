import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import merchantInvoicesAPI from '../../api/v2/merchantInvoicesAPI'
import moment from 'moment'


export const postMerchantInvoiceCreate = createAsyncThunk(
    "merchantInvoices-create/invoiceCreate/post",
    async (payload) => {
        const formatedPayload = {...payload, edat: +moment(payload.edat)}
        const response = await merchantInvoicesAPI.createMerchantInvoice(formatedPayload)
        return response
    }
)

export const postMerchantClientCreate = createAsyncThunk(
    "merchantInvoices-create/clientCreate/post",
    async (payload) => {
        const response = await merchantInvoicesAPI.createMerchantClient(payload)
        return response
    }
)

export const getMerchantClients = createAsyncThunk(
    "merchantInvoices-create/merchantClients/get",
    async () => {
        const response = await merchantInvoicesAPI.getMerchantClients()
        return response
    }
)

const initialState = {
    merchantClients: [],
    merchantClientsOptions: [],
    loading: false
}

const merchantInvoicesCreateSlice = createSlice({
    name: "merchantInvoices-create",
    initialState,
    reducers: {
        clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [postMerchantInvoiceCreate.pending]: (state) => {
            state.loading = true
        },
        [postMerchantInvoiceCreate.fulfilled]: (state) => {
            state.loading = false
        },
        [postMerchantInvoiceCreate.rejected]: (state) => {
            state.loading = false
        },
        [postMerchantClientCreate.pending]: (state) => {
            state.loading = true
        },
        [postMerchantClientCreate.fulfilled]: (state) => {
            state.loading = false
        },
        [postMerchantClientCreate.rejected]: (state) => {
            state.loading = false
        },
        [getMerchantClients.pending]: (state) => {
            state.loading = true
        },
        [getMerchantClients.fulfilled]: (state, { payload }) => {
            state.merchantClients = payload
            state.merchantClientsOptions = payload.map(el => ({ value: el.id, label: `${el.name} ${el.inn}`}))
            state.loading = false
        },
        [getMerchantClients.reject]: (state) => {
            state.currencies = []
            state.loading = false
        },
    }
});

export const {
    clearData,
} = merchantInvoicesCreateSlice.actions
export default merchantInvoicesCreateSlice.reducer