import axios from 'axios'

const ROOT_URL = "/api/v2/dict"

const dictsAPI = {
    // read
    getTerminalsGroupDict: () => axios.get(`${ROOT_URL}/terminalGroups`).then(r => r.data),
    getTerminalsDict: (data) => {
		if(data){
			return axios.get(`${ROOT_URL}/terminals?search=${data}`).then(r => r.data)
		}
		return axios.get(`${ROOT_URL}/terminals`).then(r => r.data)
	},
    getMerchantsDict: (data) => {
		if(data){
			return axios.get(`${ROOT_URL}/merchants?search=${data}`).then(r => r.data)
		}
		return axios.get(`${ROOT_URL}/merchants`).then(r => r.data)
	},
    getMerchantsPointsContract: (merchantId, bankId) => axios.get(`${ROOT_URL}/merchant/${merchantId}/bank/${bankId}/contracts`).then(r => r.data),
    getMerchantsWithBanksDict: () => axios.get(`${ROOT_URL}/merchants?withBanksOnly=true`).then(r => r.data),
    getPartners: () => axios.get(`${ROOT_URL}/partners`).then(r => r.data),
    getMerchantsForPartner: (partnerId) => axios.get(`${ROOT_URL}/partner/${partnerId}/merchants`).then(r => r.data),
    getUsers: (userRole) => {
        if (userRole) {
            return axios.get(`${ROOT_URL}/users?role=${userRole}`).then(r => r.data)
        } else {
            return axios.get(`${ROOT_URL}/users`).then(r => r.data)
        }
    },
    getActivitiesKinds: (data) => axios.post(`${ROOT_URL}/activitiesKinds`, {bankId: null, ...data}).then(r => r.data),
    getLeadTypes: () => axios.get(`${ROOT_URL}/leadFlows`).then(r => r.data),
    getLeadResponsibles: () => axios.get(`${ROOT_URL}/lead_responsible_users`).then(r => r.data),
    requestCommissions: (data) => axios.post(`${ROOT_URL}/commissions`, data).then(r => r.data), 
    getMerchantPoints:(merchantApplicationId) => axios.get(`/api/v2/merchantApplication/${merchantApplicationId}/points`).then(r => r.data),
	getInfoByMCC: (data) => axios.post(`${ROOT_URL}/merchant_application/mcc`,data).then(r => r.data),
}

export default dictsAPI