import * as actions from "./actions";
import { createReducer } from "redux-act";
// import { filterDataForId } from "../../../../core/utils/filters/MainFilter"; 
import { FD_partnerCodes, FD_comissionsCombobox, FD_banks, FD_terminals } from "../../../../core/utils/filters/FormatConverter";


const initialState = {
  data: [], 
  loading: false,
  createUpdate: {
    loading: false,
  },
  partnerCodes: { // merchant_applications/new - 1 таб - Код партнера
    loading: true,
    data: [],
    // codesSubPartners: [],
    // codeOwn: 0,
  },
  feeComissionsCombobox: { // merchant_applications/new - 1 таб - Профили комиссий
    data: [],
    loading: false, 
  },
  inn: {
    data: {},
    loading: false, 
    success: false, 
    error: false, 
  },
  bik: {
    data: {},
    loading: false, 
    success: false, 
    error: false, 
  },
  uploadFile:{
    success: false,
    loading: false,
  },
  uploadExtraFile:{
    success: false,
    loading: false,
  },
  deleteFile:{
    loading: false,
  },
  getFiles:{
    data: [],
    loading: false,
  },  
  one: {   
    loading: true,
    data: {},
  },  
  allTmsForOneApp: {   
    loading: true,
    data: [],
  },  
  confirmAppInBank: {
    data: {},
    loading: false,
  },
  banks: {
    data: [],
    dictBanks: [],
    loading: false,
  },
  banksFields: {
    data: {},
    loading: false,
  },
  prepareTerminals: {
    loading: false,
  },
  tableBanks: {
    data: {},
    loading: false,
  },
  recommendBanks:{
    data: {},
    loading: false,
  },
  rollBackApp:{
    loading: false,
  },
  forceStatus:{
    loading: false,
  },
  resendToMerchant: {
    loading: false,
  },
  timerSla:{
    loading: false,
  },
  documentSize:{
    allow: true,
    loading: false,
  },
  banksForMerchant: {
    data: [],
    loading: false,
  },
  banksForApplication: {
    data: [],
    loading: false,
  },
  terminalsFromMerchantsAndBanks: {
    data: [],
    loading: false,
  },
  innForMerchant: {
    data: '',
    loading: false,
  },
  refuseFromManager: {
    loading: false,
  },
  dashboard: {
    loading: true,
    data: {}
  }, 
  allDocs: {
    loading: false,
    data: []
  }, 
  infoOneApp: {
    loading: false,
    data: {}
  },
  filePersons: {
    loading: false,
    data: []
  }, 
  // --- widgets --->
  totalApp: {
    loading: false,
    data: false
  }, 
  doneApp: {
    loading: false,
    data: false
  }, 
  totalDeclinedBank: {
    loading: false,
    data: false
  }, 
  declinedMerchant: {
    loading: false,
    data: false
  }, 
  totalDraftApp: {
    loading: false,
    data: false
  }, 
  totalInWorkApp: {
    loading: false,
    data: false
  }, 
  waitingClientApp: {
    loading: false,
    data: false
  }, 
  waitingBankApp: {
    loading: false,
    data: false
  }, 
  editRowTable: {
    loading: false, 
  }, 
  // <----
  saveDocumentsFromSdm: {
    loading: false, 
  }, 

  extraFiles: [],
  bankQuestions: {
    loading: false,
    data: []
  }, 
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- widgets --->
    // totalApp
    [actions.requestGetTotalApp]: (state, action) => ({
      ...state,  totalApp: {...state.totalApp, loading: true}
    }),  
    [actions.finalGetTotalApp]: (state, action) => ({
      ...state,  totalApp: {...state.totalApp, data: action,  loading: false}
    }),   
    // totalDoneApp
    [actions.requestGetTotalDoneApp]: (state, action) => ({
      ...state,  doneApp: {...state.doneApp, loading: true}
    }),  
    [actions.finalGetTotalDoneApp]: (state, action) => ({
      ...state,  doneApp: {...state.doneApp, data: action,  loading: false}
    }),   
    //  DeclinedBank
    [actions.requestGetTotalDeclinedBank]: (state, action) => ({
      ...state,  totalDeclinedBank: {...state.totalDeclinedBank, loading: true}
    }),  
    [actions.finalGetTotalDeclinedBank]: (state, action) => ({
      ...state,  totalDeclinedBank: {...state.totalDeclinedBank, data: action,  loading: false}
    }),   
    //  DeclinedMerchant
    [actions.requestGetTotalDeclinedMerchant]: (state, action) => ({
      ...state,  declinedMerchant: {...state.declinedMerchant, loading: true}
    }),  
    [actions.finalGetTotalDeclinedMerchant]: (state, action) => ({
      ...state,  declinedMerchant: {...state.declinedMerchant, data: action,  loading: false}
    }),   
    //  TotalDraftApp
    [actions.requestGetTotalDraftApp]: (state, action) => ({
      ...state, totalDraftApp: {...state.totalDraftApp, loading: true}
    }),  
    [actions.finalGetTotalDraftApp]: (state, action) => ({
      ...state,  totalDraftApp: {...state.totalDraftApp, data: action,  loading: false}
    }),   
    //  totalInWorkApp
    [actions.requestGetTotalInWorkApp]: (state, action) => ({
      ...state, totalInWorkApp: {...state.totalInWorkApp, loading: true}
    }),  
    [actions.finalGetTotalInWorkApp]: (state, action) => ({
      ...state,  totalInWorkApp: {...state.totalInWorkApp, data: action,  loading: false}
    }),   
    //  waitingClientApp
    [actions.requestGetWaitingClientApp]: (state, action) => ({
      ...state, waitingClientApp: {...state.waitingClientApp, loading: true}
    }),  
    [actions.finalGetWaitingClientApp]: (state, action) => ({
      ...state,  waitingClientApp: {...state.waitingClientApp, data: action,  loading: false}
    }),   
    //  waitingBankApp
    [actions.requestGetWaitingBankApp]: (state, action) => ({
      ...state, waitingBankApp: {...state.waitingBankApp, loading: true}
    }),  
    [actions.finalGetWaitingBankApp]: (state, action) => ({
      ...state,  waitingBankApp: {...state.waitingBankApp, data: action,  loading: false}
    }),   
    // <----

    // --- * create/update ->>
    [actions.requestCreateUpdateData]: (state, action) => ({
      ...state,
      createUpdate: { ...initialState.createUpdate, loading: true }
    }),

    [actions.successCreateUpdateData]: (state, action) => {
      return {
        ...state,
        createUpdate: {...state.createUpdate, loading: false
        },
      };
    },

    // --- * confirmAppInBank
    [actions.requestConfirmAppInBank]: (state, action) => ({
      ...state,  confirmAppInBank: {...state.confirmAppInBank, loading: true}
    }),  
    [actions.successConfirmAppInBank]: (state, action) => ({
      ...state,  confirmAppInBank: {...state.confirmAppInBank, data: action,  loading: false}
    }),  

    // --- * confirmAppInBank
    [actions.requestGetAllDocs]: (state, action) => ({
      ...state,  allDocs: {...initialState.allDocs, loading: true}
    }),  
    [actions.finalGetAllDoc]: (state, action) => ({
      ...state,  allDocs: {...state.allDocs, data: action,  loading: false}
    }),  

    // --- * rollBackApp
    [actions.requestRollBackApp]: (state, action) => ({
      ...state,  rollBackApp: {...state.rollBackApp, loading: true}
    }),  
    [actions.successRollBackApp]: (state, action) => ({
      ...state,  rollBackApp: {...state.rollBackApp, loading: false}
    }),  

    // --- * ForceStatus
    [actions.requestForceStatus]: (state, action) => ({
      ...state,  forceStatus: {...state.forceStatus, loading: true}
    }),  
    [actions.finalForceStatus]: (state, action) => ({
      ...state,  forceStatus: {...state.forceStatus, loading: false}
    }), 
    
    // --- * resendToMerchant
    [actions.requestResendToMerchant]: (state, action) => ({
      ...state,  resendToMerchant: {...state.resendToMerchant, loading: true}
    }),  
    [actions.successResendToMerchant]: (state, action) => ({
      ...state,  resendToMerchant: {...state.resendToMerchant, loading: false}
    }), 

    // --- *  Get InfoOneApp
    [actions.requestGetInfoOneApp]: (state, action) => ({
      ...state, infoOneApp: {...state.infoOneApp, loading: true}
    }),  
    [actions.finallGetInfoOneApp]: (state, action) => ({
      ...state,  infoOneApp: {...state.infoOneApp, loading: false, data: action}
    }),  
    [actions.clearGetInfoOneApp]: (state, action) => ({
      ...state,  infoOneApp: initialState.infoOneApp
    }),  

    // --- *  EditRowTable
    [actions.requestEditRowTable]: (state, action) => ({
      ...state,  editRowTable: {...initialState.editRowTable, loading: true}
    }),  
    [actions.finalEditRowTable]: (state, action) => {
      let newData = state.data
      if(action) newData = newData.map(el => {
        if(+action.id === + el.id) return {...el, 'regNrDate': action.regNrDate, 'regNr': action.regNr}
        return el
      })
      return {
      ...state,  editRowTable: {...state.editRowTable, loading: false},
      data: newData
    }},  

    // --- * Prepare Terminals
    [actions.requestPrepareTerminals]: (state, action) => ({
      ...state,  prepareTerminals: {...state.prepareTerminals, loading: true}
    }),  
    [actions.successPrepareTerminals]: (state, action) => ({
      ...state,  prepareTerminals: {...state.prepareTerminals, data: action,  loading: false}
    }),  

    // --- * get table banks
    [actions.requestGetTableBanks]: (state, action) => ({
      ...state,  tableBanks: {...state.tableBanks, loading: true}
    }),  
    [actions.successGetTableBanks]: (state, action) => ({
      ...state,  tableBanks: {...state.tableBanks, data: action,  loading: false}
    }),  

    // --- * get Recommend Banks
    [actions.requestGetRecommendBanks]: (state, action) => ({
      ...state,  recommendBanks: {...state.recommendBanks, loading: true}
    }),  
    [actions.successGetRecommendBanks]: (state, action) => ({
      ...state,  recommendBanks: {...state.recommendBanks, data: action,  loading: false}
    }),  

    // --- * requestGetBanks
    [actions.requestGetBanks]: (state, action) => ({
      ...state,  banks: {...state.banks, loading: true}
    }),  
    [actions.successGetBanks]: (state, action) => ({
      ...state,  banks: {...state.banks, dictBanks: FD_banks(action), data: action, loading: false}
    }),  

    // --- * banksFields
    [actions.requestGetBanksFields]: (state, action) => ({
      ...state,  banksFields: {...initialState.banksFields, loading: true}
    }),  
    [actions.successGetBanksFields]: (state, action) => ({
      ...state,  banksFields: {...state.banksFields, data: action,  loading: false}
    }), 
    [actions.clearGetBanksFields]: (state, action) => ({
      ...state,  banksFields: initialState.banksFields
    }), 


    [actions.errorCreateUpdateData]: (state, action) => {
      return {
        ...state,
        createUpdate: {...state.createUpdate, loading: false, success: false, error: true, id: null
        },
      };
    },
    //  Get AllTmsForOneApp  
    [actions.requestGetAllTmsForOneApp]: (state, action) => ({
      ...state,  allTmsForOneApp: {...initialState.allTmsForOneApp, loading: true}
    }),
    [actions.finallGetAllTmsForOneApp]: (state, action) => ({
      ...state,  allTmsForOneApp: {...state.allTmsForOneApp, loading: false, data: action}
    }),
    [actions.clearGetAllTmsForOneApp]: (state, action) => ({
      ...state,  allTmsForOneApp: initialState.allTmsForOneApp
    }),
 
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true,
      data: [],
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),


    // --- * requestGetOne->>
    [actions.requestGetOne]: (state, action) => ({
      ...state,
      one: { ...initialState.one, loading: true}
    }),
    [actions.getOneSuccess]: (state, action) => ({
      ...state,
      one: { ...state.one, loading: false, data: action} 
    }),
    [actions.clearOne]: (state, action) => ({
      ...state, one: initialState.one
    }),

    // --- * requestGetTimerSla->>
    [actions.requestGetTimerSla]: (state, action) => ({
      ...state, timerSla: { ...initialState.timerSla, loading: true}
    }),
    [actions.finalGetTimerSla]: (state, action) => ({
      ...state, timerSla: { ...state.timerSla, loading: false} 
    }), 

    // --- * partnerCodes->>
    [actions.requestPartnerCodes]: (state, action) => ({
      ...state,
      partnerCodes: { ...state.partnerCodes, loading: true}
    }),
    [actions.successPartnerCodes]: (state, action) => ({
      ...state,
      partnerCodes: { ...state.partnerCodes, loading: false, data: FD_partnerCodes(action)} // {codesSubPartners, codeOwn}
    }),
    
    // --- *  GetBanksForMerchant->>
    [actions.requestGetBanksForMerchant]: (state, action) => ({
      ...state, banksForMerchant: { ...state.banksForMerchant, loading: true}
    }),
    [actions.finalGetBanksForMerchant]: (state, action) => ({
      ...state, banksForMerchant: { ...state.banksForMerchant, loading: false, data: FD_banks(action)}
    }),

    // --- *  GetBanksForMerchant->>
    [actions.requestGetBanksForApplication]: (state, action) => ({
      ...state, banksForApplication: { ...state.banksForApplication, loading: true}
    }),
    [actions.finalGetBanksForApplication]: (state, action) => ({
      ...state, banksForApplication: { ...state.banksForApplication, loading: false, data: FD_banks(action)}
    }),
    
    // --- *  GetTerminalsFromMerchantsAndBanks->>
    [actions.requestGetTerminalsFromMerchantsAndBanks]: (state, action) => ({
      ...state, terminalsFromMerchantsAndBanks: { ...state.terminalsFromMerchantsAndBanks, loading: true}
    }),
    [actions.finalGetTerminalsFromMerchantsAndBanks]: (state, action) => ({
      ...state, terminalsFromMerchantsAndBanks: { ...state.terminalsFromMerchantsAndBanks, loading: false, data: FD_terminals(action)}
    }),
    
    // --- *  Get InnForMerchant->>
    [actions.requestGetInnForMerchant]: (state, action) => ({
      ...state, innForMerchant: { ...state.innForMerchant, loading: true}
    }),
    [actions.finalGetInnForMerchant]: (state, action) => ({
      ...state, innForMerchant: { ...state.innForMerchant, loading: false, data: action}
    }),
    [actions.clearGetInnForMerchant]: (state, action) => ({
      ...state, innForMerchant: initialState.innForMerchant
    }),


    // --- * ComissionsCombobox ->> 
    [actions.requestComissionsCombobox]: (state, action) => ({
      ...state, 
      feeComissionsCombobox: { ...initialState.feeComissionsCombobox, loading: true}
    }),
    [actions.successComissionsCombobox]: (state, action) => ({
      ...state,
      feeComissionsCombobox: { ...state.feeComissionsCombobox, loading: false, 
        data: FD_comissionsCombobox(action)}
    }),

    // --- * inn
    [actions.requestCompanyForINN]: (state, action) => ({
      ...state, 
      inn: { ...initialState.inn, data: state.inn.data, loading: true}
    }),
    [actions.successCompanyForINN]: (state, action) => { 
      if (Object.keys(action).length > 0){
        return {
          ...state,
          inn: { ...state.inn, loading: false, data: action, error: false, success: true}}
      } else {
        return {
          ...state,
          inn: { ...state.inn, loading: false, data: {}, error: true, success: false}}
      }
    },
    [actions.clearCompanyForINN]: (state, action) => ({
      ...state, inn: initialState.inn
    }),

    // --- * bik
    [actions.requestCompanyForBIK]: (state, action) => ({
      ...state, 
      bik: { ...initialState.bik, data: state.bik.data, loading: true}
    }),
    [actions.successCompanyForBIK]: (state, action) => { 
      if (Object.keys(action).length > 0){
        return {
          ...state,
          bik: { ...state.bik, loading: false, data: action, error: false, success: true}}
      } else {
        return {
          ...state,
          bik: { ...state.bik, loading: false, data: {}, error: true, success: false}}
      }
    },
    [actions.clearCompanyForBIK]: (state, action) => ({
      ...state,  bik: initialState.bik
    }),

    // --- * upload file  
    [actions.requestUploadFile]: (state, action) => ({
      ...state, 
      uploadFile: { ...initialState.uploadFile, loading: true}
    }),
    [actions.successUploadFile]: (state, action) => {  
      return {
        ...state, 
        uploadFile: { ...state.uploadFile, loading: false, success: Object.keys(action).length > 0}}
    }, 

    [actions.successUploadExtraFiles]: (state, action) => {
      return {...state, uploadExtraFile: {...state.uploadExtraFile, loading: false, success: Object.keys(action).length}};
    },

    // --- * upload file  
    [actions.requestGetFiles]: (state, action) => ({
      ...state, 
      getFiles: { ...initialState.getFiles, loading: true}
    }),
    [actions.successGetFiles]: (state, action) => {  
      return {
        ...state, 
        getFiles: { ...state.getFiles, loading: false, data: action}}
    }, 
    [actions.clearGetFiles]: (state, action) => {  
      return {...state, getFiles: initialState.getFiles}
    }, 

    // --- * upload file  
    [actions.requestGetDocumentSize]: (state, action) => ({
      ...state,  documentSize: {...initialState.documentSize, loading: true}
    }),
    [actions.finalGetDocumentSize]: (state, action) => {  
      return { ...state,  documentSize: { ...state.documentSize, loading: false, allow: action}}
    },
    
    
    // --- * delete file
    [actions.requestDeleteFile]: (state, action) => ({
      ...state, deleteFile: {...initialState.deleteFile, loading: true}
    }),
    [actions.successDeleteFile]: (state, action) => {
      return { ...state,  deleteFile: { ...state.deleteFile, loading: false}}
    },
    
    // --- * get RefuseFromManager
    [actions.requestRefuseFromManager]: (state, action) => ({
      ...state, refuseFromManager: {...initialState.refuseFromManager, loading: true}
    }),
    [actions.finalRefuseFromManager]: (state, action) => {
      return { ...state,  refuseFromManager: { ...state.refuseFromManager, loading: false}}
    },

    // --- * get RefuseFromManager
    [actions.requestSaveDocumentsFromSdm]: (state, action) => ({
      ...state, saveDocumentsFromSdm: {...initialState.saveDocumentsFromSdm, loading: true}
    }),
    [actions.successSaveDocumentsFromSdm]: (state, action) => {
      return { ...state,  saveDocumentsFromSdm: { ...state.saveDocumentsFromSdm, loading: false}}
    },
    
    // --- * get FilePersons
    [actions.requestFilePersons]: (state, action) => ({
      ...state, filePersons: {...initialState.filePersons, loading: true}
    }),
    [actions.successFilePersons]: (state, action) => {
      return { ...state,  filePersons: { ...state.filePersons, loading: false, data: action}}
    },

    // --- * get getBankQuestions
    [actions.requestGetBankQuestions]: (state, action) => ({
      ...state, bankQuestions: {...initialState.bankQuestions, loading: true}
    }),
    [actions.successGetBankQuestions]: (state, action) => {
      return { ...state,  bankQuestions: { ...state.bankQuestions, loading: false, data: action}}
    },

    // --- * post sendBankQuestions
    [actions.requestSendAnswersBankQuestions]: (state, action) => ({
      ...state, bankQuestions: {...state.bankQuestions, loading: true}
    }),
    [actions.successSendAnswersBankQuestions]: (state, action) => {
      return { ...state,  bankQuestions: { ...state.bankQuestions, loading: false, data: action.merchantQuestions ? action.merchantQuestions : []}}
    },
    
    // --- * get Dashboard
    [actions.requestGetDashboard]: (state, action) => ({
      ...state, dashboard: {...initialState.dashboard, loading: true}
    }),
    [actions.finalGetDashboard]: (state, action) => {
      const data = action ? action: {}
      return { ...state,  dashboard: { ...state.dashboard, loading: false, data: data},
        'totalApp': {...state.totalApp, data: action.totalApp},
        'doneApp': {...state.doneApp, data: action.totalDoneApp},
        'totalDeclinedBank': {...state.totalDeclinedBank, data: action.totalDeclinedByBankApp},
        'declinedMerchant': {...state.declinedMerchant, data: action.totalDeclinedByMerchantApp},
        'totalDraftApp': {...state.totalDraftApp, data: action.totalDraftApp},
        'totalInWorkApp': {...state.totalInWorkApp, data: action.totalInWorkApp},
        'waitingClientApp': {...state.waitingClientApp, data: action.waitingClientApp},
        'waitingBankApp': {...state.waitingClientApp, data: action.waitingBankApp},
      }
    },
    [actions.successGetExtraFilesList]: (state, extraFiles)=> ({...state, extraFiles}),
    [actions.actionsExtraFiles]: (state, payload)=>{
        
        let extraFiles = [...state.extraFiles].map(f=>{

            if(f.documentType === payload.documentType && f.merchantPointId === payload.merchantPointId){
              
              if(payload.action === "DELETE"){
                return {...f, id: null, uploaded: false}
              }else if(payload.action === "UPLOAD"){
                return {...f, id: payload.result[0], uploaded: true}
              }
            }

            return f;
          });

      return {...state, extraFiles};
    },
    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
