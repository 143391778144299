import { notification } from 'antd';

const openNotificationWithIcon = ({type, message,description,duration,...props })=> {
  notification[type]({
    message: message || '',
    description: description || '',
    duration: duration || '',
	...props
  });
};

export default openNotificationWithIcon