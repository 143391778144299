
import temperature from './temperature/saga';   
import transactions from './transactions/saga';   
import reports from './reports/saga';   
import dashboard from './dashboard/saga';

export default [  
  ...temperature,    
  ...transactions,    
  ...reports,    
  ...dashboard,
]
 