import { all } from 'redux-saga/effects';
  
import pages from './pages/Saga'; 

import auth from './auth/Saga';  
import core from './core/Saga';  
import other from './other/Saga';  

const rootSaga = function* root() {
  yield all([ 
    ...core, 
    ...other, 
    ...auth, 
    ...pages, 
  ])
}
export default rootSaga