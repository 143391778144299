import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue'
import columns from './columns';
import formatingObjectToView from '../shares/helpers/formatingObjectForView';
import { setCurrentPosForTerminalCreate, setCurrentCustomerForTerminalCreate, setCurrentTerminalNameForTerminalCreate, setActivateFastPayments } from '../terminal-create/terminalCreateSlice'
import posesAPI from '../../api/v2/posesAPI';
import terminalsAPI from '../../api/v2/terminalsAPI';
import dictsAPI from '../../api/v2/dictsAPI';

const defaultSelectedColumns = columns.filter(column => column.optional !== true).map(column => column.key)

export const fetchPosMainData = createAsyncThunk(
    "pos/main/fetch",
    async ({posId, bankId}) => {
        let response = await posesAPI.getOne(posId, bankId)
        const activitiesKinds = await dictsAPI.getActivitiesKinds()
        response = {...response, activityTypeId: activitiesKinds.data[response.activityTypeId]}
        return response
    }
)

export const fetchPosTerminals = createAsyncThunk(
    'pos/terminals/fetch',
    async (posId) => {
        const response = await terminalsAPI.getDataByPosId(posId)
        return response
    }
)

export const fetchAvailableAgreementBanks = createAsyncThunk(
    'pos/availableAgreementBanks/fetch',
    async({id}, thunkAPI) => {
        const response = await posesAPI.getAvailablePointBanks(id)
		const allBanksList = thunkAPI.getState().core.dict.banks.allBanksList
		const selectedBankId = thunkAPI.getState().pos.selectedBankId

		const banks = response?.length ? response.map(el => ({label: el.name, value: el.id})) : [];

		const getBankSelectOptions = (allBanksList, selectedBankId, pointBanksOptions) => {
			if(!pointBanksOptions?.length && selectedBankId){
				return allBanksList.filter(el => el.value === selectedBankId)
			}
			if(pointBanksOptions?.length && selectedBankId && pointBanksOptions.every(el => el.value !== selectedBankId)){
				const selectedOption = allBanksList.find(el => el.id === selectedBankId)
				return selectedOption ? [...pointBanksOptions, {value:selectedOption.id ,label:selectedOption.label}] : pointBanksOptions
			}
			return pointBanksOptions
		};
		
        return getBankSelectOptions(allBanksList,selectedBankId, banks)
    }
)

export const goToCreateTerminal = createAsyncThunk(
    "pos/create-terminal",
    async (posId, thunkAPI) => {
        const { clientId, posNameRu, activateFastPayments } = thunkAPI.getState().pos.main.data
        thunkAPI.dispatch(setCurrentCustomerForTerminalCreate(clientId))
        thunkAPI.dispatch(setCurrentPosForTerminalCreate(posId))
        thunkAPI.dispatch(setCurrentTerminalNameForTerminalCreate(posNameRu))
		if(typeof activateFastPayments === 'boolean'){
			thunkAPI.dispatch(setActivateFastPayments(activateFastPayments))
		}
		
    }
)

const initialState = {
    main: {
        data: {},
        loading: false
    },
	banksOptions: [],
	selectedBankId: null,
    terminalsList: {
        listData: [],
        selectedColumns: defaultSelectedColumns,
        loading: false
    },
    responsible: {
        switchMatcher: {
            loading: false,
        },
        payload: {
            loading: false
        }
    },
    address: {
        switchMatcher: {
            loading: false,
        },
        payload: {
            loading: false
        }
    },
	sbpColumnsData:[],
	sbpColumnsDataWasChanged:false,
}

const posSlice = createSlice({
    name: "pos",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.terminalsList.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.terminalsList.selectedColumns = initialState.terminalsList.selectedColumns
        },
		setSbpColumnsData: (state, { payload }) => {
            state.sbpColumnsData =  payload
			if(!state.sbpColumnsDataWasChanged) state.sbpColumnsDataWasChanged = true
        },
		setSelectedBankId: (state, { payload }) => {
            state.selectedBankId =  payload
        },
		clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        // Общая информация
        [fetchPosMainData.pending]: (state, { payload }) => {
            state.main.loading = true
        },
        [fetchPosMainData.fulfilled]: (state, { payload }) => {
            state.main.data = formatingObjectToView(payload, keysWithDateValues, keysWithDateTimeValues)
            state.main.loading = false
        },
        [fetchPosMainData.rejected]: (state, { payload }) => {
            state.main.loading = false
        },
        // Общая информация
        [fetchAvailableAgreementBanks.pending]: (state, { payload }) => {
            state.main.loading = true
        },
        [fetchAvailableAgreementBanks.fulfilled]: (state, { payload }) => {
            state.banksOptions = payload
            state.main.loading = false
        },
        [fetchAvailableAgreementBanks.rejected]: (state, { payload }) => {
            state.main.loading = false
        },
        // Терминалы для точки продаж
        [fetchPosTerminals.pending]: (state, { payload }) => {
            state.terminalsList.loading = true
        },
        [fetchPosTerminals.fulfilled]: (state, { payload }) => {
            state.terminalsList.listData = payload?.terminals?.map(terminal => {
                const formatedTerminal = formatingObjectToView(terminal, keysWithDateValues, keysWithDateTimeValues)
                return { ...formatedTerminal, key: nanoid() }
            })
			if(payload?.terminals?.length){
				state.sbpColumnsData = payload.terminals.map(terminal => ({
					terminalId: terminal.id,
					mapSbp: Boolean(terminal?.sbp)
				}))
				state.selectedBankId = payload.terminals[0].bankId
			};
			if(!payload?.terminals?.length){
				state.sbpColumnsData = []
			};
            state.terminalsList.loading = false
        },
        [fetchPosTerminals.rejected]: (state, { payload }) => {
            state.terminalsList.loading = false
        },
    }
});

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    setSbpColumnsData,
    setSelectedBankId,
    clearData,
} = posSlice.actions
export default posSlice.reducer