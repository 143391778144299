import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[tct points] requestGetDataPage')
export const getDataPageSuccess = createAction('[tct points] getDataPageSuccess')
export const getDataPageError = createAction('[tct points] getDataPageError')
// get tcpSelect
export const requestGetTcpSelect = createAction('[tct points] requestGetTcpSelect')
export const successGeTcptSelect = createAction('[tct points] successGeTcptSelect')
// get one Point
export const requestGetOnePoint = createAction('[tct points] requestGetOnePoint')
export const successGetOnePoint = createAction('[tct points] successGetOnePoint')
export const clearGetOnePoint = createAction('[tct points] clearGetOnePoint')
// update/create point
export const requestCreateUpdatePointTct = createAction('[merchant] requestCreateUpdatePointTct')
export const successCreateUpdatePointTct = createAction('[merchant] successCreateUpdatePointTct')
//  Get AllowedMerchantBanks
export const requestGetAllowedMerchantBanks = createAction('[merchant] requestGetAllowedMerchantBanks')
export const finalGetAllowedMerchantBanks = createAction('[merchant] finalGetAllowedMerchantBanks')
//  Upload FileTst
export const requestUploadFileTst = createAction('[merchant] requestUploadFileTst') 