import { createAction } from 'redux-act';


// get one
export const requestGetOne = createAction('[functionality] requestGetOne')
export const successGetOne  = createAction('[functionality] successGetOne')
export const clearGetOne  = createAction('[functionality] clearGetOne')
// get Terminals
export const requestGetTerminals  = createAction('[functionality] requestGetTerminals')
export const finalGetTerminals  = createAction('[functionality] finalGetTerminals')
export const clearGetTerminals  = createAction('[functionality] clearGetTerminals')
// get LoyaltyProgram
export const requestGetLoyaltyProgram = createAction('[functionality] requestGetLoyaltyProgram')
export const finalGetLoyaltyProgram = createAction('[functionality] finalGetLoyaltyProgram')
// get tstList
export const requestGetTstList = createAction('[functionality] requestGetTstList')
export const finalGetTstList = createAction('[functionality] finalGetTstList')
// get tstList
export const requestGetDynamicTerminals = createAction('[functionality] requestGetDynamicTerminals')
export const finalGetDynamicTerminals  = createAction('[functionality] finalGetDynamicTerminals')

//   create Update
export const requestCreateUpdate = createAction('[functionality] requestCreateUpdate')
export const finalCreateUpdate  = createAction('[functionality] finalCreateUpdate')
