import moment from 'moment';

export const calculateClientTimeForFilter = (period, isFormatForDatePicker = false) => {
    let startDate = "",
        endDate = "";
    const getDateISOS = (date, subtract) => {
        if (subtract) {
            startDate = moment().subtract(subtract[0], subtract[1]).startOf(date).toISOString(true);
            endDate = moment().subtract(subtract[0], subtract[1]).endOf(date).toISOString(true);
        } else {
            startDate = moment().startOf(date).toISOString(true);
            endDate = moment().endOf(date).toISOString(true);
        }
    };

    if (period === "THIS_DAY") getDateISOS("day");
    else if (period === "THIS_WEEK") getDateISOS("week");
    else if (period === "THIS_MONTH") getDateISOS("month");
    else if (period === "THIS_YEAR") getDateISOS("year");
    else if (period === "YESTERDAY") getDateISOS("days", [1, "days"]);
    else if (period === "LAST_WEEK") getDateISOS("isoWeek", [1, "weeks"]);
    else if (period === "LAST_MONTH") getDateISOS("month", [1, "months"]);
    else if (period === "LAST_YEAR") getDateISOS("year", [1, "years"]);
    else {
        startDate = "";
        endDate = "";
    }

    if (isFormatForDatePicker) {
        return [moment(startDate), moment(endDate)];
    }
    return { startDate, endDate };
};