import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[leads] requestGetDataPage')
export const getDataPageSuccess = createAction('[leads] getDataPageSuccess')
export const getDataPageError = createAction('[leads] getDataPageError')
// create
export const requestCreate = createAction('[leads] requestCreate')
export const finalCreate = createAction('[leads] finalCreate')
// create NoLogin
export const requestCreateNoLogin = createAction('[leads] requestCreateNoLogin')
export const finalCreateNoLogin = createAction('[leads] finalCreateNoLogin')
// get one
export const requestGetOne = createAction('[leads] requestGetOne')
export const finalGetOne = createAction('[leads] finalGetOne')
// get leadTypes
export const requestGetLeadTypes = createAction('[leads] requestGetLeadTypes')
export const finalGetLeadTypes = createAction('[leads] finalGetLeadTypes')
// get goods/services
export const requestGetGoodsServices = createAction('[leads] requestGetGoodsServices')
export const finalGetGoodsServices = createAction('[leads] finalGetGoodsServices')
// get goods/services
export const requestUpdate = createAction('[leads] requestUpdate')
export const finalGetUpdate = createAction('[leads] finalGetUpdate')
// covert
export const requestCovertLeadToApp = createAction('[leads] requestCovertLeadToApp')
export const finalCovertLeadToApp = createAction('[leads] finalCovertLeadToApp')
//  TopWidgets
export const requestGetTopWidgets = createAction('[leads] requestGetTopWidgets')
export const finalGetTopWidgets = createAction('[leads] finalGetTopWidgets')
//   personalData
export const requestPersonalData = createAction('[leads] requestPersonalData')
export const finalPersonalData = createAction('[leads] finalPersonalData')
//  CloseLead
export const requestCloseLead = createAction('[leads] requestCloseLead')
export const finalCloseLead = createAction('[leads] finalCloseLead')
//  SendNewComment
export const requestSendNewComment = createAction('[leads] requestSendNewComment')
export const finalSendNewComment = createAction('[leads] finalSendNewComment')

// --- files in comments--->
//  GetAllLeadFiles
export const requestGetLeadFiles = createAction('[leads] requestGetLeadFiles')
export const finalGetLeadFiles = createAction('[leads] finalGetLeadFiles')
//  UploadLeadFile
export const requestUploadLeadFile = createAction('[leads] requestUploadLeadFile')
export const finalUploadLeadFile = createAction('[leads] finalUploadLeadFile')
//   DownloadFile
export const requestDownloadFile = createAction('[leads] requestDownloadFile')
export const finalDownloadFile = createAction('[leads] finalDownloadFile')
// <----

// --- files lead in cashbox --->
//   DownloadFile
export const requestDownloadCashBoxLeadFile = createAction('[leads] requestDownloadCashBoxLeadFile')
export const finalDownloadCashBoxLeadFile = createAction('[leads] finalDownloadCashBoxLeadFile')
// <----

// --- widgets --->
//  TotalLeads
export const requestGetTotalLeads = createAction('[leads] requestGetTotalLeads')
export const finalGetTotalLead = createAction('[leads] finalGetTotalLead')
//  newLeads
export const requestGetNewLeads = createAction('[leads] requestGetNewLeads')
export const finalGetNewLeads = createAction('[leads] finalGetNewLeads')
//  inProgressLeads
export const requestGetInProgressLeads = createAction('[leads] requestGetInProgressLeads')
export const finalGetInProgressLeads = createAction('[leads] finalGetInProgressLeads')
//  convertedLeads
export const requestGetConvertedLeads = createAction('[leads] requestGetConvertedLeads')
export const finalGetConvertedLeads = createAction('[leads] finalGetConvertedLeads')
// <----


export const updateResponsibleUser = createAction('[leads] updateResponsibleUser')
export const successUpdateResponsibleUser = createAction('[leads] successUpdateResponsibleUser')