import login from './login/reducer'; 
import logout from './logout/reducer'; 
import user from './user/reducer'; 

export default (state={}, action) => {  
	return {
		login: login(state.login, action),  
		logout: logout(state.logout, action),  
		user: user(state.user, action),  
	} 
} 