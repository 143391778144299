import * as actions from "./actions";
import { createReducer } from "redux-act";
// import { filterDataForId } from "../../../../core/utils/filters/MainFilter"; 


const initialState = { 
  data: [], 
  loading: false, 
  one: {
    loading: false,
    data: {},
  },
  closeShift: {
    loading: false,
  },
  shifts: {
    data: [],
    loading: false,
  },
  create: {
    loading: false,
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- Async get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.successGetOne]: (state, action) => {
      return { ...state, one: {...initialState.one, loading: false, data: action}}
    },
    [actions.clearGetOne]: (state, action) => ({
      ...state, one: initialState.one
    }),
    
    // --- * close Shift ->>
    [actions.requestСloseShift]: (state, action) => ({
      ...state, closeShift: {...state.closeShift, loading: true}
    }),
    [actions.successСloseShift]: (state, action) => {
      return { ...state, closeShift: {...state.closeShift, loading: false}}
    },
    
    // --- *  create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.finalCreate]: (state, action) => {
      return { ...state, create: {...state.create, loading: false}}
    },
    
    // --- * get Shifts ->>
    [actions.requestGetShifts]: (state, action) => ({
      ...state, shifts: {...state.shifts, loading: true}
    }),
    [actions.successGetShifts]: (state, action) => {
      return { ...state, shifts: {...state.shifts, data: action, loading: false}}
    },
    [actions.clearGetShifts]: (state, action) => {
      return { ...state, shifts: initialState.shifts}
    },


    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
