import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import customersAPI from "../../api/v2/customersAPI"
import generalAPI from "../../api/v2/generalAPI"
import posesAPI from "../../api/v2/posesAPI"
import terminalsAPI from "../../api/v2/terminalsAPI"
import { defaultOperationTypes } from "../../core/dict"

export const fetchCreateTerminal = createAsyncThunk(
    "terminalCreate/create/fetch",
    async (payload, thunkAPI) => {
		try {
			const images = thunkAPI.getState().terminalCreate.images
			const [vendorId, modelId] = payload.vendorModel
			const requestData = { ...payload, ...images, vendorId, modelId }
			delete requestData.vendorModel
			const response = await terminalsAPI.createOne(requestData)
			return response
		} catch (error) {
			return error
		}
        
    }
)

export const fetchCheckSerialNumber = createAsyncThunk(
    "terminalCreate/check-sn/fetch",
    async (payload) => {
        const response = await generalAPI.checkSn(payload)
        return response
    }
)

export const fetchCheckBankTID = createAsyncThunk(
    "terminalCreate/check-tid/fetch",
    async (payload) => {
        const response = await generalAPI.checkBankTID(payload)
        return response
    }
)

export const getCustomers = createAsyncThunk(
    "terminalCreate/customers",
    async () => {
        const response = await customersAPI.getData()
        return response
    }
)

export const getPoses = createAsyncThunk(
    "terminalCreate/poses",
    async (clientId) => {
        const response = await posesAPI.getDataByClientId(clientId)
        return response
    }
)

export const getPinpads = createAsyncThunk(
    "terminalCreate/pinpads",
    async (model, thunkAPI) => {
        if (!model) return []
        const { pinPads, models } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        const currentModel = models.find((el) => el.id === +model)
        return currentModel.pinPads.map((pinpad) => ({
            value: pinpad,
            label: pinPads[pinpad],
        }))
    }
)

export const getDefaultOperations = createAsyncThunk(
    "terminalCreate/default-operations",
    async (model, thunkAPI) => {
        if (!model) return []
        const { models } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        const currentModel = models.find((el) => el.id === +model)
        return currentModel.termDefaultOperationTypes.map((operation) => ({
            value: operation,
            label: defaultOperationTypes[operation],
        }))
    }
)

export const getCurrencies = createAsyncThunk(
    "terminalCreate/currencies",
    async (_, thunkAPI) => {
        const { currencies } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        return Object.entries(currencies).map((currency) => ({
            value: currency[0],
            label: currency[1],
        }))
    }
)

export const getCascaderOptions = createAsyncThunk(
    "terminalCreate/cascader",
    async (_, thunkAPI) => {
        const { models, vendors } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        const vendorModel = Object.entries(vendors).map((vendor) => {
            const modelsForVendor = models
                .filter((el) => +el.vendorId === +vendor[0])
                .map((model) => {
                    return { value: model.id, label: model.name }
                })
            return {
                value: vendor[0],
                label: vendor[1],
                children: modelsForVendor,
            }
        })
        return vendorModel
    }
)

export const getTmsConfigsByModel = createAsyncThunk(
    "terminalCreate/tmsConfigs",
    async (modelId, thunkAPI) => {
        const { tmsConfigs } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        return tmsConfigs
            .filter((config) => config.modelIds.includes(+modelId))
            .map((config) => ({ value: config.id, label: config.name, ...config }))
    }
)

const initialState = {
    currentPos: undefined,
    currentCustomer: undefined,
    currentName: undefined,
    currentMcc: undefined,
    saveWithCopy: false,
    activateFastPayments: false,
    allowBlankSn: false,
    validSerialNumber: {
        loading: false,
        data: {},
    },
    validBankTID: {
        loading: false,
        data: {},
    },
    options: {
        customers: [],
        poses: [],
        pinpads: [],
        defaultOperations: [],
        currencies: [],
        cascader: [],
        tmsConfigs: [],
    },
    images: {
        mainSplashScreen: "",
        splashScreen: "",
        receiptLogo: "",
    },
    loading: false,
}

const terminalCreateSlice = createSlice({
    name: "terminalCreate",
    initialState,
    reducers: {
        /* 
        если при сохранении заявки Точки продаж выбрали "Сохранить и создать терминал"
        записываем ответ с сервера о созданной точке продаж и сразу ее устанавливаем в поле posId        
        */
        setCurrentPosForTerminalCreate: (state, { payload }) => {
            state.currentPos = payload
        },
        setCurrentCustomerForTerminalCreate: (state, { payload }) => {
            state.currentCustomer = payload
        },
        setCurrentTerminalNameForTerminalCreate: (state, { payload }) => {
            state.currentName = payload
        },
        setCurrentCustomMccForTerminalCreate: (state, { payload }) => {
            state.currentMcc = payload
        },
        setSaveWithCopy: (state, { payload }) => {
            state.saveWithCopy = payload
        },
        addBase64MainLogo: (state, { payload }) => {
            state.images.mainSplashScreen = payload
        },
        addBase64KktLogo: (state, { payload }) => {
            state.images.splashScreen = payload
        },
        addBase64ReceiptLogo: (state, { payload }) => {
            state.images.receiptLogo = payload
        },
        resetSnValidation: (state) => {
            state.validSerialNumber = initialState.validSerialNumber
        },
        resetTIDValidation: (state) => {
            state.validBankTID = initialState.validBankTID
        },
		setActivateFastPayments: (state, { payload }) => {
            state.activateFastPayments = payload
        },
		setAllowBlankSn: (state, { payload }) => {
            state.allowBlankSn = payload
        },
        clearFormData:  () => initialState,
    },
    extraReducers: {
        [fetchCreateTerminal.pending]: (state) => {
            state.loading = true
        },
        [fetchCreateTerminal.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [fetchCreateTerminal.rejected]: (state, { payload }) => {
            state.loading = false
        },
        // запрос проверки серийника
        [fetchCheckSerialNumber.pending]: (state) => {
            state.validSerialNumber.loading = true
        },
        [fetchCheckSerialNumber.fulfilled]: (state, { payload }) => {
            state.validSerialNumber.data = payload
            state.validSerialNumber.loading = false
        },
        [fetchCheckSerialNumber.rejected]: (state, { payload }) => {
            state.validSerialNumber.loading = false
        },
        // запрос проверки TID
        [fetchCheckBankTID.pending]: (state) => {
            state.validBankTID.loading = true
        },
        [fetchCheckBankTID.fulfilled]: (state, { payload }) => {
            state.validBankTID.data = payload
            state.validBankTID.loading = false
        },
        [fetchCheckBankTID.rejected]: (state, { payload }) => {
            state.validBankTID.loading = false
        },
        [getCustomers.fulfilled]: (state, { payload }) => {
            state.options.customers = payload.clients.map((customer) => ({
                value: customer.id,
                label: `${customer.businessName} (${customer.TIN})`,
            }))
        },
        [getPoses.fulfilled]: (state, { payload }) => {
            state.options.poses = payload.poses.map((pos) => ({
                value: pos.id,
                label: pos.posName,
            }))
        },
        [getPinpads.fulfilled]: (state, { payload }) => {
            state.options.pinpads = payload
        },
        [getDefaultOperations.fulfilled]: (state, { payload }) => {
            state.options.defaultOperations = payload
        },
        [getCurrencies.fulfilled]: (state, { payload }) => {
            state.options.currencies = payload
        },
        [getCascaderOptions.fulfilled]: (state, { payload }) => {
            state.options.cascader = payload
        },
        [getTmsConfigsByModel.fulfilled]: (state, { payload }) => {
            state.options.tmsConfigs = payload
        },
    },
})

export const {
    addBase64MainLogo,
    addBase64KktLogo,
    addBase64ReceiptLogo,
    setCurrentPosForTerminalCreate,
    setCurrentCustomerForTerminalCreate,
    setCurrentTerminalNameForTerminalCreate,
    setSaveWithCopy,
    resetSnValidation,
    resetTIDValidation,
    clearFormData,
    setCurrentCustomMccForTerminalCreate,
    setActivateFastPayments,
    setAllowBlankSn,
} = terminalCreateSlice.actions
export default terminalCreateSlice.reducer
