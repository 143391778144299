import { useCallback } from 'react';
import { useQuery } from 'react-query';
import tcpAPI from "../../../api/merchant/tcp";
import customersAPI from '../../../api/v2/customersAPI';
import applicationsChangeDataAPI from '../../../api/v2/applicationsChangeDataAPI'
import { generateOptionsFromObject } from '../../shares/helpers/options';
import { useSelector } from "react-redux";
import { selectPermissions } from "../../shares/helpers/commonReduxSelectors";
import posesAPI from "../../../api/v2/posesAPI";
import { serverResponseNotification } from '../../../api/apiTools';


export const QUERY_KEYS = {
    CUSTOMER_TRANSFER_GET_COMMISSIONS: 'CUSTOMER_TRANSFER_GET_COMMISSIONS',
    CUSTOMER_GET_COMMISSIONS: 'CUSTOMER_GET_COMMISSIONS',
    CUSTOMER_GET_POINTS: 'CUSTOMER_GET_POINTS',
    CUSTOMER_GET_BANKS_LIST: 'CUSTOMER_GET_BANKS_LIST',
    CUSTOMER_TRANSFER_GET_BANKS: 'CUSTOMER_TRANSFER_GET_BANKS',
	GET_BY_TIN: 'GET_BY_TIN',
    GET_RELATED_CUSTOMERS: 'GET_RELATED_CUSTOMERS',
    CUSTOMERS_GET_AVAILABLE_TERMINAL_BANKS: 'CUSTOMERS_GET_AVAILABLE_TERMINAL_BANKS',
}


export const useGetAvailableTerminalBanks = (enabled, id) => { 
    return useQuery([QUERY_KEYS.CUSTOMERS_GET_AVAILABLE_TERMINAL_BANKS, id], () => posesAPI.getAvailableTerminalBanks(id), {
        enabled: Boolean(enabled),
        select: (data = []) => {
			if(data?.length){
				return  data.map(el => ({label: el.name, value: el.id})) 
			}
			return []
		},
		onSuccess: (response) => { 
			if(response?.success === false){
				return serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			};
        },
        onError: (response) => {
			if(response?.success === false){
				return serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			};
        },
    })
};

export const useGetRelatedCustomers = ({ id, enabled = true }) => {
    return useQuery( [QUERY_KEYS.GET_RELATED_CUSTOMERS, id],() => customersAPI.getRelatedCustomers(id),{ 
			enabled,
			select: (data) => data?.data || []
		}
    )
};

export const useGetSameCustomers = ({ TIN, enabled = true }) => {
    return useQuery([QUERY_KEYS.GET_BY_TIN],() => customersAPI.getDataByTin(TIN),{
		     // если 1 клиент = возвращаем пустой массив
			select: ({ clients }) => clients?.length > 1 ? clients : [], 
			enabled,
		}
    )
};

export const useAssociatedCustomers = ({ id = null, TIN }) => {

	const permissions = useSelector(selectPermissions)
    const hasPartnerUpdatePermission = permissions["partners_create:fromPortal"]

    // связанные клиенты
    const { data: relatedCustomersData = [], isLoading: relatedLoading } = useGetRelatedCustomers({ id, enabled:Boolean(hasPartnerUpdatePermission && !!id) });
    // связанные и несвязанные
    const { data: sameCustomers = [], isLoading: sameLoading } = useGetSameCustomers({ TIN, enabled:Boolean(hasPartnerUpdatePermission && !!TIN) });

    const unRelatedCustomers = sameCustomers.reduce((prev, curr) => {
		if(!relatedCustomersData.includes(curr.id) && curr.id !== +id){
			return [...prev,  {...curr, value: curr?.id, label: `${curr?.businessName} - (id ${curr?.id})${curr?.partnerName ?` - ${curr?.partnerName}` : '' }` }]
		}
		return prev
	},[])
	
	const relatedCustomers = sameCustomers.reduce((prev, curr) => {
		if(relatedCustomersData.includes(curr.id) && curr.id !== +id){
			return [...prev,  {...curr, value: curr?.id, label: `${curr?.businessName} - (id ${curr?.id})${curr?.partnerName ?` - ${curr?.partnerName}` : '' }` }]
		}
		return prev
	},[])

	return {
        relatedCustomers,
        unRelatedCustomers,
        sameCustomers,
        loading: relatedLoading || sameLoading
    }
};

export const useGetCustomerCommissions = (partnerId, bankIds) => {
    return useQuery([QUERY_KEYS.CUSTOMER_TRANSFER_GET_COMMISSIONS, +partnerId], () => customersAPI.getCustomerCommissions({partnerId, bankIds}), {
        enabled: Boolean(partnerId),
    })
}
export const useGetCustomerBanks = (merchantId) => {
    return useQuery([QUERY_KEYS.CUSTOMER_TRANSFER_GET_BANKS, +merchantId], () => applicationsChangeDataAPI.getBanksForMerchant(merchantId), {
      }, {
        enabled: Boolean(merchantId),
    })
}

export const useGetBankList = (id) => {
	return useQuery([QUERY_KEYS.CUSTOMER_GET_BANKS_LIST, +id], () => tcpAPI.getBankList(id) , {
		enabled: Boolean(id),
	});
};

export const useGetPoints = ({ merchant_id, bank_id,contract_id }) => {

	return useQuery([QUERY_KEYS.CUSTOMER_GET_POINTS, merchant_id, contract_id, bank_id], () => tcpAPI.getTableCommissionsPoints({ merchant_id, bank_id,contract_id }), {
		enabled: Boolean(merchant_id && contract_id && bank_id),
		select: useCallback((data = {}) => {
           	return generateOptionsFromObject(data?.data)
        }, []),
		onSuccess: () => {
        },
	});
};

export const useGetCommissions = (merchantId,  commissionAgreementId, pointId) => {

	return useQuery([QUERY_KEYS.CUSTOMER_GET_COMMISSIONS,+pointId,+commissionAgreementId], () => tcpAPI.getTableCommissionsRates({ merchantId, agreementId: commissionAgreementId, pointId }), {
		enabled: Boolean(pointId && commissionAgreementId),
		select: (data = {}) => {
            return data?.data?.rates
        }, 
        onError: (data) => {
	     
		},
        onSuccess: (data) => {
	       
		},
	});
};
