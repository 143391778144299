import { Spin } from 'antd'

const LoadingPage = () => {
    return (
        <div className="h-full w-full flex items-center justify-center">
            <Spin size="large" tip="Loading..." />
        </div>
    )
}

export default LoadingPage
