import * as actions from "./actions";
import { createReducer } from "redux-act";
import { FD_fieldsForSearch } from "../../../../core/utils/filters/FormatConverter"; 


const initialState = {
  couriers: [],
  totalSum: 0,
  successCount: 0, 
  data: [], 
  loading: true, 
  fieldsForSearch: {
    loading: false,
    data: {}
  },
  downloadCsvTransaction: {
    loading: false,
  },
  banksForTransactions: {
    loading: false,
    data: [],
  },
};

const changeMainDataForCancel = (data, id, addit={}) => {
  return data.map(el => {
    if(el.transactionId === id) return {...el, ...addit}
    else return el
  })
}

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list ? action.list : [], successCount: action.successCount || 0, totalSum: action.totalSum || 0 };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
    }),
    // --- Async get couriers->>
    [actions.requestGetCouriers]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.successGetCouriers]: (state, action) => {
      return { ...state, loading: false, couriers: action};
    },
    
    // --- * get fields For Search ->>
    [actions.requestGetFieldsForSearch]: (state, action) => ({
      ...state, fieldsForSearch: {...state.fieldsForSearch, loading: true}
    }),
    [actions.successGetFieldsForSearch]: (state, action) => {
      return { ...state, fieldsForSearch: {...state.fieldsForSearch, data: FD_fieldsForSearch(action), loading: false}}
    },
    
    // --- * get Download Csv Transaction->>
    [actions.requestDownloadCsvTransaction]: (state, action) => ({
      ...state, downloadCsvTransaction: {...state.downloadCsvTransaction, loading: true}
    }),
    [actions.successDownloadCsvTransaction]: (state, action) => {
      return { ...state, downloadCsvTransaction: {...state.downloadCsvTransaction,  loading: false}}
    },

    // --- * get  BanksForTransactions ->>
    [actions.requestBanksForTransactions]: (state, action) => ({
      ...state, banksForTransactions: {...state.banksForTransactions, loading: true}
    }),
    [actions.successBanksForTransactions]: (state, action) => {
      return { ...state, banksForTransactions: {...state.banksForTransactions, data: action, loading: false}}
    },

    // --- * CancelTransaction ->>
    [actions.finalCancelTransaction]: (state, action) => {
      let newData = state.data 
      if(action.result) newData = changeMainDataForCancel(newData, action.id, {cancelRequested: true, amountToReverse: action.amount})
      return { ...state, data: newData}
    },
    // --- *  ConfirmCancelTransaction ->>
    [actions.finalConfirmCancelTransaction]: (state, action) => {
      let newData = state.data
      if(action.result) newData = changeMainDataForCancel(newData, action.id, {cancelRequested: true, transactionType: "REVERSAL"})
      return { ...state, data: newData}
    },
    // --- *  denyCancelTransaction ->>
    [actions.finalDenyCancelTransaction]: (state, action) => {
      let newData = state.data
      if(action.result) newData = changeMainDataForCancel(newData, action.id, {cancelRequested: false})
      return { ...state, data: newData}
    },


    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
