import { availableBlockTypes, availableCityType, availableCityTypeForKkt, availableFlatTypes, availableHouseTypes, availableRegion, availableStreetType } from "../../../core/utils/other/new.staticDataForFields"


export const getRegionLabel = (value = '') => {
	const result = availableRegion.find(item => item.value === value)?.label
    return result ? ' ' + result : ''
}

export const getStreetType = (value = '') => {
	const result = availableStreetType.find(item => item.value === value)?.label
    return result ? ' ' + result  : ''
}

export const getSettlementTypeForKkt = (value = '') => {
    const result = availableCityTypeForKkt.find(item => item.value === value)?.label
	if(result === 'Станица') return ' ст-ца'
	if(result === 'Поселок городского типа') return ' пгт'
	if(result === 'Рабочий поселок') return ' рп'
	if(result === 'Аул') return ' аул'
	if(result === 'Микрорайон') return ' мкр-н'

	return result ?  ' ' + result.toLowerCase()[0] + '.' : ''
}

export const generateAddressLine = (address = {},isKkt = false) => {
    if (address === null) address = {}
	
	const findInList = (list,value) =>  {
		const label = list.find(type => type.value === value)?.label;
		return label ? label : ""
	};

	const getRegion = (address) => {
		if(address?.country && address?.country !== 'RUSSIA' && address?.region){
			return address?.region
		}
		return address?.region ? `${findInList(availableRegion,address.region)}` : ""
	};

	if(!isKkt){
		let postalCode = address?.postCode ? `${address.postCode}` : "";
		let region = getRegion(address)
		let area = address?.area ? `${address.area }`:""
		let city = address?.city ? `г. ${address?.city}`: "";
		let locality = address?.settlementName || address?.settlementType  ? `${address?.settlementType ? `${findInList(availableCityType,address?.settlementType)} `: ""}${address?.settlementName || ""}`: "";
		let street = address?.streetName || address?.streetType ? `${address?.streetType? `${findInList(availableStreetType,address.streetType)} ` : ""}${address.streetName || ""}` : "";
		let house = address?.plot || address?.houseType ? `${ address?.houseType ? `${findInList(availableHouseTypes,address?.houseType)} ` : ""}${address?.plot || ""}` : ""
		let block = address?.building || address?.blockType ? `${address?.blockType ?  `${findInList(availableBlockTypes,address?.blockType)}`: ""}${!address?.blockType ? ", ":" "}${address.building || ""}` : ""
		let home = address?.unit || address?.flatType ?`${address?.flatType ? `${findInList(availableFlatTypes,address?.flatType)} `: ""}${address?.unit || ""}`:"";
		
		return [postalCode, region, area, city, locality, street, house, block, home].reduce((acc, curr, index,arr) => {
			const prev = index === 0 ? undefined : arr[index-1];
			if(!!(curr && prev) || !!(curr && acc) ) {
				 acc += `, ${curr}`
				} 
			 else {
				acc += curr
			}
			 return acc

		},'');

	} else {

		return `${address.postCode ? address.postCode : ''}${address.region ? getRegionLabel(address.region) : ''}${address.area ? ' ' + address.area : ''}${address.city ? ' г. ' + address.city : ''}${(address.settlementType && address.settlementName) ?  getSettlementTypeForKkt(address.settlementType) + ' ' + address.settlementName : ''}${(address.streetName && address.streetType) ? getStreetType(address.streetType) + ' ' + address.streetName : ''}${(address.streetName && !address.streetType) ? ' ул. ' + address.streetName : ''}${address.plot ? ' д. ' + address.plot: ''}${address.building ? ' к. ' + address.building : ''}${address.unit ? ' кв. ' + address.unit : ''}`
	}
		
}

export const generateAddressLineCreateFunc = (address = {}) => {
    if (address === null) address = {}
    return `${address.postalCode ? address.postalCode : ''}${address.region ? getRegionLabel(address.region) : ''}${address.area ? ' ' + address.area : ''}${address.city ? ' г. ' + address.city : ''}${(address.cityType && address.locality) ?  getSettlementTypeForKkt(address.cityType) + ' ' + address.locality : ''}${(address.street && address.streetType) ? getStreetType(address.streetType) + ' ' + address.street : ''}${(address.street && !address.streetType) ? ' ул. ' + address.street : ''}${address.house ? ' д. ' + address.house: ''}${address.block ? ' блок ' + address.block: ''}${address.building ? ' к. ' + address.building : ''}${address.unit ? ' кв. ' + address.unit : ''}${address.office ? ' офис ' + address.office : ''}`
};

export const getAddressWithRegionLabel = (address = {}) => {
	if(address?.hasOwnProperty('country') && address?.hasOwnProperty('region')) {
		if(address.country === 'RUSSIA') return address

		const regionLabel = availableRegion.find(el => el.value === address.region)?.label
		return {
			...address,
			region: regionLabel ? regionLabel : address.region 
		}
	}
	return address
};

export const generateAddressLineForApplication = (address = {}) => {
    if (address === null) address = {}
    return `${address.postalCode ? address.postalCode : ''}${address.regionType ? ' ' + getRegionLabel(address.regionType) : ''}${address.locality ? ' г ' + address.locality : ''}${(address.street && address.streetType) ? ' ' + getStreetType(address.streetType) + ' ' + address.street : ''}${(address.street && !address.streetType) ? ' ул ' + address.street : ''}${address.home ? ' д ' + address.home: ''}${address.build ? ' к ' + address.build : ''}${address.office ? ' кв ' + address.office : ''}`
}
