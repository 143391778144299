import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SwitchBankSelect } from "../pos-common/SwitchBankSelect";
import usePermissions from "../shares/helpers/usePermissions";
import { useSwitchTerminalBank } from "./hooks/mutations";
import { useGetTerminalsBankOptions } from "./hooks/queries";
import { fetchPosTerminals } from "./posSlice";
import { useMemo } from "react";
import { selectAllBanksList } from "../shares/helpers/commonReduxSelectors";

export const ColumnSwitchBankSelect = ({record}) => {
	const dispatch = useDispatch()
    const { posId } = useParams()
	const { isBankPartner,permissions } = usePermissions()
	const allBanksOptions = useSelector(selectAllBanksList)
	const switchBankPermission = permissions['clientsSection:terminals:bankSwitching'];

	const { mutateAsync: switchBank, isLoading} = useSwitchTerminalBank();
	const { data: options = [], isLoading: isBankOptionsLoading} = useGetTerminalsBankOptions((record.id && !isBankPartner && switchBankPermission), record.id);

	const switchBankCallback = ({setBankLocalData, ...data}) => {
		switchBank(data)
		.then(res => {
			setBankLocalData(prev => ({...prev, bankWasChanged: false}))
			dispatch(fetchPosTerminals(posId))
		})
	};

	const bankOptions = !switchBankPermission 
	? allBanksOptions.map(el => ({value: Number(el.value), label: el.label}))
	: options

	return (
		<SwitchBankSelect 
			bankId={record?.bankId} 
			id={record.id} 
			bankOptions={bankOptions} 
			switchBankCallback={switchBankCallback} 
			isLoading={isLoading || isBankOptionsLoading} 
		/>
	)
};