import axios from 'axios'

export default {  
	getData: (data) => axios.get(`/reports?page=${data.page}&elementsPerPage=${data.elementsPerPage}`, data).then(r => r.data),  
	getOne: (data) => axios.get(`/report/${data.id}`).then(r => r.data),  
	getSelectFields: (data) => axios.get(`/transactions/fields`, data).then(r => r.data),  
	getSubPartnersSelect: (data) => axios.get(`/sub-partners/combobox`, data).then(r => r.data),  

	createData: (data) => axios.post(`/reports`, data).then(r => r.data),  
	updateData: (data) => axios.put(`/reports`, data).then(r => r.data),  
}  