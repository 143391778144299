import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dashboardAPI from '../../api/v2/dashboardAPI'
import oldDashboardAPI from '../../api/statistics/dashboard'
import { calculateClientTimeForFilter } from '../shares/helpers/calculateClientTimeForFilter'
import { FD_MapToSelect } from '../../core/utils/filters/FormatConverter'
import { currencyList } from '../shares/dicts/currency'

const convertFiltersToOldApi = (values) => {
	return {
		startDate: values?.startDate || null,
		endDate: values?.endDate || null,
		tsp: values?.merchantId || null,
		tst: values?.merchantPointId || null,
		tspGroup: values?.merchantGroupId || null,
		terminal: values?.terminalId || null,
		terminalGroup: values?.terminalGroupId || null,
	}
};


const preparePieData = (data) => {
	return data.reduce((prev, curr) => {
		if(['paymentTypeToTotalData'].includes(curr[0])){
			const paymentTypeToTotalData = curr[1]
			if(!Object.keys(paymentTypeToTotalData)?.length){
				return {
					...prev,
					paymentOptions:initialState.pie.paymentOptions
				}
			}
			const labels  = []
			const options = []
			for (const key in paymentTypeToTotalData) {
				labels.push(key)
				const totalAmount = paymentTypeToTotalData?.[key]?.totalAmount || 0
				options.push(totalAmount)
			}

			return {
				...prev,
				paymentOptions:{
					options,
					labels,
				}
			 }
		}
		if(['totalReverseRefundAmount', 'totalPaymentAmount'].includes(curr[0]) && curr[1] !== null){
			return {
				...prev,
				transactionOptions:{
					options:[...prev.transactionOptions.options, curr[1]],
					labels:[...prev.transactionOptions.labels, curr[0]],
				}
			 }
		}
		return prev

	},{
		loading:false,
		transactionOptions: {
			options:[],
			labels:[],
		},
		paymentOptions: {
			options:[],
			labels:[],
		}
	});
};

export const getPieStatistics = createAsyncThunk("dashboard/getPieStatistics/get",
    async (payload, { dispatch, getState, rejectWithValue }) => {

		try {
			const stateRangeDateTime = getState().dashboard.pieFilters.rangeDateTime
			
			const { rangeDateTime, ...restData } = payload
			let data = restData
			if(!rangeDateTime){
				data = {
					...restData,
					startDate: stateRangeDateTime[0].format(),
					endDate: stateRangeDateTime[1].format(),
				}
			} else {
				data = {
					...restData,
					startDate: rangeDateTime[0].format(),
					endDate: rangeDateTime[1].format(),
				}
			};

			const response = await dashboardAPI.getPieStatistics(data)
			return response

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const getCountTransactions = createAsyncThunk("dashboard/getCountTransactions/get",
    async (payload, { dispatch, getState, rejectWithValue }) => {
		try {
			const stateRangeDateTime = getState().dashboard.countTransactionsFilters.rangeDateTime
			
			const { rangeDateTime, ...restData } = payload
			let data = restData
			if(!rangeDateTime){
				data = {
					...restData,
					startDate: stateRangeDateTime[0].format(),
					endDate: stateRangeDateTime[1].format(),
				}
			} else {
				data = {
					...restData,
					startDate: rangeDateTime[0].format(),
					endDate: rangeDateTime[1].format(),
				}
			};

			const response = await oldDashboardAPI.getCountTransactions(convertFiltersToOldApi(data))
			return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const getCountShifts = createAsyncThunk("dashboard/getCountShifts/get",
    async (payload, { dispatch, getState, rejectWithValue }) => {
		try {
			const stateRangeDateTime = getState().dashboard.countShiftsFilters.rangeDateTime
			
			const { rangeDateTime, ...restData } = payload
			let data = restData
			if(!rangeDateTime){
				data = {
					...restData,
					startDate: stateRangeDateTime[0].format(),
					endDate: stateRangeDateTime[1].format(),
				}
			} else {
				data = {
					...restData,
					startDate: rangeDateTime[0].format(),
					endDate: rangeDateTime[1].format(),
				}
			};
			const response = await oldDashboardAPI.getCountShifts(convertFiltersToOldApi(data))
			return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const getParamsForFilters = createAsyncThunk("dashboard/getParamsForFilters/get",
    async (values, { dispatch, getState, rejectWithValue }) => {
		try {
			const response = await oldDashboardAPI.getParamsForFilters({})
			return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const initialState = {
	data: {},
	serverData: {},
	currency:{
		selectedCurrency: null,
		selectedCurrencySymbol: null,
		currencyOptions: [],
	},

	pie: {
		loading: false,
		transactionOptions: {
			options:[0,0],
			labels:["totalPaymentAmount", "totalReverseRefundAmount"],
		},
		paymentOptions: {
			options:[0,0,0],
			labels:['posAmount','internetAmount', 'sbpAmount'],
		}
	},
	pieFilters: {
		rangeDateTime: calculateClientTimeForFilter('THIS_DAY',true),
		terminalId: null,
		terminalGroupId: null,
		merchantId: null,
		merchantGroupId: null,
		merchantPointId: null,
		paymentType: null,
	},
	countTransactionsFilters: {
		rangeDateTime: calculateClientTimeForFilter('THIS_DAY',true),
		terminalId: null,
		terminalGroupId: null,
		merchantId: null,
		merchantGroupId: null,
		merchantPointId: null,
	},
	countShiftsFilters: {
		rangeDateTime: calculateClientTimeForFilter('THIS_DAY',true),
		terminalId: null,
		terminalGroupId: null,
		merchantId: null,
		merchantGroupId: null,
		merchantPointId: null,
	},
	filtersOptions: {
		merchantsOptions:[],
		merchantsGroupOptions:[],
		terminalsOptions:[],
		terminalsGroupOptions:[],
	},
	chrono:{
		countTransactionsLoading: false,
		countShiftsOptionsLoading: false,
		countTransactionsOptions: [],
		countShiftsOptions: [],
	}
}


const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: { 
		setPieFilters: (state,{ payload }) => {
			state.pieFilters = payload
		}, 
		setCountShiftsFilters: (state,{ payload }) => {
			state.countShiftsFilters = payload
		}, 
		setCountTransactionsFilters: (state,{ payload }) => {
			state.countTransactionsFilters = payload
		}, 
		changeCurrency: (state,{ payload }) => {
			const { currency } = payload

			const pieData = preparePieData(Object.entries(state.serverData[currency]))

            state.pie = pieData
            state.data = state.serverData[currency]
            state.currency = {
				...state.currency,
				selectedCurrency: currency,
				selectedCurrencySymbol: currencyList?.[currency]?.symbol || currency,
			}
		}, 
		clearFormData: () => initialState 
    },
    extraReducers: {

        [getPieStatistics.pending]: (state) => {
            state.pie.loading = true
        },
        [getPieStatistics.fulfilled]: (state, { payload }) => {
			const { data } = payload
			state.pie.loading = false

			if(Object.keys(data)?.length){
				const firstCurrency = Object.keys(data)[0]
				const pieData = preparePieData(Object.entries(data[firstCurrency]))

				state.pie = pieData
				state.data = data[firstCurrency]
				state.serverData = data
				
				state.currency = {
					selectedCurrency: firstCurrency,
					selectedCurrencySymbol: currencyList?.[firstCurrency]?.symbol || firstCurrency,
					currencyOptions:  Object.keys(data).map(el => ({value: el, label: `${currencyList[el].symbol}(${currencyList[el].code})`}))
				}
			} else {
				state.pie = initialState.pie 
				state.data = initialState.data
				state.serverData = initialState.serverData
				state.currency = initialState.currency
			}
        },
        [getPieStatistics.rejected]: (state) => {
            state.pie.loading = false
        },
        [getParamsForFilters.pending]: (state, { payload }) => {
            state.pie.loading = true
			state.chrono.countShiftsOptionsLoading = true
			state.chrono.countTransactionsLoading = true
        },
        [getParamsForFilters.fulfilled]: (state, { payload }) => {
			state.pie.loading = false
			state.chrono.countShiftsOptionsLoading = false
			state.chrono.countTransactionsLoading = false
            state.filtersOptions = {
				merchantsOptions: FD_MapToSelect(payload?.merchants || {}),
				merchantsGroupOptions:FD_MapToSelect(payload?.merchantGroups || {}),
				terminalsOptions:FD_MapToSelect(payload?.terminals || {}),
				terminalsGroupOptions:FD_MapToSelect(payload?.terminalGroups || {}),
			}

        },
        [getParamsForFilters.rejected]: (state) => {
			state.pie.loading = false
			state.chrono.countShiftsOptionsLoading = false
			state.chrono.countTransactionsLoading = false
        },
        [getCountTransactions.pending]: (state, { payload }) => {
            state.chrono.countTransactionsLoading = true
        },
        [getCountTransactions.fulfilled]: (state, { payload }) => {
            state.chrono.countTransactionsLoading = false
            state.chrono.countTransactionsOptions = payload
        },
        [getCountTransactions.rejected]: (state) => {
            state.chrono.countTransactionsLoading = false
        },
        [getCountShifts.pending]: (state, { payload }) => {
            state.chrono.countShiftsOptionsLoading = true
        },
        [getCountShifts.fulfilled]: (state, { payload }) => {
            state.chrono.countShiftsOptionsLoading = false
            state.chrono.countShiftsOptions = payload
        },
        [getCountShifts.rejected]: (state) => {
            state.chrono.countShiftsOptionsLoading = false
        },
    }
});

export const {
    setCountShiftsFilters,
    setCountTransactionsFilters,
    setPieFilters,
    changeCurrency,
    clearFormData,
} = DashboardSlice.actions
export default DashboardSlice.reducer