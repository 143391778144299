import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
// sagas
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [...getDefaultMiddleware({serializableCheck: false,}), sagaMiddleware]
// const store = createStore(combineReducers(rootReducer), composeWithDevTools(applyMiddleware(
// 	sagaMiddleware
// )))

const store = configureStore(
	{
		reducer: rootReducer,
		middleware: middlewares
	}
)

sagaMiddleware.run(rootSaga);
export default store