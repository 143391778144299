import * as actions from "./actions";
import { createReducer } from "redux-act";
// import { filterDataForId } from "../../../../core/utils/filters/MainFilter"; 


const initialState = { 
  data: [], 
  loading: false, 
  one: {
    loading: false,
    data: {},
  },
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  availableTerminals: {
    data: {},
    loading: false,
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- Async get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.successGetOne]: (state, action) => {
      return { ...state, one: {...initialState.one, loading: false, data: action}}
    },
    [actions.clearGetOne]: (state, action) => ({
      ...state, one: initialState.one
    }),

    
    
    // --- *  create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.finalCreate]: (state, action) => {
      return { ...state, create: {...state.create, loading: false}}
    },
    
    
    // --- *  get AvailableTerminals ->>
    [actions.requestGetAvailableTerminals]: (state, action) => ({
      ...state, availableTerminals: {...initialState.availableTerminals, loading: true}
    }),
    [actions.finalGetAvailableTerminals]: (state, action) => {
      return { ...state, availableTerminals: {...state.availableTerminals, loading: false, data: action}}
    },
    
    // --- *  Update ->>
    [actions.requestUpdate]: (state, action) => ({
      ...state, update: {...initialState.update, loading: true}
    }),
    [actions.finalUpdate]: (state, action) => {
      return { ...state, update: {...state.update, loading: false}}
    },

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
