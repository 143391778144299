import { createAction } from 'redux-act';
  
// send submit
export const requestSendNewMerch = createAction('[NewMerch] requestSendNewMerch') 
export const finalSendNewMerch  = createAction('[NewMerch] finalSendNewMerch')  
// get all list files for hash
export const requestCheckHashNewMerch = createAction('[NewMerch] requestCheckHashNewMerch') 
export const finalCheckHashNewMerch  = createAction('[NewMerch] finalCheckHashNewMerch')  
// delete file
export const requestDeleteFileNewMerch = createAction('[NewMerch] requestDeleteFileNewMerch')
// download file
export const requestDownloadFileNewMerch = createAction('[NewMerch] requestDownloadFileNewMerch') 
// download file
export const requestDownloadPersonalDocNewMerch = createAction('[NewMerch] requestDownloadPersonalDocNewMerch') 
// upload   file
export const requestUploadFileNewMerch = createAction('[NewMerch] requestUploadFileNewMerch')  