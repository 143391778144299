import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    merchantPointsAvailableAddressList: [],
    merchantPointsAdressOptions: [],
    merchantPointsNamesOptions: [],
	isSignatureLoading: false,
}

const ofdSlice = createSlice({
    name: "ofdReg",
    initialState,
    reducers: {
        setMerchantPointsOptions: (state, { payload }) => {
            state.merchantPointsAvailableAddressList = payload || []
            state.merchantPointsAdressOptions =
                payload?.map(({ legalAddress }, index) => ({
                    value: index,
                    label: Object.values(legalAddress).join(","),
                })) || []
            state.merchantPointsNamesOptions =
                payload?.map(({ name, id }) => ({
                    value: id,
                    label: name,
                })) || []
        },
        setSignatureLoading: (state, { payload }) => {
            state.isSignatureLoading = payload 
        },
    },
})

export const { setMerchantPointsOptions, setSignatureLoading } = ofdSlice.actions
export default ofdSlice.reducer
