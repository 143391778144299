import { Col } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { SwitchBankSelect } from "../pos-common/SwitchBankSelect";
import usePermissions from "../shares/helpers/usePermissions";
import { fetchCustomerMainData, fetchCustomerPoses } from "./customerSlice";
import { useSwitchPosMerchantBank } from "./hooks/mutations";
import { useGetAvailableTerminalBanks } from "./hooks/useCustomerQueries";


export const ColumnSwitchBankSelect = ({record}) => {
	const dispatch = useDispatch()
    const { id } = useParams()	
	const { isBankPartner } = usePermissions()

	const [startFetching, setStartFetching] = useState(false)
	const { changeBankOptions } = useSelector(state => state.customer)

	const { data: options = [], isLoading: isBankOptionsLoading } = useGetAvailableTerminalBanks((record.id && !isBankPartner && startFetching), record.id);
	const { mutateAsync: switchBank, isLoading} = useSwitchPosMerchantBank();

	const switchBankCallback = ({setBankLocalData, ...data}) => {
		switchBank(data)
		.then(response => {
			dispatch(fetchCustomerPoses(id))
			dispatch(fetchCustomerMainData(id))
			setBankLocalData(prev => ({...prev, bankWasChanged: false}))
		})
	};

	const bankOptions = useMemo(() => {
		if(!record?.terminalBankIds?.length) return []
		if(!startFetching) return changeBankOptions.filter(el => record?.terminalBankIds.includes(el.value))
		return options
	},[startFetching, changeBankOptions, record?.terminalBankIds, options]);

	const  currentBankId = useMemo(() => {
		if(!record?.terminalBankIds?.length) return ''
		if(record?.terminalBankIds?.length === 1){
			return +record?.terminalBankIds.join(',') 
		}
		return record?.terminalBankIds.join(',')
	},[record?.terminalBankIds]);

	const currentBankOption = useMemo(() => {
		if(record?.terminalBankIds?.length > 1) {
			const label =  changeBankOptions.reduce((prev, curr) => {
				if(record?.terminalBankIds.includes(curr.value)){
					return [...prev, curr.label]
				}
				return prev
			},[])
			const currentOption =  {value: record?.terminalBankIds.join(','), label: label.join(', ')}
			return [...bankOptions, currentOption]
		}
		
		return bankOptions
	},[bankOptions, changeBankOptions, record?.terminalBankIds]);

	return (
		<Col span={24}>
			<SwitchBankSelect 
				bankId={currentBankId} 
				id={record.id} 
				bankOptions={currentBankOption} 
				switchBankCallback={switchBankCallback} 
				isLoading={isLoading || isBankOptionsLoading} 
				startFetching={startFetching}
				setStartFetching={setStartFetching}
			/>
		</Col>
	)
};

