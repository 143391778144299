import * as actions from "./actions";
import { createReducer } from "redux-act";
import { FD_activitiesKinds } from "../../../../core/utils/filters/FormatConverter";



const initialState = {
  points: {  // точки выбранной заявки
    data: [],
    loading: false
  },
  MCCPointsError:false,
  cacheDataPoints: [],  // кэшируем данные точки
  onePoint: {  // 1 точка
    data: {},
    loading: false
  },
  createUpdatePoint: {
    data: {},
    loading: false,
  },
  activitiesKinds: {
    loading: false,
    data: [],
  },
  // tms
  tmsList: {
    loading: false,
    data: [],
  },
  updateTmsList: {
    loading: false,
  },
  updateTmsListFully: {
    loading: false,
  },
  updateCurrentTmsList: {
    loading: false,
  },
  doublesSn: {
    loading: false,
  },
  updateTmsQuickReg: {
    loading: false,
  },
  itemsFromTerminalTabs: [

  ]
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- * getPointsByApplicationId
    [actions.requestPointsByApplicationId]: (state, action) => ({
      ...state,
      points: { ...initialState.points, loading: true }
    }),
    [actions.successPointsByApplicationId]: (state, action) => {
      const data = action.list ? action.list : []
      return {
        ...state,
        points: { ...state.points, loading: false, data: data }, cacheDataPoints: data
      }
    },
    [actions.clearPointsByApplicationId]: (state, action) => ({
      ...state, points: initialState.points
    }),
    // clear CacheDataPoints
    [actions.clearCacheDataPoints]: (state, action) => ({
      ...state, cacheDataPoints: initialState.cacheDataPoints
    }),
    // update cache points
    [actions.requestUpdateCachePoints]: (state, action) => ({
      ...state, cacheDataPoints: action
    }),

    // --- * getPointsByApplicationId
    [actions.requestGetOnePoint]: (state, action) => ({
      ...state,
      onePoint: { ...initialState.onePoint, loading: true }
    }),
    [actions.successGetOnePoint]: (state, action) => {
      return {
        ...state,
        onePoint: { ...state.onePoint, loading: false, data: action }
      }
    },
    [actions.clearGetOnePoint]: (state, action) => ({
      ...state, onePoint: initialState.onePoint
    }),


    // --- * createUpdatePoint
    [actions.requestCreateUpdatePoint]: (state, action) => ({
      ...state,
      createUpdatePoint: { ...state.createUpdatePoint, loading: true }
    }),
    [actions.successCreateUpdatePoint]: (state, action) => ({
      ...state,
      createUpdatePoint: { ...state.createUpdatePoint, loading: false, data: action }
    }),
    [actions.errorCreateUpdatePoint]: (state, action) => ({
      ...state,
      createUpdatePoint: { ...state.createUpdatePoint, loading: false }
    }),
    [actions.clearCreateUpdatePoint]: (state, action) => ({
      ...state, createUpdatePoint: initialState.createUpdatePoint
    }),


    // --- * get ActivitiesKinds 
    [actions.requestGetActivitiesKinds]: (state, action) => ({
      ...state, activitiesKinds: { ...initialState.activitiesKinds, loading: true }
    }),
    [actions.successGetActivitiesKinds]: (state, action) => {
      return {
        ...state, activitiesKinds: { ...state.activitiesKinds, loading: false, data: FD_activitiesKinds(action) }
      }
    },
    [actions.clearGetActivitiesKinds]: (state, action) => ({
      ...state, activitiesKinds: initialState.activitiesKinds
    }),

    // --- * update TmsList
    [actions.requestUpdateTmsList]: (state, action) => ({
      ...state, updateTmsList: { ...initialState.updateTmsList, loading: true }
    }),
    [actions.successUpdateTmsList]: (state, action) => {
      return {
        ...state, updateTmsList: { ...state.updateTmsList, loading: false }
      }
    },

    // --- * update fully TmsList
    [actions.requestUpdateTmsListFully]: (state, action) => ({
      ...state, updateTmsListFully: { ...initialState.updateTmsListFully, loading: true }
    }),
    [actions.finalUpdateTmsListFully]: (state, action) => {
      return {
        ...state, updateTmsListFully: { ...state.updateTmsListFully, loading: false }
      }
    },
    // --- * update current TmsList
    [actions.requestUpdateCurrentTmsList]: (state, action) => ({
      ...state, updateCurrentTmsList: { ...initialState.updateCurrentTmsList, loading: true }
    }),
    [actions.finalUpdateCurrentTmsList]: (state, action) => {
      return {
        ...state, updateCurrentTmsList: { ...state.updateCurrentTmsList, loading: false }
      }
    },

    // --- * update  TmsListQuickReg 
    [actions.requestUpdateTmsListQuickReg]: (state, action) => ({
      ...state, updateTmsQuickReg: { ...initialState.updateTmsQuickReg, loading: true }
    }),
    [actions.successUpdateTmsListQuickReg]: (state, action) => {
      return {
        ...state, updateTmsQuickReg: { ...state.updateTmsQuickReg, loading: false }
      }
    },

    // --- * Check DoublesSn
    [actions.requestCheckDoublesSn]: (state, action) => ({
      ...state, doublesSn: { ...initialState.doublesSn, loading: true }
    }),
    [actions.finalCheckDoublesSn]: (state, action) => {
      return {
        ...state, doublesSn: { ...state.doublesSn, loading: false }
      }
    },

    // --- * requestGetTmsList
    [actions.requestGetTmsList]: (state, action) => ({
      ...state, tmsList: { ...initialState.tmsList, loading: true }
    }),
    [actions.successGetTmsList]: (state, action) => {
      return {
        ...state, tmsList: { ...state.tmsList, loading: false, data: action }
      }
    },
    [actions.clearGetTmsList]: (state, action) => {
      return { ...state, tmsList: initialState.tmsList }
    },

    [actions.setItemsFromTerminalTab]: (state, itemsFromTerminalTabs) => ({
      ...state, itemsFromTerminalTabs
    }),
	[actions.setMCCPointsError]: (state, action) => ({
		...state, MCCPointsError: action
	  }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
