import { useCallback } from "react"
import { useQuery } from "react-query"
import customersAPI from "../../../api/v2/customersAPI";

export const QUERY_KEYS = {
	POS_GET_NSPK_DATA: 'POS_GET_NSPK_DATA'
};

export const useGetNspkData = (id, permissions, params = {}) => {
	 return useQuery([QUERY_KEYS.POS_GET_NSPK_DATA, id], () => customersAPI.getNspkData(id), {
		enabled: Boolean(id && permissions),
		select: useCallback(({ data = [], errorMessage }) => {
			if(!Array.isArray(data) || !data?.length) return {options: [], errorMessage};
			const options = data.map(item => ({ value: item.merchantId, label:`${item.brandName} (мсс ${item.mcc}) - ${item.address}` }));
            return {options, errorMessage} 
        }, []),
		...params,
	})
}