import * as actions from './actions'
import { createReducer } from 'redux-act'
import {
    FD_banks,
    FD_partners,
    FD_MapToSelect,
    FD_default,
	FD_banksMap
} from '../../../core/utils/filters/FormatConverter'

const initialState = {
    banks: {
        data: [], // Массив всех банков  без изменений
        dictBanks: [], // format: [{value, label}, ] Список банков для опций
        allBanksList: [], // format: [{value, label}, ] Список всех банков для опций
        allBanksData: [], // Массив банков для  заявок без изменений
        loading: false,
    },
    payments: {
        data: [],
        loading: false,
    },
    partners: {
        data: [],
        loading: false,
    },
    merchants: {
        data: [],
        loading: false,
    },
    merchantPoints: {
        data: [],
        loading: false,
    },
    usersForApp: {
        data: [],
        loading: false,
    },
    pinpads: {
        data: [],
        loading: false,
    },
    allDataForTmsConfigs: {
        data: {},
        dataAsCascader: [],
        terminalInterfacesAsOptions: [],
        currenciesAsOptions: [],
        loading: false,
    },
	leadsSearchOpen: false,
}

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
    {
        // --- * get Banks
        [actions.requestGetBanks]: (state, action) => ({
            ...state,
            banks: { ...state.banks, loading: true },
        }),
        [actions.successGetBanks]: (state, action) => ({
            ...state,
            banks: {
                ...state.banks,
                dictBanks: FD_banks(action),
                data: action,
                loading: false,
            },
        }),
        [actions.successGetDictBanks]: (state, action) => ({
            ...state,
            banks: {
                ...state.banks,
				allBanksData: action,
                allBanksList: FD_banks(action),           
                allBanksMap: FD_banksMap(action),           
            },
        }),
        // --- * get payments
        [actions.requestGetPayments]: (state, action) => ({
            ...state,
            payments: { ...state.payments, loading: true },
        }),
        [actions.successGetPayments]: (state, action) => ({
            ...state,
            payments: { ...state.payments, data: action, loading: false },
        }),

    // --- * get partners
    [actions.requestGetPartners]: (state, action) => ({
      ...state,  partners: {...state.paymenpartnersts, loading: true}
    }),  
    [actions.finalGetPartners]: (state, action) => ({
      ...state,  partners: {...state.partners,  data: FD_partners(action), default: FD_default(action), loading: false}
    }), 

        // --- * get usersForApp
        [actions.requestUsersForApp]: (state, action) => ({
            ...state,
            usersForApp: { ...initialState.usersForApp, loading: true },
        }),
        [actions.finalUsersForApp]: (state, action) => ({
            ...state,
            usersForApp: {
                ...state.usersForApp,
                data: FD_MapToSelect(action),
                loading: false,
            },
        }),

        // --- * get Merchants ->>
        [actions.requestGetMerchants]: (state, action) => ({
            ...state,
            merchants: { ...initialState.merchants, loading: true },
        }),
        [actions.finalGetMerchants]: (state, action) => {
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    data: FD_MapToSelect(action),
                    loading: false,
                },
            }
        },

        // --- * get Merchant points->>
        [actions.requestGetMerchantPoints]: (state, action) => ({
            ...state,
            merchantPoints: { ...state.merchantPoints, loading: true },
        }),
        [actions.finalGetMerchantsPoints]: (state, action) => {
            return {
                ...state,
                merchantPoints: {
                    ...state.merchantPoints,
                    data: FD_MapToSelect(action),
                    loading: false,
                },
            }
        },
        // --- * get Merchant points by params->>
        [actions.requestGetMerchantPointsByParams]: (state, action) => ({
            ...state,
            merchantPoints: { ...state.merchantPoints, loading: true },
        }),
        [actions.finallGetMerchantPointsByParams]: (state, action) => {
            return {
                ...state,
                merchantPoints: {
                    ...state.merchantPoints,
                    data: FD_MapToSelect(action.data),
                    loading: false,
                },
            }
        },
        // --- * get pinpads->>
        [actions.requestGetPinpads]: (state, action) => ({
            ...state,
            pinpads: { ...state.pinpads, loading: true },
        }),
        [actions.successGetPinpads]: (state, action) => {
            return {
                ...state,
                pinpads: {
                    ...state.pinpads,
                    data: FD_MapToSelect(action),
                    loading: false,
                },
            }
        },
        // --- * get allDataForTmsConfigs->>
        [actions.requestGetAllDataForTmsConfigs]: (state, action) => ({
            ...state,
            allDataForTmsConfigs: {
                ...state.allDataForTmsConfigs,
                loading: true,
            },
        }),
        [actions.successGetAllDataForTmsConfigs]: (state, action) => {
            const { models = [], vendors = {}, tmsConfigs = [], terminalInterfaces = [], currencies = {} } = action
            const terminalInterfacesAsOptions = Object?.entries(
                terminalInterfaces
            )?.map(([key, value]) => {
                return { value: key, label: value }
            })
            const currenciesAsOptions = Object.entries(
                currencies
            )?.map(([key, value]) => {
                return { value: +key, label: value }
            })
            const vendorModelConfig = Object?.entries(vendors)?.map((vendor) => {
                const modelsForVendor = models
                    ?.filter((el) => +el.vendorId === +vendor[0])
                    ?.map((model) => {
                        const configForModel = tmsConfigs
                            ?.filter((config) =>
                                config.modelIds.includes(+model.id)
                            )
                            ?.map((config) => ({
                                value: +config.id,
                                label: config.name,
								...config
                            }))
                        return {
                            value: +model.id,
                            label: model.name,
                            children: configForModel,
                        }
                    })
                return {
                    value: +vendor[0],
                    label: vendor[1],
                    children: modelsForVendor,
                }
            })
            return {
                ...state,
                allDataForTmsConfigs: {
                    ...state.allDataForTmsConfigs,
                    data: action,
                    dataAsCascader: vendorModelConfig,
                    terminalInterfacesAsOptions,
                    currenciesAsOptions,
                    loading: false,
                },
            }
        },
		[actions.changeLeadSearchOpen]: (state, action) => {
			return {   ...state,
				leadsSearchOpen: action ?? !state.leadsSearchOpen,
			}
		 },
        LOGOUT_FROM_SYSTEM: (state, action) => initialState,
    },
    initialState
)
