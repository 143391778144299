import merchant from './merchant/Reducer';  
import crm from './crm/Reducer';  
import tct from './tct/Reducer';  
import statistics from './statistics/Reducer';  
import services from './services/Reducer';  
import administration from './administration/Reducer';

export default (state={}, action) => {  
	return { 
		merchant: merchant(state.merchant, action),   
		crm: crm(state.crm, action),   
		tct: tct(state.tct, action),   
		statistics: statistics(state.statistics, action),   
		services: services(state.services, action),
		administration: administration(state.administration, action),
	}
} 