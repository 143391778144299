
export const currencyList  = {
    "8": {
        "ISOnum": 8,
        "demonym": "Albanian",
        "majorPlural": "Lekë",
        "majorSingle": "Lek",
        "name": "Albanian Lek",
        "symbol": "L",
        "code": "ALL"
    },
    "12": {
        "ISOnum": 12,
        "demonym": "Algerian",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Algerian Dinar",
        "symbol": "DA",
        "code": "DZD"
    },
    "32": {
        "ISOnum": 32,
        "demonym": "Argentine",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Argentine Peso",
        "symbol": "AR$",
        "code": "ARS"
    },
    "36": {
        "ISOnum": 36,
        "demonym": "Australian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Australian Dollar",
        "symbol": "AU$",
        "code": "AUD"
    },
    "44": {
        "ISOnum": 44,
        "demonym": "Bahamian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Bahamian Dollar",
        "symbol": "$",
        "code": "BSD"
    },
    "48": {
        "ISOnum": 48,
        "demonym": "Bahraini",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Bahraini Dinar",
        "symbol": "BD",
        "code": "BHD"
    },
    "50": {
        "ISOnum": 50,
        "demonym": "Bangladeshi",
        "majorPlural": "Taka",
        "majorSingle": "Taka",
        "name": "Bangladeshi Taka",
        "symbol": "৳",
        "code": "BDT"
    },
    "51": {
        "ISOnum": 51,
        "demonym": "Armenian",
        "majorPlural": "Dram",
        "majorSingle": "Dram",
        "name": "Armenian Dram",
        "symbol": "֏",
        "code": "AMD"
    },
    "52": {
        "ISOnum": 52,
        "demonym": "Barbadian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Barbadian Dollar",
        "symbol": "BBD$",
        "code": "BBD"
    },
    "60": {
        "ISOnum": 60,
        "demonym": "Bermudian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Bermudian Dollar",
        "symbol": "$",
        "code": "BMD"
    },
    "64": {
        "ISOnum": 64,
        "demonym": "Bhutanese",
        "majorPlural": "Ngultrums",
        "majorSingle": "Ngultrum",
        "name": "Bhutanese Ngultrum",
        "symbol": "Nu.",
        "code": "BTN"
    },
    "68": {
        "ISOnum": 68,
        "demonym": "Bolivian",
        "majorPlural": "Bolivianos",
        "majorSingle": "Boliviano",
        "name": "Bolivian Boliviano",
        "symbol": "Bs.",
        "code": "BOB"
    },
    "72": {
        "ISOnum": 72,
        "demonym": "Botswana",
        "majorPlural": "Pula",
        "majorSingle": "Pula",
        "name": "Botswana Pula",
        "symbol": "P",
        "code": "BWP"
    },
    "84": {
        "ISOnum": 84,
        "demonym": "Belize",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Belize Dollar",
        "symbol": "BZ$",
        "code": "BZD"
    },
    "90": {
        "ISOnum": 90,
        "demonym": "Solomon Islands",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Solomon Islands Dollar",
        "symbol": "SI$",
        "code": "SBD"
    },
    "96": {
        "ISOnum": 96,
        "demonym": "Brunei",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Brunei Dollar",
        "symbol": "B$",
        "code": "BND"
    },
    "104": {
        "ISOnum": 104,
        "demonym": "Myanmar",
        "majorPlural": "Kyat",
        "majorSingle": "Kyat",
        "name": "Myanmar Kyat",
        "symbol": "Ks",
        "code": "MMK"
    },
    "108": {
        "ISOnum": 108,
        "demonym": "Burundian",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Burundian Franc",
        "symbol": "FBu",
        "code": "BIF"
    },
    "116": {
        "ISOnum": 116,
        "demonym": "Cambodian",
        "majorPlural": "Riels",
        "majorSingle": "Riel",
        "name": "Cambodian Riel",
        "symbol": "៛",
        "code": "KHR"
    },
    "124": {
        "ISOnum": 124,
        "demonym": "Canadian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Canadian Dollar",
        "symbol": "CA$",
        "code": "CAD"
    },
    "132": {
        "ISOnum": 132,
        "demonym": "Cabo Verdean",
        "majorPlural": "Escudo",
        "majorSingle": "Escudo",
        "name": "Cabo Verdean Escudo",
        "symbol": "CV$",
        "code": "CVE"
    },
    "136": {
        "ISOnum": 136,
        "demonym": "Cayman Islands",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Cayman Islands Dollar",
        "symbol": "CI$",
        "code": "KYD"
    },
    "144": {
        "ISOnum": 144,
        "demonym": "Sri Lankan",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Sri Lankan Rupee",
        "symbol": "Rs.",
        "code": "LKR"
    },
    "152": {
        "ISOnum": 152,
        "demonym": "Chilean",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Chilean Peso",
        "symbol": "CL$",
        "code": "CLP"
    },
    "156": {
        "ISOnum": 156,
        "demonym": "Chinese",
        "majorPlural": "Yuan",
        "majorSingle": "Yuan",
        "name": "Chinese Yuan",
        "symbol": "CN¥",
        "code": "CNY"
    },
    "170": {
        "ISOnum": 170,
        "demonym": "Colombian",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Colombian Peso",
        "symbol": "CO$",
        "code": "COP"
    },
    "174": {
        "ISOnum": 174,
        "demonym": "Comorian",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Comorian Franc",
        "symbol": "CF",
        "code": "KMF"
    },
    "188": {
        "ISOnum": 188,
        "demonym": "Costa Rican",
        "majorPlural": "Colones",
        "majorSingle": "Colón",
        "name": "Costa Rican Colon",
        "symbol": "₡",
        "code": "CRC"
    },
    "191": {
        "ISOnum": 191,
        "demonym": "Croatian",
        "majorPlural": "Kuna",
        "majorSingle": "Kuna",
        "name": "Croatian Kuna",
        "symbol": "kn",
        "code": "HRK"
    },
    "192": {
        "ISOnum": 192,
        "demonym": "Cuban",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Cuban Peso",
        "symbol": "$MN",
        "code": "CUP"
    },
    "203": {
        "ISOnum": 203,
        "demonym": "Czech",
        "majorPlural": "Koruny",
        "majorSingle": "Koruna",
        "name": "Czech Koruna",
        "symbol": "Kč",
        "code": "CZK"
    },
    "208": {
        "ISOnum": 208,
        "demonym": "Danish",
        "majorPlural": "Kroner",
        "majorSingle": "Krone",
        "name": "Danish Krone",
        "symbol": "kr.",
        "code": "DKK"
    },
    "214": {
        "ISOnum": 214,
        "demonym": "Dominican",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Dominican Peso",
        "symbol": "RD$",
        "code": "DOP"
    },
    "222": {
        "ISOnum": 222,
        "demonym": "Salvadoran",
        "majorPlural": "Colones",
        "majorSingle": "Colón",
        "name": "Salvadoran Colón",
        "symbol": "₡",
        "code": "SVC"
    },
    "230": {
        "ISOnum": 230,
        "demonym": "Ethiopian",
        "majorPlural": "Birr",
        "majorSingle": "Birr",
        "name": "Ethiopian Birr",
        "symbol": "Br",
        "code": "ETB"
    },
    "232": {
        "ISOnum": 232,
        "demonym": "Eritrean",
        "majorPlural": "Nakfa",
        "majorSingle": "Nakfa",
        "name": "Eritrean Nakfa",
        "symbol": "Nkf",
        "code": "ERN"
    },
    "238": {
        "ISOnum": 238,
        "demonym": "Falkland Islands",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Falkland Islands Pound",
        "symbol": "FK£",
        "code": "FKP"
    },
    "242": {
        "ISOnum": 242,
        "demonym": "Fijian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Fijian Dollar",
        "symbol": "FJ$",
        "code": "FJD"
    },
    "262": {
        "ISOnum": 262,
        "demonym": "Djiboutian",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Djiboutian Franc",
        "symbol": "Fdj",
        "code": "DJF"
    },
    "270": {
        "ISOnum": 270,
        "demonym": "Gambian",
        "majorPlural": "Dalasis",
        "majorSingle": "Dalasi",
        "name": "Gambian Dalasi",
        "symbol": "D",
        "code": "GMD"
    },
    "292": {
        "ISOnum": 292,
        "demonym": "Gibraltar",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Gibraltar Pound",
        "symbol": "£",
        "code": "GIP"
    },
    "320": {
        "ISOnum": 320,
        "demonym": "Guatemalan",
        "majorPlural": "Quetzales",
        "majorSingle": "Quetzal",
        "name": "Guatemalan Quetzal",
        "symbol": "Q",
        "code": "GTQ"
    },
    "324": {
        "ISOnum": 324,
        "demonym": "Guinean",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Guinean Franc",
        "symbol": "FG",
        "code": "GNF"
    },
    "328": {
        "ISOnum": 328,
        "demonym": "Guyanese",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Guyanese Dollar",
        "symbol": "G$",
        "code": "GYD"
    },
    "332": {
        "ISOnum": 332,
        "demonym": "Haitian",
        "majorPlural": "Gourdes",
        "majorSingle": "Gourde",
        "name": "Haitian Gourde",
        "symbol": "G",
        "code": "HTG"
    },
    "340": {
        "ISOnum": 340,
        "demonym": "Honduran",
        "majorPlural": "Lempiras",
        "majorSingle": "Lempira",
        "name": "Honduran Lempira",
        "symbol": "L",
        "code": "HNL"
    },
    "344": {
        "ISOnum": 344,
        "demonym": "Hong Kong",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Hong Kong Dollar",
        "symbol": "HK$",
        "code": "HKD"
    },
    "348": {
        "ISOnum": 348,
        "demonym": "Hungarian",
        "majorPlural": "Forint",
        "majorSingle": "Forint",
        "name": "Hungarian Forint",
        "symbol": "Ft",
        "code": "HUF"
    },
    "352": {
        "ISOnum": 352,
        "demonym": "Icelandic",
        "majorPlural": "Krónur",
        "majorSingle": "Krona",
        "name": "Icelandic Krona",
        "symbol": "kr",
        "code": "ISK"
    },
    "356": {
        "ISOnum": 356,
        "demonym": "Indian",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Indian Rupee",
        "symbol": "Rs.",
        "code": "INR"
    },
    "360": {
        "ISOnum": 360,
        "demonym": "Indonesian",
        "majorPlural": "Rupiah",
        "majorSingle": "Rupiah",
        "name": "Indonesian Rupiah",
        "symbol": "Rp",
        "code": "IDR"
    },
    "364": {
        "ISOnum": 364,
        "demonym": "Iranian",
        "majorPlural": "Rials",
        "majorSingle": "Rial",
        "name": "Iranian Rial",
        "symbol": "﷼",
        "code": "IRR"
    },
    "368": {
        "ISOnum": 368,
        "demonym": "Iraqi",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Iraqi Dinar",
        "symbol": "د.ع.",
        "code": "IQD"
    },
    "376": {
        "ISOnum": 376,
        "demonym": "Israeli",
        "majorPlural": "Shekels",
        "majorSingle": "Shekel",
        "name": "Israeli new Shekel",
        "symbol": "₪",
        "code": "ILS"
    },
    "388": {
        "ISOnum": 388,
        "demonym": "Jamaican",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Jamaican Dollar",
        "symbol": "J$",
        "code": "JMD"
    },
    "392": {
        "ISOnum": 392,
        "demonym": "Japanese",
        "majorPlural": "Yen",
        "majorSingle": "Yen",
        "name": "Japanese Yen",
        "symbol": "¥",
        "code": "JPY"
    },
    "398": {
        "ISOnum": 398,
        "demonym": "Kazakhstani",
        "majorPlural": "Tenge",
        "majorSingle": "Tenge",
        "name": "Kazakhstani Tenge",
        "symbol": "₸",
        "code": "KZT"
    },
    "400": {
        "ISOnum": 400,
        "demonym": "Jordanian",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Jordanian Dinar",
        "symbol": "JD",
        "code": "JOD"
    },
    "404": {
        "ISOnum": 404,
        "demonym": "Kenyan",
        "majorPlural": "Shillings",
        "majorSingle": "Shilling",
        "name": "Kenyan Shilling",
        "symbol": "KSh",
        "code": "KES"
    },
    "408": {
        "ISOnum": 408,
        "demonym": "North Korean",
        "majorPlural": "Won",
        "majorSingle": "Won",
        "name": "North Korean Won",
        "symbol": "₩",
        "code": "KPW"
    },
    "410": {
        "ISOnum": 410,
        "demonym": "South Korean",
        "majorPlural": "Won",
        "majorSingle": "Won",
        "name": "South Korean Won",
        "symbol": "₩",
        "code": "KRW"
    },
    "414": {
        "ISOnum": 414,
        "demonym": "Kuwaiti",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Kuwaiti Dinar",
        "symbol": "KD",
        "code": "KWD"
    },
    "417": {
        "ISOnum": 417,
        "demonym": "Kyrgyzstani",
        "majorPlural": "Som",
        "majorSingle": "Som",
        "name": "Kyrgyzstani Som",
        "symbol": "с",
        "code": "KGS"
    },
    "418": {
        "ISOnum": 418,
        "demonym": "Lao",
        "majorPlural": "Kip",
        "majorSingle": "Kip",
        "name": "Lao Kip",
        "symbol": "₭N",
        "code": "LAK"
    },
    "422": {
        "ISOnum": 422,
        "demonym": "Lebanese",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Lebanese Pound",
        "symbol": "LL.",
        "code": "LBP"
    },
    "426": {
        "ISOnum": 426,
        "demonym": "Lesotho",
        "majorPlural": "maLoti",
        "majorSingle": "Loti",
        "name": "Lesotho Loti",
        "symbol": "L",
        "code": "LSL"
    },
    "430": {
        "ISOnum": 430,
        "demonym": "Liberian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Liberian Dollar",
        "symbol": "L$",
        "code": "LRD"
    },
    "434": {
        "ISOnum": 434,
        "demonym": "Libyan",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Libyan Dinar",
        "symbol": "LD",
        "code": "LYD"
    },
    "446": {
        "ISOnum": 446,
        "demonym": "Macanese",
        "majorPlural": "Patacas",
        "majorSingle": "Pataca",
        "name": "Macanese Pataca",
        "symbol": "MOP$",
        "code": "MOP"
    },
    "454": {
        "ISOnum": 454,
        "demonym": "Malawian",
        "majorPlural": "Kwacha",
        "majorSingle": "Kwacha",
        "name": "Malawian Kwacha",
        "symbol": "MK",
        "code": "MWK"
    },
    "458": {
        "ISOnum": 458,
        "demonym": "Malaysian",
        "majorPlural": "Ringgit",
        "majorSingle": "Ringgit",
        "name": "Malaysian Ringgit",
        "symbol": "RM",
        "code": "MYR"
    },
    "462": {
        "ISOnum": 462,
        "demonym": "Maldivian",
        "majorPlural": "Rufiyaa",
        "majorSingle": "Rufiyaa",
        "name": "Maldivian Rufiyaa",
        "symbol": "MRf",
        "code": "MVR"
    },
    "480": {
        "ISOnum": 480,
        "demonym": "Mauritian",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Mauritian Rupee",
        "symbol": "Rs.",
        "code": "MUR"
    },
    "484": {
        "ISOnum": 484,
        "demonym": "Mexican",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Mexican Peso",
        "symbol": "MX$",
        "code": "MXN"
    },
    "496": {
        "ISOnum": 496,
        "demonym": "Mongolian",
        "majorPlural": "Tögrög",
        "majorSingle": "Tögrög",
        "name": "Mongolian Tögrög",
        "symbol": "₮",
        "code": "MNT"
    },
    "498": {
        "ISOnum": 498,
        "demonym": "Moldovan",
        "majorPlural": "Lei",
        "majorSingle": "Leu",
        "name": "Moldovan Leu",
        "symbol": "L",
        "code": "MDL"
    },
    "504": {
        "ISOnum": 504,
        "demonym": "Moroccan",
        "majorPlural": "Dirhams",
        "majorSingle": "Dirham",
        "name": "Moroccan Dirham",
        "symbol": "DH",
        "code": "MAD"
    },
    "512": {
        "ISOnum": 512,
        "demonym": "Omani",
        "majorPlural": "Rials",
        "majorSingle": "Rial",
        "name": "Omani Rial",
        "symbol": "OR",
        "code": "OMR"
    },
    "516": {
        "ISOnum": 516,
        "demonym": "Namibian",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Namibian Dollar",
        "symbol": "N$",
        "code": "NAD"
    },
    "524": {
        "ISOnum": 524,
        "demonym": "Nepalese",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Nepalese Rupee",
        "symbol": "Rs.",
        "code": "NPR"
    },
    "532": {
        "ISOnum": 532,
        "demonym": "Netherlands Antillean",
        "majorPlural": "Guilders",
        "majorSingle": "Guilder",
        "name": "Netherlands Antillean Guilder",
        "symbol": "ƒ",
        "code": "ANG"
    },
    "533": {
        "ISOnum": 533,
        "demonym": "Aruban",
        "majorPlural": "Florin",
        "majorSingle": "Florin",
        "name": "Aruban Florin",
        "symbol": "ƒ",
        "code": "AWG"
    },
    "548": {
        "ISOnum": 548,
        "demonym": "Vanuatu",
        "majorPlural": "Vatu",
        "majorSingle": "Vatu",
        "name": "Vanuatu Vatu",
        "symbol": "VT",
        "code": "VUV"
    },
    "554": {
        "ISOnum": 554,
        "demonym": "New Zealand",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "New Zealand Dollar",
        "symbol": "NZ$",
        "code": "NZD"
    },
    "558": {
        "ISOnum": 558,
        "demonym": "Nicaraguan",
        "majorPlural": "Córdoba Oro",
        "majorSingle": "Córdoba Oro",
        "name": "Nicaraguan Córdoba",
        "symbol": "C$",
        "code": "NIO"
    },
    "566": {
        "ISOnum": 566,
        "demonym": "Nigerian",
        "majorPlural": "Naira",
        "majorSingle": "Naira",
        "name": "Nigerian Naira",
        "symbol": "₦",
        "code": "NGN"
    },
    "578": {
        "ISOnum": 578,
        "demonym": "Norwegian",
        "majorPlural": "Kroner",
        "majorSingle": "Krone",
        "name": "Norwegian Krone",
        "symbol": "kr",
        "code": "NOK"
    },
    "586": {
        "ISOnum": 586,
        "demonym": "Pakistani",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Pakistani Rupee",
        "symbol": "Rs.",
        "code": "PKR"
    },
    "590": {
        "ISOnum": 590,
        "demonym": "Panamanian",
        "majorPlural": "Balboa",
        "majorSingle": "Balboa",
        "name": "Panamanian Balboa",
        "symbol": "B/.",
        "code": "PAB"
    },
    "598": {
        "ISOnum": 598,
        "demonym": "Papua New Guinean",
        "majorPlural": "Kina",
        "majorSingle": "Kina",
        "name": "Papua New Guinean Kina",
        "symbol": "K",
        "code": "PGK"
    },
    "600": {
        "ISOnum": 600,
        "demonym": "Paraguayan",
        "majorPlural": "Guaraníes",
        "majorSingle": "Guaraní",
        "name": "Paraguayan Guaraní",
        "symbol": "₲",
        "code": "PYG"
    },
    "604": {
        "ISOnum": 604,
        "demonym": "Peruvian",
        "majorPlural": "Soles",
        "majorSingle": "Sol",
        "name": "Peruvian Sol",
        "symbol": "S/.",
        "code": "PEN"
    },
    "608": {
        "ISOnum": 608,
        "demonym": "Philippine",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Philippine Peso",
        "symbol": "₱",
        "code": "PHP"
    },
    "634": {
        "ISOnum": 634,
        "demonym": "Qatari",
        "majorPlural": "Riyals",
        "majorSingle": "Riyal",
        "name": "Qatari Riyal",
        "symbol": "QR",
        "code": "QAR"
    },
    "643": {
        "ISOnum": 643,
        "demonym": "Russian",
        "majorPlural": "Rubles",
        "majorSingle": "Ruble",
        "name": "Russian Ruble",
        "symbol": "₽",
        "code": "RUB"
    },
    "646": {
        "ISOnum": 646,
        "demonym": "Rwandan",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Rwandan Franc",
        "symbol": "FRw",
        "code": "RWF"
    },
    "654": {
        "ISOnum": 654,
        "demonym": "Saint Helena",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Saint Helena Pound",
        "symbol": "£",
        "code": "SHP"
    },
    "682": {
        "ISOnum": 682,
        "demonym": "Saudi",
        "majorPlural": "Riyals",
        "majorSingle": "Riyal",
        "name": "Saudi Riyal",
        "symbol": "SR",
        "code": "SAR"
    },
    "690": {
        "ISOnum": 690,
        "demonym": "Seychellois",
        "majorPlural": "Rupees",
        "majorSingle": "Rupee",
        "name": "Seychellois Rupee",
        "symbol": "Rs.",
        "code": "SCR"
    },
    "694": {
        "ISOnum": 694,
        "demonym": "Sierra Leonean",
        "majorPlural": "Leones",
        "majorSingle": "Leone",
        "name": "Sierra Leonean Leone",
        "symbol": "Le",
        "code": "SLL"
    },
    "702": {
        "ISOnum": 702,
        "demonym": "Singapore",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Singapore Dollar",
        "symbol": "S$",
        "code": "SGD"
    },
    "704": {
        "ISOnum": 704,
        "demonym": "Vietnamese",
        "majorPlural": "Dong",
        "majorSingle": "Dong",
        "name": "Vietnamese Dong",
        "symbol": "₫",
        "code": "VND"
    },
    "706": {
        "ISOnum": 706,
        "demonym": "Somali",
        "majorPlural": "Shillings",
        "majorSingle": "Shilling",
        "name": "Somali Shilling",
        "symbol": "Sh.So.",
        "code": "SOS"
    },
    "710": {
        "ISOnum": 710,
        "demonym": "South African",
        "majorPlural": "Rand",
        "majorSingle": "Rand",
        "name": "South African Rand",
        "symbol": "R",
        "code": "ZAR"
    },
    "728": {
        "ISOnum": 728,
        "demonym": "South Sudanese",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "South Sudanese Pound",
        "symbol": "SS£",
        "code": "SSP"
    },
    "748": {
        "ISOnum": 748,
        "demonym": "Swazi",
        "majorPlural": "Emalangeni",
        "majorSingle": "Lilangeni",
        "name": "Swazi Lilangeni",
        "symbol": "L",
        "code": "SZL"
    },
    "752": {
        "ISOnum": 752,
        "demonym": "Swedish",
        "majorPlural": "Kronor",
        "majorSingle": "Krona",
        "name": "Swedish Krona",
        "symbol": "kr",
        "code": "SEK"
    },
    "756": {
        "ISOnum": 756,
        "demonym": "Swiss",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Swiss Franc",
        "symbol": "Fr.",
        "code": "CHF"
    },
    "760": {
        "ISOnum": 760,
        "demonym": "Syrian",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Syrian Pound",
        "symbol": "LS",
        "code": "SYP"
    },
    "764": {
        "ISOnum": 764,
        "demonym": "Thai",
        "majorPlural": "Baht",
        "majorSingle": "Baht",
        "name": "Thai Baht",
        "symbol": "฿",
        "code": "THB"
    },
    "776": {
        "ISOnum": 776,
        "demonym": "Tongan",
        "majorPlural": "Pa'anga",
        "majorSingle": "Pa'anga",
        "name": "Tongan Paʻanga",
        "symbol": "T$",
        "code": "TOP"
    },
    "780": {
        "ISOnum": 780,
        "demonym": "Trinidad and Tobago",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Trinidad and Tobago Dollar",
        "symbol": "TT$",
        "code": "TTD"
    },
    "784": {
        "ISOnum": 784,
        "demonym": "UAE",
        "majorPlural": "Dirhams",
        "majorSingle": "Dirham",
        "name": "United Arab Emirates Dirham",
        "symbol": "د.إ.",
        "code": "AED"
    },
    "788": {
        "ISOnum": 788,
        "demonym": "Tunisian",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Tunisian Dinar",
        "symbol": "DT",
        "code": "TND"
    },
    "800": {
        "ISOnum": 800,
        "demonym": "Ugandan",
        "majorPlural": "Shillings",
        "majorSingle": "Shilling",
        "name": "Ugandan Shilling",
        "symbol": "USh",
        "code": "UGX"
    },
    "807": {
        "ISOnum": 807,
        "demonym": "Macedonian",
        "majorPlural": "Denars",
        "majorSingle": "Denar",
        "name": "Macedonian Denar",
        "symbol": "den",
        "code": "MKD"
    },
    "818": {
        "ISOnum": 818,
        "demonym": "Egyptian",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Egyptian Pound",
        "symbol": "E£",
        "code": "EGP"
    },
    "826": {
        "ISOnum": 826,
        "demonym": "Pound Sterling",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Pound Sterling",
        "symbol": "£",
        "code": "GBP"
    },
    "834": {
        "ISOnum": 834,
        "demonym": "Tanzanian",
        "majorPlural": "Shillings",
        "majorSingle": "Shilling",
        "name": "Tanzanian Shilling",
        "symbol": "TSh",
        "code": "TZS"
    },
    "840": {
        "ISOnum": 840,
        "demonym": "US",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "United States Dollar",
        "symbol": "$",
        "code": "USD"
    },
    "858": {
        "ISOnum": 858,
        "demonym": "Uruguayan",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Uruguayan Peso",
        "symbol": "$U",
        "code": "UYU"
    },
    "860": {
        "ISOnum": 860,
        "demonym": "Uzbekistani",
        "majorPlural": "Som",
        "majorSingle": "Som",
        "name": "Uzbekistani Som",
        "symbol": "сум",
        "code": "UZS"
    },
    "882": {
        "ISOnum": 882,
        "demonym": "Samoan",
        "majorPlural": "Tala",
        "majorSingle": "Tala",
        "name": "Samoan Tala",
        "symbol": "T",
        "code": "WST"
    },
    "886": {
        "ISOnum": 886,
        "demonym": "Yemeni",
        "majorPlural": "Rials",
        "majorSingle": "Rial",
        "name": "Yemeni Rial",
        "symbol": "YR",
        "code": "YER"
    },
    "901": {
        "ISOnum": 901,
        "demonym": "New Taiwan",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "New Taiwan Dollar",
        "symbol": "NT$",
        "code": "TWD"
    },
    "928": {
        "ISOnum": 928,
        "demonym": "Venezuelan",
        "majorPlural": "Bolívares",
        "majorSingle": "Bolívar",
        "name": "Venezuelan Bolívar Soberano",
        "symbol": "Bs.F",
        "code": "VES"
    },
    "929": {
        "ISOnum": 929,
        "demonym": "Mauritanian",
        "majorPlural": "Ouguiya",
        "majorSingle": "Ouguiya",
        "name": "Mauritanian Ouguiya",
        "symbol": "UM",
        "code": "MRU"
    },
    "930": {
        "ISOnum": 930,
        "demonym": "Sao Tome",
        "majorPlural": "Dobras",
        "majorSingle": "Dobra",
        "name": "Sao Tome and Príncipe Dobra",
        "symbol": "Db",
        "code": "STN"
    },
    "931": {
        "ISOnum": 931,
        "demonym": "Cuban Convertible",
        "majorPlural": "Pesos",
        "majorSingle": "Peso",
        "name": "Cuban convertible Peso",
        "symbol": "CUC$",
        "code": "CUC"
    },
    "932": {
        "ISOnum": 932,
        "demonym": "Zimbabwean",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Zimbabwean Dollar",
        "symbol": "Z$",
        "code": "ZWL"
    },
    "933": {
        "ISOnum": 933,
        "demonym": "Belarusian",
        "majorPlural": "Rubles",
        "majorSingle": "Ruble",
        "name": "Belarusian Ruble",
        "symbol": "Br",
        "code": "BYN"
    },
    "934": {
        "ISOnum": 934,
        "demonym": "Turkmenistan",
        "majorPlural": "Manat",
        "majorSingle": "Manat",
        "name": "Turkmenistan Manat",
        "symbol": "m.",
        "code": "TMT"
    },
    "936": {
        "ISOnum": 936,
        "demonym": "Ghanaian",
        "majorPlural": "Cedis",
        "majorSingle": "Cedi",
        "name": "Ghanaian Cedi",
        "symbol": "GH₵",
        "code": "GHS"
    },
    "938": {
        "ISOnum": 938,
        "demonym": "Sudanese",
        "majorPlural": "Pounds",
        "majorSingle": "Pound",
        "name": "Sudanese Pound",
        "symbol": "£SD",
        "code": "SDG"
    },
    "941": {
        "ISOnum": 941,
        "demonym": "Serbian",
        "majorPlural": "Dinars",
        "majorSingle": "Dinar",
        "name": "Serbian Dinar",
        "symbol": "din",
        "code": "RSD"
    },
    "943": {
        "ISOnum": 943,
        "demonym": "Mozambican",
        "majorPlural": "Meticais",
        "majorSingle": "Metical",
        "name": "Mozambican Metical",
        "symbol": "MTn",
        "code": "MZN"
    },
    "944": {
        "ISOnum": 944,
        "demonym": "Azerbaijani",
        "majorPlural": "Manat",
        "majorSingle": "Manat",
        "name": "Azerbaijani Manat",
        "symbol": "ман",
        "code": "AZN"
    },
    "946": {
        "ISOnum": 946,
        "demonym": "Romanian",
        "majorPlural": "Lei",
        "majorSingle": "Leu",
        "name": "Romanian Leu",
        "symbol": "L",
        "code": "RON"
    },
    "949": {
        "ISOnum": 949,
        "demonym": "Turkish",
        "majorPlural": "Lira",
        "majorSingle": "Lira",
        "name": "Turkish Lira",
        "symbol": "TL",
        "code": "TRY"
    },
    "950": {
        "ISOnum": 950,
        "demonym": "Central African CFA",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Central African CFA Franc BEAC",
        "symbol": "Fr",
        "code": "XAF"
    },
    "951": {
        "ISOnum": 951,
        "demonym": "East Caribbean",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "East Caribbean Dollar",
        "symbol": "$",
        "code": "XCD"
    },
    "952": {
        "ISOnum": 952,
        "demonym": "West African CFA",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "West African CFA Franc BCEAO",
        "symbol": "₣",
        "code": "XOF"
    },
    "953": {
        "ISOnum": 953,
        "demonym": "CFP",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "CFP Franc (Franc Pacifique)",
        "symbol": "₣",
        "code": "XPF"
    },
    "967": {
        "ISOnum": 967,
        "demonym": "Zambian",
        "majorPlural": "Kwacha",
        "majorSingle": "Kwacha",
        "name": "Zambian Kwacha",
        "symbol": "ZK",
        "code": "ZMW"
    },
    "968": {
        "ISOnum": 968,
        "demonym": "Surinamese",
        "majorPlural": "Dollars",
        "majorSingle": "Dollar",
        "name": "Surinamese Dollar",
        "symbol": "Sr$",
        "code": "SRD"
    },
    "969": {
        "ISOnum": 969,
        "demonym": "Malagasy",
        "majorPlural": "Ariary",
        "majorSingle": "Ariary",
        "name": "Malagasy Ariary",
        "symbol": "Ar",
        "code": "MGA"
    },
    "971": {
        "ISOnum": 971,
        "demonym": "Afghan",
        "majorPlural": "Afghani",
        "majorSingle": "Afghani",
        "name": "Afghan Afghani",
        "symbol": "Af",
        "code": "AFN"
    },
    "972": {
        "ISOnum": 972,
        "demonym": "Tajikistani",
        "majorPlural": "Somoni",
        "majorSingle": "Somoni",
        "name": "Tajikistani Somoni",
        "symbol": "TJS",
        "code": "TJS"
    },
    "973": {
        "ISOnum": 973,
        "demonym": "Angolan",
        "majorPlural": "Kwanza",
        "majorSingle": "Kwanza",
        "name": "Angolan Kwanza",
        "symbol": "Kz",
        "code": "AOA"
    },
    "975": {
        "ISOnum": 975,
        "demonym": "Bulgarian",
        "majorPlural": "Leva",
        "majorSingle": "Lev",
        "name": "Bulgarian Lev",
        "symbol": "лв.",
        "code": "BGN"
    },
    "976": {
        "ISOnum": 976,
        "demonym": "Congolese",
        "majorPlural": "Francs",
        "majorSingle": "Franc",
        "name": "Congolese Franc",
        "symbol": "FC",
        "code": "CDF"
    },
    "977": {
        "ISOnum": 977,
        "demonym": "Bosnia-Herzegovina",
        "majorPlural": "Marks",
        "majorSingle": "Convertible Mark",
        "name": "Bosnia and Herzegovina Convertible Mark",
        "symbol": "KM",
        "code": "BAM"
    },
    "978": {
        "ISOnum": 978,
        "demonym": "",
        "majorPlural": "Euros",
        "majorSingle": "Euro",
        "name": "Euro",
        "symbol": "€",
        "code": "EUR"
    },
    "980": {
        "ISOnum": 980,
        "demonym": "Ukrainian",
        "majorPlural": "Hryvnias",
        "majorSingle": "Hryvnia",
        "name": "Ukrainian Hryvnia",
        "symbol": "₴",
        "code": "UAH"
    },
    "981": {
        "ISOnum": 981,
        "demonym": "Georgian",
        "majorPlural": "Lari",
        "majorSingle": "Lari",
        "name": "Georgian Lari",
        "symbol": "₾",
        "code": "GEL"
    },
    "985": {
        "ISOnum": 985,
        "demonym": "Polish",
        "majorPlural": "Zlotys",
        "majorSingle": "Zloty",
        "name": "Polish Zloty",
        "symbol": "zł",
        "code": "PLN"
    },
    "986": {
        "ISOnum": 986,
        "demonym": "Brazilian",
        "majorPlural": "Reais",
        "majorSingle": "Real",
        "name": "Brazilian Real",
        "symbol": "R$",
        "code": "BRL"
    }
}