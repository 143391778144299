export const ticketColorPriority = {
    'A_PLANNED': 'cyan',
    'B_CLIENT_WAITING': 'orange',
    'C_HIGH': 'volcano'
}

export const ServiceTicketPriorities = [
    { value: 'A_PLANNED', label: 'Плановая' },
    { value: 'B_CLIENT_WAITING', label: 'Клиент ждет' },
    { value: 'C_HIGH', label: 'Максимальный приоритет' },
];

export const ServiceTicketStatuses = [
    { value: 'NEW', label: 'Новая' },
    { value: 'IN_PROGRESS', label: 'В работе' },
    { value: 'WAITING', label: 'Ожидаем клиента' },
    { value: 'CHECK_OUT', label: 'Выезд' },
    { value: 'PROBLEM', label: 'Проблема' },
    { value: 'DENIED', label: 'Отказ' },
    { value: 'BANK_CALL', label: 'Звонок из банка' },
    { value: 'CLARIFICATION', label: 'Уточнение данных' },
    { value: 'WAITING_FOR_ENGINEER_CALL', label: 'Ждет звонка инженера' },
    { value: 'FIRST_SERVICE_CALL', label: 'Первичный обзвон CC' },
    { value: 'REPEATED_SERVICE_CALL', label: 'Повторный звонок CC' },
    { value: 'NEEDS_CONFIRMATION', label: 'Выполнено' },
    { value: 'Needs confirmation', label: 'Выполнено (Требует подтверждения)' },
    { value: 'NEEDS_CONFIRMATION_LOST', label: 'Выполнено (Клиент ушел в другой банк)' },
    { value: 'Needs confirmation lost', label: 'Выполнено (Клиент ушел в другой банк) (Требует подтверждения)' },
    { value: 'COMPLETED', label: 'Завершена' },
    { value: 'MERCHANT_CLOSED', label: 'ТСП закрыто' },
];

export const ServiceTicketTypes = [
    { value: 'INSTALL', label: 'Установка оборудования' },
    { value: 'REMOVE', label: 'Снятие оборудования' },
    { value: 'SERVICE', label: 'Обслуживание' },
];