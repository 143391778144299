import newLead from './newLead/saga';  
import newMerch from './newMerch/saga';  
import faq from './faq/saga';  
import news from './news/saga';  
 
export default [ 
  ...newLead,  
  ...newMerch,  
  ...faq,  
  ...news,  
]
 