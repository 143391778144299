import moment from "moment";

export function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item) && !moment.isMoment(item));
}
  
  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
//  export function mergeDeep(target, ...sources) {
// 	if (!sources.length) return target;
// 	const source = sources.shift();
// console.log(target,sources)
// 	if (isObject(target) && isObject(source)) {
// 		for (const key in source) {
// 			if (isObject(source[key])) {
// 				if (!target[key]) Object.assign(target, { [key]: {} });
// 				mergeDeep(target[key], source[key]);
// 			} else {
// 				console.log(target, { [key]: source[key] }, source, key)
// 				Object.assign(target, { [key]: source[key] });
// 			}
// 		}
// 	}

// 	return mergeDeep(target, ...sources);
// }
export function  mergeDeep(target, source) {
	let output = Object.assign({}, target);
	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach(key => {
			if (isObject(source[key])) {
				if (!(key in target)){
					Object.assign(output, { [key]: source[key] });
				} else {
					output[key] = mergeDeep(target[key], source[key]);
				}
				
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}
	return output;
};

export function flatObject(obj) {
	function flat(res, key, val, pre = '') {
	  const prefix = [pre, key].filter(v => v).join('.');

	  if(Object.prototype.toString.call(val) === '[object Object]'){
		return Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
	  }
	  return Object.assign(res, { [prefix]: val});
	}
  
	return Object.keys(obj).reduce((prev, curr) => flat(prev, curr, obj[curr]), {});
}
export function unFlatObject(data) {
	const result = {};

	for (let i in data) {
	  const keys = i.split('.')
	  keys.reduce((prev, curr, index) => {
		if(prev[curr]) return prev[curr]

		if(isNaN(Number(keys[index + 1]))) return prev[curr] = (keys.length - 1 === index ? data[i] : {})
		
		return prev[curr] = []

	  }, result)
	};

	return result
}
/**  setNestedProp({}, ["first", "second", "a"], "foo") */
export function setNestedProp(obj = {}, [prop, ...rest], value) {
    const newObj = Array.isArray(obj) ? [...obj] : {...obj};
    newObj[prop] = rest.length ? setNestedProp(obj[prop], rest, value) : value;
    return newObj;
}