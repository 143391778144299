
import applications from './applications/saga';  
import app_points from './app_points/saga';  
import tcp from './tcp/saga';  
import tcp_groups from './tcp_groups/saga';  
import terminals from './terminals/saga';  
import shifts from './shifts/saga';  
import functionality from './functionality/saga';
import terminalsGroup from './terminalsGroup/saga';

export default [  
  ...applications,  
  ...app_points,  
  ...tcp,  
  ...tcp_groups,  
  ...terminals,  
  ...shifts,  
  ...functionality,
  ...terminalsGroup,
]
 