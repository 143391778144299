import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/other/faq';  
import * as actions from './actions'   

export default [    
	takeLatest(actions.requestGetFileList, requestGetFileList),      
	takeLatest(actions.downloadFile, downloadFile),      
]
 

//
function* requestGetFileList(action) {
	try {   
		const data = yield call(API.getFileList, action.payload);  
		yield put(actions.finalGetFileList(data))  
	} catch (e) { 
		yield put(actions.finalGetFileList([])) 
	}
} 

//
function* downloadFile(action) {
	try {   
		yield call(API.downloadFile, action.payload);  
	} catch (e) { 
	}
} 
 