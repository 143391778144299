import * as actions from "./actions";
import { createReducer } from "redux-act";


const initialState = { 
  data: [], 
  loading: false, 
  one: {
    loading: false,
    data: {},
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- Async get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.successGetOne]: (state, action) => {
      return { ...state, one: {...initialState.one, loading: false, data: action}}
    },
    [actions.clearGetOne]: (state, action) => ({
      ...state, one: initialState.one
    }),
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
