import { Row, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useGetNspkData } from '../pos-common/hooks/queries';
import OptionsPopover from '../shares/components/OptionsWithPopover';
import { setNspkSelectOptions, setSbpColumnsData } from './customerSlice';
import { fastPaymentConfigOptions, getFastPaymentPopoverTitle } from '../shares/dicts/pos';
import { selectPermissions } from '../shares/helpers/commonReduxSelectors';
import { checkUpdatePermissionBySection, sbpPermissionSectionType } from '../pos-common/helpers';

const findSpbColumnsObject = (sbpColumnsData, recordId) => {
	return sbpColumnsData.find(item => item.merchantPointId === recordId)
};

const changeSelectOption = (value,name, dispatch,sbpColumnsData, recordId ) => {
	const newData = sbpColumnsData.map(pos => {
		if(pos.merchantPointId === recordId){
			if(name === 'fastPaymentMerchantId' && !value){
				return {...pos, [name]: null, fastPaymentConfigsOnTerminals: null}
			}
			return {...pos, [name]: value ? value : null}
		}
		return pos
	});
	dispatch(setSbpColumnsData(newData))
};


export const CustomerFastPaymentConfigSelect = ({ record }) => {
	const dispatch = useDispatch()
	const sbpColumnsData = useSelector(state => state.customer.sbpColumnsData)
	const permission = useSelector(selectPermissions);
	const hasUpdatePermission = checkUpdatePermissionBySection(permission, sbpPermissionSectionType.customer)

	const onChange = (value) => {
		changeSelectOption(value,'fastPaymentConfigsOnTerminals', dispatch,sbpColumnsData, record?.id )
	};

	const currentPoint = useMemo(() => {
		if(!sbpColumnsData?.length) return 'DO_NOT_CHANGE'

		return findSpbColumnsObject(sbpColumnsData, record?.id)
	},[record?.id, sbpColumnsData]);

	return (
		<Row className='w-full' style={{ width: 180}}>
			<Select
				allowClear
				className='w-full'
				value={currentPoint?.fastPaymentConfigsOnTerminals}
				onChange={onChange}
				disabled={!currentPoint?.fastPaymentMerchantId || !hasUpdatePermission}
			>
				{fastPaymentConfigOptions.map((opt, index) => (
					<Select.Option key={opt.value}  value={opt.value} >
						<OptionsPopover  options={opt} title={getFastPaymentPopoverTitle(opt.value)} />
					</Select.Option>
				))}
			</Select>
		</Row>
	)
};


export const CustomerFastPaymentNspkSelect = ({ record }) => {

	const dispatch = useDispatch()
    const { id } = useParams()
	const sbpColumnsData = useSelector(state => state.customer.sbpColumnsData)
	const permission = useSelector(selectPermissions);
	const hasUpdatePermission = checkUpdatePermissionBySection(permission, sbpPermissionSectionType.customer)

	const { data: { options = []} = {} } = useGetNspkData(id , true,{
		onSuccess: ({ options }) => {
			dispatch(setNspkSelectOptions(options))
        },
	})

	const onChange = (value) => {
		changeSelectOption(value,'fastPaymentMerchantId', dispatch,sbpColumnsData, record?.id )
	};

	const currentValue = useMemo(() => {
		if(!sbpColumnsData?.length || !options?.length ) return null

		return findSpbColumnsObject(sbpColumnsData, record?.id)?.fastPaymentMerchantId
	},[record?.id, sbpColumnsData, options?.length]);

	return (
		<Row className='w-full' style={{ width: 200}}>
			<Select
				allowClear
				showSearch 
				optionFilterProp="label"
				className='w-full'
				value={currentValue}
				onChange={onChange}
				disabled={!hasUpdatePermission}
			>
				{options.map((opt, index) => (
					<Select.Option key={opt.value}  value={opt.value} >
						<OptionsPopover  options={opt} title={opt.label} />
					</Select.Option>
				))}
			</Select>
		</Row>
	)
};

