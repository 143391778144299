import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import merchantInvoicesAPI from '../../api/v2/merchantInvoicesAPI'
import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import formatingObjectToView from "../shares/helpers/formatingObjectForView"
import moment from 'moment'


export const getMerchantInvoice = createAsyncThunk(
    "merchantInvoices-update/one/get",
    async (invoiceId) => {
        const response = await merchantInvoicesAPI.getMerchantInvoices()
        return {response, invoiceId}
    }
)

export const postMerchantInvoiceCreate = createAsyncThunk(
    "merchantInvoices-update/create/post",
    async (payload) => {
        const invoiceParts = [{description: payload.description, clientId: payload.invoiceParts, status: 'NEW', amount: payload.totalAmount}]
        const formatedPayload = {...payload, edat: +moment(payload.edat), invoiceParts, status: 'NEW'}
        const response = await merchantInvoicesAPI.createMerchantInvoice(formatedPayload)
        return response
    }
)

export const getMerchantClients = createAsyncThunk(
    "merchantInvoices-update/merchantClients/get",
    async () => {
        const response = await merchantInvoicesAPI.getMerchantClients()
        return response
    }
)

const initialState = {
    data: [],
    merchantClients: [],
    merchantClientsOptions: [],
    loading: false
}

const merchantInvoicesCreateSlice = createSlice({
    name: "merchantInvoices-update",
    initialState,
    reducers: {
        clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [postMerchantInvoiceCreate.pending]: (state) => {
            state.loading = true
        },
        [postMerchantInvoiceCreate.fulfilled]: (state) => {
            state.loading = false
        },
        [postMerchantInvoiceCreate.rejected]: (state) => {
            state.loading = false
        },
        [getMerchantClients.pending]: (state) => {
            state.loading = true
        },
        [getMerchantClients.fulfilled]: (state, { payload }) => {
            state.merchantClients = payload
            state.merchantClientsOptions = payload.map(el => ({ value: el.id, label: `${el.name} ${el.inn}`}))
            state.loading = false
        },
        [getMerchantClients.reject]: (state) => {
            state.currencies = []
            state.loading = false
        },
        [getMerchantInvoice.pending]: (state) => {
            state.loading = true
        },
        [getMerchantInvoice.fulfilled]: (state, { payload }) => {
            const invoice = payload?.response?.find((element, index, array) => {
                if (element.id === Number(payload.invoiceId)) {
                    return element
                }
                return false
            })
            keysWithDateTimeValues.forEach(el => {
                if (invoice[el]) {
                    invoice[el] = moment(invoice[el])
                }
            })
            state.data = invoice
            state.loading = false
        },
        [getMerchantInvoice.rejected]: (state) => {
            state.loading = false
        },
    }
});

export const {
    clearData,
} = merchantInvoicesCreateSlice.actions
export default merchantInvoicesCreateSlice.reducer