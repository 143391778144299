import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dictsAPI from '../../api/v2/dictsAPI'
import applicationsAPI from '../../api/v2/applicationsAPI'
import moment from 'moment'
import { documentTypeOptions, exemplarType } from '../shares/dicts/fileTypes'


export const getUsers = createAsyncThunk(
    "application-update/users/get",
    async () => {
        const response = await dictsAPI.getUsers()
        return response
    }
)

export const getWatchers = createAsyncThunk(
    "application-update/watcher/get",
    async (appId) => {
        const response = await applicationsAPI.getWatchers(appId)
        return response
    }
)

export const addWatcher = createAsyncThunk(
    "application-update/addWatcher/post",
    async (data) => {
        const response = await applicationsAPI.addWatcher(data)
        return response
    }
)

export const removeWatcher = createAsyncThunk(
    "application-update/removeWatcher/post",
    async (data) => {
        const response = await applicationsAPI.removeWatcher(data)
        return response
    }
)

export const checkSnExists = createAsyncThunk(
    "application-update/checkSnExists/post",
    async (data) => {
        const response = await applicationsAPI.checkSnExists(data)
        return response
    }
)

export const confirmTerminals = createAsyncThunk(
    "application-update/confirmTerminals/post",
    async (id, thunkAPI) => {
        try {
            const response = await applicationsAPI.confirmTerminals(id)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getArchivedFiles = createAsyncThunk(
    "application-update/archivedFiles/post",
    async (id) => {
        const response = await applicationsAPI.getArchivedFiles(id)
        return response
    }
)

export const downloadFile = createAsyncThunk(
    "application-update/downloadFile/get",
    async (data) => {
        const response = await applicationsAPI.downloadFile(data)
        return response
    }
)


const initialState = {
    options: {
        users: [],
    },
    watchers: [],
    archiveFiles: {
        data: [],
        loading: false
    },
    loading: false
}


const userApplicationUpdateSlice = createSlice({
    name: "application-update",
    initialState,
    reducers: {},
    extraReducers: {
        [getUsers.pending]: (state) => {
            state.loading = true
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            const data = Object.entries(payload.data).map(el => ({ value: el[0], label: el[1] }))
            state.options.users = data
            state.loading = false
        },
        [getUsers.rejected]: (state) => {
            state.loading = false
        },
        [getWatchers.pending]: (state) => {
            state.loading = true
        },
        [getWatchers.fulfilled]: (state, { payload }) => {
            state.watchers = Object.keys(payload.data).map((key) => ({value: key, label: payload.data[key]}))
            state.loading = false
        },
        [getWatchers.rejected]: (state) => {
            state.loading = false
        },
        [addWatcher.pending]: (state) => {
            state.loading = true
        },
        [addWatcher.fulfilled]: (state) => {
            state.loading = false
        },
        [addWatcher.rejected]: (state) => {
            state.loading = false
        },
        [removeWatcher.pending]: (state) => {
            state.loading = true
        },
        [removeWatcher.fulfilled]: (state) => {
            state.loading = false
        },
        [removeWatcher.rejected]: (state) => {
            state.loading = false
        },
        [checkSnExists.pending]: (state) => {
            state.loading = true
        },
        [checkSnExists.fulfilled]: (state) => {
            state.loading = false
        },
        [checkSnExists.rejected]: (state) => {
            state.loading = false
        },
        [confirmTerminals.pending]: (state) => {
            state.loading = true
        },
        [confirmTerminals.fulfilled]: (state) => {
            state.loading = false
        },
        [confirmTerminals.rejected]: (state) => {
            state.loading = false
        },
        [getArchivedFiles.pending]: (state) => {
            state.archiveFiles.loading = true
        },
        [getArchivedFiles.fulfilled]: (state, {payload}) => {
            state.archiveFiles.data = payload.map(item => ({...item, cdat: moment(item.cdat).format("lll"), documentType: documentTypeOptions.find(docType => docType.value === item.documentType)?.label, exemplarType: exemplarType.find(exmplrType => exmplrType.value === item.exemplarType)?.label}))
            state.archiveFiles.loading = false
        },
        [getArchivedFiles.rejected]: (state) => {
            state.archiveFiles.loading = false
        },
    }
});

export default userApplicationUpdateSlice.reducer