import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[waiters] requestGetDataPage')
export const getDataPageSuccess = createAction('[waiters] getDataPageSuccess')
export const getDataPageError = createAction('[waiters] getDataPageError')
// create
export const requestCreate = createAction('[waiters] requestCreate')
export const finalCreate = createAction('[waiters] finalCreate')
// update
export const requestUpdate = createAction('[waiters] requestUpdate')
export const finalGetUpdate = createAction('[waiters] finalGetUpdate')
// get one
export const requestGetOne = createAction('[waiters] requestGetOne')
export const finalGetOne = createAction('[waiters] finalGetOne')
// --- cards
// get cards
export const requestGetCreditCards = createAction('[waiters] requestGetCreditCards')
export const finalGetCreditCards = createAction('[waiters] finalGetCreditCards')
export const clearGetCreditCards = createAction('[waiters] clearGetCreditCards')
// add card
export const requestAddCreditCard = createAction('[waiters] requestAddCreditCard')
export const finalAddCreditCard = createAction('[waiters] finalAddCreditCard')
// delete card
export const requestDelCreditCard = createAction('[waiters] requestDelCreditCard')
export const finalDelCreditCard = createAction('[waiters] finalDelCreditCard')