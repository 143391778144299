import axios from 'axios'

const ROOT_URL = "/api/v2/poses"

const posesAPI = {
    // read
    getData: () => axios.get(ROOT_URL).then(r => r.data),
    getDataByTin: (TIN) => axios.get(`${ROOT_URL}?TIN=${TIN}`).then(r => r.data),
    getDataByClientId: (clientId) => axios.get(`${ROOT_URL}?clientId=${clientId}`).then(r => r.data),
    getDataByAdvancedSearch: (data) => axios.post(`/api/v2/clients/advanced-search`, data).then(r => r.data),
    getOne: (posId, bankId) => axios.get(`${ROOT_URL}/${posId}/main${bankId ? `?bankId=${bankId}` : ''}`).then(r => r.data),
    getOneForUpdate: (posId) => axios.get(`${ROOT_URL}/${posId}/record`).then(r => r.data),
    getAvailableTerminalBanks: (posId) => axios.get(`${ROOT_URL}/${posId}/availableTerminalBanks`).then(r => r.data),
    getAvailablePointBanks: (posId) => axios.get(`${ROOT_URL}/${posId}/availableAgreementBanks`).then(r => r.data),

    // update
    updateOne: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/record`, payload)
        .then(r => r.data),
    switchTerminalsBank: ({ id, payload }) => axios.put(`${ROOT_URL}/${id}/switchTerminalsBank`, payload)
        .then(r => r.data),
    // updateOneAddress: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/address`, payload)
    //     .then(r => r.data),
    // updateOneSwitchAddress: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/switch-address-match`, payload)
    //     .then(r => r.data),
    // updateOneResponsible: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/responsible`, payload)
    //     .then(r => r.data),
    // updateOneSwitchResponsible: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/switch-responsible-match`, payload)
    //     .then(r => r.data),
    // create
    createOne: (payload) => axios.post(ROOT_URL, payload)
        .then(r => r.data),
    createOneServiceTicketsComment: ({ posId, payload }) => axios.post(`/api/v2/terminals/${posId}/service-ticket-comments`, payload)
        .then(r => r.data),
    createOneServiceComments: ({ posId, payload }) => axios.post(`/api/v2/terminals/${posId}/service-comments`, payload)
        .then(r => r.data),
    putPosToFastPaymentsConfig: ({ posId, payload }) => axios.put(`${ROOT_URL}/${posId}/mapTerminalsToFastPaymentsConfig`, payload)
        .then(r => r.data),
}
export default posesAPI