import axios from 'axios' 
import {downloadBlob, optionsForAxiosBlob} from '../apiTools';

export default {  
	// --- widgets --->
	getTotalApp: (data) => axios.post(`/statistic/applications/totalApp`, data).then(r => r.data), 
	getTotalDoneApp: (data) => axios.post(`/statistic/applications/totalDoneApp`, data).then(r => r.data), 
	getTotalDeclinedBank: (data) => axios.post(`/statistic/applications/totalDeclinedByBankApp`, data).then(r => r.data), 
	getTotalDeclinedMerchant: (data) => axios.post(`/statistic/applications/totalDeclinedByMerchantApp`, data).then(r => r.data), 
	getTotalDraftApp: (data) => axios.post(`/statistic/applications/totalDraftApp`, data).then(r => r.data), 
	getTotalInWorkApp: (data) => axios.post(`/statistic/applications/totalInWorkApp`, data).then(r => r.data), 
	getWaitingClientApp: (data) => axios.post(`/statistic/applications/waitingClientApp`, data).then(r => r.data), 
	getWaitingBankApp: (data) => axios.post(`/statistic/applications/waitingBankApp`, data).then(r => r.data), 
	// <----
	createUpdateData: (data) => axios.post(`/merchant_application`, data).then(r => r.data),  
	confirmAppInBank: (data) => axios.post(`/merchant_application/confirm`, data).then(r => r.data),  
	rollBackApp: (data) => axios.post(`/merchant_application/${data.id}/reject`, data).then(r => r.data),  
	forceStatus: (data) => axios.post(`/merchant_application/forceRepeat`, data).then(r => r.data),
	resendToMerchant: (data) => axios.post(`/merchant_application/resendToMerchant`, data).then(r => r.data),
	longPollingStatus: (data) => axios.post(`/merchant_application/status`, data.server, {timeout: 55000, cancelToken: data.cancelToken}).then(r => r.data),  // 5m - client waiting
	prepareTerminals: (data) => axios.post(`/merchant_application/${data.id}/prepareTerminals`, data).then(r => r.data),  
	getData: (data) => axios.post(`/merchant_applications`, data).then(r => r.data),  
	getOne: (data) => axios.get(`/merchant_application/${data.id}`).then(r => r.data),  
	getPartnerCodes: (data) => axios.post(`/merchant_application/fields`, data).then(r => r.data),
	getDashboardApps: (data) => axios.post(`/statistic/applications`, data).then(r => r.data),
	getAllDocs: (data) => axios.get(`/merchant_application/${data.id}/files`).then(r => r.data),
	documentsChecked: (data) => axios.post(`/merchant_application/received`, data).then(r => r.data),
	getCompanyForINNKub: (data) => axios.post(`/kub/company`, data).then(r => r.data),
	editRowTable: (data) => axios.post(`/merchant_application/regNr/upd`, data).then(r => r.data),
	getAllTmsForOneApp: (data) => axios.post(`/merchant_points/${data.applicationId}`, data).then(r => r.data),
	// getTimerSla: (data) => axios.post(`/dict/commissions`, data).then(r => ({"minSla": 0.1})).catch(e => ({"minSla": 2})),
	getTimerSla: (data) => axios.post(`/merchant_application/${data.applicationId}/sla`, data).then(r => r.data),
	getInfoOneApp: (data) => axios.post(`/merchant_application/details`, data).then(r => r.data),

	getComissionsCombobox: (data) => axios.post(`/dict/commissions`, data).then(r => r.data),  
	getCompanyForINN: (data) => axios.get(`/suggestion/company?inn=${data.inn}`, data).then(r => r.data),  
	getCompanyForBIK: (data) => axios.get(`/suggestion/bank?bik=${data.bik}`, data).then(r => r.data), 
	// banks
	getTableBanks: (data) => axios.post(`/api/v2/merchant_application/commission/details`, data).then(r => r.data), 
	getRecommendBanks: (data) => axios.post(`/merchant_application/commission/min`, data).then(r => r.data), 
	getBanks: () => axios.get(`/api/v2/application/banks`).then(r => r.data), 
	getBanksFields: (data) => axios.post(`/merchant_application/getAdditionalFields`, data).then(r => r.data),
	getBanksForMerchant: (data) => axios.get(`/dict/merchant/${data.merchant_id}/banks`).then(r => r.data),
	getTerminalsFromMerchantsAndBanks: (data) => axios.post(`/dict/merchant/terminals`, data).then(r => r.data),
	getInnForMerchant: (data) => axios.get(`/merchant/${data.merchant_id}/inn`).then(r => r.data),
	// files
	uploadFile: (data) => axios.post(`/api/v2/merchant_application/uploadFiles`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),

	uploadFileExtra: (data) => axios.post(`/api/v2/merchant_application/uploadFiles`, data).then(r => r.data),
	
		getFiles: (data) => axios.get(`/merchant_application/${data.id}/files`).then(r => r.data),  
	deleteFile: (data) => axios.delete(`/merchant_application/${data.applicationId}/file/${data.id}`).then(r => r.data),  
	downloadFile: (data) => axios.get(`/merchant_application/${data.applicationId}/file/${data.id}`, {responseType: 'blob'})
		.then(r => downloadBlob(r, data.fileName)),
	filePersons: (applicationId) => axios.get(`/api/v2/dict/merchant_application/${applicationId}/persons`).then(r => r.data),
	// documents
	getDocumentSize: (data) => axios.post(`/merchant_application/${data.applicationId}/files/is_allowed_size`).then(r => r.data),  
	downloadXml: (data) => axios.post(`/merchant_application/downloadBankFiles`, data, {responseType: 'blob'}
 ).then(res => downloadBlob(res, 'documents.zip')), 

	downloadOffer: (data) => axios.post(`/merchant_application/downloadOffer`, {
		...data.body,
		fileName: data.fileName
	}, {responseType: 'blob'}).then(r => downloadBlob(r, data.fileName)),

	downloadApplication: (data) => axios.get(`/merchant_points/${data.applicationId}`).then(r => r.data.list).then(res => {
		if(!res) return
			res.forEach(el => 
				axios.post("/merchant_application/downloadRequest/" + el.id, {...data.body, id: data.applicationId}, {responseType: 'blob'})
				.then(r => downloadBlob(r, `request_mp${el.id}_application${data.applicationId}.docx`)),
		)
	}),
	
	downloadDataPage: (data) => axios.post(`/merchant_application/exportToXlsx`, {
		...data,
	}, optionsForAxiosBlob()).then(r => downloadBlob(r, 'Таблица_заявок.xlsx')),
	
	refuseFromManager: (data) => {
		const payload = data.text ? {text: data.text} : {}
		let url = ''
		if(data.sendTo === 'bank') url = 'decline_bank'
		else if(data.sendTo === 'customer') url = 'decline_merchant'
		
		return axios.post(`/merchant_application/${data.applicationId}/${url}`, payload).then(r => r.data)
	},

	getExtraFilesList: (data)=> axios.post(`/merchant_application/signing_by_merchant_files`, data).then(r => r.data),
	saveDocumentsFromSdm: (id)=> axios.post(`/api/v2/merchant_application/${id}/saveDocumentsFromSdm`).then(r => r.data),
	getBankQuestions: (id)=> axios.get(`/api/v2/merchant_application/${id}/questions`).then(r => r.data),
	sendAnswersBankQuestions: (data)=> axios.post(`/api/v2/updateMerchantApplicationAnswers`, data).then(r => r.data),
	updateBankResponse: (data)=> axios.post(`/merchant_application/update_bank_response`, data).then(r => r.data),
	generateFile: (data) => axios.post(`/api/v2/merchant_application/generate_file`, data, optionsForAxiosBlob()).then(resp => downloadBlob(resp, data.fileName)),
	merchantConfirm: (data) => axios.post(`/api/v2/merchantSignedApplication`, data,).then(r => r.data),
	updateMerchantApplication: (data) => axios.post(`/api/v2/updateMerchantApplication`, data,).then(r => r.data),
	updateMerchantPointTerminalConfigs: (data) => axios.post(`/api/v2/updateMerchantPointTerminalConfigs`, data,).then(r => r.data),
	closeApplication: ({id})=> axios.post(`/merchant_application/${id}/finishApplication`, {}).then(r => r.data),
	closeAsService: (data)=> axios.post(`/api/v2/merchant_application/doneAsService`,data).then(r => r.data),
}