import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[roles] requestGetDataPage')
export const getDataPageSuccess = createAction('[roles] getDataPageSuccess')
export const getDataPageError = createAction('[roles] getDataPageError')
// create
export const requestCreate = createAction('[roles] requestCreate')
export const finalCreate = createAction('[roles] finalCreate')
// update
export const requestUpdate = createAction('[roles] requestUpdate')
export const finalGetUpdate = createAction('[roles] finalGetUpdate')
// get one
export const requestGetOne = createAction('[roles] requestGetOne')
export const finalGetOne = createAction('[roles] finalGetOne')
// check Login
export const requestCheckLogin = createAction('[roles] requestCheckLogin')
export const finalCheckLogin = createAction('[roles] finalCheckLogin')
// get one
export const requestGetRolesSelected = createAction('[roles] requestGetRolesSelected')
export const finalGetRolesSelected = createAction('[roles] finalGetRolesSelected')
export const clearGetRolesSelected = createAction('[roles] clearGetRolesSelected')
// get one role
export const requestGetAdminRolesAvailable = createAction('[roles] requestGetAdminRolesAvailable')
export const finalGetAdminRolesAvailable = createAction('[roles] finalGetAdminRolesAvailable')
export const clearGetAdminRolesAvailable = createAction('[roles] clearGetAdminRolesAvailable')
// reset password
export const requestResetPassword = createAction('[roles] requestResetPassword')
export const finalResetPassword = createAction('[roles] finalResetPassword')
export const requestResetUserPassword = createAction('[roles] requestResetUserPassword')
export const finalResetUserPassword = createAction('[roles] finalResetUserPassword')

export const requestGetMerchantRolesAvailable = createAction('[roles] requestGetMerchantRolesAvailable')
