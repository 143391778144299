import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/other/newLead';  
import * as actions from './actions'   

export default [  
	takeLatest(actions.requestSendNewLead, requestSendNewLead),   
	takeLatest(actions.requestCheckHashNewLead, requestCheckHashNewLead),  

	takeLatest(actions.requestDeleteFileNewLead, requestDeleteFileNewLead),   
	takeLatest(actions.requestDownloadFileNewLead, requestDownloadFileNewLead),   
	takeLatest(actions.requestUploadFileNewLead, requestUploadFileNewLead),
	takeLatest(actions.requestUploadFileNewLead2, requestUploadFileNewLead2)  
]
 

//
function* requestSendNewLead(action) {
	try {   
		const data = yield call(API.sendNewLead, action.payload.request);  
		yield put(actions.finalSendNewLead(data)) 
		action.payload.callback(data)    
	} catch (e) { 
		yield put(actions.finalSendNewLead({}))
		action.payload.callback(false) 
	}
} 

//
function* requestCheckHashNewLead(action) {
	try {   
		const data = yield call(API.checkHashNewLead, action.payload.request);  
		yield put(actions.finalCheckHashNewLead(data)) 
		action.payload.callback(true)    
	} catch (e) { 
		yield put(actions.finalCheckHashNewLead({}))
		action.payload.callback(false) 
	}
} 

//
function* requestDeleteFileNewLead(action) {
	try {   
		yield call(API.deleteFileNewLead, action.payload.request);  
		action.payload.callback(true)    
	} catch (e) { 
		action.payload.callback(false) 
	}
} 


//
function* requestDownloadFileNewLead(action) {
	try {   
		yield call(API.downloadFileNewLead, action.payload); 
	} catch (e) {  }
} 
 
//
function* requestUploadFileNewLead(action) {
	try {
		const data = yield call(API.uploadFile, action.payload);  
		action.payload.callback(data)    
	} catch (e) { 
		action.payload.callback(false) 
	}
} 
  
//
function* requestUploadFileNewLead2(action) {
	console.log(action)
	try {
		const data = yield call(API.uploadFile2, action.payload);  
		action.payload.callback(data)    
	} catch (e) { 
		action.payload.callback(false) 
	}
} 
  