import { useState, useEffect } from "react"
import bannersAPI from "../../api/v2/bannersAPI"

const useBanners = () => {
    const [banners, setBanners] = useState([])

    const getBanners = async () => {
        const response = await bannersAPI.getBanners()
        setBanners(response)
    }

    const unsubscribeBanner = async (id, index) => {
        await bannersAPI.unsubscribeBanner({id})
        setBanners(prev => {
            const newArr = [...prev]
            newArr.splice(index, 1)
            return newArr
        })
    }

    useEffect(() => {
        getBanners()
		return () => setBanners([])
    }, [])

    return [banners, unsubscribeBanner]
}

export default useBanners