import { createAction } from 'redux-act';

// -- widgets -->
// 1 cashFlow 
export const requestGetCashFlow = createAction('[dashboard] requestGetCashFlow')
export const finalGetCashFlow  = createAction('[dashboard] finalGetCashFlow') 
// 2 PurchasesCount
export const requestGetPurchasesCount = createAction('[dashboard] requestGetPurchasesCount')
export const finalGetPurchasesCount  = createAction('[dashboard] finalGetPurchasesCount') 
// 3 PurchasesCount
export const requestGetRefundsCount = createAction('[dashboard] requestGetRefundsCount')
export const finalGetRefundsCount = createAction('[dashboard] finalGetRefundsCount') 
// 4 reversalsCount
export const requestGetReversalsCount = createAction('[dashboard] requestGetReversalsCount')
export const finalGetReversalsCount = createAction('[dashboard] finalGetReversalsCount') 
// <---


// get TopWidgets
export const requestGetTopWidgets = createAction('[dashboard] requestGetTopWidgets')
export const finalGetTopWidgets  = createAction('[dashboard] finalGetTopWidgets')
// get CountTransactions
export const requestGetCountTransactions = createAction('[dashboard] requestGetCountTransactions')
export const finalGetCountTransactions  = createAction('[dashboard] finalGetCountTransactions')
// get TopWidgets
export const requestGetCountShifts = createAction('[dashboard] requestGetCountShifts')
export const finalGetCountShifts  = createAction('[dashboard] finalGetCountShifts')
// get  ParamsForFilters
export const requestGetParamsForFilters = createAction('[dashboard] requestGetParamsForFilters')
export const finalGetParamsForFilters  = createAction('[dashboard] finalGetParamsForFilters')
// get TspIntensity
export const requestGetTspIntensity = createAction('[dashboard] requestGetTspIntensity')
export const finalGetTspIntensity  = createAction('[dashboard] finalGetTspIntensity')
// get TspIntensity
export const requestGetTspRejected = createAction('[dashboard] requestGetTspRejected')
export const finalGetTspRejected  = createAction('[dashboard] finalGetTspRejected')
// get getTotalErrors
export const requestGetTotalErrors = createAction('[dashboard] requestGetTotalErrors')
export const finalGetTotalErrors  = createAction('[dashboard] finalGetTotalErrors')
// get getTotalAmount
export const requestTotalAmount = createAction('[dashboard] requestTotalAmount')
export const finalTotalAmount  = createAction('[dashboard] finalTotalAmount')