import { createAction } from 'redux-act';

// create / update
export const requestCreateUpdateData = createAction('[merchant] requestCreateUpdateData')
export const successCreateUpdateData = createAction('[merchant] successCreateUpdateData')
export const errorCreateUpdateData = createAction('[merchant] errorCreateUpdateData') 
// ConfirmApp In Bank
export const requestConfirmAppInBank = createAction('[merchantPoints] requestConfirmAppInBank')  
export const successConfirmAppInBank = createAction('[merchantPoints] successConfirmAppInBank')  
// RollBackApp In Bank
export const requestRollBackApp = createAction('[merchantPoints] requestRollBackApp')  
export const successRollBackApp= createAction('[merchantPoints] successRollBackApp')  
// ForceStatus In Bank
export const requestForceStatus = createAction('[merchantPoints] requestForceStatus')  
export const finalForceStatus = createAction('[merchantPoints] finalForceStatus')  
// longPollingStatus
export const requestlongPollingStatus = createAction('[merchantPoints] requestlongPollingStatus')  
// prepare tarminals
export const requestPrepareTerminals = createAction('[merchantPoints] requestPrepareTerminals')  
export const successPrepareTerminals = createAction('[merchantPoints] successPrepareTerminals')  
// get table baks
export const requestGetTableBanks = createAction('[merchantPoints] requestGetTableBanks')  
export const successGetTableBanks = createAction('[merchantPoints] successGetTableBanks')  
// get RecommendBanks
export const requestGetRecommendBanks = createAction('[merchantPoints] requestGetRecommendBanks')  
export const successGetRecommendBanks = createAction('[merchantPoints] successGetRecommendBanks')  
// get
export const requestGetDataPage = createAction('[merchant] requestGetDataPage')
export const getDataPageSuccess = createAction('[merchant] getDataPageSuccess')
export const getDataPageError = createAction('[merchant] getDataPageError')
// get xlsx
export const requestDownloadDataPage = createAction('[merchant] requestDownloadDataPage')
// get one
export const requestGetOne = createAction('[merchant] requestGetOne')
export const getOneSuccess = createAction('[merchant] GetOneSuccess') 
export const clearOne = createAction('[merchant] clearOne') 
// get TimerSla
export const requestGetTimerSla = createAction('[merchant] requestGetTimerSla')
export const finalGetTimerSla = createAction('[merchant] finalGetTimerSla') 
// --- selects
// get partnerCodes
export const requestPartnerCodes = createAction('[merchant] requestPartnerCodes')
export const successPartnerCodes = createAction('[merchant] successPartnerCodes') 
// get comissionsCombobox
export const requestComissionsCombobox = createAction('[merchant] requestComissionsCombobox')
export const successComissionsCombobox  = createAction('[merchant] successComissionsCombobox') 
// get inn
export const requestCompanyForINN = createAction('[merchant] getCompanyForINN')
export const successCompanyForINN  = createAction('[merchant] successGetCompanyForINN') 
export const clearCompanyForINN  = createAction('[merchant] clearCompanyForINN') 
//  INN Kub
export const requestCompanyForINNKub  = createAction('[merchant] requestCompanyForINNKub') 
// get bik
export const requestCompanyForBIK = createAction('[merchant] requestCompanyForBIK')
export const successCompanyForBIK = createAction('[merchant] successCompanyForBIK') 
export const clearCompanyForBIK = createAction('[merchant] clearCompanyForBIK') 
// get bik with callback
export const requestCompanyCallbackBIK = createAction('[merchant] requestCompanyCallbackBIK')
// get banks
export const requestGetBanks = createAction('[merchant] requestGetBanks')
export const successGetBanks = createAction('[merchant] successGetBanks') 
// get Banks Fields
export const requestGetBanksFields = createAction('[merchant] requestGetBanksFields')
export const successGetBanksFields= createAction('[merchant] successGetBanksFields') 
export const clearGetBanksFields= createAction('[merchant] clearGetBanksFields') 
//  Get AllTmsForOneApp 
export const requestGetAllTmsForOneApp = createAction('[merchant] requestGetAllTmsForOneApp') 
export const finallGetAllTmsForOneApp = createAction('[merchant] requestGetAllTmsForOneApp') 
export const clearGetAllTmsForOneApp = createAction('[merchant] clearGetAllTmsForOneApp') 
//  Get infoOneApp
export const requestGetInfoOneApp = createAction('[merchant] requestGetInfoOneApp') 
export const finallGetInfoOneApp = createAction('[merchant] finallGetInfoOneApp') 
export const clearGetInfoOneApp = createAction('[merchant] clearGetInfoOneApp') 
// --- fILES 
// upload file
export const requestUploadFile = createAction('[merchant] requestUploadFile')
export const successUploadFile = createAction('[merchant] successUploadFile') 
// get files
export const requestGetFiles = createAction('[merchant] requestGetFiles')
export const successGetFiles = createAction('[merchant] successGetFiles') 
export const clearGetFiles = createAction('[merchant] clearGetFiles') 
// delete files
export const requestDeleteFile = createAction('[merchant] requestDeleteFile')
export const successDeleteFile = createAction('[merchant] successDeleteFile') 
// download files
export const requestDownloadFile = createAction('[merchant] requestDownloadFile') 
// downloadXml
export const downloadXml = createAction('[merchant] downloadXml') 
// downloadOffer
export const downloadOffer = createAction('[merchant] downloadOffer') 
// downloadApplication
export const downloadApplication = createAction('[merchant] downloadApplication') 

// get document size 
export const requestGetDocumentSize = createAction('[merchant] requestGetActivitiesKinds')  
export const finalGetDocumentSize = createAction('[merchant] finalGetDocumentSize')

// get BanksForMerchant
export const requestGetBanksForMerchant = createAction('[merchant] requestGetBanksForMerchant')
export const finalGetBanksForMerchant = createAction('[merchant] finalGetBanksForMerchant')
// get BanksForApplication
export const requestGetBanksForApplication = createAction('[merchant] requestGetBanksForApplication')
export const finalGetBanksForApplication = createAction('[merchant] finalGetBanksForApplication')
// get TerminalsFromMerchantsAndBanks
export const requestGetTerminalsFromMerchantsAndBanks = createAction('[merchant] requestGetTerminalsFromMerchantsAndBanks')
export const finalGetTerminalsFromMerchantsAndBanks = createAction('[merchant] finalGetTerminalsFromMerchantsAndBanks')
// get InnForMerchant
export const requestGetInnForMerchant = createAction('[merchant] requestGetInnForMerchant')
export const finalGetInnForMerchant = createAction('[merchant] finalGetInnForMerchant')
export const clearGetInnForMerchant = createAction('[merchant] clearGetInnForMerchant')

// get refuseFromManager
export const requestRefuseFromManager = createAction('[merchant] requestRefuseFromManager')
export const finalRefuseFromManager = createAction('[merchant] finalRefuseFromManager')

// get  allDocs
export const requestGetAllDocs = createAction('[merchant] requestGetAllDocs')
export const finalGetAllDoc = createAction('[merchant] finalGetAllDoc')

// get Dashboard
export const requestGetDashboard = createAction('[merchant] requestGetDashboard')
export const finalGetDashboard = createAction('[merchant] finalGetDashboard')

//  DocumentsChecked
export const requestDocumentsChecked = createAction('[merchant] requestDocumentsChecked')
export const finalDocumentsChecked = createAction('[merchant] finalDocumentsChecked')

//  editRowTable
export const requestEditRowTable = createAction('[merchant] requestEditRowTable')
export const finalEditRowTable= createAction('[merchant] finalEditRowTable')

//  requestFilePersons
export const requestFilePersons = createAction('[merchant] requestFilePersons')
export const successFilePersons = createAction('[merchant] successFilePersons')

// --- widgets --->
//  App 
export const requestGetTotalApp = createAction('[merchant] requestGetTotalApp')
export const finalGetTotalApp = createAction('[merchant] finalGetTotalApp')
//  DoneApp
export const requestGetTotalDoneApp = createAction('[merchant] requestGetTotalDoneApp')
export const finalGetTotalDoneApp = createAction('[merchant] finalGetTotalDoneApp')
//  DeclinedBank
export const requestGetTotalDeclinedBank = createAction('[merchant] requestGetTotalDeclinedBank')
export const finalGetTotalDeclinedBank = createAction('[merchant] finalGetTotalDeclinedBank')
//  DeclinedMerchant
export const requestGetTotalDeclinedMerchant = createAction('[merchant] requestGetTotalDeclinedMerchant')
export const finalGetTotalDeclinedMerchant = createAction('[merchant] finalGetTotalDeclinedMerchant')
//  DraftApp
export const requestGetTotalDraftApp = createAction('[merchant] requestGetTotalDraftApp')
export const finalGetTotalDraftApp  = createAction('[merchant] finalGetTotalDraftApp')
//  totalInWorkApp
export const requestGetTotalInWorkApp = createAction('[merchant] requestGetTotalInWorkApp')
export const finalGetTotalInWorkApp  = createAction('[merchant] finalGetTotalInWorkApp')
// waitingClientApp
export const requestGetWaitingClientApp = createAction('[merchant] requestGetWaitingClientApp')
export const finalGetWaitingClientApp  = createAction('[merchant] finalGetWaitingClientApp')
// waitingBankApp
export const requestGetWaitingBankApp = createAction('[merchant] requestGetWaitingBankApp')
export const finalGetWaitingBankApp  = createAction('[merchant] finalGetWaitingBankApp')
// <----

export const requestGetExtraFilesList = createAction('[merchant] requestGetExtraFilesList')
export const successGetExtraFilesList = createAction('[merchant] successGetExtraFilesList')

export const requestUploadExtraFiles = createAction('[merchant] requestUploadExtraFiles')
export const successUploadExtraFiles = createAction('[merchant] successUploadExtraFiles')
export const actionsExtraFiles = createAction('[merchant] actionsExtraFiles')


// Отправить ТСП для банка ВТБ при статусе Требует подтверждения
export const requestResendToMerchant = createAction('[merchant] requestResendToMerchant')
export const successResendToMerchant = createAction('[merchant] successResendToMerchant')

// Запросить документы из SMD
export const requestSaveDocumentsFromSdm = createAction('[merchant] requestSaveDocumentsFromSdm')
export const successSaveDocumentsFromSdm = createAction('[merchant] successSaveDocumentsFromSdm')

// Получаем вопросы от банка
export const requestGetBankQuestions = createAction('[merchant] requestGetBankQuestions')
export const successGetBankQuestions = createAction('[merchant] successGetBankQuestions')

// Отправляем ответы на вопросы от банка
export const requestSendAnswersBankQuestions = createAction('[merchant] requestSendAnswersBankQuestions')
export const successSendAnswersBankQuestions = createAction('[merchant] successSendAnswersBankQuestions')