import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/merchant/shifts';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetOne, requestGetOne),   
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload);  
		yield put(actions.successGetOne(data))
	} catch (error) {   
		yield put(actions.successGetOne({}))
	}
} 
