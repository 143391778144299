import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"

import partnersAPI from "../../api/v2/partnersAPI"

import columns from "./columns"

import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import formatingObjectToView from "../shares/helpers/formatingObjectForView"

const defaultSelectedColumns = columns
    .filter((column) => column.optional !== true)
    .map((column) => column.key)

export const fetchPartners = createAsyncThunk(
    "partners/list",
    async (paginations) => {
        const response = await partnersAPI.getAllPartnersData(paginations)
        return response
    }
)

const initialState = {
    listData: [],
    listDataFiltered: [],
    pagination: {
        current: 1,
        total: 0,
        elementsPerPage: 10
    },
    selectedColumns: defaultSelectedColumns,
    searchDataString: "",
    advancedSearchData: {
        login: null,
        firstName: null,
        lastName: null,
    },
    loading: false,
}

const partnersSlice = createSlice({
    name: "partners",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.selectedColumns = initialState.selectedColumns
        },

        searchData: (state, { payload }) => {
            state.searchDataString = payload.toLowerCase()
        },

        advancedSearchData: (state, { payload }) => {
            const formatingValues = formatingObjectToView(
                payload,
                keysWithDateValues,
                keysWithDateTimeValues
            )
            state.advancedSearchData = payload
            state.listDataFiltered = state.listData.filter((item) => {
                let res = true
                for (const key in formatingValues) {
                    if (Object.hasOwnProperty.call(formatingValues, key)) {
                        if (
                            formatingValues[key] === "-" ||
                            formatingValues[key] === null ||
                            formatingValues[key] === undefined ||
                            formatingValues[key] === ""
                        ) {
                            continue
                        }
                        res = item[key]
                            .toLowerCase()
                            .includes(formatingValues[key].toLowerCase())
                    }
                }
                return res
            })
        },

        changePagination: (state, { payload }) => {
            state.pagination = {...state.pagination, ...payload}
        },

        clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        [fetchPartners.pending]: (state) => {
            state.loading = true
        },
        [fetchPartners.fulfilled]: (state, { payload }) => {
            const data = payload?.list?.map((partner) => {
                let formatedPartner = formatingObjectToView(
                    partner,
                    keysWithDateValues,
                    keysWithDateTimeValues
                )
                return { ...formatedPartner, key: nanoid() }
            })
            state.listData = data
            state.listDataFiltered = data
            state.pagination = {...state.pagination, current: +payload.currentPage, total: +payload.countElements}
            state.advancedSearchData = initialState.advancedSearchData
            state.loading = false
        },
        [fetchPartners.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    searchData,
    // advancedSearchData,
    clearData,
    changePagination
} = partnersSlice.actions
export default partnersSlice.reducer
