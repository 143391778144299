import * as actions from "./actions";
import { createReducer } from "redux-act";
import { changeRowInDataArray } from "../../../../core/utils/other/_reduxTools"; 


const initialState = { 
  data: [], 
  loading: false, 
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  createNoLogin: {
    loading: false,
  },
  one: {
    loading: false,
  },
  types: {
    loading: false,
    data: []
  },
  goodsService: {
    loading: false,
  },
  convert: {
    loading: false,
  },
  topWidgets: {
    loading: true,
    data: {}
  },
  // --- widgets --->
  totalLeads: {
    loading: false,
    data: false
  },
  newLeads: {
    loading: false,
    data: false
  },
  inProgressLeads: {
    loading: false,
    data: false
  },
  convertedLeads: {
    loading: false,
    data: false
  },
  personalData: {
    loading: false,
  },
  closeLead: {
    loading: false,
  },
  // <----
  // --- files --->
  files: {
    loading: false,
    data: []
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- widgets --->
    // totalLeads
    [actions.requestGetTotalLeads]: (state, action) => ({
      ...state, totalLeads: {...initialState.totalLeads, loading: true}
    }),
    [actions.finalGetTotalLead]: (state, action) => {
      return { ...state, totalLeads: {...state.totalLeads, loading: false, data: action}}
    },
    // newLeads
    [actions.requestGetNewLeads]: (state, action) => ({
      ...state, newLeads: {...initialState.newLeads, loading: true}
    }),
    [actions.finalGetNewLeads]: (state, action) => {
      return { ...state, newLeads: {...state.newLeads, loading: false, data: action}}
    },
    // inProgressLeads
    [actions.requestGetInProgressLeads]: (state, action) => ({
      ...state, inProgressLeads: {...initialState.inProgressLeads, loading: true}
    }),
    [actions.finalGetInProgressLeads]: (state, action) => {
      return { ...state, inProgressLeads: {...state.inProgressLeads, loading: false, data: action}}
    },
    // convertedLeads
    [actions.requestGetConvertedLeads]: (state, action) => ({
      ...state, convertedLeads: {...initialState.convertedLeads, loading: true}
    }),
    [actions.finalGetConvertedLeads]: (state, action) => {
      return { ...state, convertedLeads: {...state.convertedLeads, loading: false, data: action}}
    },
    // <----

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true,
      data: [],
    }),
    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },
    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- * create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.finalCreate]: (state, action) => {
      let newData = state.data
      if(action) newData = [action, ...newData]
      return { ...state, create: {...state.create, loading: false}, data: newData};
    }, 
    
    // --- *  Update ->>
    [actions.requestUpdate]: (state, action) => ({
      ...state, update: {...state.update, loading: true}
    }),
    [actions.finalGetUpdate]: (state, action) => {
      let newData = state.data
      if(action) newData = state.data.map(el => {
        if(+el.id === +action.id) return action
        return el
      })
      return { ...state, update: {...state.update, loading: false}, data: newData};
    }, 
    
    // --- * create  noLogin ->>
    [actions.requestCreateNoLogin]: (state, action) => ({
      ...state, createNoLogin: {...state.createNoLogin, loading: true}
    }),
    [actions.finalCreateNoLogin]: (state, action) => {
      return { ...state, createNoLogin: {...state.createNoLogin, loading: false}};
    }, 
    
    // --- * get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.finalGetOne]: (state, action) => {
      return { ...state, one: {...state.one, loading: false}};
    }, 

     // --- * get lead types ->>
     [actions.requestGetLeadTypes]: (state, action) => ({
      ...state, types: {...state.types, loading: true}
    }),
    [actions.finalGetLeadTypes]: (state, action) => {
      return { ...state, types: {data: action, loading: false}};
    }, 
    
    // --- * get Goods Services ->>
    [actions.requestGetGoodsServices]: (state, action) => ({
      ...state, goodsService: {...state.goodsService, loading: true}
    }),
    [actions.finalGetGoodsServices]: (state, action) => {
      const modelsOptions = action.map(item => ({value: item.id, label: item.name}))
      return { ...state, goodsService: {...state.goodsService, modelsOptions, loading: false}};
    }, 
    

    // --- * get  TopWidgets 
    [actions.requestGetTopWidgets]: (state, action) => ({
      ...state, topWidgets: {...initialState.topWidgets, loading: true}
    }),
    [actions.finalGetTopWidgets]: (state, action) => {
      const data = action ? action : {}
      return { ...state, topWidgets: {...state.topWidgets, loading: false, data: data},
        'totalLeads': {...state.totalLeads, data: data.totalLeads},
        'newLeads': {...state.newLeads, data: data.newLeads},
        'inProgressLeads': {...state.inProgressLeads, data: data.inProgressLeads},
        'convertedLeads': {...state.convertedLeads, data: data.convertedLeads},
      }
    },

    
    // -- request transform update

    // --- * PersonalData ->>
    [actions.requestPersonalData]: (state, action) => ({
      ...state, personalData: {...initialState.personalData, loading: true}
    }),
    [actions.finalPersonalData]: (state, action) => { 
      return { ...state, personalData: {...state.personalData, loading: false},
      data: changeRowInDataArray(action, state.data)}
    },

    // --- *  converted 
    [actions.requestCovertLeadToApp]: (state, action) => ({
      ...state, convert: {...state.convert, loading: true}
    }),
    [actions.finalCovertLeadToApp]: (state, action) => { 
      return { ...state, convert: {...state.convert, loading: false}};
    },
  
    // CloseLead
    [actions.requestCloseLead]: (state, action) => ({
      ...state, closeLead: {...initialState.closeLead, loading: true}
    }),
    [actions.finalCloseLead]: (state, action) => {
      return { ...state, closeLead: {...state.closeLead, loading: false},
      data: changeRowInDataArray(action, state.data)}
    },

    // getFiles
    [actions.requestGetLeadFiles]: (state, action) => ({
      ...state, files: {...initialState.files, loading: true}
    }),
    [actions.finalGetLeadFiles]: (state, action) => { 
      return { ...state, files: {...state.files, loading: false}};
    },

    [actions.successUpdateResponsibleUser]: (state, action) => ({
      ...state, data: state.data.map((lead)=>{
        if(lead.id === action.id){
          lead.responsibleAdminId = action.responsibleAdminId;
        }
        return lead;
      })
    }),

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
