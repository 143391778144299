import React from 'react'
const Application = React.lazy(() => import('./views/CRM/Leads/application/Application'))

//const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'))
const Dashboard = React.lazy(() => import('./features/dashboard/Dashboard'))
const TemperatureMap = React.lazy(() => import('./views/TemperatureMap'))
const Transactions = React.lazy(() =>
    import('./views/Transactions/Transactions')
)

const Terminals = React.lazy(() => import('./views/Terminals/Terminals'))
const TerminalGroups = React.lazy(() =>
    import('./views/TerminalsGroup/TerminalGroups')
)
const TerminalsShifts = React.lazy(() =>
    import('./views/Terminals/shifts/TerminalsShifts')
)
const RegistrationNEW = React.lazy(() =>
    import('./views/Merchants/RegistrationNEWMiddleware')
)
const MerchantApplications = React.lazy(() =>
    import('./views/Merchants/Applications/Applications')
)
const AcquiringApplication = React.lazy(() =>
    import('./views/Merchants/AcquiringApplication/ApplicationWrapper')
)
const ApplicationChangeData = React.lazy(() =>
    import('./features/application-change-data/ApplicationChangeData')
)
const KktCreateApps = React.lazy(() =>
    import('./features/kkt-applications/KktApplications')
)
const KepList = React.lazy(() => import('./features/kkt-table/KepList'))
const KktRegistartion = React.lazy(() =>
    import('./features/kkt-registration/KktRegistration')
)

const SubPartners = React.lazy(() =>
    import('./views/SubPartners/SubPartnersList/SubPartners')
)
const Merchants = React.lazy(() =>
    import('./views/Merchants/MerchantsPage/Merchants')
)
const MerchantPoints = React.lazy(() =>
    import('./views/MerchantPoints/MerchantPoints')
)
const MerchantGroups = React.lazy(() =>
    import('./views/MerchantGroups/MerchantGroups')
)
const Leads = React.lazy(() => import('./views/CRM/Leads/table/Leads'))
const Users = React.lazy(() => import('./features/users/Users'))
const UserUpdate = React.lazy(() => import('./features/user-update/UserUpdate'))
const UserView = React.lazy(() => import('./features/user-view/UserView'))
const UserCreate = React.lazy(() => import('./features/user-create/UserCreate'))
const Partners = React.lazy(() => import('./features/partners/Partners'))
const PartnerCreate = React.lazy(() =>
    import('./features/partner-create/PartnerCreate')
)
const PartnerUpdate = React.lazy(() =>
    import('./features/partner-update/PartnerUpdate')
)
const Roles = React.lazy(() => import('./views/Administration/Roles/Roles'))
const QuickRegistration = React.lazy(() =>
    import('./views/Merchants/QuickRegistration')
)
const WaitersList = React.lazy(() =>
    import('./views/Services/WaitersList/WaitersList')
)
const CourierList = React.lazy(() =>
    import('./views/Services/Couriers/CouriersList')
)
const CourierTransactions = React.lazy(() =>
    import('./views/Services/CourierTransactions/CourierTransactions')
)
const CreateFunctionality = React.lazy(() =>
    import('./views/Terminals/CreateFunctionalityMiddleware')
)
const News = React.lazy(() => import('./views/FAQ/News'))
const Help = React.lazy(() => import('./views/FAQ/Help'))
const CreatingApps = React.lazy(() =>
    import('./views/CreatingApps/CreatingApps')
)

// refactored resources
const Customers = React.lazy(() => import('./features/customers/Customers'))
const CustomersTransactions = React.lazy(() =>
    import('./features/transactions/Transactions')
)
const Customer = React.lazy(() => import('./features/customer/Customer'))
const CustomerCreate = React.lazy(() =>
    import('./features/customer-create/CustomerCreate')
)
const CustomerChange = React.lazy(() =>
    import('./features/customer-change/CustomerChange')
)
const CustomersPos = React.lazy(() => import('./features/pos/Pos'))
const CustomerPosCreate = React.lazy(() =>
    import('./features/pos-create/PosCreate')
)
const CustomerPosChange = React.lazy(() =>
    import('./features/pos-change/PosChange')
)
const CustomerTerminals = React.lazy(() =>
    import('./features/terminals/Terminals')
)
const CustomerTerminal = React.lazy(() =>
    import('./features/terminal/Terminal')
)
const CustomerTerminalCreate = React.lazy(() =>
    import('./features/terminal-create/TerminalCreate')
)
const RecurrentSchedulesTable = React.lazy(() => 
    import('./features/recurrent-payments/RecurrentSchedulesTable')
)
const RecurrentScheduleForm = React.lazy(() => 
    import('./features/recurrent-payments/RecurrentScheduleForm')
)
const CustomerApplications = React.lazy(() =>
    import('./features/applications/Applications')
)
const Reports = React.lazy(() => import('./features/reports/Reports'))

const MerchantInvoices = React.lazy(() => import('./features/merchantInvoices/MerchantInvoices'))
const MerchantInvoicesCreate = React.lazy(() => import('./features/merchantInvoices-create/MerchantInvoicesCreate'))
const MerchantInvoicesUpdate = React.lazy(() => import('./features/merchantInvoices-update/MerchantInvoicesUpdate'))
const MerchantPayment = React.lazy(() => import('./features/merchant-payment/merchant-payment-table/MerchantPayment'))
const MerchantPaymentCreate = React.lazy(() => import('./features/merchant-payment/merchant-payment-create/MerchantPaymentCreate'))
const MerchantPaymentUpdate = React.lazy(() => import('./features/merchant-payment/merchant-payment-update/MerchantPaymentUpdate'))

const Scoring = React.lazy(() => import('./features/scoring'))

const ServiceTickets = React.lazy(() =>
    import('./features/service-tickets/ServiceTickets')
)
const ServiceTicket = React.lazy(() =>
    import('./features/service-ticket/ServiceTicket')
)
const PartnerTemplates = React.lazy(() =>
    import('./features/partner-templates/PartnerTemplates')
)

const NewLead = React.lazy(() => 
    import('./core/Pages/NewLead')
)

const ApplicationQueue = React.lazy(() => 
    import('./features/application-queue/ApplicationQueue.jsx')
)
const ApplicationTerminal = React.lazy(() => 
    import('./features/application-terminal/ApplicationTerminal.jsx')
)



const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: Dashboard,
        permission: 'ROLE_PGW_LOGIN',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        permission: 'graphics',
    },
    {
        path: '/temperatureMap',
        name: 'TemperatureMap',
        component: TemperatureMap,
        permission: 'terminals_terminalsMap',
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        permission: 'transactions list',
    },
    // {path: '/reports', name: 'Reports', component: Reports, permission: "transactions reports"},
    {
        path: '/terminals',
        name: 'Terminals',
        component: Terminals,
        permission: 'terminals',
    },

    {
        path: '/terminals/groups',
        name: 'Terminal groups',
        component: TerminalGroups,
        permission: 'terminal groups',
    },
    {
        path: '/terminals/shifts',
        name: 'Terminal shifts',
        component: TerminalsShifts,
        permission: 'terminal shifts',
    },
    { path: '/users', name: 'Users', component: Users, permission: 'users' },
    {
        path: '/users/create',
        name: 'UserCreate',
        component: UserCreate,
        permission: 'users',
    },
    {
        path: '/users/view/:userId',
        name: 'UserView',
        component: UserView,
        permission: ['users', "users_own:read"],
    },
    {
        path: '/users/update/:userId',
        name: 'UserUpdate',
        component: UserUpdate,
        permission: ['users', "users_own:read"],
    },
    {
        path: '/users/role_groups',
        name: 'Role groups',
        component: Roles,
        permission: 'roles groups',
    },
    {
        path: '/partners',
        name: 'Partners',
        component: Partners,
        permission: 'partners',
    },
    {
        path: '/partners/create',
        name: 'PartnerCreate',
        component: PartnerCreate,
        permission: 'partners',
    },
    {
        path: '/partners/update/:partnerId',
        name: 'PartnerUpdate',
        component: PartnerUpdate,
        permission: ['partners', 'partner:view'],
    },
    {
        path: '/subpartners',
        name: 'SubPartnersList',
        component: SubPartners,
        permission: 'partners',
    },
    // new
    {
        path: '/merchant_applications',
        name: 'Merchant applications',
        component: MerchantApplications,
        permission: 'merchant applications',
    },
    {
        path: '/merchant_application_new',
        name: 'Merchant application new',
        component: AcquiringApplication,
        permission: 'merchant applications',
		bannedPermission: 'merchant applications_kub_application',
    },
    {
        path: '/merchant_application_new/:id',
        name: 'Merchant application new',
        component: AcquiringApplication,
        permission: 'merchant applications',
		bannedPermission: 'merchant applications_kub_application',
    },
    {
        path: '/quick_merchant_applications',
        name: 'Merchant applications',
        component: MerchantApplications,
        permission: 'merchant applications',
    },
    {
        path: '/merchant_applications/:id(\\d+)',
        name: 'Merchant application update',
        component: RegistrationNEW,
        permission: 'merchant applications',
    },
    {
        path: '/quick_merchant_applications/:id(\\d+)',
        name: 'Merchant application update',
        component: QuickRegistration,
        permission: 'merchant applications',
    },
    {
        path: '/merchant_applications_new',
        name: 'Merchant registration',
        component: RegistrationNEW,
        permission: 'merchant applications_create',
    },
    {
        path: '/quickRegistration',
        name: 'Quick Registration',
        component: QuickRegistration,
        permission: 'merchants_create',
    },
    {
        path: '/application_change-data_new',
        name: 'Заявка на изменение данных ТСТ',
        component: ApplicationChangeData,
        permission: 'applicationleads_update',
    },
    {
        path: '/application_change-data/:id',
        name: 'Заявка на изменение данных ТСТ',
        component: ApplicationChangeData,
        permission: 'merchant applications_update',
    },
    {
        path: '/application_terminal_new',
        name: 'Создание заявки на терминал',
        component: ApplicationTerminal,
        permission: ['merchant applications'],
		bannedPermission: 'merchant applications_kub_application',
    },
    {
        path: '/application_terminal/:id',
        name: 'Заявка на терминал',
        component: ApplicationTerminal,
        permission: ['merchant applications'],
        bannedPermission: 'merchant applications_kub_application',
    },
    {
        path: '/app-queue',
        name: 'PSB application queue',
        component: ApplicationQueue,
        permission: 'partners_create:fromPortal',
    },
    // ККТ ЗАЯВКИ
    {
        path: '/kkt-creating-app',
        name: 'Создать заявку ККТ',
        component: KktCreateApps,
        permission: ['kkt:crud', 'kkt:create'],
    },
    {
        path: '/kep-list',
        name: 'Список КЭП',
        component: KepList,
        permission: ['kkt:crud', 'kkt:update'],
    },
    {
        path: '/kkt-create',
        name: 'Регистрация ККТ',
        component: KktRegistartion,
        permission: ['kkt:crud', 'kkt:create'],
    },
    {
        path: '/kkt-update/:id',
        name: 'Изменения ККТ',
        component: KktRegistartion,
        permission: ['kkt:crud', 'kkt:update'],
    },

    {
        path: '/merchants',
        name: 'Merchants',
        component: Merchants,
        permission: 'merchants',
    },
    {
        path: '/merchant_points',
        name: 'MerchantPointsList',
        component: MerchantPoints,
        permission: 'merchantpoints',
    },
    {
        path: '/merchant_groups',
        name: 'MerchantGroups',
        component: MerchantGroups,
        permission: 'merchant groups',
    },
    {
        path: '/leads',
        name: 'Leads',
        component: Leads,
        permission: 'applicationleads',
    },
    {
        path: '/leads/:id',
        name: 'Изменения заявки',
        component: Application,
        permission: 'applicationleads',
    },
    {
        path: '/createLead',
        name: 'Создание заявки',
        component: Application,
        permission: 'applicationleads',
    },
    {
        path: '/leads',
        name: 'Leads',
        component: NewLead,
        permission: 'ROLE_PGW_LOGIN',
    },

    {
        path: '/waiters',
        name: 'Waiters list',
        component: WaitersList,
        permission: 'waiters',
    },
    {
        path: '/couriers',
        name: 'Courier list',
        component: CourierList,
        permission: 'couriers',
    },
    {
        path: '/courier_transactions',
        name: 'Courier transactions',
        component: CourierTransactions,
        permission: 'transactions list_couriers_read',
    },

    {
        path: '/functionality_extension',
        name: 'Functionality extension',
        component: CreateFunctionality,
        permission: 'terminals_functionality_extension',
    },
    {
        path: '/functionality_extension/:id(\\d+)',
        name: 'Functionality extension',
        component: CreateFunctionality,
        permission: ['terminals_functionality_extension','merchant applications_uploadDocs','merchant applications_update','merchant applications'],
    },

    // {
    //     path: '/news',
    //     name: 'News',
    //     component: News,
    //     permission: 'ROLE_PGW_LOGIN',
    // },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        permission: 'ROLE_PGW_LOGIN',
    },
    {
        path: '/creating_apps',
        name: 'Creating Apps',
        component: CreatingApps,
        permission: ['merchant applications','applicationleads'],
    },

    // refactored resources
    // отчеты
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        permission: 'report jobs',
    },
    {
        path: '/scoring',
        name: 'Скоринг',
        component: Scoring,
        permission: ['merchant applications_kub_application',"partners_create:fromPortal",'merchant applications_scoring_files'],
    },

    // действия с мерчантами и их объектами
    {
        path: '/customers/customer',
        name: 'Customers',
        component: Customers,
        permission: ['clientsSection:all', 'clientsSection:merchants'],
    },
    {
        path: '/customers/customer/:id',
        name: 'Customer',
        component: Customer,
        permission: ['clientsSection:all', 'clientsSection:merchants'],
    },
    {
        path: '/customers/pos/:posId',
        name: 'Pos',
        component: CustomersPos,
        permission: ['clientsSection:all', 'clientsSection:points'],
    },
    {
        path: '/customers/terminal',
        name: 'Терминалы',
        component: CustomerTerminals,
        permission: ['clientsSection:all', 'clientsSection:terminals'],
    },
    {
        path: '/customers/terminal/:id',
        name: 'Терминал',
        component: CustomerTerminal,
        permission: ['clientsSection:all', 'clientsSection:terminals'],
    },
    {
        path: '/customers/transactions',
        name: 'Transactions',
        component: CustomersTransactions,
        permission: [
            'clientsSection:transactions:allFilters',
            'clientsSection:transactions:engineer',
        ],
    },
    {
        path: '/recurrent',
        name: 'Управление подписками',
        component: RecurrentSchedulesTable,
        permission: [
            'recurringSchedulers:view',
            'recurringSchedulers:create',
            'recurringSchedulers:update',
            'recurringSchedulers:delete',
            'recurringSchedulers:crud'
        ],
    },
    {
        path: '/recurrent/:id',
        name: 'Данные подписки',
        component: RecurrentScheduleForm,
        permission: [
            'recurringSchedulers:view',
            'recurringSchedulers:update',
            'recurringSchedulers:delete',
            'recurringSchedulers:crud'
        ],
    },
    {
        path: '/recurrent-create',
        name: 'Создание подписки',
        component: RecurrentScheduleForm,
        permission: [
            'recurringSchedulers:create',
            'recurringSchedulers:crud'
        ],
    },

    // заявки мерчантов
    {
        path: '/customers/applications',
        name: 'Applications',
        component: CustomerApplications,
        permission: ['clientsSection:all', 'clientsSection:update'],
    },
    {
        path: '/customers/applications/customer-create',
        name: 'New customer',
        component: CustomerCreate,
        permission: ['clientsSection:update', 'clientsSection:merchants:crud'],
    },
    {
        path: '/customers/applications/customer-change/:customerId',
        name: 'Change customer',
        component: CustomerChange,
        permission: [
            'clientsSection:update',
            'clientsSection:merchants:crud',
            'clientsSection:merchants:reportsUpdate',
        ],
    },
    {
        path: '/customers/applications/pos-create',
        name: 'New pos',
        component: CustomerPosCreate,
        permission: ['clientsSection:update', 'clientsSection:points:crud'],
    },
    {
        path: '/customers/applications/pos-change/:posId',
        name: 'Change pos',
        component: CustomerPosChange,
        permission: ['clientsSection:update', 'clientsSection:points:crud'],
    },
    {
        path: '/customers/applications/terminal-create',
        name: 'New terminal',
        component: CustomerTerminalCreate,
        permission: [
            'clientsSection:update',
            'clientsSection:terminals:crud',
            'clientsSection:terminals:create',
        ],
    },
    // таблица service
    {
        path: '/service/tickets',
        name: 'Service tickets table',
        component: ServiceTickets,
        permission: ['service:serviceTickets'],
    },
    {
        path: '/service/tickets/:ticketId',
        name: 'Service ticket',
        component: ServiceTicket,
        permission: ['service:serviceTickets'],
    },
    {
        path: '/templates',
        name: 'Partner templates',
        component: PartnerTemplates,
        permission: ['templates:view'],
    },

    // счета
    {
		path: '/merchant_invoices', 
		name: 'Merchant invoices table',
		 component: MerchantInvoices, 
		 permission: ['merchantInvoices:invoices', 'merchantInvoices:invoices:update', 'merchantInvoices:invoices:downloadReceipt', 'merchantInvoices:invoices:create']
		},
    {
		path: '/merchant_invoices/create', 
		name: 'New Merchant invoice', 
		component: MerchantInvoicesCreate, 
		permission: ['merchantInvoices:invoices:create']
	},
    {
		path: '/merchant_invoices/update/:invoiceId', 
		name: 'Merchant invoice', 
		component: MerchantInvoicesUpdate, 
		permission: ['merchantInvoices:invoices', 'merchantInvoices:invoices:update']},
    // платежи
    {
		path: '/merchant_payment', 
		name: 'Merchant payment table',
		 component: MerchantPayment, 
		 permission: ['merchantInvoices:payments']
		},
    {
		path: '/merchant_payment/create', 
		name: 'New Merchant payment', 
		component: MerchantPaymentCreate, 
		permission: ['merchantInvoices:payments:create']
	},
    {
		path: '/merchant_payment/update/:id', 
		name: 'Merchant payment update', 
		component: MerchantPaymentUpdate, 
		permission: ['merchantInvoices:payments']
	},
];

export default routes
