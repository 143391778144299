import * as actions from "./actions";
import { createReducer } from "redux-act";
import { gettext } from 'ttag';

const rolesList = ["graphics",
  "merchant groups",
  "merchant groups_create",
  "merchant groups_update",
  "merchant applications",
  "merchant applications_create",
  "merchant applications_confirm",
  "merchant applications_update",
  "merchant applications_multitid",
  "merchant applications_terminalType",
  "merchant applications_pinpad",
  "merchant applications_defaultTerminalOperation",
  "merchant applications_cashbox_comment",
  "merchant applications_description",
  "merchant applications_viewSigningData",
  "merchant applications_manualTerminalConfigurationPreview",
  "merchant applications_uploadSignAndPrint",
  "merchant applications_manageDocuments",
  "merchant applications_bank_refuse",
  "merchant applications_client_refuse",
  "applicationleads",
  "applicationleads_create",
  "applicationleads_update",
  "applicationleads_request_personal_data",
  "applicationleads_manage_docs",
  "applicationleads_preview_docs",
  "applicationleads_leadTransfer",
  "applicationleads_close_lead",
  "terminal",
  "terminals_individual_ui",
  "terminals_functionality_extension",
  "transactions list",
  "transactions list_reverse",
  "transactions list_reverse_confirm",
  "transactions list_receipt",
  "transactions list_couriers_read",
  "terminal groups",
  "terminal groups_create",
  "terminal groups_update",
  "couriers",
  "couriers_create",
  "couriers_update",
  "users",
  "users_create",
  "users_update",
  "waiters",
  "waiters_create",
  "waiters_update",
  "merchantpoints",
  "merchantpoints_update",
  "merchants",
  "merchants_create",
  "merchants_update",
  "roles groups",
  "roles groups_create",
  "roles groups_update",
  "news",
  "partners_emailTmpls"]

const initialState = {
  data: [],
  loading: false,
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  one: {
    loading: false,
  },
  checkLogin: {
    loading: false,
  },
  resetPassword: {
    loading: false,
  },
  resetUserPassword: {
    loading: false,
  },
  rolesSelected: {
    data: [],
    loading: false,
  },
  adminRoles: {
    data: [],
    loading: false,
  }
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {

    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),
    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },
    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),

    // --- * create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: { ...state.create, loading: true }
    }),
    [actions.finalCreate]: (state, action) => {
      let newData = state.data
      if (action) newData = [action, ...newData]
      console.log(newData);
      return { ...state, create: { ...state.create, loading: false }, data: newData };
    },

    // --- *  Update ->>
    [actions.requestUpdate]: (state, action) => ({
      ...state, update: { ...state.update, loading: true }
    }),
    [actions.finalGetUpdate]: (state, action) => {
      let newData = state.data
      if (action) newData = state.data.map(el => {
        if (+el.id === +action.id) return action
        return el
      })
      return { ...state, update: { ...state.update, loading: false }, data: newData };
    },


    // --- * get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: { ...state.one, loading: true }
    }),
    [actions.finalGetOne]: (state, action) => {
      return { ...state, one: { ...state.one, loading: false } };
    },


    // --- * get  CheckLogin ->>
    [actions.requestCheckLogin]: (state, action) => ({
      ...state, checkLogin: { ...state.checkLogin, loading: true }
    }),
    [actions.finalCheckLogin]: (state, action) => {
      return { ...state, checkLogin: { ...state.checkLogin, loading: false } };
    },

    // --- * get RolesSelected ->>
    [actions.requestGetRolesSelected]: (state, action) => ({
      ...state, rolesSelected: { ...initialState.rolesSelected, loading: true }
    }),
    [actions.finalGetRolesSelected]: (state, action) => {
      return { ...state, rolesSelected: { ...state.rolesSelected, loading: false, data: action } };
    },
    [actions.clearGetRolesSelected]: (state, action) => ({
      ...state, rolesSelected: initialState.rolesSelected
    }),
    // --- * get adminRoles ->>
    [actions.requestGetAdminRolesAvailable]: (state, action) => ({
      ...state, adminRoles: { ...initialState.adminRoles, loading: true }
    }),
    [actions.finalGetAdminRolesAvailable]: (state, action) => {
      const newData = rolesList.filter(el => action.includes(el)).map((el) => ({ value: el, key: el, label: gettext(el) }))
      return { ...state, adminRoles: { ...state.adminRoles, loading: false, data: newData } };
    },
    [actions.clearGetAdminRolesAvailable]: (state, action) => ({
      ...state, adminRoles: initialState.adminRoles
    }),

    // --- * get ResetPassword ->>
    [actions.requestResetPassword]: (state, action) => ({
      ...state, resetPassword: { ...initialState.resetPassword, loading: true }
    }),
    [actions.finalResetPassword]: (state, action) => {
      return { ...state, resetPassword: { ...state.resetPassword, loading: false } };
    },
    [actions.requestResetUserPassword]: (state, action) => ({
      ...state, resetPassword: { ...initialState.resetUserPassword, loading: true }
    }),
    [actions.finalResetUserPassword]: (state, action) => {
      return { ...state, resetPassword: { ...state.resetUserPassword, loading: false } };
    },


    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
