import axios from 'axios'
import { downloadBlob } from '../apiTools'


const ROOT_URL = "/api/v2"

const applicationsChangeDataAPI = {
    // Get options
	getDictMerchantsBanks: (merchantId) => axios.get(`/dict/banks?merchantId=${merchantId}`).then(r => r.data),
    getBanksForMerchant: (merchantId) => axios.get(`/dict/merchant/${merchantId}/banks`).then(r => r.data),
    getApplicationData: (applicationId) => axios.get(`${ROOT_URL}/merchantApplication/${applicationId}`).then(r => r.data),
    getMerchantPointsByParams: (data) => {
		const contarctId = data.contract_id ? `&contract_id=${data.contract_id}` : ''
		return axios.get(`${ROOT_URL}/dict/merchant_points?merchant_id=${data.merchantId}&bank_id=${data.bankId}${contarctId}`).then(r => r.data)
	},
    getMerchantPointsData: (data) => {
		const contarctId = data.contract_id ? `&contract_id=${data.contract_id}` : ''
		return axios.get(`${ROOT_URL}/dict/merchant_points_data?merchant_id=${data.merchantId}&bank_id=${data.bankId}${contarctId}`).then(r => r.data)
	},
    // Application methods
    createChangeDataApplication: (data) => axios.post(`${ROOT_URL}/change_merchant_data_application`, data).then(r => r.data),
    updateChangeDataApplication: (data) => axios.post(`${ROOT_URL}/updateMerchantApplication`, data).then(r => r.data),
    sendTspChangeDataApplication: (data) => axios.post(`${ROOT_URL}/sendMerchantApplication`, data).then(r => r.data),
    confirmTspChangeDataApplication: (applicationId) => axios.post(`${ROOT_URL}/confirmMerchantApplication`, applicationId).then(r => r.data),
    prepareTerminals: (data) => axios.post(`/merchant_application/${data.id}/prepareTerminals`, data).then(r => r.data),
    refuseTspChangeDataApplication: (data) => {
		let url = ''
		if(data.sendTo === 'bank') url = 'decline_bank'
		else if(data.sendTo === 'customer') url = 'decline_merchant'

		return axios.post(`/merchant_application/${data.applicationId}/${url}`, data).then(r => r.data)
	},
    rollBackApplication: (data) => axios.post(`/merchant_application/${data.id}/reject`, data).then(r => r.data),
    // Files
    getAllApplicationFiles: (applicationId) => axios.get(`/merchant_application/${applicationId}/files`).then(r => r.data),
    uploadApplicationFile: (data) => axios.post(`${ROOT_URL}/merchant_application/uploadFiles`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),
    deleteApplicationFile: (data) => axios.delete(`/merchant_application/${data.applicationId}/file/${data.id}`).then(r => r.data),
    downloadApplicationFile: (data) => axios.get(`/merchant_application/${data.applicationId}/file/${data.id}`, {responseType: 'blob'})
        .then(r => downloadBlob(r, data.fileName)),
    merchantConfirmApplication: (data)=> axios.post("/api/v2/merchantSignedApplication",data)
}

export default applicationsChangeDataAPI