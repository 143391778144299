import { useSelector } from "react-redux";
import { selectAllBanksData } from "./commonReduxSelectors";

const usePermissions = () => {
    const { adminRoles: permissions, partner_id, partnerBeanName, partner_ids, merchant_ids, bankBean, bank_id  } = useSelector(state => state.auth.user.userToken);

    const allBanksData = useSelector(selectAllBanksData);

    const isSuperUser = permissions["partners_create:fromPortal"];
    const isMerchant = Boolean(!partner_id);
    const isPartner = Boolean(partner_id);
    const isKUB = permissions['merchant applications_kub_application'];
    const isKUBNew = bankBean === 'KUBNew';
    const isGenBank = bankBean === 'GEN';
    const isAtol = partnerBeanName === 'ATOL'
	const isUserHasAdditionalPartners = Boolean(partner_ids);
	const isUserHasAdditionalMerchant = Boolean(merchant_ids);
	const isBankPartner = Boolean(bank_id) || allBanksData?.length === 1;

	return {
		permissions,
        isSuperUser, 
		isMerchant, 
		isPartner, 
		isKUB, 
		isKUBNew, 
		isAtol, 
		isUserHasAdditionalPartners, 
		isUserHasAdditionalMerchant, 
		isBankPartner, 
		isGenBank, 
    }
};

export default usePermissions
