import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceAPI from '../../api/v2/serviceAPI';
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue'
import formatingObjectToView from '../shares/helpers/formatingObjectForView';

export const fetchServiceTicket = createAsyncThunk(
    "serviceTicket/fetch",
    async (serviceTicketId) => {
        const response = await serviceAPI.getOne(serviceTicketId)
        return response
    }
)

export const fetchServiceTicketComments = createAsyncThunk(
    "serviceTicket/comments/fetch",
    async (serviceTicketId) => {
        const response = await serviceAPI.getOne(serviceTicketId)
        return response
    }
)

export const postServiceTicketComment = createAsyncThunk(
    "serviceTicket/create-ticket-comment/post",
    async ({ticketId, comment}) => {
        const response = await serviceAPI.createOneServiceTicketsComment({ticketId, comment})
        return response
    }
)

const initialState = {
    data: {
        comments: {list: [], commentsLoading: false}
    },
    loading: false
}

const serviceTicketSlice = createSlice({
    name: "serviceTicket",
    initialState,
    reducers: {
        
    },
    extraReducers: {
        [fetchServiceTicket.pending]: (state) => {
            state.loading = true
        },
        [fetchServiceTicket.fulfilled]: (state, { payload }) => {
            const formatedPayload = payload.comments?.map?.((item) => {
                return formatingObjectToView(item, keysWithDateValues, keysWithDateTimeValues)
            })
            state.data = formatingObjectToView({...payload, comments: {list: formatedPayload, commentsLoading: false}}, keysWithDateValues, keysWithDateTimeValues)
            state.loading = false
        },
        [fetchServiceTicket.rejected]: (state) => {
            state.loading = false
        },
        [fetchServiceTicketComments.pending]: (state) => {
            state.data.comments.commentsLoading = true
        },
        [fetchServiceTicketComments.fulfilled]: (state, { payload }) => {
            const formatedPayload = payload.comments?.map?.((item) => {
                return formatingObjectToView(item, keysWithDateValues, keysWithDateTimeValues)
            })
            state.data.comments = {list: formatedPayload, commentsLoading: false}
        },
        [fetchServiceTicketComments.rejected]: (state) => {
            state.data.comments.commentsLoading = false
        },
    }
});

export default serviceTicketSlice.reducer