import applications from './applications/reducer';  
import app_points from './app_points/reducer';  
import tcp from './tcp/reducer';  
import tcp_groups from './tcp_groups/reducer';  
import terminals from './terminals/reducer';  
import shifts from './shifts/reducer';  
import functionality from './functionality/reducer';
import terminalsGroup from './terminalsGroup/reducer';

export default (state={}, action) => {  
	return { 
		applications: applications(state.applications, action),   
		app_points: app_points(state.app_points, action),   
		tcp: tcp(state.tcp, action),   
		tcp_groups: tcp_groups(state.tcp_groups, action),   
		terminals: terminals(state.terminals, action),   
		shifts: shifts(state.shifts, action),
		functionality: functionality(state.functionality, action),
		terminalsGroup: terminalsGroup(state.terminalsGroup, action),
	}
} 