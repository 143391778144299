import React from "react"
import { useHistory } from "react-router-dom"
import { Button, Tag } from "antd"
import { EyeTwoTone, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { generatePhoneWithCode } from "../shares/helpers/generatePhoneWithCode";

const ViewDetails = ({ record }) => {
    const history = useHistory()
    const handleEdit = () => {
        const { pathname } = history.location
        if (record.id) {
            history.push(`${pathname}/view/${record.id}`)
        }
    }
    return <Button type="text" icon={<EyeTwoTone />} onClick={handleEdit} />
}

const columns = [
    {
        title: 'Статус',
        dataIndex: 'enabled',
        key: 'enabled',
        align: 'center',
        width: "110px",
        render: (enabled) => {
            if (enabled) {
                return <span>
                    <Tag icon={ <CheckCircleOutlined /> } color="success">
                        Активен
                    </Tag>
                </span>
            }
            return <span>
                <Tag icon={ <MinusCircleOutlined /> } color="error">
                    Неактивен
                </Tag>
            </span>
        },
        sorter: (a, b) => {
            if (a.enabled > b.enabled) {
                return 1
            }
            if (a.enabled < b.enabled) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Партнер",
        dataIndex: "partnerName",
        key: "partnerName",
        sorter: (a, b) => {
            if (a.partnerName > b.partnerName) {
                return 1
            }
            if (a.partnerName < b.partnerName) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Логин",
        dataIndex: "login",
        key: "login",
    },
    {
        title: "Имя",
        dataIndex: "firstName",
        key: "firstName",
    },
    {
        title: "Фамилия",
        dataIndex: "lastName",
        key: "lastName",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Телефон",
        key: "phone",
        render: (data) => generatePhoneWithCode(data.phone, data.phoneCode)
    },
    {
        title: "Дата входа",
        dataIndex: "ldat",
        key: "ldat",
        sorter: (a, b) => {
            const first = +moment(a.cdat, "lll")
            const second = +moment(b.cdat, "lll")
            if (first > second) {
                return 1
            }
            if (first < second) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Дата создания",
        dataIndex: "cdat",
        key: "cdat",
        sorter: (a, b) => {
            const first = +moment(a.cdat, "ll")
            const second = +moment(b.cdat, "ll")
            if (first > second) {
                return 1
            }
            if (first < second) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Действия",
        key: "actions",
        align: "center",
        render: (_, record) => <ViewDetails record={record} />,
    },
]

export default columns