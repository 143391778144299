import * as actions from "./actions";
import { createReducer } from "redux-act";
import { FD_banks } from "../../../../core/utils/filters/FormatConverter";


const initialState = { 
  data: [], 
  loading: false, 
  tcp: {
    data: [],
    loading: false
  },
  onePoint: {
    data: {},
    loading: false
  },
  createUpdatePointTct: {
    data: {},
    loading: false
  },
  allowedMerchantBanks: {
    data: {},
    loading: false
  },
  isE100LoyalProgram: {
    data: false,
    loading: false
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get data ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true,
      data: [],
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- * get tcp select ->>
    [actions.requestGetTcpSelect]: (state, action) => ({
      ...state,
      tcp: {...state.tcp, loading: true, data: []}
    }),
    [actions.successGeTcptSelect]: (state, action) => ({
      ...state,
      tcp: {...state.tcp, loading: false, data: action}
    }),
    
    // --- * get one point->>
    [actions.requestGetOnePoint]: (state, action) => ({
      ...state,
      onePoint: {...state.onePoint, loading: true}
    }),
    [actions.successGetOnePoint]: (state, action) => ({
      ...state,
      onePoint: {...state.onePoint, loading: false, data: action}
    }),
    [actions.clearGetOnePoint]: (state, action) => ({
      ...state, onePoint: initialState.onePoint
    }),
    
    // --- * get one point->>
    [actions.requestCreateUpdatePointTct]: (state, action) => ({
      ...state,
      createUpdatePointTct: {...initialState.createUpdatePointTct, loading: true}
    }),
    [actions.successCreateUpdatePointTct]: (state, action) => ({
      ...state,
      createUpdatePointTct: {...state.createUpdatePointTct, loading: false, data: action}
    }),
    
    // --- * get  AllowedMerchantBanks->>
    [actions.requestGetAllowedMerchantBanks]: (state, action) => ({
      ...state,
      allowedMerchantBanks: {...initialState.allowedMerchantBanks, loading: true}
    }),
    [actions.finalGetAllowedMerchantBanks]: (state, action) => ({
      ...state,
      allowedMerchantBanks: {...state.allowedMerchantBanks, loading: false, data: FD_banks(action)}
    }),
    


    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
