import { message } from "antd"
import { useMutation, useQuery } from "react-query"
import { serverArrayResponseNotification, serverResponseNotification } from "../../../api/apiTools";
import posesAPI from '../../../api/v2/posesAPI';
import terminalsAPI from "../../../api/v2/terminalsAPI";

export const QUERY_KEYS = {
	POS_GET_TERMINAL_BANKS: 'POS_GET_TERMINAL_BANKS',
};


export const useGetTerminalsBankOptions = (enabled, id) => { 
    return useQuery([QUERY_KEYS.POS_GET_TERMINAL_BANKS, id], () => terminalsAPI.getTerminalsBank(id), {
        enabled: Boolean(enabled),
        select: (data = []) => {
			if(data?.length){
				return  data.map(el => ({label: el.name, value: el.id})) 
			}
			return []
		},
    })
}

const getDescription = (item) => {
	return item?.terminalId ? `ID: ${item.terminalId}.` : null
};

export const usePostPosActivateSbp = () => {
    return useMutation(posesAPI.putPosToFastPaymentsConfig, {
        onSuccess: (response) => { 
			if(response?.errors?.length){
				return 	serverArrayResponseNotification({
					getDescription, 
					errorTypeKey: 'errorType', 
					errorMessageKey: 'errorMessage', 
					dataSource: response?.errors, 
					type: 'warning',
				})
			};

			if(response?.success === false){
				return serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			};

			response?.success && message.success('Данные успешно обновлены')
        },
        onError: (response) => {
			if(response?.success === false){
				serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			}
        },
    })
};