import React from "react"
import { useHistory } from "react-router-dom"
import { Button, Tag } from "antd"
import { EyeTwoTone } from "@ant-design/icons"
import { gettext } from "ttag"
import { ticketColorPriority } from "../shares/dicts/serviceTickets"
import moment from 'moment'

const ViewDetails = ({ record }) => {
    const history = useHistory()
    const handleEdit = () => {
        const { pathname } = history.location
        if (record.id) {
            history.push(`${pathname}/${record.id}`)
        }
    }
    return <Button type="text" icon={<EyeTwoTone />} onClick={handleEdit} />
}

const columns = [
    {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        render: (status) => {
            return <span>{gettext(status)}</span>
        },
        sorter: (a, b) => {
            if (a.status > b.status) {
                return 1
            }
            if (a.status < b.status) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Приоритет",
        dataIndex: "priority",
        key: "priority",
        render: (priority) => {
            return (
                <Tag color={ticketColorPriority[priority]}>
                    {gettext(priority)}
                </Tag>
            )
        },
        sorter: (a, b) => {
            if (a.priority > b.priority) {
                return 1
            }
            if (a.priority < b.priority) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Тип",
        dataIndex: "remoteType",
        key: "remoteType",
        render: (remoteType) => {
            return <span>{gettext(remoteType)}</span>
        },
        sorter: (a, b) => {
            if (a.priority > b.priority) {
                return 1
            }
            if (a.priority < b.priority) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Наименование",
        dataIndex: "merchantName",
        key: "merchantName",
        sorter: (a, b) => {
            if (a.name > b.name) {
                return 1
            }
            if (a.name < b.name) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Банк",
        dataIndex: "bankName",
        key: "bankName",
        sorter: (a, b) => {
            if (a.bankName > b.bankName) {
                return 1
            }
            if (a.bankName < b.bankName) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Дата создания",
        dataIndex: "cdat",
        key: "cdat",
        sorter: (a, b) => {
            const first = +moment(a.cdat, "lll")
            const second = +moment(b.cdat, "lll")
            if (first > second) {
                return 1
            }
            if (first < second) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Ответственный",
        dataIndex: "responsibleName",
        key: "responsibleName",
        sorter: (a, b) => {
            if (a.responsibleName > b.responsibleName) {
                return 1
            }
            if (a.responsibleName < b.responsibleName) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Телефон",
        dataIndex: "contactPhone",
        key: "contactPhone",
        optional: true
    },
    {
        title: "Адрес",
        dataIndex: "address",
        key: "address",
        optional: true
    },
    {
        title: "Действия",
        key: "actions",
        align: "center",
        render: (_, record) => <ViewDetails record={record} />,
    },
]

export default columns
