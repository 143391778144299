import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/merchant/terminalsGroup';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetOne, requestGetOne),     
	takeLatest(actions.requestCreate, requestCreate),
	takeLatest(actions.requestUpdate, requestUpdate),

	takeLatest(actions.requestGetAvailableTerminals, requestGetAvailableTerminals),
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data)
		yield put(actions.successGetOne(data))
	} catch (error) {   
		action.payload.callback({})
		yield put(actions.successGetOne({}))
	}
} 



//
function* requestCreate(action) {
	try {
		const data = yield call(API.create, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalCreate(data))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalCreate())
	}
}

//
function* requestUpdate(action) {
	try {
		const data = yield call(API.update, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalUpdate(data))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalUpdate())
	}
}

// 
function* requestGetAvailableTerminals(action) {
	try {  
		const data = yield call(API.getAvailableTerminals, action.payload); 
		yield put(actions.finalGetAvailableTerminals(data))
	} catch (error) {   
		yield put(actions.finalGetAvailableTerminals({}))
	}
}