import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/merchant/tcp';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestGetTableCommissions, requestGetTableCommissions),   
]

// 1
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 1
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data) 
		yield put(actions.finalGetOne(data))
	} catch (error) { 
		action.payload.callback({})   
		yield put(actions.finalGetOne({}))
	}
} 

// 1
function* requestGetTableCommissions(action) {
	try {  
		const data = yield call(API.getTableCommissions, action.payload); 
		yield put(actions.finalGetTableCommissions(data))
	} catch (error) {   
		yield put(actions.finalGetTableCommissions([]))
	}
} 
