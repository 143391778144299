import axios from 'axios'
import { downloadBlob } from '../apiTools'


const ROOT_URL = "/api/v2/merchant_application"

const applicationsAPI = {


	// get
    getWatchers: (appId) => axios.get(`${ROOT_URL}/${appId}/watchers`).then(r => r.data),
    checkSnExists: (data) => axios.post(`${ROOT_URL}/check_sn_exists/pgw_tms_app`, data).then(r => r.data),
    getBanksForApplication: () => axios.get(`/api/v2/application/banks`).then(r => r.data),
    getArchivedFiles: (applicationId) => axios.post(`/merchant_application/${applicationId}/historyFiles`).then(r => r.data),
    downloadFile: (data) => axios.get(`/merchant_application/${data.merchantApplicationId}/file/${data.id}`, {responseType: 'blob'})
		.then(r => downloadBlob(r, data.name)),
    getPartnersInitiators: (applicationId) => axios.get(`${ROOT_URL}/partnersInitiators?merchantApplicationId=${applicationId}`).then(r => r.data),
    // add
    addWatcher: (data) => axios.post(`${ROOT_URL}/createWatcher`, data).then(r => r.data),
    // delete
    removeWatcher: (data) => axios.post(`${ROOT_URL}/deleteWatcher`, data).then(r => r.data),
    // post
    confirmTerminals: (id) => axios.post(`${ROOT_URL}/${id}/confirmTerminals`).then(r => r.data),
    updateCommission: ({ id, data }) => axios.post(`${ROOT_URL}/${id}/updateCommission`,data).then(r => r.data),
    uploadFilesWithConfigs: (data) => axios.post(`${ROOT_URL}/uploadFilesWithConfigs`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),
	externalRequest: (data)=> axios.post(`${ROOT_URL}/externalRequest`,data).then(r => r.data),
	updateMerchantApplicationQuestion: (data)=> axios.post(`/api/v2/updateMerchantApplicationQuestion`,data).then(r => r.data),
	deleteMerchantApplicationQuestion: (data)=> axios.post(`/api/v2/deleteMerchantApplicationQuestion`,data).then(r => r.data),
	// заявка v2

	//get
    getApplication: (appId) => axios.get(`/api/v3/merchant_application/${appId}`).then(r => r.data),
	getMerchantPoints: (applicationId) => axios.get(`/api/v3/merchant_application/${applicationId}/merchant_points`).then(r => r.data),

	//post
	createTerminalApplication: (data)=> axios.post(`/api/v3/create_terminal_application`,data).then(data => data),

	saveMerchantPoint: (data)=> axios.post(`/api/v3/merchant_application/save_point`,data).then(data => data),
    deleteMerchantPoint: (data)=> axios.post(`/api/v3/merchant_application/delete_point`,data).then(data => data),
    createApplication: (data)=> axios.post(`/api/v3/create_merchant_application`,data).then(data => data),
    updateApplication: (data)=> axios.post(`/api/v3/update_merchant_application`,data).then(data => data),
    sendApplication: (data)=> axios.post(`/api/v3/send_merchant_application`,data).then(data => data),
	merchantSigned: (data) => axios.post(`/api/v2/merchantSignedApplication`, data).then(r => r.data),
	merchantConfirm: (data) => axios.post(`/api/v2/confirmMerchantApplication`, data).then(r => r.data),
	prepareTerminals: (data) => axios.post(`/merchant_application/${data.id}/prepareTerminals`, data).then(r => r.data),
}

export default applicationsAPI
