import * as actions from "./actions";
import { createReducer } from "redux-act";
// import { filterDataForId } from "../../../../core/utils/filters/MainFilter"; 


const initialState = { 
  data: [], 
  loading: false, 
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list};
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),


    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
