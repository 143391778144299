import { useEffect, useState } from "react";
import { flatObject } from "../../../../features/shares/helpers/objects";
import { setAppLoading } from "../redux/slice";


export const useValidateFormByTrigger = (form,validationTrigger, setErrorFields) => {
	const [touched, setTouched] = useState(false);
	const formName = form?.__INTERNAL__?.name

	const setIsTouched = () => {
		setTouched(true)
	};

	useEffect(() => {
		if(form && touched){

			form.validateFields()
				.then(() => {
					setErrorFields(prev => ({ ...prev, [formName]: {} }))
				})
				.catch(({ errorFields }) => {
					if (errorFields.length) {

						const obj = {}
						for (let index = 0; index < errorFields.length; index++) {
							const key = errorFields[index]['name'].join('.')
							obj[key] = true
						}
						setErrorFields(prev => ({ ...prev, [formName]: obj }))
					}
				})
		}
	}, [touched,validationTrigger])

	return { setTouched, setIsTouched }
};

/** для свитчеров */
/**@param validationTrigger - Array */
/**@param validateFields - Array */

export const useValidateFieldsByTrigger = (form,validationTrigger,validateFields, setErrorFields) => {
	const [touched, setTouched] = useState(false);
	const formName = form?.__INTERNAL__?.name

	const setIsTouched = () => {
		setTouched(true)
	};

	useEffect(() => {
		if(form && touched){

			form.validateFields(validateFields)
				.then((values) => {

					const validatingFields = flatObject(values)
					
					for (let index = 0; index < Object.keys(validatingFields).length; index++) {				
						validatingFields[Object.keys(validatingFields)[index]] = false
					}

					setErrorFields(prev => {
						if (prev.hasOwnProperty(formName)) {
							return { ...prev, [formName]: { ...prev[formName], ...validatingFields } }
						}
						return { ...prev, [formName]: validatingFields }
					})
				})
				.catch(({ errorFields }) => {
					if (errorFields?.length) {
						const obj = {}
						for (let index = 0; index < errorFields.length; index++) {
							const key = errorFields[index]['name'].join('.')
							obj[key] = true
						}
						setErrorFields(prev => {
							if (prev.hasOwnProperty(formName)) {
								return { ...prev, [formName]: { ...prev[formName], ...obj } }
							}
							return { ...prev, [formName]: obj }
						})
					}
				})
		}
	},[touched,...validationTrigger])

	return { setTouched, setIsTouched }
};


export const validateForm = (form, setErrorFields, setErrorTabs) => {
	const formName = form?.__INTERNAL__?.name
		form.validateFields()
			.then((values) => {
				setErrorFields(prev => ({ ...prev, [formName]: {} }))
				setErrorTabs && setErrorTabs(prev => ({ ...prev, [formName]: false }))
			})
			.catch(({ errorFields }) => {
				if (errorFields?.length) {
					const obj = {}
					for (let index = 0; index < errorFields.length; index++) {
						const key = errorFields[index]['name'].join('.')
						obj[key] = true
					}
					setErrorFields(prev => {
						return { ...prev, [formName]: obj }
					})
					setErrorTabs && setErrorTabs(prev => ({ ...prev, [formName]: true }))
				}
			})
};


export const validateForms = async (forms, setErrorFields,dispatch) => {
	let result = {};

	try {
		for (let form in forms) {
			if (form === 'businessDataForm') continue;

			await forms[form].validateFields()
				// eslint-disable-next-line no-loop-func
				.then(() => {
					
					setErrorFields(prev => ({ ...prev, [form]: {} }))
					result = { ...result, [form]: false }
				})
				// eslint-disable-next-line no-loop-func
				.catch(({ errorFields }) => {
					if (errorFields.length) {

						const obj = {}
						for (let index = 0; index < errorFields.length; index++) {
							const key = errorFields[index]['name'].join('.')
							obj[key] = true
						}
						setErrorFields(prev => ({ ...prev, [form]: obj }))

						result = { ...result, [form]: true }
					}
				})
		}
	} catch (e) {
		throw (e)
	} finally {
		 dispatch(setAppLoading({isDataValidate: true}))
	}

	return result
};

export const validateFields = ({form,validateFields, setErrorFields}) => {
	const formName = form?.__INTERNAL__?.name

	if(form && validateFields){

		form.validateFields(validateFields)
			.then((values) => {
				const validatingFields = flatObject(values)
				
				for (let index = 0; index < Object.keys(validatingFields).length; index++) {				
					validatingFields[Object.keys(validatingFields)[index]] = false
				}

				setErrorFields(prev => {
					if (prev.hasOwnProperty(formName)) {
						return { ...prev, [formName]: { ...prev[formName], ...validatingFields } }
					}
					return { ...prev, [formName]: validatingFields }
				})
			})
			.catch(({ errorFields }) => {
				if (errorFields?.length) {
					const obj = {}
					for (let index = 0; index < errorFields.length; index++) {
						const key = errorFields[index]['name'].join('.')
						obj[key] = true
					}
					setErrorFields(prev => {
						if (prev.hasOwnProperty(formName)) {
							return { ...prev, [formName]: { ...prev[formName], ...obj } }
						}
						return { ...prev, [formName]: obj }
					})
				}
			})
	}
};
