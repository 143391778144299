
import { Row, Switch } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSbpColumnsData } from './posSlice';
import { selectPermissions } from '../shares/helpers/commonReduxSelectors';
import { checkUpdatePermissionBySection, sbpPermissionSectionType } from '../pos-common/helpers';

const ColPosConnectSbp = ({ record }) => {
	const dispatch = useDispatch()
	const sbpColumnsData = useSelector(state => state.pos.sbpColumnsData)
	const permission = useSelector(selectPermissions);
	const hasUpdatePermission = checkUpdatePermissionBySection(permission, sbpPermissionSectionType.pos)

	const onChange = (value) => {

		const newData = sbpColumnsData.map(pos => {
			if(pos.terminalId === record?.id){
				return {...pos, mapSbp:value}
			}
			return pos
		});
		dispatch(setSbpColumnsData(newData))
	};

	const getCurrentValue = () => {
		if(!sbpColumnsData?.length) return false
		return sbpColumnsData.find(item => item.terminalId === record?.id)?.mapSbp
	};
	
	const currentValue = getCurrentValue();

	return (
		<Row className='w-full' justify='center'>
			<Switch 
				checked={currentValue} 
				onChange={onChange}
				disabled={!hasUpdatePermission}
			/>
		</Row>
	)
};

export default ColPosConnectSbp