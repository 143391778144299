import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partnersAPI from '../../api/v2/partnersAPI';


export const getPartnerTemplates = createAsyncThunk(
    "partner-templates/data/get",
    async () => {
        let response = await partnersAPI.getPartnerTemplates()
        response = response.templates.reduce((dataObj, item) => {
            return {...dataObj, [item.name]: item.text}
        }, {})
        return response
    }
)

export const updatePartnerTemplates = createAsyncThunk(
    "partner-templates/templatesUpdate/put",
    async (payload) => {
        const response = await partnersAPI.updatePartnerTemplates(payload)
        return response
    }
)

const initialState = {
    data: {},
    loading: false
}


const partnerTemplatesSlice = createSlice({
    name: "partner-templates",
    initialState,
    reducers: {
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [getPartnerTemplates.pending]: (state) => {
            state.loading = true
        },
        [getPartnerTemplates.fulfilled]: (state, { payload }) => {
            state.data = payload
            state.loading = false
        },
        [getPartnerTemplates.rejected]: (state) => {
            state.loading = false
        },
        [updatePartnerTemplates.pending]: (state) => {
            state.loading = true
        },
        [updatePartnerTemplates.fulfilled]: (state) => {
            state.loading = false
        },
        [updatePartnerTemplates.rejected]: (state) => {
            state.loading = false
        },
    }
})

export const {
    clearFormData
} = partnerTemplatesSlice.actions
export default partnerTemplatesSlice.reducer