import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { FD_MapToSelect, FD_modelsAll } from "../../../../core/utils/filters/FormatConverter";

import API from '../../../../api/merchant/merchant_points';
import merchantApplicationAPI from '../../../../api/v2/merchantApplicationAPI';
import dictsAPI from '../../../../api/v2/dictsAPI';
import * as actions from './actions'  
import {changeLeadConvertedByPartner} from '../../../other/additionalvalidation/actions';

export default [ 
	// points 
	takeLatest(actions.requestPointsByApplicationId, requestPointsByApplicationId),  
	takeLatest(actions.requestGetOnePoint, requestGetOnePoint),  
	takeLatest(actions.requestDeletePoint, requestDeletePoint),  
	takeLatest(actions.requestCreateUpdatePoint, requestCreateUpdatePoint),  
	takeLatest(actions.requestGetActivitiesKinds, requestGetActivitiesKinds),  
	// tms
	takeLatest(actions.requestUpdateTmsList, requestUpdateTmsList),  
	takeLatest(actions.requestUpdateTmsListFully, requestUpdateTmsListFully),  
	takeLatest(actions.requestUpdateCurrentTmsList, requestUpdateCurrentTmsList),  
	takeLatest(actions.requestUpdateTmsListQuickReg, requestUpdateTmsListQuickReg),  
	takeLatest(actions.requestGetTmsList, requestGetTmsList),  
	takeLatest(actions.requestCheckDoublesSn, requestCheckDoublesSn),
	takeLatest(actions.requestDownloadPDFHistory, requestDownloadPDFHistory),

	takeEvery(actions.requestGetTmsHistory, requestGetTmsHistory),
	takeEvery(actions.requestGetTmsDownloadFiles, requestGetTmsDownloadFiles),
	takeEvery(actions.requestDownloadFilesArchive, requestDownloadFilesArchive),
	takeLatest(actions.requestCheckTmsForWarning, requestCheckTmsForWarning),
	takeLatest(actions.requestUpdateBankResponse, requestUpdateBankResponse)
]

//
function* requestPointsByApplicationId(action) {
	try {   
		const data = yield call(API.getPointsByApplicationId, action.payload);  
		yield put(actions.successPointsByApplicationId(data))   
		
	} catch (error) {   
		yield put(actions.successPointsByApplicationId([]))
	}
} 

//
function* requestDeletePoint(action) {
	try {    
		yield call(API.deletePoint, action.payload.request);   
		action.payload.callback(action.payload.request.merchantPointId)
	} catch (error) {   
		action.payload.callback(false)
	}
} 

//
function* requestCreateUpdatePoint(action) {
	try {   

		const data = yield call(API.createUpdatePoint, action.payload.request);  
		yield put(actions.successCreateUpdatePoint(data)) 

		yield put(changeLeadConvertedByPartner("CHECK_DONE"));
		
		action.payload.callback('success')

	} catch (error) {   
		yield put(actions.errorCreateUpdatePoint({}))
		action.payload.callback('error')
	}
} 

//
function* requestGetActivitiesKinds(action) {
	try {   
		const data = yield call(dictsAPI.getActivitiesKinds, action.payload);  
		yield put(actions.successGetActivitiesKinds(data.data))
	} catch (error) {   
		yield put(actions.successGetActivitiesKinds({}))
	}
}

//
function* requestUpdateTmsList(action) {
	try {   
		const data = yield call(API.updateTmsList, action.payload.request);  
		action.payload.callback(data)
		yield put(actions.successUpdateTmsList(data))   
		
	} catch (error) {   
		action.payload.callback(false)
		yield put(actions.successUpdateTmsList({}))
	}
}

//
function* requestUpdateTmsListFully(action) {
	try {   
		const data = yield call(merchantApplicationAPI.tmsUpdateAll, action.payload.request);  
		action.payload.callback(data)
		yield put(actions.finalUpdateTmsListFully(data))   
		
	} catch (error) {   
		action.payload.callback(error)
		yield put(actions.finalUpdateTmsListFully({}))
	}
}
//
function* requestUpdateCurrentTmsList(action) {
	try {   
		const data = yield call(merchantApplicationAPI.tmsUpdateCurrentConfigs, action.payload.request);  
		action.payload.callback(data)
		yield put(actions.finalUpdateCurrentTmsList(data))   
		
	} catch (error) {   
		action.payload.callback(error)
		yield put(actions.finalUpdateCurrentTmsList({}))
	}
}

//
function* requestUpdateTmsListQuickReg(action) {
	try {   
		const data = yield call(API.updateTmsListQuickReg, action.payload.request);
		action.payload.callback(data)
		yield put(actions.successUpdateTmsListQuickReg(data))   
		
	} catch (error) {   
		action.payload.callback(error.response)
		yield put(actions.successUpdateTmsListQuickReg({}))
	}
}

//
function* requestCheckDoublesSn(action) {
	try {
		const data = yield call(API.checkDoublesSn, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalCheckDoublesSn(data))
		
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalCheckDoublesSn({}))
	}
}

//
function* requestGetTmsList(action) {
	try {   
		const data = yield call(API.getTmsList, action.payload);  
		yield put(actions.successGetTmsList(data))   
		
	} catch (error) {   
		yield put(actions.successGetTmsList([]))
	}
} 

//
function* requestGetOnePoint(action) {
	try {   
		const data = yield call(API.getOnePoint, action.payload);  
		yield put(actions.successGetOnePoint(data))   
		
	} catch (error) {   
		yield put(actions.successGetOnePoint({}))
	}
}

//
function* requestCheckTmsForWarning(action) {
	try {   
		const data = yield call(API.checkTmsForWarning, action.payload.request); 
		action.payload.callback(data)
	} catch (error) {   
		action.payload.callback(false)
	}
} 

//
function* requestGetTmsHistory(action) {
	try {   
		const data = yield call(API.getTmsHistory, action.payload.request); 
		action.payload.callback(data)
	} catch (error) {   
		action.payload.callback([])
	}
} 

//
function* requestGetTmsDownloadFiles(action) {
	try {   
		const data = yield call(API.getTmsDownloadFiles, action.payload.request); 
		action.payload.callback(data)
	} catch (error) {   
		action.payload.callback({})
	}
} 

//
function* requestDownloadPDFHistory(action) {
	try {   
		yield call(API.downloadPDFHistory, action.payload);  
	} catch (error) {    
	}
} 

//
function* requestDownloadFilesArchive(action) {
	try {   
		yield call(API.downloadFilesArchive, action.payload);  
	} catch (error) {    
	}
} 

//
function* requestUpdateBankResponse(action) {
	try {   
		console.log("request update bank response ", action)
		const data = yield call(API.updateBankResponse, action.payload.request);  
		action.payload.callback(data)
		
	} catch (error) {   
		action.payload.callback(false)
	}
}