import axios from 'axios'

// const getTerminals = [
// 	{id: 2, name: "Trm Sn1", vendor: "Ali baba", model: "AC 998 34", address: "Москва, охотный ряд 2"},
// 	{id: 463, name: "Rte 3", vendor: "IP SOl", model: "FDf3", address: "Москва, Тмир 223"},
// ]
//
// const getLoyaltyProgram = [
// 	{value: 1, label: "E100"},
// 	{value: 2, label: "CityLife"},
// ]

// base64 -  будет отсылать браузер
const getTstList = [
	{id: 1, name: "Тст1", uploaded: true},
	{id: 23, name: "Тст 2", uploaded: false},
	{id: 43, name: "т с т 44", uploaded: false},
]

const getDynamicTerminals = [
	{id: 1, name: "Тст1", fields: [
			{id: 1, name: "Sn terminal", type: "TEXT", value: "123", required: 1, disabled: false},
			{id: 2, name: "Term date", type: "DATE", value: "", required: 0, disabled: true},
	]},
	{id: 68, name: "Тст2 2 2", fields: [
			{id: 111, name: "text 1", type: "TEXT", value: "text", required: 5, disabled: false},
			{id: 2, name: "text 2", type: "CHECKBOX", value: true, required: 0, disabled: false},
			{id: 3, name: "Combo", type: "COMBOBOX", value: '2', required: 1, disabled: false,
				options: [
					{value: '0', label: 't1'},
					{value: '2', label: 't233'}
				]
			},
	]},
]

export default {
	getOne: (data) => axios.get(`/terminal/${data.id}`, data).then(r => r.data),
	createUpdate: (data) => axios.post(`/terminal/createUpdate`, data).then(r => r.data),
	
	getTerminals: (data) => {
		let url = `/dict/merchant/${data.merchant_id}/terminals`
		if(data?.nonLoyalty){
			url = `/dict/merchant/${data.merchant_id}/terminals?nonLoyalty=true`
		}
		if(data?.ena){
			url = `/dict/merchant/${data.merchant_id}/terminals?ena=true`
		}
		if(data?.ena && data?.nonLoyalty){
			url = `/dict/merchant/${data.merchant_id}/terminals?nonLoyalty=true&ena=true`
		}
		return axios.get(url).then(r => r.data)
	},
	getTerminalsWithBankID: ({merchant_id,bank_id}) => axios.get(`/dict/merchant/${merchant_id}/terminals?bankId=${bank_id}`).then(r => r.data),
	getLoyaltyProgram: (data) => axios.post(`/merchant_application/loyalties`, data).then(r => r.data),
	getTstList: (data) => axios.post(`/terminal_extension/merchant_points`, data).then(r => r.data),
	getDynamicTerminals: (data) => axios.post(`/terminal_extension/additional_fields`, data).then(r => r.data),
}