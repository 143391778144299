import { EyeTwoTone } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import React from 'react';
import customHistory from '../../history';
import { generatePhoneWithCode } from '../shares/helpers/generatePhoneWithCode';
import { CustomerFastPaymentConfigSelect, CustomerFastPaymentNspkSelect } from './CustomerFastPaymentSelects';
import { ColumnSwitchBankSelect } from './ColumnSwitchBankSelect';

const ViewDetails = ({ record }) => {
    const handleEdit = () => {
            if (record.id) {
                customHistory.push(`/customers/pos/${record.id}`)
            }
    }

    return <Button type="text" icon={ <EyeTwoTone /> } onClick={ handleEdit } />
}

const columns = [
    {
        title: 'Статус',
        // dataIndex: 'posStatus',
        key: 'posStatus',
        align: 'center',
        // sorter: (a, b) => {
        //     if (a.posName > b.posName) {
        //         return 1;
        //     }
        //     if (a.posName < b.posName) {
        //         return -1;
        //     }
        //     // a должно быть равным b
        //     return 0;
        // },
        render: () => {
            return <span>
                <Tag color={ true ? "green" : "red" }>{ true ? "Активен" : "Неактивен" }</Tag>
            </span>
        },
    },
	{
        title: 'Банк',
        key: 'bank',
		width: 180,
		render: (value, record) => {           
			return <ColumnSwitchBankSelect record={record} />
        },
    },
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '80px',
        sorter: (a, b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        },
    },
    {
        title: 'Точка продаж',
        dataIndex: 'posName',
        key: 'posName',
        sorter: (a, b) => {
            if (a.posName > b.posName) {
                return 1;
            }
            if (a.posName < b.posName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
    },
    {
        title: 'Терминалов',
        dataIndex: 'numberofTreminals',
        key: 'numberofTreminals',
        sorter: (a, b) => {
            return a - b;
        },
    },
    {
        title: 'МСС',
        dataIndex: 'mcc',
        key: 'mcc',
    },
	{
        title: 'Выбор применения QR',
        key: 'fastPaymentConfigsOnTerminals',
		width: 180,
        hidden: true,
		render: (value, record) => {           
			return <CustomerFastPaymentConfigSelect record={record} />
        },
    },
	{
        title: 'Рег. данные НСПК' ,
        key: 'fastPaymentMerchantId',
		width: 200,
        hidden: true,
		render: (value, record) => {
			return <CustomerFastPaymentNspkSelect record={record}/>
        },
    },
    {
        title: 'Город',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => {
            if (a.city > b.city) {
                return 1;
            }
            if (a.city < b.city) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        
    },
    {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
		render: (value, record) => {
			return <div style={{minWidth: 150}}> {value} </div>
        },
    },
    {
        title: 'Режим работы',
        dataIndex: 'openingHours',
        key: 'openingHours',
    },
    
    {
        title: 'Контакт',
        dataIndex: 'responsibleName',
        key: 'responsibleName',
        sorter: (a, b) => {
            if (a.responsibleName > b.responsibleName) {
                return 1;
            }
            if (a.responsibleName < b.responsibleName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
    },
    {
        title: 'Телефон',
        key: 'responsiblePhone',
        render: (data) => generatePhoneWithCode(data.responsiblePhone, data.responsiblePhoneCode)
    },
    {
        title: 'Действия',
        key: 'actions',
        align: 'center',
        render: (text, record) => (
            <ViewDetails record={ record } />
        )
    },
];

export default columns
