export const fastPaymentConfigOptions = [
	{value:'DO_NOT_CHANGE',label:"Выбрать терминалы"},
	{value:'ALL',label:"На все терминалы"},
	{value:'NONE',label:"Ни на один терминал"},
];

export const getFastPaymentPopoverTitle = (value) => {
	if(value === 'NONE') return 'После подтверждения изменений в колонке «Рег. данные НСПК» оплата по QR не будет доступна ни на одном терминале данной торговой точки'
	if(value === 'ALL') return 'После подтверждения изменений в колонке «Рег. данные НСПК» оплата по QR будет доступна на всех терминалах данной торговой точки после запроса сессии ТМС'
	if(value === 'DO_NOT_CHANGE') return 'После подтверждения изменений в колонке «Рег. данные НСПК» в колонке «Действия» необходимо перейти в ТСТ и в таблице терминалов подключить оплату по QR (колонка «подключить СБП»)'
	return ''
};