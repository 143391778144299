import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[terminals] requestGetDataPage')
export const getDataPageSuccess = createAction('[terminals] getDataPageSuccess')
export const getDataPageError = createAction('[terminals] getDataPageError')
// get one
export const requestGetOne = createAction('[terminals] requestGetOne')
export const successGetOne  = createAction('[terminals] successGetOne')
export const clearGetOne  = createAction('[terminals] clearGetOne')
//  Сlose Shift
export const requestСloseShift = createAction('[terminals] requestСloseShift')
export const successСloseShift  = createAction('[terminals] successСloseShift')
//  get Shifts
export const requestGetShifts = createAction('[terminals] requestGetShifts')
export const successGetShifts  = createAction('[terminals] successGetShifts')
export const clearGetShifts  = createAction('[terminals] clearGetShifts')
//  create
export const requestCreate = createAction('[terminals] requestCreate')
export const finalCreate  = createAction('[terminals] finalCreate')