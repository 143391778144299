import {addLocale, useLocale as use_locale} from "ttag";

export const i18n = {
    init,
    setLocale,
    getLocale,
    transliterateToEnglish
};

const mapChartersRusAndEnglish = {
    1025: "Yo", 1040: "A", 1041: "B", 1042: "V", 1043: "G", 1044: "D", 1045: "E",
    1046: "Zh", 1047: "Z", 1048: "I", 1049: "I", 1050: "K", 1051: "L", 1052: "M",
    1053: "N", 1054: "O", 1055: "P", 1056: "R", 1057: "S", 1058: "T", 1059: "U",
    1060: "F", 1061: "H", 1062: "Ts", 1063: "Ch", 1064: "Sh", 1065: "Sch", 1066: "",
    1067: "I", 1068: "", 1069: "E", 1070: "Yu", 1071: "Ya",
    1072: "a", 1073: "b", 1074: "v", 1075: "g", 1076: "d", 1077: "e", 1078: "zh",
    1079: "z", 1080: "i", 1081: "i", 1082: "k", 1083: "l", 1084: "m", 1085: "n",
    1086: "o", 1087: "p", 1088: "r", 1089: "s", 1090: "t", 1091: "u", 1092: "f",
    1093: "h", 1094: "ts", 1095: "ch", 1096: "sh", 1097: "sch", 1098: "", 1099: "i",
    1100: "", 1101: "e", 1102: "yu", 1103: "ya", 1105: "yo"};

function init() {

    const locale = getLocale();

	const translationsObj = require(`../../../i18n/${locale}.po.json`);
	addLocale(locale, translationsObj);
	use_locale(locale);
    
}

function getLocale() {
    return localStorage.getItem('locale') || "ru";
}

// private function
function saveLocale(locale) {
    localStorage.setItem('locale', locale);
}

function setLocale(locale) {
    saveLocale(locale);
    window.location.reload();
}

function transliterateToEnglish(string) {
    let engString = string.split('').map(function (char) {
        const specialSbl = mapChartersRusAndEnglish[char.charCodeAt(0)]
        return specialSbl === undefined ? char : specialSbl
    }).join("");

    if (string === string.toUpperCase()){
        engString = engString.toUpperCase();
    }

    return engString;
}

