import axios from 'axios'
import {downloadBlob} from '../apiTools';

//without action -> saga
export const checkTmsSerialNumber = (data) => axios.post(`/tms/checkSn`, data).then(r => r.data).catch(e => e);

export default {  
	getPointsByApplicationId: (data) => axios.get(`/merchant_points/${data.applicationId}`).then(r => r.data),  
	getOnePoint: (data) => axios.post(`/merchant_application/${data.appId}/merchant_point/${data.pointId}`).then(r => r.data),  
	deletePoint: (data) => axios.post(`/merchant_application/delete_point`, data).then(r => r.data),  
	createUpdatePoint: (data) => axios.post(`/merchant_application/point`, data).then(r => r.data),  
	getActivitiesKinds: (data) => axios.post(`/merchant_point/activitiesKinds`, {bankId: null, ...data}).then(r => r.data),  
	// tmsmerchant_application/fast/tms/upd
	getTmsList: (data) => axios.post(`/merchant_application/${data.ma_id}/merchant_point/${data.mp_id}/tms/list`).then(r => r.data),
	updateTmsList: (data) => axios.post(`/merchant_point/tms/upd`, data).then(r => r.data),
	updateTmsListFully: (data) => axios.post(`/merchant_point/tms/upd/all`, data).then(r => r.data),
	updateTmsListQuickReg: (data) => axios.post(`/merchant_application/fast/tms/upd`, data).then(r => r.data),
	checkDoublesSn: (data) => axios.post(`/merchant_application/check_sn_exists`, data).then(r => r.data),
	checkTmsForWarning: (data) => axios.post(`/merchant_application/check_sn_exists/pgw_tms`, data).then(r => r.data),
	getTmsHistory: (data) => axios.post(`/merchant_point/tms/getSdmHistory`, data).then(r => r.data),
	downloadPDFHistory: (data) => axios.post(`/merchant_point/tms/getSdmHistory`, data, {responseType: 'blob'}
	).then(res => downloadBlob(res, 'document.pdf')), 
	downloadFilesArchive: (data) => axios.post(`/merchant_application/downloadConfigFiles`, data, {responseType: 'blob'}
	).then(res => downloadBlob(res, 'documents.zip')), 

	getTmsDownloadFiles: (data) => axios.post(`/merchant_application/terminal_files`, data).then(r => r.data), 

	updateBankResponse: (data) => axios.post(`/merchant_application/update_bank_response`, data).then(r => r.data),

}