import axios from 'axios'

// первое число = 00:00, последние 23:00
const getCountTransactions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14,15,16,17,18,19,20,21,22,23,24]

const getCountShifts = [1, 2, 3, 4, 15, 6, 7, 8, 9, 10, 11, 12, 13,14,15,16,17,18,19,20,21,22,23,24]

const getTspIntensity = {
	count: [44, 55, 31, 47, 31, 43, 26, 41, 31, 47, 33],
	money: [15000, 17000, 13000, 14213, 16000.34, 17000, 55000, 11000, 17000, 16000, 17000],
}

const getTspRejected = {
	completed: [44, 55, 41, 67, 22, 43],
	rejected: [13, 23, 20, 8, 13, 27],
}

// жду отсортированный список ошибок
const getTotalErrors = [
	{count: 53, name: "Рекорд"},
	{count: 47, name: "Неплохо"},
	{count: 44, name: "ИП Хрен с горы"},
	{count: 32, name: "Рога и копыта"},
	{count: 30, name: "Рога и копыта"},
	{count: 12, name: "12 друзей оушена"},
	{count: 3, name: "Тройка"},
	{count: 2, name: "Мини"},
	{count: 1, name: "Ван"},
]

const getTotalAmount = {
	successfully: [15000, 35002.2, 25933.5, 19872, 35002.2, 25933.5, 19872, 35002.2, 25933.5, 19872, 35002.2, 25933.5, 19872],
	averageCheck: [12000, 30000, 24900, 14888, 30000, 24900, 14888, 30000, 24900, 14888, 30000, 24900, 14888],
	unsuccessfully: [345, 1114.4, 5441.6, 1000, 1114.4, 5441.6, 1000, 1114.4, 5441.6, 1000, 1114.4, 5441.6, 1000],
}

export default {
	// getParamsForFilters: (data) => axios.post(`/statistic/transactions`, data).then(r => r.data),
	// getCountShifts: (data) => axios.post(`/statistic/transactions`, data).then(r => getCountShifts).catch(e => getCountShifts),
	// getTotalAmount: (data) => axios.post(`/statistic/transactions`, data).then(r => getTotalAmount).catch(e => getTotalAmount),
	// getCountTransactions: (data) => axios.post(`/statistic/transactions`, data).then(r => []).catch(e => []),
	getCountTransactions: (data) => axios.post(`/graphs/transactions/hour`, data).then(r => r.data),

	getCountShifts: (data) => axios.post(`/graphs/terminalShifts/hour`, data).then(r => r.data),
	getTotalAmount: (data) => axios.post(`/graphs/transactions/day`, data).then(r => r.data),
	getParamsForFilters: (data) => axios.get(`/transactions/fields`, data).then(r => r.data),
	getTspIntensity: (data) => axios.post(`/graphs/intensity`, data).then(r => r.data),
	getTspRejected: (data) => axios.post(`/graphs/merchants/status`, data).then(r => r.data),
	getTotalErrors: (data) => axios.post(`/graphs/transactions/error`, data).then(r => r.data), 


	// -- widgets --> 
	getTopWidgets: (data) => axios.post(`/statistic/transactions`, data).then(r => r.data),
	getCashFlow: (data) => axios.post(`/statistic/transactions/totalTurnover`, data).then(r => r.data),
	getPurchasesCount: (data) => axios.post(`/statistic/transactions/purchasesCount`, data).then(r => r.data),
	getRefundsCount: (data) => axios.post(`/statistic/transactions/refundsCount`, data).then(r => r.data),
	getReversalsCount: (data) => axios.post(`/statistic/transactions/reversalsCount`, data).then(r => r.data),
}