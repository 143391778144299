import { call, put, takeLatest } from 'redux-saga/effects'; 
import * as actions from './actions'  
import API from '../../../api/core/connection';  

export default [ 
	takeLatest(actions.requestDownloadAnyFile, requestDownloadAnyFile),    
]


function* requestDownloadAnyFile(action) {
	try {  
		const data = yield call(API.downloadAnyFile, action.payload.request);  
		action.payload.callback(true) 
	} catch (error) {    
		action.payload.callback(false) 
	}
} 