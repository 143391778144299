
import merchant from './merchant/Saga';  
import crm from './crm/Saga';  
import tct from './tct/Saga';  
import statistics from './statistics/Saga';  
import services from './services/Saga';  
import administration from './administration/Saga';

export default [  
  ...merchant,  
  ...crm,  
  ...tct,  
  ...statistics,  
  ...services,  
  ...administration,
]
 