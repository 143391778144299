import React from "react"
import { useHistory } from "react-router-dom"
import { Button, Tag } from "antd"
import { EyeTwoTone, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const ViewDetails = ({ record }) => {
    const history = useHistory()
    const handleEdit = () => {
        const { pathname } = history.location
        if (record.id) {
            history.push(`${pathname}/update/${record.id}`)
        }
    }
    return <Button type="text" icon={<EyeTwoTone />} onClick={handleEdit} />
}

const columns = [
    {
        title: 'Статус',
        dataIndex: 'enabled',
        key: 'enabled',
        align: 'center',
        width: "110px",
        render: (enabled) => {
            if (enabled) {
                return <span>
                    <Tag icon={ <CheckCircleOutlined /> } color="success">
                        Активен
                    </Tag>
                </span>
            }
            return <span>
                <Tag icon={ <MinusCircleOutlined /> } color="error">
                    Неактивен
                </Tag>
            </span>
        },
        sorter: (a, b) => {
            if (a.enabled > b.enabled) {
                return 1
            }
            if (a.enabled < b.enabled) {
                return -1
            }
            return 0
        },
    },
    {
        title: "ИНН",
        dataIndex: "tin",
        key: "tin",
        sorter: (a, b) => {
            if (a.partnerName > b.partnerName) {
                return 1
            }
            if (a.partnerName < b.partnerName) {
                return -1
            }
            return 0
        },
    },
    {
        title: "Название",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Контактное лицо",
        dataIndex: "contactName",
        key: "contactName",
    },
    {
        title: "Контактный телефон",
        dataIndex: "contactPhone",
        key: "contactPhone",
    },
    {
        title: "Действия",
        key: "actions",
        align: "center",
        render: (_, record) => <ViewDetails record={record} />,
    },
]

export default columns