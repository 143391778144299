import { Popover, Row, Typography } from "antd";

const OptionsPopover = ({ options, title, placement = 'leftTop' }) => {
	return (
			<Popover content={
				<Row style={{maxWidth: 300}}>
					<Typography.Text> {title}</Typography.Text>
				</Row>
				}
				placement={placement}
			>
				<Typography.Text  style={{width: 200}}> {options?.label}</Typography.Text>
			</Popover>
	)
};

export default OptionsPopover;
