import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import generalAPI from '../../../api/v2/generalAPI';

export const fetchOtp = createAsyncThunk(
    "general/otp/fetch",
    async (purpose) => {
        const response = await generalAPI.getOtp(purpose)
        return response
    }
)

const initialState = {
    company: {
        data: {},
        loading: false
    }
}

const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        clearGeneralData: (state) => {
            state.company = initialState.company
        }
    },
    extraReducers: {}
});

// export const {

// } = generalSlice.actions
export default generalSlice.reducer