import * as actions from "./actions";
import { createReducer } from "redux-act";
import { FD_REPORTS_fieldsForSelect, FD_REPORTS_subPartnersSelect } from "../../../../core/utils/filters/FormatConverter"; 


const initialState = { 
  data: [], 
  loading: true, 
  selectFields: {
    loading: false,
    data: {}
  },
  subPartnersSelect: {
    loading: false,
    data: []
  },
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  one: {
    loading: false,
    data: {},
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),

    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list ? action.list : []  };
    },

    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
    }),
    
    
    // --- * get Select fields ->>
    [actions.requestGetSelectFields]: (state, action) => ({
      ...state, selectFields: {...state.selectFields, loading: true}
    }),
    [actions.successGetSelectFields]: (state, action) => {
      return { ...state, selectFields: {...state.selectFields, data: FD_REPORTS_fieldsForSelect(action), loading: false}}
    },
    
    
    
    // --- * create ->>
    [actions.requestCreateData]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.createDataFinish]: (state, action) => {
      let newData = state.data
      if(action) newData = [...newData, action]
      return { ...state, create: {...state.create, loading: false}, data: newData}
    },
    
    // --- * update ->>
    [actions.requestUpdateData]: (state, action) => ({
      ...state, update: {...state.update, loading: true}
    }),
    [actions.finalUpdateData]: (state, action) => {
      let newData = state.data
      if(action) newData = newData.map(el => { 
        if(action.id && ''+el.id === ''+action.id) return action
        return el
      }) 
      return { ...state, update: {...state.update, loading: false}, data: newData}
    },
    
    // --- * get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.finalGetOne]: (state, action) => {
      return { ...state, one: {...state.one, loading: false, data: action}}
    },
    [actions.clearGetOne]: (state, action) => {
      return { ...state, one: initialState.one}
    },
    
    // --- * get SubPartners Select ->>
    [actions.requestGetSubPartnersSelect]: (state, action) => ({
      ...state, subPartnersSelect: {...state.subPartnersSelect, loading: true}
    }),
    [actions.finalGetSubPartnersSelect]: (state, action) => {
      return { ...state, subPartnersSelect: {...state.subPartnersSelect, loading: false, data: FD_REPORTS_subPartnersSelect(action)}}
    },
    
    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
