import { createAction } from 'redux-act';


// get data
export const requestGetDataPage = createAction('[transactions] requestGetDataPage')
export const getDataPageSuccess = createAction('[transactions] getDataPageSuccess')
export const getDataPageError = createAction('[transactions] getDataPageError')
// get fields For Search
export const requestGetFieldsForSearch = createAction('[transactions] requestGetFieldsForSearch')
export const successGetFieldsForSearch  = createAction('[transactions] successGetFieldsForSearch')
// request Download Csv Transaction
export const requestDownloadCsvTransaction = createAction('[transactions] requestDownloadCsvTransaction')
export const successDownloadCsvTransaction   = createAction('[transactions] successDownloadCsvTransaction')
// request  Banks For Transactions
export const requestBanksForTransactions = createAction('[transactions] requestBanksForTransactions')
export const successBanksForTransactions   = createAction('[transactions] successBanksForTransactions')
// request CancelTransaction
export const requestCancelTransaction= createAction('[transactions] requestCancelTransaction')
export const finalCancelTransaction = createAction('[transactions] finalCancelTransaction')
// request ConfirmCancelTransaction
export const requestConfirmCancelTransaction = createAction('[transactions] requestConfirmCancelTransaction')
export const finalConfirmCancelTransaction = createAction('[transactions] finalConfirmCancelTransaction')
// request DenyCancelTransaction
export const requestDenyCancelTransaction = createAction('[transactions] requestDenyCancelTransaction')
export const finalDenyCancelTransaction = createAction('[transactions] finalDenyCancelTransaction')
// request DownloadCheck
export const requestDownloadCheck = createAction('[transactions] requestDownloadCheck') 