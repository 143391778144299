import React from 'react';
import {t} from 'ttag';
// styles
import { notification } from 'antd';

//  "success",  "error"
// type = success, info, warning, error
const CustomNotification = ({type, description, time=0, message}) => {
 
	const _message = message ?  message : (type === "success" ? t`Successful` : t`Failure`)
 
	return notification[type]({
		duration: time,
		message: _message,
		description: description,
	})
};

export default CustomNotification;