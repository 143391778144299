import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partnersAPI from '../../api/v2/partnersAPI'


export const getPartnerData = createAsyncThunk(
    "partner-update/partnerData/get",
    async (partnerId) => {
        const response = await partnersAPI.getOnePartnerData(partnerId)
        return response
    }
)

export const getBanksManagers = createAsyncThunk(
    "partner-update/banksOptions/get",
    async (partnerId) => {
        const response = await partnersAPI.getBanksManagers(partnerId)
        return response
    }
)

export const getsignProxyPrint = createAsyncThunk(
    "partner-update/signProxyPrint/get",
    async ({partnerId, bankId}) => {
        const response = await partnersAPI.getSigns(partnerId, bankId)
        return response
    }
)

export const updatePartner = createAsyncThunk(
    "partner-update/partnerData/put",
    async (payload, thunkAPI) => {
        const id = thunkAPI.getState().partnerUpdate.partnerData.id
        const response = await partnersAPI.updatePartner({...payload, id})
        return response
    }
)

export const updatePartnerSDMData = createAsyncThunk(
    "partner-update/partnerSDMData/put",
    async (payload) => {
        const response = await partnersAPI.updatePartnerSDMData(payload)
        return response
    }
)

export const updateBanksManagers = createAsyncThunk(
    "partner-update/banksOptions/put",
    async (payload, thunkAPI) => {
        const partnerId = thunkAPI.getState().partnerUpdate.partnerData.id
        if (payload.bankId) {
            const response = await partnersAPI.updatePartnerBankManagers({...payload, partnerId})
            return response
        } else {
            const banks = payload.selectedBanksOptions.map(({bankId, managers}) => {
                managers = managers.map((item, index) => {
                    if (index === 0) {
                        return {id: item, isDefault: true}
                    } else {
                        return {id: item, isDefault: false}
                    }
                })
                return {bankId, managers}
            })
            const response = await partnersAPI.updatePartnerBankManagers({banks, partnerId})
            return response
        }
    }
)

export const updatePartnerSignProxy = createAsyncThunk(
    "partner-update/partnerSignProxy/put",
    async ({partnerId, bankId, data}) => {
        await partnersAPI.updatePartnerBankData({partnerId, bankId, ...data})
        const response = await partnersAPI.getSigns(partnerId, bankId)
        return response
    }
)

export const updatePartnerBankLogoPrintSign = createAsyncThunk(
    "partner-update/partnerBankLogoPrintSign/put",
    async ({partnerId, bankId, type, img}, thunkAPI) => {
        thunkAPI.dispatch(startImgLoading(type))
        if (type === 'logo') {
            await partnersAPI.updatePartnerBankLogo({partnerId, bankId, fileData: img})
        }
        if (type === 'print') {
            await partnersAPI.updatePartnerBankPrint({partnerId, bankId, fileData: img})
        }
        if (type === 'sign') {
            await partnersAPI.updatePartnerBankSign({partnerId, bankId, fileData: img})
        }
        if (type === 'VTBSignAndPrint') {
            const signAndPrintArray = img.map(item => ({fileData: item}))
            await partnersAPI.updatePartnerBankSignAndPrintFiles({partnerId, bankId, files: signAndPrintArray})
        }
        const response = await partnersAPI.getSigns(partnerId, bankId)
        return {...response, type}
    }
)


const initialState = {
    partnerData: {},
    options: {
        banksOptions: [],
        managersOptions: {},
        currentBanksModalData: [],
        signProxyPrint: {},
        VTBSignAndPrint: [],
        loading: false,
        logoLoading: false,
        printLoading: false,
        signLoading: false,
        VTBSignAndPrintLoading: false
    },
    loading: false,
}
 
const partnerCreateSlice = createSlice({
    name: "partner-update",
    initialState,
    reducers: {
        startImgLoading: (state, {payload}) => {
            if (payload === 'logo') {
                state.options.logoLoading = true
            }
            if (payload === 'print') {
                state.options.printLoading = true
            }
            if (payload === 'sign') {
                state.options.signLoading = true
            }
            if (payload === 'VTBSignAndPrint') {
                state.options.VTBSignAndPrintLoading = true
            }
        },
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key]
                }
            }
        },
    },
    extraReducers: {
        [getPartnerData.pending]: (state) => {
            state.loading = true
        },
        [getPartnerData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.partnerData = payload
        },
        [getPartnerData.rejected]: (state) => {
            state.loading = false
        },
        [getBanksManagers.pending]: (state) => {
            state.options.loading = true
        },
        [getBanksManagers.fulfilled]: (state, { payload }) => {
            state.options.banksOptions = payload.banks.map(item => ({value: item.bankId, label: item.bankName}))
            const managersOptions = payload.banks.map(({bankId, managers}) => {
                return [bankId, managers.map(item => ({value: item.id, label: item.name}))]
            })
            state.options.managersOptions = Object.fromEntries(managersOptions)
            state.options.currentBanksModalData = payload.banks?.reduce((newArr, item) => {
                if (item.selected) {
                    return [...newArr, {bankId: item.bankId, bankName: item.bankName, selected: item.selected, bankBeanName: item.bankBeanName, managers: item.managers.reduce((newArr, item) => {
                        if (item.selected) {
                            return [...newArr, item.id]
                        }
                        return newArr
                    }, [])}]
                }
                return newArr
            }, [])
            state.options.loading = false
        },
        [getBanksManagers.rejected]: (state) => {
            state.options.loading = false
        },
        [getsignProxyPrint.pending]: (state) => {
            state.options.loading = true
        },
        [getsignProxyPrint.fulfilled]: (state, { payload }) => {
            state.options.loading = false
            state.options.signProxyPrint = payload
        },
        [getsignProxyPrint.rejected]: (state) => {
            state.options.loading = false
        },
        [updatePartner.pending]: (state) => {
            state.loading = true
        },
        [updatePartner.fulfilled]: (state) => {
            state.loading = false
        },
        [updatePartner.rejected]: (state) => {
            state.loading = false
        },
        [updatePartnerSDMData.pending]: (state) => {
            state.loading = true
        },
        [updatePartnerSDMData.fulfilled]: (state) => {
            state.loading = false
        },
        [updatePartnerSDMData.rejected]: (state) => {
            state.loading = false
        },
        [updateBanksManagers.pending]: (state) => {
            state.loading = true
        },
        [updateBanksManagers.fulfilled]: (state) => {
            state.loading = false
        },
        [updateBanksManagers.rejected]: (state) => {
            state.loading = false
        },
        [updatePartnerSignProxy.pending]: (state) => {
            state.loading = true
        },
        [updatePartnerSignProxy.fulfilled]: (state) => {
            state.loading = false
        },
        [updatePartnerSignProxy.rejected]: (state) => {
            state.loading = false
        },
        [updatePartnerBankLogoPrintSign.fulfilled]: (state, { payload }) => {
            if (payload.type === 'logo') {
                state.options.signProxyPrint.logo = payload.logo
                state.options.logoLoading = false
            }
            if (payload.type === 'print') {
                state.options.signProxyPrint.print = payload.print
                state.options.printLoading = false
            }
            if (payload.type === 'sign') {
                state.options.signProxyPrint.sign = payload.sign
                state.options.signLoading = false
            }
            if (payload.type === 'VTBSignAndPrint') {
                state.options.signProxyPrint.files = payload.files
                state.options.VTBSignAndPrintLoading = false
            }
        },
        [updatePartnerBankLogoPrintSign.rejected]: (state) => {
            state.options.logoLoading = false
            state.options.printLoading = false
            state.options.signLoading = false
            state.options.VTBSignAndPrintLoading = false
        },
    }
});

export const {
    startImgLoading,
    clearFormData,
} = partnerCreateSlice.actions
export default partnerCreateSlice.reducer