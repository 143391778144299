import { BankOutlined } from "@ant-design/icons";
import { Button, Popover, Row, Select, Spin, Typography } from "antd";
import {  useEffect, useState } from "react";
import usePermissions from "../shares/helpers/usePermissions";

export const SwitchBankSelect = ({ 
	bankId, 
	id, 
	bankOptions,
	switchBankCallback,
	isLoading,
	startFetching,
	setStartFetching,
}) => {

	const { permissions } = usePermissions()

	const [bankLocalData, setBankLocalData] = useState({bankId: '', bankWasChanged: false})

	useEffect(() => {
		setBankLocalData({bankId, bankWasChanged: false})
	}, [bankId])

	const onBankChange = (value) => {
		if(!value){
			return setBankLocalData({bankId: bankId, bankWasChanged: false})
		}
		setBankLocalData({bankId: value, bankWasChanged: true})
	};

	const onBankChangeClick = () => {
		switchBankCallback({id, payload: {bankId: bankLocalData.bankId}, setBankLocalData})
	};

	const onSelectDropdown = (e) => {
		if(setStartFetching) !startFetching && setStartFetching(true)
	};
	/** если выбрано несколько банков, делаем опцию для отображения и скрываем для выбора */

	return (
		<Spin spinning={Boolean(isLoading)}>
			<Row className="mt-1 mb-1 w-full">
				<Select
					disabled={!permissions['clientsSection:terminals:bankSwitching']}
					value={bankLocalData.bankId}
					onChange={onBankChange}
					style={{ maxWidth:220, minWidth:120 }}
					onDropdownVisibleChange={onSelectDropdown}
					size='small'
					allowClear
				>
				{bankOptions.map((opt, index) => {
					if(opt.label.includes(',')){
						return <Select.Option 
									key={index} 
									disabled={opt.disabled} 
									value={opt.value} 
									style={{position: 'absolute', visibility:'hidden' , zIndex: -999, pointerEvents:'none'}}
								> 
								<Popover 
									content={
									<Row>
										<Typography.Text> {opt.label}</Typography.Text>
									</Row>}
									placement='topRight'
								>
									 {opt.label}
								</Popover>
							</Select.Option>
					}
						return <Select.Option key={index} 	disabled={opt.disabled} value={opt.value} > 
								{opt.label}
							</Select.Option>
				})}
				</Select>
			</Row>
			{bankLocalData.bankWasChanged && (bankLocalData.bankId !== bankId) &&
			<Button  
				className="mb-1" 
				style={{ width:120 }}
				size='small' 
				type="primary" 
				icon={<BankOutlined />}
				onClick={onBankChangeClick}
				loading={isLoading}
			> Подвердить </Button>
			}
		</Spin>
	
	)
};