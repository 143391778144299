import axios from 'axios'

export default {
	getData: (data) => axios.get(`/roles/groups?page=${data.page}&elementsPerPage=${data.elementsPerPage}`, data).then(r => r.data),
	create: (data) => axios.post(`/roles`, data).then(r => r.data),
	update: (data) => axios.put(`/roles`, data).then(r => r.data),
	getOne: (data) => axios.get(`/roles/${data.id}`).then(r => r.data),
	
	checkLogin: (data) => axios.post(`/user/${data.login}`).then(r => r.data), // true - юзер существует в бд
	getRoles: () => axios.get(`/user/roles/available`).then(r => r.data),
	// resetPassword: (data) => axios.post(`/user/updatePassword`, data).then(r => r.data),
	resetPassword: (data) => axios.post(`/api/v2/user/resetUserPassword`, data).then(r => r.data),
}