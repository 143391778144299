const useCheckDomainName = () => {

	const location = window.location.origin
	const isAtol = location.includes('atol')

	return {
		isAtol,
	}
};

export default useCheckDomainName