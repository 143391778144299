import axios from 'axios'
import jwt_decode from 'jwt-decode'
import customHistory from '../../../history'
// redux
import { addToken } from '../../../store/auth/user/actions'
import store from '../../../store/store'

/** Устонавливаем токен в заголвоки и ложим его в редакс */
export const setToken = (token) => {
    store.dispatch(addToken(jwt_decode(token)))
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const axiosDefault = {
    /** Запускается в самом начале приложения (если есть токен в  localstorage) или после успешного логина с токеном
     * Получаем словари, устонавливаем токен в заголовки, помещаем инфо из токена в редакс auth.login.user */
    setAuthAndGetInitialData: async (token) => {
        // устанавливаем токен в начале приложения
        await setToken(token)
    },
    setSettings: (url) => {
		//локально
        axios.defaults.baseURL = url
		//axios.defaults.baseURL = '/'
        axios.defaults.headers['Content-Type'] = 'application/json'
        axios.defaults.headers['x-compress'] = 'application/json'
        axios.defaults.headers['Accept'] = 'application/json'
        // [Before]
        axios.interceptors.request.use((config) => {
            const isOfd = config.url?.includes('ofd/api/v1')
            if (isOfd) {
                return config
            }
            const isApiV2 = config.url?.slice(0, 7) === '/api/v2'
            const isApiV3 = config.url?.slice(0, 7) === '/api/v3'
            if (isApiV2 || isApiV3) {
                return config
            }
            config.url = '/api/v1' + config.url
            return config
        })
        // [After]
        axios.interceptors.response.use(
            // После каждого запроса обновляем токен
            (config) => {
                if (config.headers && config.headers.token)
                    setToken(config.headers.token)
                return config
            },
            // если ошибка авторизации - удаляем токен, заголовок и редиректим
            (error) => {
                // console.log(123, error, error.code, error.response, window.location )
			
				if (
					window.location.origin.includes('merchant') &&
					(      window.location.pathname.includes('application_change-data/') 
						|| window.location.pathname.includes('leads/')
						|| window.location.pathname.includes('merchant_applications/')
					) 
					&& (!error.response || error.response.status !== 200)
					) {

                    delete localStorage.user
                    delete axios.defaults.headers.common['Authorization']

					window.location.href = window.location.origin+'/login'
                    throw error.response && error.response.data
                        ? error.response.data
                        : error 
                };

				// ошибка НЕ истечение времени, не отменена нами... во всех других кейсах должен быть ответ и статук не 401(неавторизован)
				if (
                    error.code !== 'ECONNABORTED' &&
                    error.toString() !== 'Cancel' &&
                    (!error.response || error.response.status === 401)
                ) {
                    console.warn('ERROR AUTH AXIOS default!!!!')
                    delete localStorage.user
                    delete axios.defaults.headers.common['Authorization']
                    // store.dispatch(delUserInToken()) // удаляем данные о юзе(токен инфо) в редаксе
                    customHistory.push('/login')
                    throw error.response && error.response.data
                        ? error.response.data
                        : error
                } else {
                    // ! Мы выбрасываем исключение еще до тоо как оно дойдет до .then, .catch в api и отдаем содержимое в перехватчик-сагу
                    // throw error - так мы пробрасываем исключение дальше в очередь (сохраняя все методы ошибки)
                    // throw error.response.data - так мы заранее разворачиваем все месседжи ошибки из аксиоса и передаем их дальше
                    throw error.response && error.response.data
                        ? error.response.data
                        : error
                }
            }
        )
    },
}

export default axiosDefault
