import axios from 'axios'

const ROOT_URL = "/api/v2"

const generalAPI = {
    // read
    getCompanyDataFromTIN: (TIN) => axios.get(`${ROOT_URL}/suggestion/company?TIN=${TIN}`).then(r => r.data),
    getOtp: (payload) => axios.put(`${ROOT_URL}/otp`, payload).then(r => r.data),
    checkSn: (payload) => axios.put(`${ROOT_URL}/checkSN`, payload).then(r => r.data),
    checkBankTID: (payload) => axios.put(`${ROOT_URL}/checkBankTid`, payload).then(r => r.data),
    getLogo: () => axios.get(`${ROOT_URL}/logo`).then(r => r.data),
    getAddressSplitData: (data) => axios.get(`${ROOT_URL}/suggestion/address?search=${data}`).then(r => r.data),
    getSupportText: () => axios.get(`${ROOT_URL}/supportText`).then(r => r.data),
    getBankDataByBic: (bic) => axios.get(`/suggestion/bank?bik=${bic}`).then(r => r.data),
	getExternalCompanyInfoByInn: (inn) => axios.get(`${ROOT_URL}/suggestion/externalCompany?inn=${inn}`).then(r => r.data),

}

export default generalAPI
