import { createAction } from 'redux-act';
 
// add token to redux
export const addToken = createAction('[auth user] requestLogin') 
 
// get Settings
export const requestGetSettings = createAction('[auth user] requestGetSettings') 
export const finalGetSettings = createAction('[auth user] finalGetSettings') 
export const clearGetSettings = createAction('[auth user] clearGetSettings') 
// get Settings bank
export const requestGetBankSettings = createAction('[auth user] requestGetBankSettings') 
export const finalGetBankSettings = createAction('[auth user] finalGetSettings') 
export const clearGetBankSettings = createAction('[auth user] clearGetSettings') 
// update one Settings bank
export const requestUpdOneBankSettings = createAction('[auth user] requestUpdOneBankSettings') 
export const finalUpdOneBankSettings = createAction('[auth user] finalUpdOneBankSettings') 
// update  Settings 
export const requestUpdateSettings = createAction('[auth user] requestUpdateSettings') 
export const finalUpdateSettings = createAction('[auth user] finalUpdateSettings') 
// get allRoles
export const requestGetAllRoles = createAction('[auth user] requestGetAllRoles') 
export const finalrequestGetAllRoles = createAction('[auth user] finalrequestGetAllRoles') 
 
// add user settings
export const userSettings = createAction('[auth user] userSettings') 