import axios from 'axios'

const ROOT_URL = '/api/v2'

const transactionsAPI = {
    // read
    getDataByAdvancedSearch: (data) => axios.post(`${ROOT_URL}/transactions/advanced-search`, data)
		.then(r => r.data),
    getData: () => axios.get(`${ROOT_URL}/transactions`).then(r => r.data),
	cancelTransaction: (data) => axios.post(`${ROOT_URL}/transaction/reverse`, data)
        .then(r => r.data),
	confirmCancelTransaction: (data) => axios.post(`${ROOT_URL}/transaction/reverse/confirm`, data)
        .then(r => r.data),
	denyCancelTransaction: (data) => axios.post(`${ROOT_URL}/transaction/reverse/reject`, data)
        .then(r => r.data),
}

export default transactionsAPI