import axios from 'axios' 
import {downloadBlob} from '../apiTools';
  

export default {   
	sendNewLead: (data) => axios.post(`/new_lead/send`, data).then(r => r.data),  

	checkHashNewLead: (data) => axios.post(`/new_lead/${data}`, data).then(r => r.data), 
	deleteFileNewLead: (data) => axios.post(`/new_lead/${data.id}/file/${data.fileId}/del`, data).then(r => r.data), 
	downloadFileNewLead: (data) => axios.post(`/new_lead/${data.id}/file/${data.fileId}`, {}, {responseType: 'blob'})
	.then(r => downloadBlob(r, data.fileName)), 
	downloadPersonalDocNewMerch: (data) => axios.post(`/new_lead/${data.hash}/generate_file`, data, {responseType: 'blob'}).then(resp => downloadBlob(resp, data.fileName)),
	uploadFile: (data) => axios.post(`/new_lead/${data.id}/upload${data.appKey ? "/"+data.appKey : ''}`, 
		data.payload).then(r => r.data),

	uploadFile2: (data) => axios.post(`/api/v2/new_lead/upload`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),


}

export const requestIdForNewMerchantPoint = (data) => axios.post(`/new_lead/${data.id}/addMerchantPoint`, {id: data.id}).then(r=>r)
export const requestDeleteMerchantPoint = (data) => axios.post(`/new_lead/${data.id}/deleteMerchantPoint`, {id: data.merchantId}).then(r=>r)
