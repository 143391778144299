import {t} from 'ttag';

// !!! Весь функционал для изменения данных в формат react-select:  {label: 'name', value: '5'} ///

/** 
 * {421: "ыы", 34: "test"}
  */
 export const FD_MapToSelect = data => {
	if(typeof data !== 'object' || !Object.keys(data).length) return []

	return Object.keys(data).map(key => {return {value: key, label: data[key]}})
}
/** For new models data structure */
export const FD_modelsAll = data => {
	if (data && data.length) {
		return data.map(el => ({value: el.id, label: el.name}))
	}

	return []
}

/** For new models data structure */
// export const FD_termDefaultOperationTypes = data => {
// 	if (data && data.length) {
// 		return data.map(({termDefaultOperationTypes}) => ({value: termDefaultOperationTypes.id, label: termDefaultOperationTypes.name}))
// 	}

// 	return []
// }

/** For terminals */
 export const FD_terminals = data => { 
	if(data && data.length){
		return data.map(el => {
			return {value: ''+el.id, label: el.serialNumber, ena: el.ena, tid: el.tid, modelId: el.modelId ? el.modelId : '' ,vendorId: el.vendorId ? el.vendorId : '' }
		})
	}

	return []
}


/** 
 * [page] merchant_applications/new  -> tab 1
 * @param {Object} data {codesSubPartners: [], codeOwn: "777"}
  */
export const FD_partnerCodes = data => {  
	if(typeof data !== 'object' || !Object.keys(data).length) return []

	let ownCode = data.codeOwn
	let subPartnersCodes = (data.codesSubPartners || []).map(code => ({label: code, value: code}));

	let options = [{label: ownCode + ` (${t`Own code`})` , value: String(ownCode)}];
	options.push(...subPartnersCodes);
	// console.log(options);
	return options; 
}

/** 
 * [page] merchant_applications/new  -> tab 1
 * @param {Object} {421: "ыы", 34: "test"}
  */
export const FD_comissionsCombobox = data => {  
	if(typeof data !== 'object' || !Object.keys(data).length) return []

	return Object.keys(data).map(key => {return {value: key, label: data[key]}})
}

/** 
 * [page] merchant_applications  - создание ТСТ
 * @param {Object} data kindActivity: {0: "RT", 1: "TY", ...}
  */
export const FD_activitiesKinds = data => {  

	if(!data || !Object.values(data).length) return []

	let result = []
	for (const [value, label] of Object.entries(data)) {
		result.push({value, label})
	}

	return result
}

/** 
 * [page] merchant_applications  - создание ТСТ
 * @param {Object} data kindActivity: {0: "RT", 1: "TY", ...}
  */


/** 
 * [page] merchant_applications  - Банки
  */
export const FD_banks = data => {  
	if(!Array.isArray(data)) return []

	let result = []
	data.map(el => result.push({...el, value: ''+el.id, label: el.name}))
	return result
}
export const FD_banksMap = data => {  
	if(!Array.isArray(data)) return []

	return data.reduce((prev, curr) => {
		prev[curr.id] = curr
		return prev
	},{})
}



export const FD_fieldsForSearch = data => {  
	if(typeof data !== 'object' || !Object.values(data).length) return {}

	let result = {}
	for (const key in data) {
		result[key] = []
		for (const [value, label] of Object.entries(data[key])) {
			result[key].push({value, label})
		}
	}
	return result
}


export const FD_partners = action => {  
	if(!action?.data?.length) return []
	const { data } = action
	return data.map(item =>  ({value: item.id, label: item.name}))
}

export const FD_default = action => {  
	if(!action?.data?.length) return []
	const { data } = action
	return data.find(item =>  (item.isDefault === true))?.id
}


export const FD_REPORTS_fieldsForSelect = data => {  
	if(typeof data !== 'object' || !Object.values(data).length) return {}

	let result = {}
	for (const [name, obj] of Object.entries(data)) {
		result[name] = []
		for (const [value, label] of Object.entries(obj)) {
			result[name].push({value: Number(value), label})
		}
	}
	return result
}

export const FD_REPORTS_subPartnersSelect = data => {  
	if(typeof data !== 'object' || !data.details || !Object.values(data).length) return []

	let result = []
	for (const [value, label] of Object.entries(data.details)) {
		result.push({key: value, title: label})
	}
	return result
}