import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import usersAPI from '../../api/v2/usersAPI';
import dictsAPI from '../../api/v2/dictsAPI';
import rolesAPI from '../../api/v2/rolesAPI';
import { gettext } from 'ttag';
import { generatePhoneWithCode } from '../shares/helpers/generatePhoneWithCode';


export const getUser = createAsyncThunk(
    "user-view/get",
    async (userId) => {
        const response = await usersAPI.getOne(userId)
        return response
    }
)

export const resetUpdateUserPassword = createAsyncThunk(
    "user-view/passwordUpdate/put",
    async (payload, thunkAPI) => {
        try {
            const response = await usersAPI.resetUpdateUserPassword(payload)
            return response
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getUsersOptions = createAsyncThunk(
    "user-view/users/get",
    async () => {
        const response = await dictsAPI.getUsers()
        return response
    }
)

export const getRolesListOptions = createAsyncThunk(
    "user-view/rolesList/get",
    async () => {
        const response = await rolesAPI.getUserRolesList()
        return response
    }
)
export const getPartnersOptions = createAsyncThunk(
    "user-view/partners/get",
    async () => {
        const response = await dictsAPI.getPartners()
        return response
    }
)
const initialState = {
    data: {},
    options: {
        users: [],
        rolesList: [],
		partners: [],
    },
    loading: false
}

const userViewSlice = createSlice({
    name: "user-view",
    initialState,
    reducers: {
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [getUser.pending]: (state) => {
            state.loading = true
        },
        [getUser.fulfilled]: (state, { payload }) => {
            state.data = {...payload, phone: generatePhoneWithCode(payload.phone, payload.phoneCode)}
            state.loading = false
        },
        [getUser.rejected]: (state) => {
            state.loading = false
        },
        [getUsersOptions.fulfilled]: (state, { payload }) => {
            state.options.users = Object.entries(payload.data).map(el => ({ value: +el[0], label: el[1] }))
        },
        [getUsersOptions.reject]: (state) => {
            state.options.users = []
        },
        [resetUpdateUserPassword.pending]: (state) => {
            state.loading = true
        },
        [resetUpdateUserPassword.fulfilled]: (state) => {
            state.loading = false
        },
        [resetUpdateUserPassword.rejected]: (state) => {
            state.loading = false
        },
        [getRolesListOptions.fulfilled]: (state, { payload }) => {
            state.options.rolesList = Object.entries(payload).map(el => ({ value: el[1], label: gettext(el[1]) }))
        },
        [getRolesListOptions.reject]: (state) => {
            state.options.rolesList = []
        },
		[getPartnersOptions.fulfilled]: (state, { payload }) => {
            state.options.partners = Object.entries(payload).map(el => ({ value: +el[0], label: el[1] }))
        },
        [getPartnersOptions.reject]: (state) => {
            state.options.partners = []
        },
    }
})

export const {
    clearFormData
} = userViewSlice.actions
export default userViewSlice.reducer