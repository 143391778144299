import React from 'react'
// styles
import './index.sass'
// redux
import { connect } from 'react-redux'
import LoaderLogo from './LoaderLogo'
import classNames from 'classnames'
import useCheckDomainName from '../../shares/helpers/useCheckDomainName.js'
import LoaderLogoAtol from './LoaderLogoAtol'

const LoadingAnimation = ({ children, isStartAppNimation }) => {
	const { isAtol } = useCheckDomainName();
    /**
     * Проверяем что мы на проде и анимация из редакса true.
     *  Изменяем в false ее в функции Loading Suspence в DefaultLayaut когда прогрузилось все
     **/
    const check = () => {
        //return true
        if (isStartAppNimation) return true
        return false
    }
    return (
        <>
            {check() && (
                <div 
					className={ classNames('AN',{ 'atol-background-gradient' : isAtol })}
				>
                    <div className="AN_title">
						<div className="AN_h1" > 
							<LoaderLogoAtol  show={isAtol}/>
							<div className='AN-logo-wrap'>
								<div/>
								<LoaderLogo
									className={ isAtol ? 'hidden' : ''}
								/>
								<span className={ isAtol ? 'hidden' : ''}>
									<span style={{color:'#0e6f93'}}> Ваш</span>
									<br /> платежный<br /> проводник
								</span>							
							</div>					
						</div>
                    </div>
                </div>
            )}

            {children}
        </>
    )
}


export default connect(
    (state) => ({
        isStartAppNimation: state.core.connection.isStartAppNimation,
    }),
    {}
)(LoadingAnimation)
