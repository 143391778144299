import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getServerResponse, serverResponseNotification } from "../../../api/apiTools";
import customersAPI from "../../../api/v2/customersAPI";
import posesAPI from "../../../api/v2/posesAPI";
import { fetchCustomerMainData } from "../customerSlice";
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from "./useCustomerQueries";


const getDescription = (item) => {
	return item?.merchantPointId ? `ID: ${item.merchantPointId}.` : null
};

export const useSwitchMerchantBank = () => {

    return useMutation(customersAPI.putSwitchBank, {
        onSuccess: (response) => { 
			getServerResponse(response, getDescription, 'success')
        },
        onError: (response) => {
			getServerResponse(response, getDescription, 'error')

        },
    })
};

export const useSwitchPosMerchantBank = () => {

    return useMutation(posesAPI.switchTerminalsBank, {
        onSuccess: (response) => { 
			getServerResponse(response, getDescription, 'success')
        },
        onError: (response) => {
			getServerResponse(response, getDescription, 'error')
        },
    })
};

export const usePostFastPaymentProviderData = () => {

    return useMutation(customersAPI.updateFastPaymentProviderData, {
        onSuccess: (response) => { 
			getServerResponse(response, getDescription, 'success')
        },
        onError: (response) => {
			getServerResponse(response, getDescription, 'error')
        },
    })
};

export const usePutOfdInfo = (dispatch, id) => {
	const { merchant_id, kep_id } = useSelector((state) => state.auth.user.userToken)
    const isMerchant = Boolean(merchant_id || kep_id)

    return useMutation(customersAPI.updateOfdInfo, {
        onSuccess: (data) => { 
           !isMerchant && dispatch(fetchCustomerMainData(id))  
        },
        onError: () => {
            message.error('Произошла ошибка')
        },
    })
}
export const useTerminateContract = (setIsOpen) => {
	const dispatch = useDispatch();
    const { id } = useParams();
	const queryClient = useQueryClient()

    return useMutation(customersAPI.terminateContract, {
        onSuccess: ({ errorType, errorMessage }) => { 
			if(errorType){
				 serverResponseNotification({
						errorMessage,
						errorType,
					})
					return
			};
			serverResponseNotification({
				title: 'Выполнено успешно',
				type:'success'
			})
			setIsOpen(false)
			dispatch(fetchCustomerMainData(id))
			queryClient.invalidateQueries([QUERY_KEYS.CUSTOMER_GET_BANKS_LIST, +id])
			
        },
        onError: ({ errorType, errorMessage }) => { 
			errorType && serverResponseNotification({
				errorMessage,
				errorType,
			})
        },
    })
}
