import * as actions from "./actions";
import { createReducer } from "redux-act";  
import HashUuid from "../../../core/utils/other/HashUuid";

const checkKubBeanName = (bankBean) => {
	if(bankBean === 'KUBNew') return 'KUB'
	return bankBean
};

const initialState = {  
  sendNewMerch: {
    data: {},
    loading: false,
  },
  dataLead: {
    data: {},
    loading: false,
  }
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
   

    // --- *  send NewMerch
    [actions.requestSendNewMerch]: (state, action) => ({
      ...state,  sendNewMerch: {...initialState.sendNewMerch, loading: true}
    }), 
    [actions.finalSendNewMerch]: (state, action) => ({
      ...state,  sendNewMerch: {...state.sendNewMerch, loading: false, data: action}
    }),   
    // --- *  CheckHashNewMerch
    [actions.requestCheckHashNewMerch]: (state, action) => ({
      ...state,  dataLead: {...initialState.dataLead, loading: true}
    }), 
    [actions.finalCheckHashNewMerch]: (state, action) => { 
      return {
     	 ...state,  
	  	dataLead: {...state.dataLead, 
		loading: false, 
        data: {
			...action, 
			bankBean: checkKubBeanName(action?.bankBean), 
			files: action.files ? action.files.map(el => ({...el, uniqueKey: HashUuid.getUuid()})) : []} 
		}
    }},   
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
