export const keysWithDateTimeValues = [
    "lastShiftCloseDateTime",
    "lastTransactionDateTime",
    "lastConfigurationChangeDateTime",
    "lastSessionDateTime",
    "lastTMSConnectionDateTime",
    "creationDateTime",
    "commentDateTime",
]

export const keysWithDateValues = [
    "changeDate",
    "installationDate",
    "contractDate",
    "deactivationDate",
    "activationDate"
]