import { createAction } from 'redux-act';
  
// send submit
export const requestSendNewLead = createAction('[new_lead] requestSendNewLead') 
export const finalSendNewLead  = createAction('[new_lead] finalSendNewLead')  
// get all list files for hash
export const requestCheckHashNewLead = createAction('[new_lead] requestCheckHashNewLead') 
export const finalCheckHashNewLead  = createAction('[new_lead] finalCheckHashNewLead')  
// delete file
export const requestDeleteFileNewLead = createAction('[new_lead] requestDeleteFileNewLead')
// download file
export const requestDownloadFileNewLead = createAction('[new_lead] requestDownloadFileNewLead') 
// upload   file
export const requestUploadFileNewLead = createAction('[new_lead] requestUploadFileNewLead')  
export const requestUploadFileNewLead2 = createAction('[new_lead] requestUploadFileNewLead2')
export const successUploadFileNewLead2 = createAction('[new_lead] successUploadFileNewLead2') 

// "/new_lead/{hash}/addMerchantPoint"
export const requestIdForNewMerchantPoint = createAction('[new_lead] requestIdForNewMerchantPoint')
