import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/statistics/temperature';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
]

// 1
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload);  
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 
