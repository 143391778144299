import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/services/courierTransactions';  
import * as actions from './actions'  
import transactionsAPI from '../../../../api/v2/transactionsAPI';

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),
	takeLatest(actions.requestGetCouriers, requestGetCouriers),  
	takeLatest(actions.requestGetFieldsForSearch, requestGetFieldsForSearch),   
	takeLatest(actions.requestDownloadCsvTransaction, requestDownloadCsvTransaction),
	takeLatest(actions.requestBanksForTransactions, requestBanksForTransactions),     
	takeLatest(actions.requestCancelTransaction, requestCancelTransaction),     
	takeLatest(actions.requestConfirmCancelTransaction, requestConfirmCancelTransaction),     
	takeLatest(actions.requestDownloadCheck, requestDownloadCheck),     
	takeLatest(actions.requestDenyCancelTransaction, requestDenyCancelTransaction),     
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
}

function* requestGetCouriers(action) {
	try {  
		const data = yield call(API.getCouriers, action.payload);  
		// action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.successGetCouriers(data))
	} catch (error) {   
		yield put(actions.successGetCouriers([]))
	}
} 

// 
function* requestGetFieldsForSearch(action) {
	try {  
		const data = yield call(API.getFieldsForSearch, action.payload);  
		yield put(actions.successGetFieldsForSearch(data))
	} catch (error) {   
		yield put(actions.successGetFieldsForSearch({}))
	}
} 

// 
function* requestDownloadCsvTransaction(action) {
	try {  
		const data = yield call(API.downloadCsvTransaction, action.payload);  
		yield put(actions.successDownloadCsvTransaction(data))
	} catch (error) {   
		yield put(actions.successDownloadCsvTransaction({}))
	}
} 

// 
function* requestBanksForTransactions(action) {
	try {  
		const data = yield call(API.getBanksForTransactions, action.payload);  
		yield put(actions.successBanksForTransactions(data))
	} catch (error) {   
		yield put(actions.successBanksForTransactions([]))
	}
} 

// 
function* requestCancelTransaction(action) {
	try {  
		const data = yield call(transactionsAPI.cancelTransaction, action.payload.request);  
		yield put(actions.finalCancelTransaction({result: data, 
			id: action.payload.request.transactionId,
			amount: action.payload.request.amount,
		}))
		action.payload.callback(data)
	} catch (error) {   
		yield put(actions.finalCancelTransaction(false))
		action.payload.callback(false)
	}
} 

// 
function* requestConfirmCancelTransaction(action) {
	try {  
		const data = yield call(transactionsAPI.confirmCancelTransaction, action.payload.request);  
		yield put(actions.finalConfirmCancelTransaction({result: data, id: action.payload.request.transactionId}))
		action.payload.callback(data)
	} catch (error) {   
		yield put(actions.finalConfirmCancelTransaction(false))
		action.payload.callback(false)
	}
} 

// 
function* requestDenyCancelTransaction(action) {
	try {  
		const data = yield call(transactionsAPI.denyCancelTransaction, action.payload.request);  
		yield put(actions.finalDenyCancelTransaction({result: data, id: action.payload.request.transactionId}))
		action.payload.callback(data)
	} catch (error) {   
		yield put(actions.finalDenyCancelTransaction(false))
		action.payload.callback(false)
	}
} 

// 
function* requestDownloadCheck(action) {
	try {  
		const data = yield call(API.downloadCheck, action.payload.request);   
		action.payload.callback(data)
	} catch (error) {    
		action.payload.callback(false)
	}
} 