import axios from "axios"

const ROOT_URL = "/api/v2"

const merchantInvoicesAPI = {
    // read
    getMerchantInvoices: () => axios.get(`${ROOT_URL}/merchantInvoices`).then((r) => r.data),
    getMerchantClients: () => axios.get(`${ROOT_URL}/merchantClients`).then((r) => r.data),
    // update
   
    // create
    createMerchantInvoice: (data) => axios.post(`${ROOT_URL}/merchantInvoices`, data).then((r) => r.data),
    createMerchantClient: (data) => axios.post(`${ROOT_URL}/merchantClients`, data).then((r) => r.data),
}

export default merchantInvoicesAPI