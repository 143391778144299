import axios from 'axios'
import { optionsForAxiosBlob, downloadBlob, getFileNameFromContentDispositionUTF8 } from '../apiTools'

const ROOT_URL = "/api/v2"

const reportsAPI = {
    // read
    getData: () => axios.get(`${ROOT_URL}/reportJobs`).then(r => r.data),
    getOneUrl: (reportJobId) => axios.get(`${ROOT_URL}/getReportUrl/${reportJobId}`).then(r => r.data),
    getScoringData: (data) => axios.post(`${ROOT_URL}/scoring/files`, data).then(r => r.data),
    getScoringFile: (fileId) => axios.get(`${ROOT_URL}/scoring/download/${fileId}`, optionsForAxiosBlob()).then(r => downloadBlob(r, getFileNameFromContentDispositionUTF8(r.headers['content-disposition']))),
    getAddressSplitData: (data) => axios.get(`${ROOT_URL}/suggestion/address?search=${data}`).then(r => r.data),
    // create
    createOne: (payload) => axios.post(`${ROOT_URL}/reportJob`, payload)
        .then(r => r.data),
}

export default reportsAPI