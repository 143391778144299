import waitersList from './waitersList/reducer';  
import couriersList from './couriersList/reducer';  
import courierTransactions from './courierTransactions/reducer';  

export default (state={}, action) => {  
	return { 
		waitersList: waitersList(state.waitersList, action),   
		couriersList: couriersList(state.couriersList, action),   
		courierTransactions: courierTransactions(state.courierTransactions, action),   
	} 
} 