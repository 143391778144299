import { createAction } from 'redux-act';


// get data
export const requestGetDataPage = createAction('[reports] requestGetDataPage')
export const getDataPageSuccess = createAction('[reports] getDataPageSuccess')
export const getDataPageError = createAction('[reports] getDataPageError')
// get fields For Search
export const requestGetFieldsForSearch = createAction('[reports] requestGetFieldsForSearch')
export const successGetFieldsForSearch  = createAction('[reports] successGetFieldsForSearch')
// get Select fields  
export const requestGetSelectFields = createAction('[reports] requestGetSelectFields')
export const successGetSelectFields = createAction('[reports] successGetSelectFields')
// create
export const requestCreateData = createAction('[reports] requestCreateData')
export const createDataFinish = createAction('[reports] createDataFinish')
// get one
export const requestGetOne = createAction('[reports] requestGetOne')
export const finalGetOne = createAction('[reports] finalGetOne')
export const clearGetOne = createAction('[reports] clearGetOne')
// get SubPartners Select
export const requestGetSubPartnersSelect = createAction('[reports] requestGetSubPartnersSelect')
export const finalGetSubPartnersSelect= createAction('[reports] finalGetSubPartnersSelect')
// update
export const requestUpdateData = createAction('[reports] requestUpdateData')
export const finalUpdateData = createAction('[reports] finalUpdateData')