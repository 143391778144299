const LoaderLogoAtol = ({ show = null }) => {

	// useEffect(() => {
	// 	const svg = document.querySelector('#atol-loader');

	// 	const children = svg.querySelectorAll('path')
	
	// 	const interval = 1000;
	// 	let arr = [4,1,2,0,3,5]
	// 	for (let i = 0; i < children.length; i++) {
	// 		setTimeout(()=>  {
	// 			children[arr[i]].style.fill = 'green'
	// 		}, i * interval)
	// 	}
	
	// }, [])

	if(!show) return null

	return (
		<div 
			className='loading-logo-atol'
		>
			<svg id='atol-loader' viewBox="0 0 220 70"  x='0px' y='0px' fill="white"  xmlns="http://www.w3.org/2000/svg">
				<path d="M115.868 1.58485C88.4214 10.1182 89.7537 50.5182 117.733 57.1849C132.922 60.9182 147.978 52.1182 152.241 37.0515C156.505 22.3849 147.711 6.11819 133.189 1.85151C125.328 -0.415153 122.796 -0.548487 115.868 1.58485ZM134.787 16.5182C143.048 23.5849 143.048 34.7849 134.787 41.8515C129.991 45.8516 120.664 46.3849 115.468 43.0516C109.073 38.7849 106.408 28.7849 109.739 22.1182C114.402 12.9182 127.06 10.1182 134.787 16.5182Z" 
					
				/>
				<path d="M22.2033 3.45153C22.603 4.78486 25.6675 17.4515 28.9984 31.4516L35.2604 57.1849H43.2546C49.1169 57.1849 51.1155 56.7849 50.5825 55.5849C50.3161 54.6516 47.1184 41.9849 43.6543 27.4515L37.1257 1.18485H29.3981C22.4698 1.18485 21.5372 1.45152 22.2033 3.45153Z" 
				
				/>
				<path d="M45.6528 7.85153V14.5182H54.3131H62.9734V35.8516V57.1849H70.3014H77.6293V35.8516V14.5182H86.2896H94.9499V7.85153V1.18485H70.3014H45.6528V7.85153Z" 
				/>
				<path d="M162.234 16.7849C160.102 25.4515 157.038 38.1182 155.439 44.7849L152.508 57.1849H160.235H167.963L174.225 31.5849C177.556 17.4515 180.754 4.78486 181.02 3.45153C181.686 1.45152 180.754 1.18485 173.959 1.18485H166.098L162.234 16.7849Z" 
				
				/>
				<path d="M3.19765 42.908L0 56.6414L7.19472 57.0414C11.1918 57.308 14.6559 57.1747 15.0556 56.908C15.3221 56.508 16.7877 51.308 18.2533 45.1747C19.7189 39.0413 21.1845 32.908 21.5842 31.5747C22.2503 29.5747 21.3177 29.308 14.2562 29.308H6.26207L3.19765 42.908Z" 
				
				/>
				<path d="M182.086 31.4516C182.486 32.7849 184.085 39.0516 185.683 45.4516L188.481 57.1849H196.475C203.404 57.1849 204.336 56.9182 203.67 54.7849C203.27 53.5849 201.672 47.3182 200.073 40.7849L197.275 29.1849H189.281C182.352 29.1849 181.42 29.4515 182.086 31.4516Z" 
				/>
			</svg>
		</div>	
	)
};

export default LoaderLogoAtol