import axios from 'axios'
import {catchError} from "../apiTools"
 

export default {   
	login: (data) => axios.post(`/login`, data).then(r => catchError(r)),
	getCodeAgain: (data) => axios.post(`/login`, data).then(r => catchError(r)),
	logout: () => axios.post(`/auth/logout`).then(r => r.data),  
	getAllRoles: () => axios.get(`/user/checkRoles`).then(r => r.data),  

	// password changing
	changePass: (data) => axios.post(`/reset_password/${data.hash}/send`, data).then(r => r.data),
	recoveryPass: (data) => axios.post(`/api/v2/restorePassword`, data).then(r => catchError(r)),
	// firstTimeChangePassword: (data) => axios.post(`/user/updatePassword`, data).then(r => catchError(r)),
	firstTimeChangePassword: (data) => axios.post(`/api/v2/user/resetUserPassword`, data).then(r => r.data),
	checkHashRecoveryPass: (data) => axios.post(`/reset_password/${data.hash}`, data).then(r => catchError(r)),

	getSettings: (data) => axios.get(`/details`, data).then(r => r.data), 
	getBankSettings: (data) => axios.get(`/partner/details/${data.bankId}`).then(r => r.data), 
	updOneBankSettings: (data) => axios.post(`/partner/details/bank`, data).then(r => r.data), 
	updateSettings: (data) => axios.post(`/partner/details`, data).then(r => r.data), 
	serviceResetPassword: (data) => axios.post(`/${data.target}/resetPassword`,data.body).then(r => r.data),

}
  