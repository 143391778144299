import temperature from './temperature/reducer';  
import transactions from './transactions/reducer';  
import reports from './reports/reducer';  
import dashboard from './dashboard/reducer';

export default (state={}, action) => {  
	return { 
		temperature: temperature(state.temperature, action),   
		transactions: transactions(state.transactions, action),   
		reports: reports(state.reports, action),
		dashboard: dashboard(state.dashboard, action),
	}
} 