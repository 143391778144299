import { createRequestQuery } from 'api/apiTools'
import axios from 'axios'

const ROOT_URL = '/api/v2'

const merchantApplicationAPI = {
	//post
    tmsUpdate: data => axios.post(`${ROOT_URL}/merchant_application/tms/upd`, data).then(r => r.data),
    tmsUpdateAll: data => axios.post(`${ROOT_URL}/merchant_application/tms/upd/all`, data).then(r => r.data),
    tmsUpdateCurrentConfigs: data =>
        axios.post(`${ROOT_URL}/merchant_application/updateCurrentConfigs`, data).then(r => r.data),
    updateExternalCode: ({ configId, ...data }) =>
        axios.post(`${ROOT_URL}/configs/${configId}/externalId`, data).then(r => r.data),
	saveExistedPoints: (data) =>
        axios.post(`${ROOT_URL}/merchant_application/addPoints`, data).then(r => r.data),

	//get
    getExistedPoints: (data) => axios.get(`${ROOT_URL}/dict/merchantPoints${createRequestQuery(data)}`).then(r => r.data),
    getExistedTerminals: (data) => axios.get(`${ROOT_URL}/terminals${createRequestQuery(data)}`).then(r => r.data),
    getCompanyInfoInn: ({ inn }) => axios.get(`${ROOT_URL}/findCompany?inn=${inn}`).then(r => r.data),
}

export default merchantApplicationAPI
