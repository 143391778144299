import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/auth/auth';  
import * as actions from './actions'  
import history from '../../../history';
import AxiosDefault from '../../../core/utils/axios/AxiosDefault';

export default [  
	takeLatest(actions.requestLogin, requestLogin),  
	takeLatest(actions.requestGetCodeAgain, requestGetCodeAgain),  
	takeLatest(actions.requestChangePass, requestChangePass),  
	takeLatest(actions.requestRecoveryPass, requestRecoveryPass),  
	takeLatest(actions.requestCheckHashRecoveryPass, requestCheckHashRecoveryPass),
	takeLatest(actions.firstTimeChangePassword, firstTimeChangePassword),
	takeLatest(actions.serviceResetPassword, serviceResetPassword)
]

//
function* requestLogin(action) {
	try {   
		const data = yield call(API.login, action.payload.request);  
		yield put(actions.successLogin())

		// console.log('JWT: ', data);
		// Если есть токен - ложим и перенаправляем, иначе это 2факторная авторизация и прокидываем в колбэк параметры
		if(data.token){
			AxiosDefault.setAuthAndGetInitialData(data.token) // устанавливаем заголовки в аксиос
			localStorage.setItem('user', JSON.stringify(data));  
			// if(data.needPasswordReset){ 
			// 	history.push("/changePassword");
			// }
			// если он был проброшен на логин из другой страницы приложения - то после успешного входа возвращаем его назад
			// const previusPage = history.location.state
			// if(previusPage && previusPage.from && previusPage.from.pathname) history.push(previusPage.from.pathname)
			// else history.push(process.env.REACT_APP_START_PATH) 
			action.payload.callback(false) // успешный логин - окно не показываем
		}
		else { 
			action.payload.callback(data) // 2 факторный логин с окном
		}

		
	} catch (e) {
		const resultErr = e ? e : {} 
		yield put(actions.errorLogin(resultErr)) 
	}
} 
 

//
function* requestGetCodeAgain(action) {
	try {   
		const data = yield call(API.getCodeAgain, action.payload.request);  
		yield put(actions.finalGetCodeAgain(data)) 
		action.payload.callback(true)    
	} catch (e) { 
		yield put(actions.finalGetCodeAgain({}))
		action.payload.callback(false) 
	}
} 


//
function* requestChangePass(action) {
	try {   
		const data = yield call(API.changePass, action.payload.request);  
		yield put(actions.successChangePass(!data?.errorType)) 
		action.payload.callback(data)    
	} catch (e) {
		yield put(actions.errorChangePass({}))
		action.payload.callback(e) 
	}
} 
 

//
function* requestRecoveryPass(action) {
	try {   
		const data = yield call(API.recoveryPass, action.payload.request);  
		yield put(actions.successRecoveryPass(data)) 
		action.payload.callback({error: !data})    
	} catch (e) { 
		yield put(actions.errorRecoveryPass({}))
		action.payload.callback({error: true, errorType: e.errorType}) 
	}
} 

//
function* requestCheckHashRecoveryPass(action) {
	try {   
		const data = yield call(API.checkHashRecoveryPass, action.payload.request);  
		action.payload.callback({error: !data})    
	} catch (e) { 
		action.payload.callback({error: true}) 
	}
} 
 

//
function* firstTimeChangePassword(action) {
	try {   
		const data = yield call(API.firstTimeChangePassword, action.payload.request);  
		action.payload.callback(data)    
	} catch (e) { 
		action.payload.callback(e) 
	}
} 

//
function* serviceResetPassword(action){
	try{
		let res = yield call(API.serviceResetPassword, {...action.payload});
		action.payload.callback(res);
	}catch(e){
		action.payload.callback(false);
	}
}
 