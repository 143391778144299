import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"
import merchantInvoicesAPI from "../../api/v2/merchantInvoicesAPI"
import columns from "./columns"
import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import formatingObjectToView from "../shares/helpers/formatingObjectForView"


const defaultSelectedColumns = columns
    .filter((column) => column.optional !== true)
    .map((column) => column.key)

export const getMerchantInvoices = createAsyncThunk(
    "merchantInvoices/list/get",
    async () => {
        const response = await merchantInvoicesAPI.getMerchantInvoices()
        return response
    }
)

const initialState = {
    listData: [],
    listDataFiltered: [],
    selectedColumns: defaultSelectedColumns,
    searchDataString: "",
    loading: false,
}

const merchantInvoicesSlice = createSlice({
    name: "merchantInvoices",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.selectedColumns = initialState.selectedColumns
        },

        searchData: (state, { payload }) => {
            state.searchDataString = payload.toLowerCase()
        },

        clearData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        [getMerchantInvoices.pending]: (state) => {
            state.loading = true
        },
        [getMerchantInvoices.fulfilled]: (state, { payload }) => {
            const data = payload?.map((item) => {
                let formatedData = formatingObjectToView(
                    item,
                    keysWithDateValues,
                    keysWithDateTimeValues
                )
                return { ...formatedData, key: nanoid() }
            })
            state.listData = data
            state.listDataFiltered = data
            state.loading = false
        },
        [getMerchantInvoices.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    searchData,
    clearData,
    changePagination
} = merchantInvoicesSlice.actions
export default merchantInvoicesSlice.reducer