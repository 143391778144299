import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/statistics/dashboard';
import * as actions from './actions'  

export default [
	takeLatest(actions.requestGetTopWidgets, requestGetTopWidgets),
	takeLatest(actions.requestGetCountTransactions, requestGetCountTransactions),
	takeLatest(actions.requestGetCountShifts, requestGetCountShifts),
	takeLatest(actions.requestGetParamsForFilters, requestGetParamsForFilters),
	takeLatest(actions.requestGetTspIntensity, requestGetTspIntensity),
	takeLatest(actions.requestGetTspRejected, requestGetTspRejected),
	takeLatest(actions.requestGetTotalErrors, requestGetTotalErrors),
	takeLatest(actions.requestTotalAmount, requestTotalAmount),
	// -- widgets -->
	takeLatest(actions.requestGetCashFlow, requestGetCashFlow),
	takeLatest(actions.requestGetPurchasesCount, requestGetPurchasesCount),
	takeLatest(actions.requestGetRefundsCount, requestGetRefundsCount),
	takeLatest(actions.requestGetReversalsCount, requestGetReversalsCount),
]

// 
function* requestGetTopWidgets(action) {
	try {  
		const data = yield call(API.getTopWidgets, action.payload);
		yield put(actions.finalGetTopWidgets(data))
	} catch (error) {
		yield put(actions.finalGetTopWidgets({}))
	}
}

//
function* requestGetCountTransactions(action) {
	try {
		const data = yield call(API.getCountTransactions, action.payload);
		yield put(actions.finalGetCountTransactions(data))
	} catch (error) {
		yield put(actions.finalGetCountTransactions([]))
	}
}

function* requestGetCountShifts(action) {
	try {
		const data = yield call(API.getCountShifts, action.payload);
		yield put(actions.finalGetCountShifts(data))
	} catch (error) {
		yield put(actions.finalGetCountShifts([]))
	}
}

function* requestGetParamsForFilters(action) {
	try {
		const data = yield call(API.getParamsForFilters, action.payload);
		yield put(actions.finalGetParamsForFilters(data))
	} catch (error) {
		yield put(actions.finalGetParamsForFilters({}))
	}
}

function* requestGetTspIntensity(action) {
	try {
		const data = yield call(API.getTspIntensity, action.payload);
		yield put(actions.finalGetTspIntensity(data))
	} catch (error) {
		yield put(actions.finalGetTspIntensity({}))
	}
}

function* requestGetTspRejected(action) {
	try {
		const data = yield call(API.getTspRejected, action.payload);
		yield put(actions.finalGetTspRejected(data))
	} catch (error) {
		yield put(actions.finalGetTspRejected({}))
	}
}

function* requestGetTotalErrors(action) {
	try {
		const data = yield call(API.getTotalErrors, action.payload);
		yield put(actions.finalGetTotalErrors(data))
	} catch (error) {
		yield put(actions.finalGetTotalErrors([]))
	}
}

function* requestTotalAmount(action) {
	try {
		const data = yield call(API.getTotalAmount, action.payload);
		yield put(actions.finalTotalAmount(data))
	} catch (error) {
		yield put(actions.finalTotalAmount({}))
	}
}

// -- widgets -->
function* requestGetCashFlow(action) {
	try {
		const data = yield call(API.getCashFlow, action.payload);
		yield put(actions.finalGetCashFlow(data))
	} catch (error) {
		yield put(actions.finalGetCashFlow(false))
	}
}
function* requestGetPurchasesCount(action) {
	try {
		const data = yield call(API.getPurchasesCount, action.payload);
		yield put(actions.finalGetPurchasesCount(data))
	} catch (error) {
		yield put(actions.finalGetPurchasesCount(false))
	}
}
function* requestGetRefundsCount(action) {
	try {
		const data = yield call(API.getRefundsCount, action.payload);
		yield put(actions.finalGetRefundsCount(data))
	} catch (error) {
		yield put(actions.finalGetRefundsCount(false))
	}
}
function* requestGetReversalsCount(action) {
	try {
		const data = yield call(API.getReversalsCount, action.payload);
		yield put(actions.finalGetReversalsCount(data))
	} catch (error) {
		yield put(actions.finalGetReversalsCount(false))
	}
}
