import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = {  
  lead_converted_by_partner: "NEED_ATTENTION"
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  { 
    // --- *  GetAllNews
    [actions.changeAddValidationValue]: (state, data) => ({
      ...state,  data
    }),
    [actions.changeLeadConvertedByPartner]: (state, data) => ({
      ...state, lead_converted_by_partner:  data
    })
  },
  initialState
);
