import pages from './pages/Reducer'
import auth from './auth/Reducer'
import core from './core/Reducer'
import other from './other/Reducer'
import generalReducer from '../features/shares/slices/generalSlice'
import terminalsReducer from '../features/terminals/terminalsSlice'
import terminalReducer from '../features/terminal/terminalSlice'
import terminalCreateReducer from '../features/terminal-create/terminalCreateSlice'
import transactionsReducer from '../features/transactions/transactionsSlice'
import reportsReducer from '../features/reports/reportsSlice'
import customersReducer from '../features/customers/customersSlice'
import customerReducer from '../features/customer/customerSlice'
import customerCreateReducer from '../features/customer-create/customerCreateSlice'
import customerChangeReducer from '../features/customer-change/customerChangeSlice'
import posCreateReducer from '../features/pos-create/posCreateSlice'
import posChangeReducer from '../features/pos-change/posChangeSlice'
import posReducer from '../features/pos/posSlice'
import serviceTicketsReducer from '../features/service-tickets/serviceTicketsSlice'
import serviceTicketReducer from '../features/service-ticket/serviceTicketSlice'
import MerchantInvoices from '../features/merchantInvoices/merchantInvoicesSlice'
import MerchantInvoicesCreate from '../features/merchantInvoices-create/merchantInvoicesCreateSlice'
import MerchantInvoicesUpdate from '../features/merchantInvoices-update/merchantInvoicesUpdateSlice'

import usersReducer from '../features/users/usersSlice'
import userViewReducer from '../features/user-view/userViewSlice'
import userCreateReducer from '../features/user-create/userCreateSlice'
import userUpdateReducer from '../features/user-update/userUpdateSlice'

import parntersReducer from '../features/partners/partnersSlice'
import partnerCreateReducer from '../features/partner-create/partnerCreateSlice'
import partnerUpdateReducer from '../features/partner-update/partnerUpdateSlice'

import loginAsReducer from '../features/login-as/loginAsSlice'
import applicationUpdateReducer from '../features/application-update/applicationUpdateSlice'
import applicationChangeDataReducer from '../features/application-change-data/applicationChangeDataSlice'

import partnerTemplatesReducer from '../features/partner-templates/partnerTemplatesSlice'
import ofdSlice from '../features/kkt-registration/ofdSlice'
import acqAppData from '../views/Merchants/AcquiringApplication/redux/slice'
import MerchantPayment from '../features/merchant-payment/merchant-payment-table/merchantPaymentSlice'
import terminalApplication from '../features/application-terminal/slice'
import DashboardSlice from '../features/dashboard/slice'

const rootReducer = {
    core,
    auth,
    pages,
    other,
    terminals: terminalsReducer,
    terminal: terminalReducer,
    terminalCreate: terminalCreateReducer,
    transactions: transactionsReducer,
    reports: reportsReducer,
    customers: customersReducer,
    customer: customerReducer,
    customerCreate: customerCreateReducer,
    customerChange: customerChangeReducer,
    serviceTickets: serviceTicketsReducer,
    serviceTicket: serviceTicketReducer,
    merchantInvoices: MerchantInvoices,
    merchantInvoicesCreate: MerchantInvoicesCreate,
    merchantInvoicesUpdate: MerchantInvoicesUpdate,
    merchantPayment: MerchantPayment,
    partners: parntersReducer,
    partnerCreate: partnerCreateReducer,
    partnerUpdate: partnerUpdateReducer,
    pos: posReducer,
    posCreate: posCreateReducer,
    posChange: posChangeReducer,
    users: usersReducer,
    userView: userViewReducer,
    userCreate: userCreateReducer,
    userUpdate: userUpdateReducer,
    general: generalReducer,
    loginAs: loginAsReducer,
    partnerTemplates: partnerTemplatesReducer,
    applicationUpdate: applicationUpdateReducer,
    applicationChangeData: applicationChangeDataReducer,
    ofdReg: ofdSlice,
    acqAppData: acqAppData,
    terminalApplication: terminalApplication,
    dashboard: DashboardSlice,
} // Добавляем редюсеры

export default rootReducer
