import * as actions from "./actions";
import { createReducer } from "redux-act";

const initialState = { 
  data: [], 
  loading: false, 
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  one: {
    loading: false,
  },
  checkLogin: {
    loading: false,
  },
  resetPassword: {
    loading: false,
  },
  rolesSelected: {
    data: [],
    loading: false,
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),
    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },
    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- * create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.finalCreate]: (state, action) => {
      let newData = state.data
      if(action) newData = [action, ...newData]
      console.log(newData);
      return { ...state, create: {...state.create, loading: false}, data: newData};
    }, 
    
    // --- *  Update ->>
    [actions.requestUpdate]: (state, action) => ({
      ...state, update: {...state.update, loading: true}
    }),
    [actions.finalGetUpdate]: (state, action) => {
      let newData = state.data
      if(action) newData = state.data.map(el => {
        if(+el.id === +action.id) return action
        return el
      })
      return { ...state, update: {...state.update, loading: false}, data: newData};
    }, 
    
    
    // --- * get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.finalGetOne]: (state, action) => {
      return { ...state, one: {...state.one, loading: false}};
    },
    
    
    // --- * get  CheckLogin ->>
    [actions.requestCheckLogin]: (state, action) => ({
      ...state, checkLogin: {...state.checkLogin, loading: true}
    }),
    [actions.finalCheckLogin]: (state, action) => {
      return { ...state, checkLogin: {...state.checkLogin, loading: false}};
    },
    
    // --- * get RolesSelected ->>
    [actions.requestGetRolesSelected]: (state, action) => ({
      ...state, rolesSelected: {...initialState.rolesSelected, loading: true}
    }),
    [actions.finalGetRolesSelected]: (state, action) => {
      return { ...state, rolesSelected: {...state.rolesSelected, loading: false, data: action}};
    },
    [actions.clearGetRolesSelected]: (state, action) => ({
      ...state, rolesSelected: initialState.rolesSelected
    }),
    
    // --- * get ResetPassword ->>
    [actions.requestResetPassword]: (state, action) => ({
      ...state, resetPassword: {...initialState.resetPassword, loading: true}
    }),
    [actions.finalResetPassword]: (state, action) => {
      return { ...state, resetPassword: {...state.resetPassword, loading: false}};
    },
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
