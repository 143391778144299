import { createAction } from 'redux-act';
  
//  GetAllNews
export const requestGetAllNews = createAction('[news] requestGetAllNews') 
export const clearGetAllNews  = createAction('[news] clearGetAllNews')   
export const finalGetAllNews  = createAction('[news] finalGetAllNews')   
// sendOneNews
export const requestSendOneNews = createAction('[news] requestSendOneNews')  
export const finalSendOneNews = createAction('[news] finalSendOneNews')   
// deleteOne
export const requestDeleteOne = createAction('[news] requestDeleteOne')  
export const finalDeleteOne = createAction('[news] finalDeleteOne')   

export const setScrollToNews = createAction('[news] setScrollToNews')   