import axios from 'axios' 

export default {  
	getBanks: (data) => axios.post(`/merchant_application/banks`, data).then(r => r.data),
	getBanksDict: () => axios.get(`/dict/banks`).then(r => r.data),  
	getPayments: (data) => axios.post(`/dict/payment_systems`, data).then(r => r.data), 
	getUsersForApp: () => axios.get(`/api/v2/dict/lead_responsible_users`).then(r => r.data), 
	getPartners: (data) => axios.get(`/api/v2/dict/lead_partners`).then(r => r.data),  
	getMerchants: () => axios.get(`/dict/merchants`).then(r => r.data),
	getMerchantsWithBanks: () => axios.get(`/api/v2/dict/merchants?withBanksOnly=true`).then(r => r.data),
	getMerchantPoints: (data) => axios.get(`/dict/merchant/${data.merchant_id}/merchant_points`, data).then(r => r.data),
	getMerchantPointsByParams: (data) => {
		const contarctId = data.contract_id ? `&contract_id=${data.contract_id}` : ''
		return axios.get(`/api/v2/dict/merchant_points?merchant_id=${data.merchant_id}&bank_id=${data.bank_id}${contarctId}`).then(r => r.data)
	},
	getPinpads: (data) => axios.post(`/dict/pinpads`, data).then(r => r.data),
	getAllDataForTmsConfigs: () => axios.post(`/dict/all_data_for_tmsConfig`).then(r => r.data),
	getAllDataForTmsConfigs: () => axios.post(`/dict/all_data_for_tmsConfig`).then(r => r.data),
	getUsersDetails: (merchantId,applicationid,inn)=> axios.get(`/api/v2/dict/usersDetails?merchant_id=${merchantId}${applicationid ? `&app_id=${applicationid}` : ""}${inn ? `&inn=${inn}` : ""}`).then(r => r.data),
}