import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/other/newMerch';  
import * as actions from './actions'   

export default [  
	takeLatest(actions.requestSendNewMerch, requestSendNewMerch),   
	takeLatest(actions.requestCheckHashNewMerch, requestCheckHashNewMerch),  

	takeLatest(actions.requestDeleteFileNewMerch, requestDeleteFileNewMerch),   
	takeLatest(actions.requestDownloadFileNewMerch, requestDownloadFileNewMerch),   
	takeLatest(actions.requestDownloadPersonalDocNewMerch, requestDownloadPersonalDocNewMerch),   
	takeLatest(actions.requestUploadFileNewMerch, requestUploadFileNewMerch)   
]
 

//
function* requestSendNewMerch(action) {
	try {   
		const data = yield call(API.sendNewMerch, action.payload.request);  
		yield put(actions.finalSendNewMerch(data)) 
		action.payload.callback(data)    
	} catch (e) { 
		yield put(actions.finalSendNewMerch({}))
		action.payload.callback(false) 
	}
} 

//
function* requestCheckHashNewMerch(action) {
	try {   
		const data = yield call(API.checkHashNewMerch, action.payload.request);   
		yield put(actions.finalCheckHashNewMerch(data)) 
		action.payload.callback(true)    
	} catch (e) { 
		yield put(actions.finalCheckHashNewMerch({}))
		action.payload.callback(false) 
	}
} 

//
function* requestDeleteFileNewMerch(action) {
	try {   
		yield call(API.deleteFileNewMerch, action.payload.request);  
		action.payload.callback(true)    
	} catch (e) { 
		action.payload.callback(false) 
	}
} 


//
function* requestDownloadFileNewMerch(action) {
	try {   
		yield call(API.downloadFileNewMerch, action.payload); 
	} catch (e) {  }
} 

//
function* requestDownloadPersonalDocNewMerch(action) {
	try {   
		yield call(API.downloadPersonalDocNewMerch, action.payload.request); 
		action.payload.callback()
	} catch (e) { action.payload.callback() }
} 
 
//
function* requestUploadFileNewMerch(action) {
	try {   
		const response = yield call(API.uploadFile, action.payload);  
		if(!response || response?.errorType) {
			throw response;
		}
		action.payload.callback(response);
	} catch (e) { 
		action.payload.callback(false) 
	}
} 
  