import axios from 'axios'
import {downloadBlob} from '../apiTools';

export default {  
	getData: (data) => axios.post(`/transactions/search`, data).then(r => r.data),    
	getFieldsForSearch: (data) => axios.get(`/transactions/fields`, data).then(r => r.data),     
	downloadCsvTransaction: (data) => {
		const url = data['exportType'] === 'csv' ? `exportToCsv` : `exportToXslx`
		return axios.post('/transactions/'+url, data, 
		{responseType: 'blob'}).then(r => downloadBlob(r, "transactions."+data['exportType']))
	},
	getBanksForTransactions: (data) => axios.get(`/dict/banks`, data).then(r => r.data),  
	downloadCheck: (data) => axios.post(`/transactions/downloadReceipt`, data, 
	{responseType: 'blob'}).then(r => downloadBlob(r, `check-${data.transactionId}.pdf`)),
	
	// switched to v2 => src/api/v2/transactionsAPI.js
	// cancelTransaction: (data) => axios.post(`/transaction/reverse`, data).then(r => r.data),  
	// confirmCancelTransaction: (data) => axios.post(`/transaction/reverse/confirm`, data).then(r => r.data),  
	// denyCancelTransaction: (data) => axios.post(`/transaction/reverse/reject`, data).then(r => r.data),  
}