import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = { 
  isStartAppNimation: true,
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    // --- filter
    [actions.changeIsStartAppNimation]: (state, action) => ({
      ...state, isStartAppNimation: action
    }),
 

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
