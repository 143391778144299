import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { formsMap } from "./helpers"
import dictsAPI from "api/v2/dictsAPI";

const initialState = {
	loading: {
		isDataLoad: false, 
		isDataValidate: false, 
	},
    invalidForms: {
		terminals: false,
		documents: false,
		bankQuestions: false,
	},
	data: {
		requestType: null, 
		overrideBankBean: null, 
		merchantApplicationStatus: null, 
	},
	options: {
		merchants:[],
		merchantsSuccess: false, 
		merchantsLoading: false, 
	},
	points: [],
	terminals: [],
	isAppSaveRequired: false,
	displayTerminalDataConf: false,
	guideData: {
		contactPerson: {},
		pretensionPerson: {
			isContactPerson: true
		},
		director: {},
		signer: {
			isDirector: false,
		},
	},
	guideDataAsString: {
		contactPersonAsString: '',
		pretensionPersonAsString: '',
		directorAsString: '',
		signerAsString: '',
	},
};

export const getMerchantsOptions = createAsyncThunk(
    "terminalApplication/get-merchants",
    async (data) => {
        const response = await dictsAPI.getMerchantsDict(data)
        return response
    }
);

/**
 прослойка для получения актуального состояния
 */
export const updateApplicationGuideData = createAsyncThunk(
    'terminalApplication/update-application-guideData',
    async ({saveApplication}, thunkAPI) => {
        const guideData = thunkAPI.getState().terminalApplication.guideData
		saveApplication({ guideData })
    }
);

const terminalApplication = createSlice({
    name: "terminalApplication",
    initialState,
    reducers: {
        setBankBeanName: (state, { payload }) => {
            state.data.overrideBankBean = payload 
        },
        setMerchantPoints: (state, { payload }) => {
            state.points = payload 
        },
        setMerchantTerminals: (state, { payload }) => {
            state.terminals = payload 
        },
        setMainAppData: (state, { payload }) => {
            state.data = payload 
        },
        setAppSaveRequired: (state, { payload }) => {
            state.isAppSaveRequired = payload 
        },
        setDisplayTerminalDataConf: (state, { payload }) => {
            state.displayTerminalDataConf = payload 
        },
        setInvalidForm: (state, { payload }) => {
			const { type, ...error } = payload 
			switch (type) {
				case formsMap.documents.name:
					state.invalidForms = {...state.invalidForms, ...error} 
					break;
				case formsMap.terminals.name:
					state.invalidForms = {...state.invalidForms, ...error} 
					break;
				case formsMap.bankQuestions.name:
					state.invalidForms = {...state.invalidForms, ...error} 
					break;
				case 'reset':
					state.invalidForms = {
						terminals: state.invalidForms[formsMap.terminals.name], 
						documents: state.invalidForms[formsMap.documents.name], 
					};
					break;
			
				default:
					state.invalidForms = {
						terminals: state.invalidForms[formsMap.terminals.name], 
						documents: state.invalidForms[formsMap.documents.name], 
						...error
					} 
			}
        },   
        setAppLoading: (state, { payload }) => {
            state.loading = {...state.loading, ...payload} 
        }, 
        setTerminalAppRequestType: (state, { payload }) => {
            state.data.requestType = payload
        }, 
        setGuideDataSectionByType: (state, { payload }) => {
			const { sectionType, ...restFields } = payload

			const getDataAsString = (data) => {
				return data?.firstName && `${data?.lastName || ''} ${data?.firstName || ''} ${data?.otherName || ''} ${(data?.phone && data?.phoneCode) ? `+${data?.phoneCode}${data?.phone}` : ''}`
			};
			const getSignerAsString = (fields) => {
				if(fields?.legalType === 'LEGAL'){
					const {inn, ogrn ,orgNameWithOutOpf} = fields
					return `${orgNameWithOutOpf || ''} ${inn ? ('ИНН: '+ inn) : ''} ${ogrn ? ('ОГРН: '+ ogrn) : ''}`
				}
				return getDataAsString(fields)
			};
			switch (sectionType) {
				case 'contactPerson':
					state.guideData.contactPerson = restFields 
					state.guideDataAsString.contactPersonAsString = getDataAsString(restFields)
					break;
				case 'pretensionPerson':
					state.guideData.pretensionPerson = restFields 
					if(restFields.isContactPerson){
						state.guideDataAsString.pretensionPersonAsString = state.guideDataAsString.contactPersonAsString
					} else {
						state.guideDataAsString.pretensionPersonAsString = getDataAsString(restFields)
					}
					break;
				case 'director':
					
					state.guideData.director = restFields 
					if(restFields?.isContactPerson){
						state.guideDataAsString.directorAsString = state.guideDataAsString.contactPersonAsString
					} else {
						state.guideDataAsString.directorAsString = getDataAsString(restFields)
					}
					
					break;
				case 'signer':
					state.guideData.signer = restFields
					if(restFields?.isDirector){
						state.guideDataAsString.signerAsString = state.guideDataAsString.directorAsString
					} else {
						
						state.guideDataAsString.signerAsString = getSignerAsString(restFields)
					}
					break;
				case 'all':
					state.data = restFields

					const { director = {}, signer = {}} = restFields?.bankParams
					const { pretensionPerson: pretensionPersonData = {}, ...contactPersonData} = restFields?.bankParams?.contactPerson

					state.guideData = { signer, director, contactPerson:contactPersonData ,pretensionPerson: pretensionPersonData }

					state.guideDataAsString.contactPersonAsString = getDataAsString(contactPersonData)
					state.guideDataAsString.pretensionPersonAsString = getDataAsString(pretensionPersonData)
					state.guideDataAsString.directorAsString = getDataAsString(director)
					state.guideDataAsString.signerAsString = getSignerAsString(signer)
					
					break;
				default:
					break;
			}
        }, 
        clearData: () => initialState,
    },
    extraReducers: {
		[getMerchantsOptions.pending]: (state, { payload }) => {
            state.options.merchantsLoading = true
			state.options.merchantsSuccess = false
        },
		[getMerchantsOptions.rejected]: (state, { payload }) => {
            state.options.merchantsLoading = false
        },
		[getMerchantsOptions.fulfilled]: (state, { payload }) => {
            state.options.merchants = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.merchantsLoading = false
			state.options.merchantsSuccess = true
        },
    },
})

export const {  
	setBankBeanName, 
	clearData, 
	setAppLoading,
	setGuideDataSectionByType,
	setTerminalAppRequestType,
	setInvalidForm,
	setMerchantPoints,
	setAppSaveRequired,
	setMainAppData,
	setDisplayTerminalDataConf,
	setMerchantTerminals,
 } = terminalApplication.actions
export default terminalApplication.reducer
