import { Input } from 'antd'
import React, { useEffect, useState } from 'react'

/**
 * Antd Input елемент только для ввода чисел
 *
 * @param {Function} onChange - Функция типа (value) => {...todo}, которую надо задебонсить
 * @param props - любые проспы из документации Antd Input
 */

const NumberInput = ({ onChange, dotAllowed = false, value, ...props }) => {
    const [number, setNumber] = useState(value ?? '')

    useEffect(() => {
		const newValue = (value === undefined || value === null) ? '' : value
		setNumber(newValue)
    }, [value])

    const onNumberChange = (e) => {
        const newValue = e.target.value
        const reg = dotAllowed ? /^[0-9]+\.?[0-9]*$/ : /^\d+$/
        if (newValue && !newValue.match(reg)) {
            return
        }
        setNumber(newValue)
        onChange && onChange(newValue)
    }

    return <Input onChange={ onNumberChange } {...props} value={ number } />
}

export default NumberInput
