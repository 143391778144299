import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[couriersList] requestGetDataPage')
export const getDataPageSuccess = createAction('[couriersList] getDataPageSuccess')
export const getDataPageError = createAction('[couriersList] getDataPageError')
// create
export const requestCreate = createAction('[couriersList] requestCreate')
export const finalCreate = createAction('[couriersList] finalCreate')
// update
export const requestUpdate = createAction('[couriersList] requestUpdate')
export const finalGetUpdate = createAction('[couriersList] finalGetUpdate')
// get one
export const requestGetOne = createAction('[couriersList] requestGetOne')
export const finalGetOne = createAction('[couriersList] finalGetOne')