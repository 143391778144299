import { message } from "antd"
import { useMutation } from "react-query"
import terminalsAPI from "../../../api/v2/terminalsAPI"
import { serverArrayResponseNotification, serverResponseNotification } from "../../../api/apiTools"

export const useSwitchTerminalBank = () => {
	return useMutation(terminalsAPI.switchTerminalBank,{
		onSuccess: (response) => { 
			if(response?.errors?.length){
				return 	serverArrayResponseNotification({
					errorTypeKey: 'errorType', 
					errorMessageKey: 'errorMessage', 
					dataSource: response?.errors, 
					type: 'warning',
				})
			};

			if(response?.success === false){
				return serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			};

			response?.success && message.success('Данные успешно обновлены')
        },
        onError: (response) => {
			if(response?.success === false){
				serverResponseNotification({
					errorType:response?.errorType,
					errorMessage:response?.errorMessage,
				})
			}
        },
		}
	)
}

