import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dictsAPI from '../../api/v2/dictsAPI';
import reportsAPI from '../../api/v2/reportsAPI';
import { reportTypes } from '../shares/dicts/reports';
import isVoidObject from '../shares/helpers/isVoidObject'
import formatingObjectToView from '../shares/helpers/formatingObjectForView';
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue'
import openNotificationWithIcon from '../shares/openNotificationWithIcon';


const downloadFile = (path) => {
    const domenName = window.location.origin === "http://172.16.12.100" ? process.env.REACT_APP_DEV_URL : window.location.origin
    const url = process.env.NODE_ENV === "production" ? domenName : process.env.REACT_APP_DEV_URL
    const link = document.createElement('a')
    link.href = `${url}${path}`
    link.setAttribute('target', '_blank')
    document.body.append(link)
    link.click()
    link.remove()
}

export const fetchReports = createAsyncThunk(
    "reports/get",
    async () => {
        const response = await reportsAPI.getData()
        return response
    }
)

export const fetchCreateReport = createAsyncThunk(
    "reports/create",
    async (payload) => {
        const { reportType, rangeDateTime, formatType } = payload
        const name = `${reportTypes[reportType]}`
        const formatedRangeDate = [
            rangeDateTime[0].startOf('day'),
            rangeDateTime[1].endOf('day'),
        ]
        const requestData = {
            ...payload,
            rangeDateTime: formatedRangeDate,
            formatType,
            name
        }
        const response = await reportsAPI.createOne(requestData)
        return response
    }
)

export const getUrlForReport = createAsyncThunk(
    "reports/get-url",
    async (reportId) => {
        const response = await reportsAPI.getOneUrl(reportId)
        downloadFile(response.url)
        return response
    }
)

export const getCustomers = createAsyncThunk(
    "reports/customers",
    async () => {
        const response = await dictsAPI.getMerchantsDict()
        return response
    }
)

export const getUsers = createAsyncThunk(
    "reports/users",
    async () => {
        const response = await dictsAPI.getUsers()
        return response
    }
)

export const getPartners = createAsyncThunk(
    "reports/partners",
    async () => {
        const response = await dictsAPI.getPartners()
        return response
    }
)

export const getScoringData = createAsyncThunk(
    "application-update/scoringData/post",
    async (data, thunkAPI) => {
        if (data) {
            const response = await reportsAPI.getScoringData(data)
            return response
        }
        const { advancedSearchData, pagination: { current, elementsPerPage } } = thunkAPI.getState().reports.scoring
        if (!isVoidObject(advancedSearchData)) {

            const searchData = Object.entries(advancedSearchData).reduce((data, [key, value]) => {
                if (key === 'directorFIO' && value) {
                    const splitDirectorFIO = value.split(' ')
                    return {...data, director_last_name: splitDirectorFIO[0] ? splitDirectorFIO[0] : null, director_first_name: splitDirectorFIO[1] ? splitDirectorFIO[1] : null, director_other_name: splitDirectorFIO[2] ? splitDirectorFIO[2] : null}
                }
                if (key === 'resultDateRange' && value.length !== 0) {
                    console.log(value)
                    return {...data, cdat_start_date: value[0], cdat_end_date: value[1]}
                }
                return value ? {...data, [key]: value} : data
            }, {})
            const response = await reportsAPI.getScoringData({page: current, size: elementsPerPage, filter: searchData})
            return response
        }
        const response = await reportsAPI.getScoringData({page: current, size: elementsPerPage})
        return response
    }
)

export const getScoringFile = createAsyncThunk(
    "application-update/scoringFile/get",
    async (fileId) => {
        const response = await reportsAPI.getScoringFile(fileId)
        return response
    }
)

export const getAddressSplitData = createAsyncThunk(
    "application-update/addressSplitData/get",
    async (data) => {
        const response = await reportsAPI.getAddressSplitData(data)
        return response
    }
)

const initialState = {
    data: {
        loading: false,
        listData: [],
    },
    options: {
        customers: [],
        users: [],
        partners: []
    },
    scoring: {
        data: [],
        pagination: {
            current: 1,
            elementsPerPage: 10,
            total: 0
        },
        searchDataString: "",
        advancedSearchData: {
            inn: "",
            directorFIO: "",
            resultDateRange: [],
            result: "",
        },
        addressOptions: [],
        address: {},
        loading: false
    },
}

const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        changeScoringPagination: (state, { payload }) => {
            state.scoring.pagination = {...state.scoring.pagination, ...payload}
        },
        searchScoringData: (state, { payload }) => {
            state.scoring.searchDataString = payload.toLowerCase()
        },
        advancedSearchScoringData: (state, { payload }) => {
            state.scoring.advancedSearchData = payload
        },
        addActualAddress: (state, { payload }) => {
            state.scoring.address = payload
        },
        refreshSearchScoringData: (state) => {
            state.scoring.advancedSearchData = initialState.scoring.advancedSearchData
            state.scoring.address = initialState.scoring.address
        },
        refreshSearchScoringAddress: (state) => {
            state.scoring.address = initialState.scoring.address
        },
        clearReportsData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [fetchReports.pending]: (state) => {
            state.data.loading = true
        },
        [fetchReports.fulfilled]: (state, { payload }) => {
            state.data.listData = payload?.reportJobs?.map(scoringItem => {
                const formatedScoringItem = formatingObjectToView(scoringItem, keysWithDateValues, keysWithDateTimeValues)
                return formatedScoringItem
            })
            state.data.loading = false
        },
        [fetchReports.rejected]: (state) => {
            state.data.loading = false
        },
        [getCustomers.fulfilled]: (state, { payload }) => {
            state.options.customers = Object.entries(payload).map(item => ({value: item[0], label: item[1]}))
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            state.options.users = Object.entries(payload.data).map(item => ({value: item[0], label: item[1]}))
        },
        [getPartners.fulfilled]: (state, { payload }) => {
            state.options.partners = Object.entries(payload).map(item => ({value: item[0], label: item[1]}))
        },
        [getScoringData.pending]: (state) => {
            state.scoring.loading = true
        },
        [getScoringData.fulfilled]: (state, { payload }) => {
            state.scoring.data = payload?.list?.map(scoringItem => {
                const formatedScoringItem = formatingObjectToView(scoringItem, keysWithDateValues, keysWithDateTimeValues)
                return formatedScoringItem
            })
            state.scoring.pagination = {...state.scoring.pagination, current: +payload.currentPage, total: +payload.countElements}
            state.scoring.loading = false
			if(payload?.errorMessage){
				openNotificationWithIcon({
					type: 'error',
					message: payload?.errorMessage
				});
			}
        },
        [getScoringData.rejected]: (state, { payload }) => {
            state.scoring.loading = false
			if(payload?.errorMessage){
				openNotificationWithIcon({
					type: 'error',
					message: payload?.errorMessage
				});
			}
        },
        [getScoringFile.pending]: (state) => {
            state.scoring.loading = true
        },
        [getScoringFile.fulfilled]: (state) => {
            state.scoring.loading = false
        },
        [getScoringFile.rejected]: (state) => {
            state.scoring.loading = false
        },
        [getAddressSplitData.pending]: (state) => {
            state.scoring.loading = true
        },
        [getAddressSplitData.fulfilled]: (state, { payload = [] }) => {
            state.scoring.addressOptions = payload.map(item => ({value: item.total}))
            state.scoring.address = payload[0]
            state.scoring.loading = false
        },
        [getAddressSplitData.rejected]: (state) => {
            state.scoring.loading = false
        },
    }
});

export const {
    changeScoringPagination,
    searchScoringData,
    advancedSearchScoringData,
    addActualAddress,
    refreshSearchScoringData,
    refreshSearchScoringAddress,
    clearReportsData
} = reportsSlice.actions
export default reportsSlice.reducer