import React, { useState, useEffect, useRef } from "react"
import { Modal, Button, Row, Col } from "antd"
import { useHistory, useLocation } from 'react-router-dom'
import { setScrollToNews } from "../../store/other/news/actions"
import { useDispatch } from "react-redux"


const Content = ({text, handleClose}) => {
    const content = useRef(null)
    const { push } = useHistory()
	const { pathname } = useLocation()
	const dispatch = useDispatch()

    useEffect(() => {
        if (content.current) {
            content.current.innerHTML = text
        }
    }, [content, text])
    
	const goToNews = () => {
		push({
			pathname: '/dashboard',
		})
		dispatch(setScrollToNews({ scroll: true, redirectFromDashboard: pathname.includes("/dashboard") }))
		handleClose()
	};

    return (
		<div>
			<div ref={content}>
			</div>
			<span 
				className="cursor-pointer" 
				style={{ color:'#60A5FA'}} 
				onClick={goToNews}> Новости
			</span>
		</div>
	)
}

const BannersModal = ({text, id, index, unsubscribeBanner}) => {
    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false)
    }

    const handleUnsubscribe = () => {
        unsubscribeBanner(id, index)
        setShow(false)
    }

    return (
        <>
            <Modal
                footer={null}
                visible={show}
                onCancel={handleClose}
                maskClosable={false}
                destroyOnClose
            >

                <Content text={text} handleClose={handleClose}/>
                <Row style={{marginTop: 10}} gutter={ [16, 16] }>
                    <Col>
                        <Button type="primary" onClick={handleClose}>Закрыть</Button>
                    </Col>
                    <Col>
                        <Button type="default" onClick={handleUnsubscribe}>Закрыть и пометить прочитанным</Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default BannersModal