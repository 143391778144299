import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"

import usersAPI from "../../api/v2/usersAPI"

import columns from "./columns"

import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import isVoidObject from '../shares/helpers/isVoidObject'
import formatingObjectToView from "../shares/helpers/formatingObjectForView"
import dictsAPI from "../../api/v2/dictsAPI"

const defaultSelectedColumns = columns
    .filter((column) => column.optional !== true)
    .map((column) => column.key)

export const fetchUsers = createAsyncThunk(
    "users/list",
    async (_, thunkAPI) => {
        const { advancedSearchData, pagination: { current, elementsPerPage } } = thunkAPI.getState().users
        if (!isVoidObject(advancedSearchData)) {
            const searchData = Object.entries(advancedSearchData).reduce((data, [key, value]) => value ? {...data, [key]: value} : data, {})
            const response = await usersAPI.getDataByAdvancedSearch({page: current, size: elementsPerPage, filter: searchData})
            return response
        }
        const response = await usersAPI.getData({current, elementsPerPage})
        return response
    }
)
export const getMerchantsOptions = createAsyncThunk(
    "users/get-merchants",
    async (data) => {
        const response = await dictsAPI.getMerchantsDict(data)
        return response
    }
);

const initialState = {
    listData: [],
    pagination: {
        current: 1,
        total: 0,
        elementsPerPage: 10
    },
    selectedColumns: defaultSelectedColumns,
    searchDataString: "",
    advancedSearchData: {
        login: "",
        first_name: "",
        last_name: "",
    },
    loading: false,
	options: {
        merchants: [],
		merchantsSuccess: false, 
    },
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.selectedColumns = initialState.selectedColumns
        },

        searchData: (state, { payload }) => {
            state.searchDataString = payload.toLowerCase()
        },

        changePagination: (state, { payload }) => {
            state.pagination = {...state.pagination, ...payload}
        },

        advancedSearchUsersData: (state, { payload }) => {
            state.advancedSearchData = payload
        },

        setDefaultCurrent: (state) => {
            state.pagination.current = 1
        },

        clearUsersData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        },
    },
    extraReducers: {
        [fetchUsers.pending]: (state) => {
            state.loading = true
        },
        [fetchUsers.fulfilled]: (state, { payload }) => {
            const data = payload?.list?.map((user) => {
                let formatedUser = formatingObjectToView(
                    user,
                    keysWithDateValues,
                    keysWithDateTimeValues
                )
                return { ...formatedUser, key: nanoid() }
            })
            state.listData = data
            state.pagination = {...state.pagination, current: +payload.currentPage, total: +payload.countElements}
            state.loading = false
        },
        [fetchUsers.rejected]: (state) => {
            state.loading = false
        },
		[getMerchantsOptions.pending]: (state, { payload }) => {
            state.options.merchantsSuccess = false
        },
		[getMerchantsOptions.fulfilled]: (state, { payload }) => {
            state.options.merchants = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.merchantsSuccess = true
        },
    },
})

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    searchData,
    advancedSearchUsersData,
    clearUsersData,
    changePagination,
    setDefaultCurrent
} = usersSlice.actions
export default usersSlice.reducer