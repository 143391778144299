import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import usersAPI from "../../api/v2/usersAPI"

const changeLocalToken = (payload) => {
    const userLocal = JSON.parse(localStorage.getItem('user'))
    userLocal.token = payload.token
    localStorage.setItem('user', JSON.stringify(userLocal))
}

export const requestNewAuthToken = createAsyncThunk(
    "loginAs/OtpGet/post",
    async ({toUserId}, thunkAPI) => {
        try {
            const response = await usersAPI.logInOutNewUser({toUserId, exit: false})
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getNewAuthToken = createAsyncThunk(
    "loginAs/newTokenGet/post",
    async ({toUserId, otp}, thunkAPI) => {
        try {
            const response = await usersAPI.logInOutNewUser({toUserId, exit: false, otp})
            changeLocalToken(response)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const logoutFromNewUser = createAsyncThunk(
    "loginAs/exit/post",
    async () => {
        const response = await usersAPI.logInOutNewUser({exit: true})
        changeLocalToken(response)
        return response
    }
)

const initialState = {
    loading: false,
}

const loginAsSlice = createSlice({
    name: "loginAs",
    initialState,
    reducers: {

    },
    extraReducers: {
        [requestNewAuthToken.pending]: (state) => {
            state.loading = true
        },
        [requestNewAuthToken.fulfilled]: (state) => {
            state.loading = false
        },
        [requestNewAuthToken.rejected]: (state) => {
            state.loading = false
        },
        [getNewAuthToken.pending]: (state) => {
            state.loading = true
        },
        [getNewAuthToken.fulfilled]: (state) => {
            window.location.reload()
            state.loading = false
        },
        [getNewAuthToken.rejected]: (state) => {
            state.loading = false
        },
        [logoutFromNewUser.pending]: (state) => {
            state.loading = true
        },
        [logoutFromNewUser.fulfilled]: (state) => {
            window.location.reload()
            state.loading = false
        },
        [logoutFromNewUser.rejected]: (state) => {
            state.loading = false
        },
    },
})

export default loginAsSlice.reducer