import axios from 'axios'

const BASE_URL = "/api/v2"

const dashboardAPI = {
    // get
    getClientsDashboardData: () => axios.get(`${BASE_URL}/statistic/clients`).then(r => r.data),
    getActiveClientsDashboardData: () => axios.get(`${BASE_URL}/statistic/activeClients`).then(r => r.data),
    getTerminalsDashboardData: () => axios.get(`${BASE_URL}/statistic/terminals`).then(r => r.data),
    getActiveTerminalsDashboardData: () => axios.get(`${BASE_URL}/statistic/activeTerminals`).then(r => r.data),
    getPieStatistics: (data) => axios.post(`${BASE_URL}/statistic/transactions/turnover`,data).then(r => r.data),
}

export default dashboardAPI
