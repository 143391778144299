import axios from 'axios'

const ROOT_URL = "/api/v2"

const dictsAPI = {
    // get
    getBanners: () => axios.get(`${ROOT_URL}/getBanners`).then(r => r.data),
    // post
    unsubscribeBanner: (data) => axios.post(`${ROOT_URL}/markBannerRead`, data).then(r => r.data)
}

export default dictsAPI