
import { useQuery } from "react-query"
import generalAPI from "../../api/v2/generalAPI"

const QUERY_KEYS = {
	GET_SUPPORT_TEXT: 'GET_SUPPORT_TEXT',
}

export const useGetSupportText = () => {
    return useQuery([QUERY_KEYS.GET_SUPPORT_TEXT], () => generalAPI.getSupportText())
}