import axios from 'axios'

export const getMerchantRolesAvailable = (data) => axios.get(`/merchant/${data.merchantId}/roles/available`).then(r => r.data)


export default {
	getData: (data) => axios.get(`/users?page=${data.page}&elementsPerPage=${data.elementsPerPage}`, data).then(r => r.data),
	create: (data) => axios.post(`/user`, data).then(r => r.data),
	update: (data) => axios.put(`/user`, data).then(r => r.data),
	getOne: (data) => axios.get(`/user/${data.id}`).then(r => r.data),
	checkLogin: (data) => axios.post(`/user/${data.login}`).then(r => r.data), // true - юзер существует в бд
	getRoles: () => axios.get(`/user/roles/available`).then(r => r.data),
	getAdminRoles: () => axios.get(`/user/listRoles/available`).then(r => r.data),
	// resetPassword: (data) => axios.post(`/user/updatePassword`, data).then(r => r.data),
	resetPassword: (data) => axios.post(`/api/v2/user/resetUserPassword`, data).then(r => r.data),
	resetUserPassword: (data) => axios.post(`/user/updateUserPassword`, data).then(r => r.data),
}