import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import API from '../../../../api/merchant/merchant_applications';
import applicationsAPI from '../../../../api/v2/applicationsAPI'
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestCreateUpdateData, requestCreateUpdateData),   
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestDownloadDataPage, requestDownloadDataPage),  
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestConfirmAppInBank, requestConfirmAppInBank),  
	takeLatest(actions.requestPrepareTerminals, requestPrepareTerminals),  
	takeLatest(actions.requestRollBackApp, requestRollBackApp),  
	takeLatest(actions.requestForceStatus, requestForceStatus), 
	takeLatest(actions.requestResendToMerchant, requestResendToMerchant), 
	takeEvery(actions.requestlongPollingStatus, requestlongPollingStatus),  
	takeLatest(actions.requestRefuseFromManager, requestRefuseFromManager),

	takeLatest(actions.requestPartnerCodes, requestPartnerCodes),      
	takeLatest(actions.requestComissionsCombobox, requestComissionsCombobox),      
	takeLatest(actions.requestCompanyForINN, requestCompanyForINN),      
	takeLatest(actions.requestCompanyForBIK, requestCompanyForBIK),  
	takeLatest(actions.requestCompanyCallbackBIK, requestCompanyCallbackBIK),  
	takeLatest(actions.requestGetBanks, requestGetBanks),  
	takeLatest(actions.requestGetBanksFields, requestGetBanksFields),  
	takeLatest(actions.requestGetTableBanks, requestGetTableBanks),  
	takeLatest(actions.requestGetRecommendBanks, requestGetRecommendBanks),  
	takeLatest(actions.requestCompanyForINNKub, requestCompanyForINNKub),  
	takeLatest(actions.requestGetAllTmsForOneApp, requestGetAllTmsForOneApp),  
	takeLatest(actions.requestGetInfoOneApp, requestGetInfoOneApp),  
	// files    
	takeEvery(actions.requestUploadFile, requestUploadFile),      
	takeLatest(actions.requestGetFiles, requestGetFiles),      
	takeLatest(actions.requestDeleteFile, requestDeleteFile),     
	takeLatest(actions.requestDownloadFile, requestDownloadFile),
	takeLatest(actions.requestFilePersons, requestFilePersons),
	// documents      
	takeLatest(actions.downloadXml, downloadXml),  
	takeLatest(actions.downloadOffer, downloadOffer),  
	takeLatest(actions.downloadApplication, downloadApplication),  
	takeLatest(actions.requestGetDocumentSize, requestGetDocumentSize),
	takeLatest(actions.requestGetAllDocs, requestGetAllDocs),
	
	takeLatest(actions.requestGetBanksForMerchant, requestGetBanksForMerchant),
	takeLatest(actions.requestGetBanksForApplication, requestGetBanksForApplication),
	takeLatest(actions.requestGetTerminalsFromMerchantsAndBanks, requestGetTerminalsFromMerchantsAndBanks),
	takeLatest(actions.requestGetInnForMerchant, requestGetInnForMerchant),
	takeLatest(actions.requestGetDashboard, requestGetDashboard),
	takeLatest(actions.requestDocumentsChecked, requestDocumentsChecked),
	takeLatest(actions.requestEditRowTable, requestEditRowTable),
	takeLatest(actions.requestGetTimerSla, requestGetTimerSla),

	takeLatest(actions.requestSaveDocumentsFromSdm, requestSaveDocumentsFromSdm), 
	// --- widgets ---> 
	takeLatest(actions.requestGetTotalApp, requestGetTotalApp),
	takeLatest(actions.requestGetTotalDoneApp, requestGetTotalDoneApp),
	takeLatest(actions.requestGetTotalDeclinedBank, requestGetTotalDeclinedBank),
	takeLatest(actions.requestGetTotalDeclinedMerchant, requestGetTotalDeclinedMerchant),
	takeLatest(actions.requestGetTotalDraftApp, requestGetTotalDraftApp),
	takeLatest(actions.requestGetTotalInWorkApp, requestGetTotalInWorkApp),
	takeLatest(actions.requestGetWaitingClientApp, requestGetWaitingClientApp),
	takeLatest(actions.requestGetWaitingBankApp, requestGetWaitingBankApp),
	takeLatest(actions.requestGetExtraFilesList, requestGetExtraFilesList),

	// extra files kostil
	takeLatest(actions.requestUploadExtraFiles, requestUploadExtraFiles),

	// questions
	takeLatest(actions.requestGetBankQuestions, requestGetBankQuestions),
	takeLatest(actions.requestSendAnswersBankQuestions, requestSendAnswersBankQuestions),
	// <----
]

// 1
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// Скачать таблицу
function* requestDownloadDataPage(action) {
	try {   
		yield call(API.downloadDataPage, action.payload);   
	} catch (error) {    
	}
} 

// 
function* requestCreateUpdateData(action) {
	try {   
		const data = yield call(API.createUpdateData, action.payload.request);   
		yield put(actions.successCreateUpdateData(data))
		action.payload.callback(data)
		
	} catch (error) { 
		yield put(actions.errorCreateUpdateData(error)) 
		action.payload.callback({...error, error: true}) 
	}
} 

// const requestGetOneINls = {
// 	dynamicBankParams: {
// 		accounts: [{bik: '1'}, {bik: '2'}],
// 		'beneficiaryIndividuals': [],
// 		'beneficiaryLegals': [],

// 		'foundationIndividuals': [],
// 		'foundationLegals': [],

// 	}
// }

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);
		action.payload.callback(data)
		yield put(actions.getOneSuccess(data))  
		// yield put(actions.getOneSuccess({...data, ...requestGetOneINls}))  
	} catch (error) {   
		action.payload.callback({})
		yield put(actions.getOneSuccess({}))
	}
} 
  
 

 
// 
function* requestPartnerCodes(action) {
	try {   
		const data = yield call(API.getPartnerCodes, action.payload);  
		yield put(actions.successPartnerCodes(data))   
		
	} catch (error) {   
		yield put(actions.successPartnerCodes({}))
	}
}
   
 
// 
function* requestComissionsCombobox(action) {
	try {   
		const data = yield call(API.getComissionsCombobox, action.payload);  
		yield put(actions.successComissionsCombobox(data))   
		
	} catch (error) {   
		yield put(actions.successComissionsCombobox([]))
	}
}
 
// 
function* requestCompanyForINN(action) {
	try {   
		const data = yield call(API.getCompanyForINN, action.payload);  
		yield put(actions.successCompanyForINN(data))   
		
	} catch (error) {   
		yield put(actions.successCompanyForINN({}))
	}
}

// 
function* requestCompanyForBIK(action) {
	try {   
		const data = yield call(API.getCompanyForBIK, action.payload);  
		yield put(actions.successCompanyForBIK(data))   
		
	} catch (error) {   
		yield put(actions.successCompanyForBIK({}))
	}
}

// 
function* requestCompanyCallbackBIK(action) {
	try {   
		const data = yield call(API.getCompanyForBIK, action.payload.request);  
		action.payload.callback(data)
	} catch (error) {   
		action.payload.callback(false)
	}
}

// 
function* requestUploadFile(action) {
	try {
		const response = yield call(API.uploadFile, action.payload.formData);
		if(!response || (response?.errorType && response?.errorType !== "FILE_NOT_VALID")) {
			throw response;
		}
		const data = response?.fileIds
		action.payload.callback(data ? data[0] : false)
		yield put(actions.successUploadFile(data))   
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.successUploadFile({}))
	}
}

// 
function* requestGetFiles(action) {
	try {   
		const data = yield call(API.getFiles, action.payload);  
		yield put(actions.successGetFiles(data))   
		
	} catch (error) {   
		yield put(actions.successGetFiles([]))
	}
}

// 
function* requestDeleteFile(action) {
	try {   
		const data = yield call(API.deleteFile, action.payload.request);
		action.payload.callback(data)
		yield put(actions.successDeleteFile(data))   
		
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.successDeleteFile([]))
	}
}
   

// 
function* requestDownloadFile(action) {
	try {   
		yield call(API.downloadFile, action.payload);   
	} catch (error) {    
	}
}

// 
function* downloadXml(action) {
	try {   
		yield call(API.downloadXml, action.payload);   
	} catch (error) {    
	}
}

// 
function* downloadOffer(action) {
	try {   
		yield call(API.downloadOffer, action.payload);   
	} catch (error) {    
	}
}

// 
function* downloadApplication(action) {
	try {   
		yield call(API.downloadApplication, action.payload);   
	} catch (error) {    
	}
}


//
function* requestConfirmAppInBank(action) {
	try {   
		const data = yield call(API.confirmAppInBank, action.payload.request);  
		yield put(actions.successConfirmAppInBank(data)) 
		action.payload.callback({status: 'PROCESSING'})  
	} catch (error) { 
		action.payload.callback({...error, error: true})
		yield put(actions.successConfirmAppInBank({}))
	}
} 

//
function* requestRollBackApp(action) {
	try {   
		const data = yield call(API.rollBackApp, action.payload.request) 
		yield put(actions.successRollBackApp(data)) 
		action.payload.callback({status: 'DRAFT'})  
	} catch (error) {   
		action.payload.callback({...error, error: true})
		yield put(actions.successRollBackApp({}))
	}
} 

//
function* requestForceStatus(action) {
	try {   
		const data = yield call(API.forceStatus, action.payload.request) 
		yield put(actions.finalForceStatus(data)) 
		action.payload.callback(data)  
	} catch (error) {   
		action.payload.callback(false)
		yield put(actions.finalForceStatus({}))
	}
}

function* requestResendToMerchant(action) {
	try {   
		const data = yield call(API.resendToMerchant, action.payload.request)
		yield put(actions.successResendToMerchant(data))
		action.payload.callback({status: 'SIGNING_BY_MERCHANT'})
	} catch (error) {   
		action.payload.callback(false)
		yield put(actions.successResendToMerchant({}))
	}
} 

//
function* requestlongPollingStatus(action) {
	try {   
		const data = yield call(API.longPollingStatus, action.payload.request);  
		action.payload.callback(data)  
	} catch (error) {   
		action.payload.callback(false)
	}
} 

//
function* requestPrepareTerminals(action) {
	try {   
		const data = yield call(API.prepareTerminals, action.payload.request);  
		yield put(actions.successPrepareTerminals(data)) 
		action.payload.callback({status: 'DONE'})  
	} catch (error) {   
		action.payload.callback({...error, error: true})
		yield put(actions.successPrepareTerminals({}))
	}
} 

//
function* requestGetBanks(action) {
	try {   
		const data = yield call(API.getBanks, action.payload);  
		yield put(actions.successGetBanks(data)) 
	} catch (error) {   
		yield put(actions.successGetBanks({}))
	}
}

//
function* requestGetBanksFields(action) {
	try {   
		const data = yield call(API.getBanksFields, action.payload);  
		yield put(actions.successGetBanksFields(data)) 
	} catch (error) {   
		yield put(actions.successGetBanksFields({}))
	}
} 

//
function* requestGetTableBanks(action) {
	try {   
		const data = yield call(API.getTableBanks, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.successGetTableBanks(data)) 
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.successGetTableBanks({}))
	}
} 

//
function* requestGetRecommendBanks(action) {
	try {   
		const data = yield call(API.getRecommendBanks, action.payload.request);  
		action.payload.callback(data)
		yield put(actions.successGetRecommendBanks(data)) 
	} catch (error) {   
		action.payload.callback(false)
		yield put(actions.successGetRecommendBanks({}))
	}
}

//
function* requestRefuseFromManager(action) {
	try {
		const data = yield call(API.refuseFromManager, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalRefuseFromManager(data))
	} catch (error) {
		action.payload.callback({...error, error: true})
		yield put(actions.finalRefuseFromManager({}))
	}
} 

//
function* requestGetAllDocs(action) {
	try {
		const data = yield call(API.getAllDocs, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalGetAllDoc(data))
	} catch (error) {
		action.payload.callback([])
		yield put(actions.finalGetAllDoc([]))
	}
}

//
function* requestEditRowTable(action) {
	try {
		const data = yield call(API.editRowTable, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalEditRowTable(data && action.payload.request))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalEditRowTable(false))
	}
}

//
function* requestGetDocumentSize(action) {
	try {   
		const data = yield call(API.getDocumentSize, action.payload);
		yield put(actions.finalGetDocumentSize(data)) 
	} catch (error) {
		yield put(actions.finalGetDocumentSize(true))
	}
}

//
function* requestGetBanksForMerchant(action) {
	try {
		const data = yield call(API.getBanksForMerchant, action.payload);
		yield put(actions.finalGetBanksForMerchant(data))
	} catch (error) {
		yield put(actions.finalGetBanksForMerchant({}))
	}
}

//
function* requestGetBanksForApplication(action) {
	try {
		const data = yield call(applicationsAPI.getBanksForApplication, action.payload);
		yield put(actions.finalGetBanksForApplication(data))
	} catch (error) {
		yield put(actions.finalGetBanksForApplication({}))
	}
}

//
function* requestGetTerminalsFromMerchantsAndBanks(action) {
	try {
		const data = yield call(API.getTerminalsFromMerchantsAndBanks, action.payload);
		yield put(actions.finalGetTerminalsFromMerchantsAndBanks(data))
	} catch (error) {
		yield put(actions.finalGetTerminalsFromMerchantsAndBanks({}))
	}
}

//
function* requestGetInnForMerchant(action) {
	try {
		const data = yield call(API.getInnForMerchant, action.payload);
		yield put(actions.finalGetInnForMerchant(data))
	} catch (error) {
		yield put(actions.finalGetInnForMerchant(''))
	}
}

//
function* requestGetInfoOneApp(action) {
	try {
		const data = yield call(API.getInfoOneApp, action.payload);
		yield put(actions.finallGetInfoOneApp(data))
	} catch (error) {
		yield put(actions.finallGetInfoOneApp({}))
	}
}

//
function* requestGetDashboard(action) {
	try {
		const data = yield call(API.getDashboardApps, action.payload);
		yield put(actions.finalGetDashboard(data))
	} catch (error) {
		yield put(actions.finalGetDashboard({}))
	}
}

//
function* requestFilePersons(action) {
	try {
		const data = yield call(API.filePersons, action.payload);
		yield put(actions.successFilePersons(data))
	} catch (error) {
		yield put(actions.successFilePersons([]))
	}
}

// --- widgets --->
function* requestGetTotalApp(action) {
	try {
		const data = yield call(API.getTotalApp, action.payload);
		yield put(actions.finalGetTotalApp(data))
	} catch (error) {
		yield put(actions.finalGetTotalApp(false))
	}
}
function* requestGetTotalDoneApp(action) {
	try {
		const data = yield call(API.getTotalDoneApp, action.payload);
		yield put(actions.finalGetTotalDoneApp(data))
	} catch (error) {
		yield put(actions.finalGetTotalDoneApp(false))
	}
}
function* requestGetTotalDeclinedBank(action) {
	try {
		const data = yield call(API.getTotalDeclinedBank, action.payload);
		yield put(actions.finalGetTotalDeclinedBank(data))
	} catch (error) {
		yield put(actions.finalGetTotalDeclinedBank(false))
	}
}

function* requestGetTotalDeclinedMerchant(action) {
	try {
		const data = yield call(API.getTotalDeclinedMerchant, action.payload);
		yield put(actions.finalGetTotalDeclinedMerchant(data))
	} catch (error) {
		yield put(actions.finalGetTotalDeclinedMerchant(false))
	}
}

function* requestGetTotalDraftApp(action) {
	try {
		const data = yield call(API.getTotalDraftApp, action.payload);
		yield put(actions.finalGetTotalDraftApp(data))
	} catch (error) {
		yield put(actions.finalGetTotalDraftApp(false))
	}
}

function* requestGetTotalInWorkApp(action) {
	try {
		const data = yield call(API.getTotalInWorkApp, action.payload);
		yield put(actions.finalGetTotalInWorkApp(data))
	} catch (error) {
		yield put(actions.finalGetTotalInWorkApp(false))
	}
}

function* requestGetWaitingClientApp(action) {
	try {
		const data = yield call(API.getWaitingClientApp, action.payload);
		yield put(actions.finalGetWaitingClientApp(data))
	} catch (error) {
		yield put(actions.finalGetWaitingClientApp(false))
	}
}

function* requestGetWaitingBankApp(action) {
	try {
		const data = yield call(API.getWaitingBankApp, action.payload);
		yield put(actions.finalGetWaitingBankApp(data))
	} catch (error) {
		yield put(actions.finalGetWaitingBankApp(false))
	}
}


// 
function* requestDocumentsChecked(action) {
	try {   
		const data = yield call(API.documentsChecked, action.payload.request);  
		action.payload.callback(data) 
	} catch (error) {    
		action.payload.callback(false) 
	}
}

// 
function* requestCompanyForINNKub(action) {
	try {   
		const data = yield call(API.getCompanyForINNKub, action.payload.request);  
		action.payload.callback(data) 
	} catch (error) {    
		action.payload.callback(false) 
	}
}

// 
function* requestGetAllTmsForOneApp(action) {
	try {   
		const data = yield call(API.getAllTmsForOneApp, action.payload);   
		yield put(actions.finallGetAllTmsForOneApp(data && data.list ? data.list : []))
	} catch (error) {    
		yield put(actions.finallGetAllTmsForOneApp([]))
	}
}

// 
function* requestGetTimerSla(action) {
	try {   
		const data = yield call(API.getTimerSla, action.payload.request);   
		yield put(actions.finalGetTimerSla(data))
		action.payload.callback(data)
	} catch (error) {    
		yield put(actions.finalGetTimerSla({}))
		action.payload.callback(false)
	}
}

function* requestGetExtraFilesList(action){
	try {   
		const data = yield call(API.getExtraFilesList, action.payload);   
		yield put(actions.successGetExtraFilesList(data))
	} catch (error) {   }
	
}


function* requestUploadExtraFiles(action){
	try {   
		const data = yield call(API.uploadFileExtra, action.payload);
		action.payload.callback(data ? data[0] : false);
		yield put(actions.successUploadExtraFiles(data))
		yield put(actions.actionsExtraFiles({action: "UPLOAD", ...action.payload, result: data}))

	} catch (error) {  
		yield put(actions.successUploadExtraFiles({}))
	 }
	
}

function* requestSaveDocumentsFromSdm(action){
	try {   
		const data = yield call(API.saveDocumentsFromSdm, action.payload.applicationId);
		action.payload.callback(data.errorType ? data : true)
		yield put(actions.successSaveDocumentsFromSdm(data))
	} catch (error) {  
		yield put(actions.successSaveDocumentsFromSdm({}))
	}
}

function* requestGetBankQuestions(action){
	try {
		const data = yield call(API.getBankQuestions, action.payload.id);
		yield put(actions.successGetBankQuestions(data))
	} catch (error) {  
		yield put(actions.successGetBankQuestions([]))
	}
}

function* requestSendAnswersBankQuestions(action){
	try {
		const data = yield call(API.sendAnswersBankQuestions, action.payload);
		action.payload.callback(data.errorType ? data.errorType : false)
		yield put(actions.successSendAnswersBankQuestions(action.payload))
	} catch (error) {  
		yield put(actions.successSendAnswersBankQuestions([]))
	}
}