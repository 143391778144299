import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customersAPI from '../../api/v2/customersAPI';
import generalAPI from '../../api/v2/generalAPI';
import { getAddressWithRegionLabel } from '../shares/helpers/generateAddressLine';
// import isVoidObject from '../shares/helpers/isVoidObject'

export const fetchChangeCustomer = createAsyncThunk(
    "customer-change/fetch-update",
    async ({ customerId, payload }, thunkAPI) => {
        try {
            const { executive, contactPerson, businessAddress, address } = thunkAPI.getState().customerChange
            const requestData = { ...payload, executive, contactPerson, businessAddress, address }
            const response = await customersAPI.updateOne({ id: customerId, payload: requestData })
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateReportingPeriods = createAsyncThunk(
    "customer-create/merchantReportConfigs/put",
    async ({customerId, merchantReportConfigs}) => {
        const response = await customersAPI.updateReportingPeriods({id: customerId, payload: {merchantReportConfigs}})
        return response
    }
)

export const fetchGetCustomerRecord = createAsyncThunk(
    "customer-change/fetch-customer-record",
    async (id) => {
        const response = await customersAPI.getOneForRecord(id)
        return response
    }
)

export const getBankDataByBic = createAsyncThunk(
    "customer-change/bankDataByBic/get",
    async (bic) => {
        const response = await generalAPI.getBankDataByBic(bic)
        return response
    }
)
export const setMerchantBankDataBanks = createAsyncThunk(
    "customer-change/setMerchantBankDataBanks",
    async (banksIds, thunkAPI) => {
		if(!banksIds?.length) return false

		const banks = thunkAPI.getState().core.dict.banks.dictBanks
		const result = banks?.some(el => banksIds.includes(+el.id) && el.showPointNameInSmsPay)
        return result
    }
)

const initialState = {
    executive: {},
    contactPerson: {},
    businessAddress: {},
    address: {},
    customerRecord: {
        record: {},
        loading: false,
    },
    reportsRecord: {
        merchantReportConfigs: [],
        loading: false
    },
    loading: false,
    saveDataError: null,
	spb: null,
	vppSbpAgent : null,
	bankBeanName: null,
	showPointNameInSmsPay: null,
}

const customerChangeSlice = createSlice({
    name: "customer-change",
    initialState,
    reducers: {
        addDirectorData: (state, { payload }) => {
            state.executive = payload
        },
        addContactPersonData: (state, { payload }) => {
            state.contactPerson = payload
        },
        addLegalAddress: (state, { payload }) => {
            state.businessAddress = payload
        },
        addActualAddress: (state, { payload }) => {
            state.address = payload
        },
		setSbpCustomerChange: (state, { payload }) => {
            state.spb = payload
        },
		setBankBeanName: (state, { payload }) => {
            state.bankBeanName = payload
        },
		setSbpAgentCustomerChange: (state, { payload }) => {
            state.vppSbpAgent = payload
        },
        clearData: (state) => initialState,
    },
    extraReducers: {
        [fetchChangeCustomer.pending]: (state) => {
            state.loading = true
        },
        [fetchChangeCustomer.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [fetchChangeCustomer.rejected]: (state, { payload }) => {
            state.saveDataError = payload?.errorType || "UNKNOWN_ERROR"
            state.loading = false
        },
        [updateReportingPeriods.pending]: (state) => {
            state.reportsRecord.loading = true
        },
        [updateReportingPeriods.fulfilled]: (state) => {
            state.reportsRecord.loading = false
        },
        [updateReportingPeriods.rejected]: (state) => {
            state.reportsRecord.loading = false
        },
        [fetchGetCustomerRecord.pending]: (state) => {
            state.customerRecord.loading = true
        },
        [fetchGetCustomerRecord.fulfilled]: (state, { payload }) => {
            state.address = getAddressWithRegionLabel(payload.address)
            state.businessAddress = { ...state.businessAddress, ...getAddressWithRegionLabel(payload.businessAddress) }
            state.contactPerson = payload.contactPerson
            state.executive = payload.executive
            state.customerRecord.record = payload
            state.reportsRecord.merchantReportConfigs = payload.merchantReportConfigs
            state.customerRecord.loading = false
        },
        [fetchGetCustomerRecord.rejected]: (state) => {
            state.customerRecord.loading = false
        },
        [getBankDataByBic.fulfilled]: (state) => {
            state.loading = false
        },
        [setMerchantBankDataBanks.fulfilled]: (state, { payload }) => {
            state.showPointNameInSmsPay = payload
        },
    }
});

export const {
    addDirectorData,
    addContactPersonData,
    addLegalAddress,
    addActualAddress,
    clearData,
	setSbpCustomerChange,
	setSbpAgentCustomerChange,
	setBankBeanName,
} = customerChangeSlice.actions
export default customerChangeSlice.reducer