import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit'
import customersAPI from '../../api/v2/customersAPI';
import posesAPI from '../../api/v2/posesAPI';
import dictsAPI from '../../api/v2/dictsAPI';
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue'
import columns from './columns';
import formatingObjectToView from '../shares/helpers/formatingObjectForView';
import { setCurrentCustomer } from '../pos-create/posCreateSlice';

const defaultSelectedColumns = columns.filter(column => column.optional !== true).map(column => column.key)

export const fetchCustomerMainData = createAsyncThunk(
    "customer/main/fetch",
    async (customerId) => {
        const response = await customersAPI.getOne(customerId)
        return response
    }
)

export const fetchCustomerPoses = createAsyncThunk(
    "customer/poses/fetch",
    async (clientId) => {
        const response = await posesAPI.getDataByClientId(clientId)
        return response
    }
)

export const goToCreatePos = createAsyncThunk(
    "customer/create-pos",
    async (_, thunkAPI) => {
        const { id } = thunkAPI.getState().customer.main.data
        thunkAPI.dispatch(setCurrentCustomer(id))
    }
)

export const getPartners = createAsyncThunk(
    "customer/partners/get",
    async () => {
        const response = await dictsAPI.getPartners()
        return response
    }
)

export const transferToAnotherPartner = createAsyncThunk(
    "customer/transferToAnotherPartner/put",
    async (data) => {
        const response = await customersAPI.transferToAnotherPartner(data)
        return response
    }
)

const initialState = {
    main: {
        data: {},
        selectedContract: undefined,
        commissions: [],
        filteredAgreementOptions: [],
        currentMerchantBankData: {
			contractNumber: '',
			contractDate: '',
			bic: '',
			bankName: '',
			corrAccount: '',
			currentAccount: '',
			id: null,
			merchantId: null,
		},
        loading: false
    },
    poses: {
        listData: [],
        selectedColumns: defaultSelectedColumns,
        loading: false
    },
    partnersOptions: [],
    transferingToAnotherPartner: false,
    tcpCommissionData: [],
	sbpColumnsData:[],
	sbpColumnsDataWasChanged:false,
	nspkSelectOptions:[],
	changeBankOptions:[],
    loading: false
}


const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.poses.selectedColumns = payload
        },
        refreshSelectedColumns: (state) => {
            state.poses.selectedColumns = initialState.poses.selectedColumns
        },
        selectContract: (state, { payload }) => {
			if(payload && state.main.data?.merchantBankData?.length){
				state.main.selectedContract = payload

				const currentMerchantBankData = state.main.data?.merchantBankData.find(i => i.id === payload)
				state.main.currentMerchantBankData = currentMerchantBankData
				return;
			}

			if(!payload){
				state.main.currentMerchantBankData = initialState.main.currentMerchantBankData
				return;
			}
        },
        setFilteredAgreementOptions: (state, { payload }) => {
			state.main.filteredAgreementOptions = payload
        },
        setCommissions: (state, { payload }) => {
			state.main.commissions = payload
        },
        clearCommissions: (state, { payload }) => {
			state.main.commissions = []
        },
        setSbpColumnsData: (state, { payload }) => {
            state.sbpColumnsData =  payload
			if(!state.sbpColumnsDataWasChanged) state.sbpColumnsDataWasChanged = true
        },
        setNspkSelectOptions: (state, { payload }) => {
            state.nspkSelectOptions =  payload
        },
        setChangeBankOptions: (state, { payload }) => {
            state.changeBankOptions =  payload
        },
		clearData: () => initialState
        
    },
    extraReducers: {
        // получаем основную информацию о клиенте
        [fetchCustomerMainData.pending]: (state, { payload }) => {
            state.main.loading = true
        },
        [fetchCustomerMainData.fulfilled]: (state, { payload }) => {
            state.main.data = formatingObjectToView(payload, keysWithDateValues, keysWithDateTimeValues)
            state.main.loading = false
        },
        [fetchCustomerMainData.rejected]: (state, { payload }) => {
            state.main.loading = false
        },
        // получаем список точек продаж для клиента
        [fetchCustomerPoses.pending]: (state, { payload }) => {
            state.poses.loading = true
        },
        [fetchCustomerPoses.fulfilled]: (state, { payload }) => {
            state.poses.listData = payload?.poses?.map(pos => {
                const formatedPos = formatingObjectToView(pos, keysWithDateValues, keysWithDateTimeValues)
                return { ...formatedPos, key: nanoid() }
            })
			if(payload?.poses?.length){
				state.sbpColumnsData = payload.poses.map(pos => ({
					merchantPointId: pos.id,
					fastPaymentMerchantId: pos?.fastPaymentMerchantId ? pos?.fastPaymentMerchantId : null ,
					fastPaymentConfigsOnTerminals: pos?.fastPaymentConfigsOnTerminals ? pos?.fastPaymentConfigsOnTerminals : null,
				}))
			};
			if(!payload?.poses?.length){
				state.sbpColumnsData = []
			};
            state.poses.loading = false
        },
        [fetchCustomerPoses.rejected]: (state, { payload }) => {
            state.poses.loading = false
        },
        // получаем список партнеров
        [getPartners.fulfilled]: (state, { payload }) => {
            state.partnersOptions = Object.entries(payload).map(item => ({value: item[0], label: item[1]}))
        },
        // передаем мерчанта другому партнеру
        [transferToAnotherPartner.pending]: (state) => {
            state.transferingToAnotherPartner = true
        },
        [transferToAnotherPartner.fulfilled]: (state) => {
            state.transferingToAnotherPartner = false
        },
        [transferToAnotherPartner.rejected]: (state) => {
            state.transferingToAnotherPartner = false
        },
    }
});

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    selectContract,
    setFilteredAgreementOptions,
    clearData,
    setCommissions,
    clearCommissions,
    setSbpColumnsData,
    setNspkSelectOptions,
    setChangeBankOptions,
} = customerSlice.actions
export default customerSlice.reducer