import { gettext, t } from "ttag";

const terminalShiftStatus =
    ['CURRENT',
        'IN_PROGRESS',
        'SUCCESS',
        'ERROR',
        'SUCCESS_WITH_ERRORS',
        'SUCCESS_MANUALLY'
    ];

const transactionStatusCombobox = [
    "SUCCESS",
    "ERROR",
    "NEW",
    "IN_PROGRESS",
    "NEED_CONFIRMATION",
    "CANCELED",
    "UNKNOWN",
    "UNKNOWN_TIMEOUT"
].map(item => { return { value: item, label: item } });

const transactionTypeCombobox = [
    //POS:
    'AGENT_AUTHORIZATION',
    'PURCHASE',
    'PURCHASE_CASH_BACK',
    'AUTHORIZATION',
    'AUTHORIZATION_CONFIRMATION',
    'WITHDRAWAL',
    'ACCRUAL',
    'TRANSFER',
    'BALANCE',
    'STATEMENT',
    'BANK_TRANSFER',
    'CUSTOMER_VALIDATION',
    'CLOSE_SHIFT',
    'CLOSE_SHIFT_NO_UPLOAD',
    'BATCH_UPLOAD',
    'BATCH_UPLOAD_FINISH',
    'CLOSE_SHIFT_FINISH',
    'STOP_CARD',
    'GET_LIMIT',
    'SET_LIMIT',
    'PIN_CHANGE',
    'P2P',
    'GENERATE_CASH_CODE',
    'CASH_IN',
    'CASH_OUT',
    'CASH_OUT_BY_CODE',
    'REFUND',

    'PIN_WK_CHANGE',
    'MAC_WK_CHANGE',

    //PAYMENT:
    'GENERATE_TOKEN',
    'STOP_TOKEN',
    'RENEW_TOKEN',
    'FEE_CALCULATION',
    'SERVICES',

    //BOTH:
    'PAYMENT',
    'QR_PAYMENT',
    'REVERSAL',
    'STATUS',
    'CUSTOM',

    //OCP
    'ACCOUNT_INFO',

    'EMV_FINAL_ADVICE'
].map(item => { return { value: item, label: item } });

const isEnableCombobox = [
    { value: 1, label: 'Активен' },
    { value: 0, label: 'Неактивен' }
];

export const transFilterStatus = [
    { value: 'SUCCESS', label: 'Success' },
    { value: 'ERROR', label: 'Error' },
    { value: 'NEW', label: 'New' },
    { value: 'IN_PROGRESS', label: 'In progress' },
    { value: 'NEED_CONFIRMATION', label: 'Need confirmation' },
    { value: 'CANCELED', label: 'Canceled' },
    { value: 'UNKNOWN', label: 'Unknown' },
    { value: 'UNKNOWN_TIMEOUT', label: 'Unknown timeout' },
];

export const oldTransFilterTypes = [
    { value: 'SUCCESS', label: 'Success' },
    { value: "AGENT_AUTHORIZATION", label: "agent authorization", },
    { value: "PURCHASE", label: "purchase", },
    { value: "PURCHASE_CASH_BACK", label: "purchase cash back", },
    { value: "AUTHORIZATION", label: "authorization", },
    { value: "AUTHORIZATION_CONFIRMATION", label: "authorization confirmation", },
    { value: "WITHDRAWAL", label: "withdrawal", },
    { value: "ACCRUAL", label: "accrual", },
    { value: "TRANSFER", label: "transfer", },
    { value: "BALANCE", label: "balance", },
    { value: "STATEMENT", label: "statement", },
    { value: "BANK_TRANSFER", label: "bank transfer", },
    { value: "CUSTOMER_VALIDATION", label: "customer validation", },
    { value: "CLOSE_SHIFT", label: "close shift", },
    { value: "CLOSE_SHIFT_NO_UPLOAD", label: "close shift no upload", },
    { value: "BATCH_UPLOAD", label: "batch upload", },
    { value: "BATCH_UPLOAD_FINISH", label: "batch upload finish", },
    { value: "CLOSE_SHIFT_FINISH", label: "close shift finish", },
    { value: "STOP_CARD", label: "stop card", },
    { value: "GET_LIMIT", label: "get limit", },
    { value: "SET_LIMIT", label: "set limit", },
    { value: "PIN_CHANGE", label: "pin change", },
    { value: "P2P", label: "p2p", },
    { value: "GENERATE_CASH_CODE", label: "generate cash code", },
    { value: "CASH_IN", label: "cash in", },
    { value: "CASH_OUT", label: "cash out", },
    { value: "CASH_OUT_BY_CODE", label: "cash out by code", },
    { value: "REFUND", label: "refund", },
    { value: "PIN_WK_CHANGE", label: "pin wk change", },
    { value: "MAC_WK_CHANGE", label: "mac wk change", },
    { value: "GENERATE_TOKEN", label: "generate token", },
    { value: "STOP_TOKEN", label: "stop token", },
    { value: "RENEW_TOKEN", label: "renew token", },
    { value: "FEE_CALCULATION", label: "fee calculation", },
    { value: "SERVICES", label: "services", },
    { value: "PAYMENT", label: "payment", },
    { value: "QR_PAYMENT", label: "qr payment", },
    { value: "REVERSAL", label: "reversal", },
    { value: "STATUS", label: "status", },
    { value: "CUSTOM", label: "custom", },
    { value: "ACCOUNT_INFO", label: "account info", },
];

const defaultOperationTypes = {
    ANY_SUM: "Любая сумма",
    FIX_SUM: "Фиксированная сумма",
    FIX_STEP_SUM: "Сумма с фиксированным шагом",
    SELECT_SUM: "Сумма по выбору",
    INPUT_SUM: "Ввод суммы"
}


export {
    terminalShiftStatus,
    isEnableCombobox,
    transactionStatusCombobox,
    transactionTypeCombobox,
    defaultOperationTypes
}

