import * as actions from './actions'
import { createReducer } from 'redux-act'

const initialState = {
    userToken: {
        adminRoles: {},
    },
	userSettings:{
		/** кнопка обратиться в поддержку */
		hideSupportButton: false,
		/** кнопка отмены транзакции в таблице транзакций */
		previousDays: null,
	},
    settings: {
        loading: false,
        data: {},
    },
    bankSettings: {
        loading: false,
        data: {},
    },
    oneBankUpdate: {
        loading: false,
    },
    settingsUpdate: {
        loading: false,
    },
}

export default createReducer(
    {
        // --- * getPointsByApplicationId
        [actions.addToken]: (state, action) => {
            return({
            ...state,
            userToken: {
                ...state.userToken,
                ...action,
                adminRoles: state.userToken.adminRoles,
            },
        })},

        // * get settings
        [actions.requestGetSettings]: (state, action) => ({
            ...state,
            settings: { ...state.settings, loading: true },
        }),
        [actions.finalGetSettings]: (state, action) => ({
            ...state,
            settings: { ...state.settings, loading: false, data: action },
        }),
        [actions.clearGetSettings]: (state, action) => ({
            ...state,
            settings: initialState.settings,
        }),

        // * get Bank settings
        [actions.requestGetBankSettings]: (state, action) => ({
            ...state,
            bankSettings: { ...state.bankSettings, loading: true },
        }),
        [actions.finalGetBankSettings]: (state, action) => ({
            ...state,
            bankSettings: {
                ...state.bankSettings,
                loading: false,
                data: action,
            },
        }),
        [actions.clearGetBankSettings]: (state, action) => ({
            ...state,
            bankSettings: initialState.bankSettings,
        }),

        // *  Upd OneBankSettings
        [actions.requestUpdOneBankSettings]: (state, action) => ({
            ...state,
            oneBankUpdate: { ...state.oneBankUpdate, loading: true },
        }),
        [actions.finalUpdOneBankSettings]: (state, action) => ({
            ...state,
            oneBankUpdate: { ...state.oneBankUpdate, loading: false },
        }),

        // *  Update Settings
        [actions.requestUpdateSettings]: (state, action) => ({
            ...state,
            settingsUpdate: { ...state.settingsUpdate, loading: true },
        }),
        [actions.finalUpdateSettings]: (state, action) => ({
            ...state,
            settingsUpdate: { ...state.settingsUpdate, loading: false },
        }),

        // *  Get AllRoles
        [actions.requestGetAllRoles]: (state, action) => ({
            ...state,
            userToken: { ...state.userToken, loading: true },
        }),
        [actions.finalrequestGetAllRoles]: (state, action) => ({
            ...state,
            userToken: {
                ...state.userToken,
                loading: false,
                adminRoles:
                    action && action.length
                        ? action.reduce(
                              (obj, name) => ({ ...obj, [name]: true }),
                              {}
                          )
                        : {},
            },
        }),

		// --- * getUserSettings
		[actions.userSettings]: (state, action) => {
			return({
			...state,
			userSettings: {
				...state.userSettings,
				...action,
			},
		})},
        LOGOUT_FROM_SYSTEM: (state, action) => initialState,
    },
    initialState
)
