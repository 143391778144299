import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/administration/roles';
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestCreate, requestCreate),   
	takeLatest(actions.requestUpdate, requestUpdate),   
	takeLatest(actions.requestGetOne, requestGetOne),
	takeLatest(actions.requestCheckLogin, requestCheckLogin),
	takeLatest(actions.requestGetRolesSelected, requestGetRolesSelected),
	takeLatest(actions.requestResetPassword, requestResetPassword),
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestCreate(action) {
	try {  
		const data = yield call(API.create, action.payload.request);  
		action.payload.callback(true)  
		yield put(actions.finalCreate(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCreate(false))
	}
} 

// 
function* requestUpdate(action) {
	try {  
		const data = yield call(API.update, action.payload.request);  
		action.payload.callback(true)  
		yield put(actions.finalGetUpdate(data))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalGetUpdate(false))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetOne(data))
	} catch (error) {   
		action.payload.callback({})  
		yield put(actions.finalGetOne({}))
	}
} 


//
function* requestCheckLogin(action) {
	try {
		const data = yield call(API.checkLogin, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalCheckLogin(data))
	} catch (error) {
		action.payload.callback(true)
		yield put(actions.finalCheckLogin({}))
	}
}

//
function* requestGetRolesSelected(action) {
	try {
		const data = yield call(API.getRoles, action.payload);
		yield put(actions.finalGetRolesSelected(data))
	} catch (error) {
		yield put(actions.finalGetRolesSelected({}))
	}
}

//
function* requestResetPassword(action) {
	try {
		const data = yield call(API.resetPassword, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalResetPassword(data))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalResetPassword({}))
	}
}


