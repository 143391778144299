import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/services/couriers'; 
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestCreate, requestCreate),   
	takeLatest(actions.requestUpdate, requestUpdate),   
	takeLatest(actions.requestGetOne, requestGetOne),            
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestCreate(action) {
	try {  
		const data = yield call(API.create, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalCreate({...action.payload.request, id: data.id}))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCreate(false))
	}
} 

// 
function* requestUpdate(action) {
	try {  
		const data = yield call(API.update, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetUpdate(action.payload.request))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalGetUpdate(false))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetOne(data))
	} catch (error) {   
		action.payload.callback({})  
		yield put(actions.finalGetOne({}))
	}
} 
