import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = {  
  data: [],
  loading: true,
  oneNews: { 
    loading: false,
  },
  scrolledToNews: { 
		scroll: false,
		redirectFromDashboard: false
	},
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  { 
    // --- *  GetAllNews
    [actions.requestGetAllNews]: (state, action) => ({
      ...state,  data: initialState.data, loading: true
    }),   
    [actions.finalGetAllNews]: (state, action) => ({
      ...state,  data: action, loading: false
    }),   
    [actions.clearGetAllNews]: (state, action) => ({
      ...state,  data: initialState.data, loading: initialState.loading
    }),   

    // --- *  SendOneNews
    [actions.requestSendOneNews]: (state, action) => ({
      ...state,  oneNews: {...initialState.oneNews, loading: true}
    }),     
    [actions.finalSendOneNews]: (state, newOne) => {
      let newData = state.data
      if(newOne) newData = [newOne, ...newData]
      return{ 
        ...state,  oneNews: {...state.oneNews, loading: false}, data: newData
      }},     

    // --- *  DeleteOne  
    [actions.finalDeleteOne]: (state, id) => {
      let newData = state.data
      if(id) newData = state.data.filter(el => el.id !== id)
      return{ 
        ...state,  data: newData
      }},

	[actions.setScrollToNews]: (state, data) => {
      return { 
        ...state,  scrolledToNews: data
      }
	},     

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
