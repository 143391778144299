import * as actions from "./actions";
import { createReducer } from "redux-act";

const initialState = { 
  data: [], 
  loading: false, 
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  one: {
    loading: false,
  },
  creditCards: {
    loading: false,
    data: []
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    
    // --- Async get ->>
    [actions.requestGetDataPage]: (state, action) => ({
      ...state,
      loading: true
    }),
    [actions.getDataPageSuccess]: (state, action) => {
      return { ...state, loading: false, data: action.list };
    },
    [actions.getDataPageError]: (state, action) => ({
      ...state,
      loading: false,
      data: [],
      error: action
    }),
    
    // --- * create ->>
    [actions.requestCreate]: (state, action) => ({
      ...state, create: {...state.create, loading: true}
    }),
    [actions.finalCreate]: (state, action) => {
      let newData = state.data
      if(action && action.id) newData = [action, ...newData]
      return { ...state, create: {...state.create, loading: false}, data: newData};
    }, 
    
    // --- *  Update ->>
    [actions.requestUpdate]: (state, action) => ({
      ...state, update: {...state.update, loading: true}
    }),
    [actions.finalGetUpdate]: (state, action) => {
      let newData = state.data
      if(action) newData = state.data.map(el => {
        if(+el.id === +action.id) return action
        return el
      })
      return { ...state, update: {...state.update, loading: false}, data: newData};
    }, 
    
    // --- *  creditCards ->>
    [actions.requestGetCreditCards]: (state, action) => ({
      ...state, creditCards: {...state.creditCards, loading: true}
    }),
    [actions.finalGetCreditCards]: (state, action) => {
      return { ...state, creditCards: {...state.creditCards, loading: false, data: Array.isArray(action) ? action: []}};
    }, 
    [actions.clearGetCreditCards]: (state, action) => {
      return { ...state, creditCards: initialState.creditCards};
    }, 
    // add CreditCard
    [actions.finalAddCreditCard]: (state, action) => {
      let newData = state.creditCards.data
      if(action && action.id) newData = {...newData, action}
      return { ...state, creditCards: {...state.creditCards, data: newData}};
    }, 
    // del CreditCard
    [actions.finalDelCreditCard]: (state, action) => {
      let newData = state.creditCards.data
      if(action && action.cardId) newData = newData.filter(el => {
        return +el.id !== +action.cardId
      })
      return { ...state, creditCards: {...state.creditCards, data: newData}};
    }, 
    
    
    // --- * get one ->>
    [actions.requestGetOne]: (state, action) => ({
      ...state, one: {...state.one, loading: true}
    }),
    [actions.finalGetOne]: (state, action) => {
      return { ...state, one: {...state.one, loading: false}};
    }, 
    

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
