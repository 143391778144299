import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../api/other/news';  
import * as actions from './actions'   

export default [  
	takeLatest(actions.requestGetAllNews, requestGetAllNews),      
	takeLatest(actions.requestSendOneNews, requestSendOneNews),      
	takeLatest(actions.requestDeleteOne, requestDeleteOne),      
]
 

//
function* requestGetAllNews(action) {
	try {   
		const data = yield call(API.getAllNews, action.payload);  
		yield put(actions.finalGetAllNews(data))  
	} catch (e) { 
		yield put(actions.finalGetAllNews([])) 
	}
} 
 
function* requestSendOneNews(action) {
	try {   
		const id = yield call(API.sendOneNews, action.payload.request);  
		yield put(actions.finalSendOneNews({...action.payload.request, id})) 
		action.payload.callback(id)    
	} catch (e) { 
		yield put(actions.finalSendOneNews(false))
		action.payload.callback(false) 
	}
} 
 
function* requestDeleteOne(action) {
	try {   
		yield call(API.deleteOne, action.payload.request); 
		yield put(actions.finalDeleteOne(action.payload.request.id)) 
		action.payload.callback(action.payload.request.id)    
	} catch (e) { 
		yield put(actions.finalDeleteOne(false))
		action.payload.callback(false) 
	}
} 
 