import axios from 'axios'

const ROOT_URL = "/api/v2"

const rolesAPI = {
    // read
    getUserRoles: () => axios.get(`${ROOT_URL}/user/roles/available`).then(r => r.data),
    getUserRolesList: () => axios.get(`${ROOT_URL}/user/listRoles/available`).then(r => r.data),
    getMerchantRoles: (merchantId) => axios.get(`${ROOT_URL}/merchants/${merchantId}/roles/available`).then(r => r.data),
    getPartnerRoles: (partnerId) => axios.get(`${ROOT_URL}/partner/${partnerId}/roles/available`).then(r => r.data),
	getUserSettings: () => axios.get(`${ROOT_URL}/settings`).then(r => r.data),
    // update
    
    // create
    
}

export default rolesAPI