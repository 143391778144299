import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import customersAPI from '../../api/v2/customersAPI';
import columns from './columns';
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue'
import isVoidObject from '../shares/helpers/isVoidObject'
import formatingObjectToView from '../shares/helpers/formatingObjectForView';

const defaultSelectedColumns = columns.filter(column => column.optional !== true).map(column => column.key)

export const fetchCustomers = createAsyncThunk(
    'customers/list',
    async (_, thunkAPI) => {
        const { advancedSearchData } = thunkAPI.getState().customers
        const isSuperUser =
            thunkAPI.getState().auth.user.userToken.adminRoles[
            "partners_create:fromPortal"
            ]
        if (!isVoidObject(advancedSearchData)) {
            const formatedRangeDate = {
                cdat_start_date: advancedSearchData.cdat_start_date && advancedSearchData.cdat_start_date.startOf('day'),
                cdat_end_date: advancedSearchData.cdat_end_date && advancedSearchData.cdat_end_date.endOf('day'),
            }
            const response = await customersAPI.getDataByAdvancedSearch({...advancedSearchData, ...formatedRangeDate})
            return response
        }
        if (isSuperUser) {
            return
        }
        const response = await customersAPI.getData()
        return response
    }
)

const initialState = {
    listData: [],
    selectedColumns: defaultSelectedColumns,
    searchDataString: '',
    searchDataValue: '',
    advancedSearchData: {
        "city": "",
        "phone": "",
        "ena": "",
        "cdat_start_date": "",
        "cdat_end_date": ""
    },
    loading: false,
	pagination: {
        current: 1,
        pageSize: 10,
    }
}

const customersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        searchData: (state, { payload }) => {
            state.searchDataString = payload.toLowerCase()
        },
        setSearchDataValue: (state, { payload }) => {
            state.searchDataValue = payload
        },

        advancedSearchCustomersData: (state, { payload }) => {
            state.advancedSearchData = payload
        },
		changePagination: (state, { payload }) => {
            state.pagination = {...state.pagination, ...payload}
        },
        clearCustomersData: () => initialState,

    },
    extraReducers: {
        [fetchCustomers.pending]: (state, { payload }) => {
            state.loading = true
        },
        [fetchCustomers.fulfilled]: (state, { payload }) => {
            state.listData = payload?.clients?.map(customer => {
                const formatedCustomer = formatingObjectToView(customer, keysWithDateValues, keysWithDateTimeValues)
                return { ...formatedCustomer, key: nanoid() }
            })
            state.loading = false
        },
        [fetchCustomers.rejected]: (state, { payload }) => {
            state.loading = false
        },
    }
});

export const {
    searchData,
    advancedSearchCustomersData,
    changePagination,
    setSearchDataValue,
    clearCustomersData
} = customersSlice.actions
export default customersSlice.reducer