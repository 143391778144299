import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"

import serviceAPI from "../../api/v2/serviceAPI"
import dictsAPI from "../../api/v2/dictsAPI"

import columns from "./columns"

import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import formatingObjectToView from "../shares/helpers/formatingObjectForView"

const defaultSelectedColumns = columns
    .filter((column) => column.optional !== true)
    .map((column) => column.key)

export const fetchService = createAsyncThunk(
    "serviceTickets/list",
    async () => {
        const response = await serviceAPI.getData()
        return response
    }
)

export const getTerminalsOptions = createAsyncThunk(
    "serviceTickets/get-terminals",
    async () => {
        const response = await dictsAPI.getTerminalsDict()
        return response
    }
)

export const postServiceTicket = createAsyncThunk(
    "serviceTickets/create-ticket/post",
    async (payload) => {
        const response = await serviceAPI.createOneServiceTickets(payload)
        return response
    }
)

const initialState = {
    listData: [],
    listDataFiltered: [],
    selectedColumns: defaultSelectedColumns,
    searchDataString: "",
    advancedSearchData: {
        cdat: null,
        priority: null,
        status: null,
        address: null,
        contactPhone: null,
    },
    createServiceTicket: {
        modalIsOpen: false,
        loading: false
    },
    options: {
        terminals: []
    },
    loading: false,
}

const serviceTicketsSlice = createSlice({
    name: "serviceTickets",
    initialState,
    reducers: {
        changeSelectedColumns: (state, { payload }) => {
            state.selectedColumns = payload
        },

        refreshSelectedColumns: (state) => {
            state.selectedColumns = initialState.selectedColumns
        },

        searchData: (state, { payload }) => {
            state.searchDataString = payload.toLowerCase()
        },

        advancedSearchServiceData: (state, { payload }) => {
            const formatingValues = formatingObjectToView(
                payload,
                keysWithDateValues,
                keysWithDateTimeValues
            )
            state.advancedSearchData = payload
            state.listDataFiltered = state.listData.filter((item) => {
                let res = true
                for (const key in formatingValues) {
                    if (Object.hasOwnProperty.call(formatingValues, key)) {
                        if (
                            formatingValues[key] === "-" ||
                            formatingValues[key] === null ||
                            formatingValues[key] === undefined ||
                            formatingValues[key] === ""
                        ) {
                            continue
                        }
                        res = item[key]
                            .toLowerCase()
                            .includes(formatingValues[key].toLowerCase())
                        if (!res) {
                            return false
                        }
                    }
                }
                return res
            })
        },

        actionInit: (state) => {
            state.createServiceTicket.modalIsOpen = true
        },

        closeAction: (state) => {
            state.createServiceTicket = initialState.createServiceTicket
        },

        clearServiceData: (state) => {
            state.listData = initialState.listData
            state.listDataFiltered = initialState.listDataFiltered
            state.selectedColumns = initialState.selectedColumns
            state.searchDataString = initialState.searchDataString
            state.advancedSearchData = initialState.advancedSearchData
            state.createServiceTicket = initialState.createServiceTicket
            state.loading = initialState.loading
        },
    },
    extraReducers: {
        [fetchService.pending]: (state) => {
            state.loading = true
        },
        [fetchService.fulfilled]: (state, { payload }) => {
            const data = payload?.serviceTickets?.map((ticket) => {
                let formatedTicket = formatingObjectToView(
                    ticket,
                    keysWithDateValues,
                    keysWithDateTimeValues
                )
                return { ...formatedTicket, key: nanoid() }
            })
            state.listData = data
            state.listDataFiltered = data
            state.advancedSearchData = initialState.advancedSearchData
            state.loading = false
        },
        [fetchService.rejected]: (state) => {
            state.loading = false
        },
        [postServiceTicket.pending]: (state) => {
            state.createServiceTicket.loading = true
        },
        [postServiceTicket.fulfilled]: (state) => {
            state.createServiceTicket.loading = false
            state.createServiceTicket.createServiceTicket = false
        },
        [postServiceTicket.rejected]: (state) => {
            state.createServiceTicket.loading = false
        },
        [getTerminalsOptions.fulfilled]: (state, { payload }) => {
            state.options.terminals = Object.entries(payload).map((el) => ({
                value: el[0],
                label: el[1],
            }))
        },
    },
})

export const {
    changeSelectedColumns,
    refreshSelectedColumns,
    searchData,
    advancedSearchServiceData,
    clearServiceData,
    closeAction,
    actionInit
} = serviceTicketsSlice.actions
export default serviceTicketsSlice.reducer