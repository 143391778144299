import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[shifts] requestGetDataPage')
export const getDataPageSuccess = createAction('[shifts] getDataPageSuccess')
export const getDataPageError = createAction('[shifts] getDataPageError')
// get one
export const requestGetOne = createAction('[shifts] requestGetOne')
export const successGetOne  = createAction('[shifts] successGetOne')
export const clearGetOne  = createAction('[shifts] clearGetOne')