import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/services/waiters';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestCreate, requestCreate),   
	takeLatest(actions.requestUpdate, requestUpdate),   
	takeLatest(actions.requestGetOne, requestGetOne),        
	takeLatest(actions.requestGetCreditCards, requestGetCreditCards),        
	takeLatest(actions.requestAddCreditCard, requestAddCreditCard),        
	takeLatest(actions.requestDelCreditCard, requestDelCreditCard),        
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestCreate(action) {
	try {  
		const data = yield call(API.create, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalCreate({...action.payload.request, id: data.id}))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalCreate(false))
	}
} 

// 
function* requestUpdate(action) {
	try {  
		const data = yield call(API.update, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetUpdate(action.payload.request))
	} catch (error) {   
		action.payload.callback(false)  
		yield put(actions.finalGetUpdate(false))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload.request);  
		action.payload.callback(data)  
		yield put(actions.finalGetOne(data))
	} catch (error) {   
		action.payload.callback({})  
		yield put(actions.finalGetOne({}))
	}
} 

// 
function* requestGetCreditCards(action) {
	try {  
		const data = yield call(API.getCreditCards, action.payload); 
		yield put(actions.finalGetCreditCards(data))
	} catch (error) {    
		yield put(actions.finalGetCreditCards([]))
	}
} 

// 
function* requestAddCreditCard(action) {
	try {  
		const data = yield call(API.addCreditCards, action.payload.request); 
		const id = (data && data.id) ? data.id : null
		yield put(actions.finalAddCreditCard({...action.payload.request, id}))
		action.payload.callback(id ? true : false) 
	} catch (error) {    
		yield put(actions.finalAddCreditCard(false))
		action.payload.callback(false) 
	}
} 

// 
function* requestDelCreditCard(action) {
	try {  
		const data = yield call(API.delCreditCard, action.payload.request); 
		yield put(actions.finalDelCreditCard({cardId: action.payload.request.cardId}))
		action.payload.callback(true) 
	} catch (error) {    
		yield put(actions.finalDelCreditCard(false))
		action.payload.callback(false) 
	}
} 


