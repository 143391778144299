import { createAction } from 'redux-act';

// --- точки ТСП
// get
export const requestPointsByApplicationId = createAction('[merchantPoints] requestPointsByApplicationId') 
export const successPointsByApplicationId = createAction('[merchantPoints] successPointsByApplicationId') 
export const clearPointsByApplicationId = createAction('[merchantPoints] clearPointsByApplicationId')
export const clearCacheDataPoints = createAction('[merchantPoints] clearCacheDataPoints')
// cache
export const requestUpdateCachePoints = createAction('[merchantPoints] requestUpdateCachePoints') 
// get ONE point
export const requestGetOnePoint = createAction('[merchantPoints] requestGetOnePoint') 
export const successGetOnePoint = createAction('[merchantPoints] successGetOnePoint') 
export const clearGetOnePoint = createAction('[merchantPoints] clearGetOnePoint')
// delete point 
export const requestDeletePoint = createAction('[merchantPoints] requestDeletePoint')  
// add / update
export const requestCreateUpdatePoint = createAction('[merchantPoints] requestCreateUpdatePoint')  
export const successCreateUpdatePoint = createAction('[merchantPoints] successCreateUpdatePoint')  
export const errorCreateUpdatePoint = createAction('[merchantPoints] errorCreateUpdatePoint')    
export const clearCreateUpdatePoint = createAction('[merchantPoints] clearCreateUpdatePoint')  
// get ActivitiesKinds 
export const requestGetActivitiesKinds = createAction('[merchantPoints] requestGetActivitiesKinds')  
export const successGetActivitiesKinds = createAction('[merchantPoints] successGetActivitiesKinds')
export const clearGetActivitiesKinds = createAction('[merchantPoints] clearGetActivitiesKinds')

export const setMCCPointsError = createAction('[merchantPoints] setMCCPointsError')

/** -- TMS --- */
// get Tms list
export const requestGetTmsList = createAction('[merchantPoints] requestGetTmsList')  
export const successGetTmsList = createAction('[merchantPoints] successGetTmsList')
export const clearGetTmsList = createAction('[merchantPoints] clearGetTmsList')
// update Tms list 
export const requestUpdateTmsList = createAction('[merchantPoints] requestUpdateTmsList')  
export const successUpdateTmsList = createAction('[merchantPoints] successUpdateTmsList')  
// update fully Tms list 
export const requestUpdateTmsListFully = createAction('[merchantPoints] requestUpdateTmsListFully')  
export const finalUpdateTmsListFully = createAction('[merchantPoints] finalUpdateTmsListFully') 
 // update current Tms list 
export const requestUpdateCurrentTmsList = createAction('[merchantPoints] requestUpdateCurrentTmsList')  
export const finalUpdateCurrentTmsList = createAction('[merchantPoints] finalUpdateCurrentTmsListTmsList')  
//    UpdateTmsListQuickReg 
export const requestUpdateTmsListQuickReg = createAction('[merchantPoints] requestUpdateTmsListQuickReg')  
export const successUpdateTmsListQuickReg  = createAction('[merchantPoints] successGetTmsConfigs')
// get  CheckDoublesSn
export const requestCheckDoublesSn = createAction('[merchantPoints] requestCheckDoublesSn')
export const finalCheckDoublesSn = createAction('[merchantPoints] finalCheckDoublesSn')
//  CheckTmsForWarning
export const requestCheckTmsForWarning = createAction('[merchantPoints] requestCheckTmsForWarning')
export const finalCheckTmsForWarning = createAction('[merchantPoints] finalCheckTmsForWarning')
// Get TmsHistory
export const requestGetTmsHistory = createAction('[merchantPoints] requestGetTmsHistory')
export const finalGetTmsHistory = createAction('[merchantPoints] finalGetTmsHistory')
// Download PDFHistory
export const requestDownloadPDFHistory = createAction('[merchantPoints] requestDownloadPDFHistory')
// get tms dowload files
export const requestGetTmsDownloadFiles = createAction('[merchantPoints] requestGetTmsDownloadFiles')
// download archive
export const requestDownloadFilesArchive = createAction('[merchantPoints] requestDownloadFilesArchive')

export const requestCheckTmsSerialNumber = createAction('[merchantPoints] requestCheckTmsSerialNumber')

export const requestUpdateBankResponse = createAction('[merchantPoints] requestUpdateBankResponse')

export const setItemsFromTerminalTab = createAction('[merchantPoints] setItemsFromTerminalTab')