export const formsMap = {
	'contactPerson': {translate: 'данные контактного лица', name: 'contactPerson'},
	'pretensionPerson': {translate: 'данные лица по претензионной работе', name: 'pretensionPerson'},
	'director': {translate: 'данные директора', name: 'director'},
	'signer': {translate: 'данные Подписанта', name: 'signer'},
	'main': {translate: 'основную информацию', name: 'main'},

	'documents': {translate: 'Файлы', name: 'documents'},
	'terminals': {translate: 'Терминалы', name: 'terminals'},
	'bankQuestions': {translate: 'Вопросы от банка', name: 'bankQuestions'},
};