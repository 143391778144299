import { createAction } from 'redux-act';


// get data
export const requestGetDataPage = createAction('[courierTransactions] requestGetDataPage')
export const getDataPageSuccess = createAction('[courierTransactions] getDataPageSuccess')
export const getDataPageError = createAction('[courierTransactions] getDataPageError')
// get couriers for search
export const requestGetCouriers = createAction('[courierTransactions] requestGetCouriers')
export const successGetCouriers = createAction('[courierTransactions] successGetCouriers')
// get fields For Search
export const requestGetFieldsForSearch = createAction('[courierTransactions] requestGetFieldsForSearch')
export const successGetFieldsForSearch  = createAction('[courierTransactions] successGetFieldsForSearch')
// request Download Csv Transaction
export const requestDownloadCsvTransaction = createAction('[courierTransactions] requestDownloadCsvTransaction')
export const successDownloadCsvTransaction   = createAction('[courierTransactions] successDownloadCsvTransaction')
// request  Banks For Transactions
export const requestBanksForTransactions = createAction('[courierTransactions] requestBanksForTransactions')
export const successBanksForTransactions   = createAction('[courierTransactions] successBanksForTransactions')
// request CancelTransaction
export const requestCancelTransaction= createAction('[courierTransactions] requestCancelTransaction')
export const finalCancelTransaction = createAction('[courierTransactions] finalCancelTransaction')
// request ConfirmCancelTransaction
export const requestConfirmCancelTransaction = createAction('[courierTransactions] requestConfirmCancelTransaction')
export const finalConfirmCancelTransaction = createAction('[courierTransactions] finalConfirmCancelTransaction')
// request DenyCancelTransaction
export const requestDenyCancelTransaction = createAction('[courierTransactions] requestDenyCancelTransaction')
export const finalDenyCancelTransaction = createAction('[courierTransactions] finalDenyCancelTransaction')
// request DownloadCheck
export const requestDownloadCheck = createAction('[courierTransactions] requestDownloadCheck') 