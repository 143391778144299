import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/statistics/reports';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetSelectFields, requestGetSelectFields),   
	takeLatest(actions.requestCreateData, requestCreateData),   
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestGetSubPartnersSelect, requestGetSubPartnersSelect),   
	takeLatest(actions.requestUpdateData, requestUpdateData),    
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 


// 
function* requestGetSelectFields(action) {
	try {  
		const data = yield call(API.getSelectFields, action.payload);  
		yield put(actions.successGetSelectFields(data))
	} catch (error) {   
		console.error(error);
		yield put(actions.successGetSelectFields({}))
	}
} 


// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload);  
		yield put(actions.finalGetOne(data))
	} catch (error) {   
		yield put(actions.finalGetOne({}))
	}
} 

// 
function* requestGetSubPartnersSelect(action) {
	try {  
		const data = yield call(API.getSubPartnersSelect, action.payload);  
		yield put(actions.finalGetSubPartnersSelect(data))
	} catch (error) {   
		yield put(actions.finalGetSubPartnersSelect({}))
	}
} 

// 
function* requestUpdateData(action) {
	try {  
		const data = yield call(API.updateData, action.payload.request);  
		action.payload.callback(true)
		yield put(actions.finalUpdateData(action.payload.request))
	} catch (error) {   
		action.payload.callback(false)
		yield put(actions.finalUpdateData())
	}
} 

// 
function* requestCreateData(action) {
	try {  
		const data = yield call(API.createData, action.payload.request);  
		action.payload.callback(true)
		yield put(actions.createDataFinish(action.payload.request))
	} catch (error) {   
		// console.error(error);
		action.payload.callback(false)
		yield put(actions.createDataFinish())
	}
} 
