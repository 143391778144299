import { Layout, Menu, Dropdown, Row, Button, Grid } from 'antd'
import {
    UserOutlined,
    TranslationOutlined,
    LogoutOutlined,
    DownOutlined,
	MenuUnfoldOutlined,
	MenuFoldOutlined,
} from '@ant-design/icons'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import ServiceDesk from '../service-desk/ServiceDesk'
import LoginAs from '../login-as/LoginAs'
import { i18n } from '../../core/utils/i18n/i18n'
import { selectPermissions, selectUserToken } from '../shares/helpers/commonReduxSelectors'
import styles from "./Layout.module.scss";
import classNames from 'classnames'

const { Header } = Layout

const { SubMenu } = Menu

const CustomHeader = ({ 
	onSettings, 
	onLogout, 
	onPartnerTemplatesEditor, 
	goToPartnersUpdate, 
	toggleSiderCollapsed,
	isCollapsed,
}) => {
	const permissions = useSelector(selectPermissions)
    const currentUser = useSelector(selectUserToken) 
	const { hideSupportButton } = useSelector(state => state.auth.user.userSettings)

    const handleClickItem = ({ key }) => {
        const currentLanguages = i18n.getLocale()
        switch (key) {
            case 'ru':
                currentLanguages !== 'ru' && i18n.setLocale('ru')
                break
            case 'en':
                currentLanguages !== 'en' && i18n.setLocale('en')
                break
            case 'profile':
                onSettings()
                break
            case 'companyProfile':
                goToPartnersUpdate()
                break
            case 'templates':
                onPartnerTemplatesEditor()
                break
            case 'logout':
                onLogout()
                break

            default:
                break
        }
    }

	return (
        <Suspense fallback={() => 'Loading...'}>
            <Header className={styles.header}>
                <Row align="middle" className={ classNames(styles.headerContent, {
					[styles.headerServiceDeskHidden]: hideSupportButton
				}) }>
					{
						isCollapsed 
						? <MenuUnfoldOutlined onClick={toggleSiderCollapsed} className={styles.menuToggle}/>
						: <MenuFoldOutlined onClick={toggleSiderCollapsed} className={styles.menuToggle}/>
					}
                    
					<div  className={styles.loginAs}>
						<LoginAs />
					</div>
					
					{!hideSupportButton &&  <ServiceDesk  className={styles.serviceDesk}/>}
                    <Dropdown
						className={styles.userMenu}
                        overlay={
                            <Menu onClick={handleClickItem}>
                                {permissions['users_own:read'] && (
                                    <Menu.Item
                                        icon={<UserOutlined />}
                                        key="profile"
                                    >{t`Profile`}</Menu.Item>
                                )}
                                {permissions['newLeadNotificationUsers:crud'] && (permissions['partners'] || permissions['partner:view']) && currentUser?.partner_id &&(
                                    <Menu.Item
                                        key="companyProfile"
                                    > {t`Сompany profile`}</Menu.Item>
                                )}                 
                                {currentUser.partner_id &&
                                currentUser.adminRoles?.['templates:view'] ? (
                                    <Menu.Item key="templates">{t`Templates`}</Menu.Item>
                                ) : null}
                                <SubMenu
                                    icon={<TranslationOutlined />}
                                    title={t`Langs`}
                                    key="languages"
                                >
                                    <Menu.Item key="ru">RU</Menu.Item>
                                    <Menu.Item key="en">EN</Menu.Item>
                                </SubMenu>
                                <Menu.Item
                                    icon={<LogoutOutlined />}
                                    key="logout"
                                >{t`Logout`}</Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="link" className={styles.loginBtn}>
                            {currentUser.adminLogin +
                                ` (${currentUser.adminId})`}{' '}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Row>
            </Header>
        </Suspense>
    )
}

export default CustomHeader
