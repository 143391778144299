import axios from 'axios'
import {downloadBlob} from '../apiTools'

export default {  
	getData: (data) => axios.get(`/lead_applications?page=${data.page}&elementsPerPage=${data.elementsPerPage}`, data).then(r => r.data),  
	create: (data) => axios.post(`/create_lead_application`, data).then(r => r.data),  
	createNoLogin: (data) => axios.post(`/lead_application_no_login`, data).then(r => r.data),  
	update: (data) => axios.post(`/update_lead_application`, data).then(r => r.data),  
	getOne: (data) => axios.post(`/lead_application/${data.id}`).then(r => r.data),  
	getGoodsServices: (data) => axios.post(`/models`).then(r => r.data),
	covertLeadToApp: (data) => axios.post(`/api/v2/convertLead`, data).then(r => r.data),
	covertLeadToAppOld: (data) => axios.post(`/convert_lead_application`, data).then(r => r.data),
	getTopWidgets: (data) => axios.post(`/statistic/leads`, data).then(r => r.data),
	// --- widgets --->
	getTotalLeads: (data) => axios.post(`/statistic/leads/total`, data).then(r => r.data),
	getNewLeads: (data) => axios.post(`/statistic/leads/new`, data).then(r => r.data),
	getInProgressLeads: (data) => axios.post(`/statistic/leads/inProgress`, data).then(r => r.data),
	getConvertedLeads: (data) => axios.post(`/statistic/leads/converted`, data).then(r => r.data),
	// --- files --->
	getLeadFiles: (data) => axios.get(`/api/v2/lead/${data.leadId}/files`).then(r => r.data), 
	uploadLeadFile: (data) => axios.post(`/api/v2/lead/uploadFiles`, data, {headers: { "content-type": "multipart/form-data" }}).then(r => r.data),
	downloadFile: (data) => axios.post(`/lead/${data.leadId}/file/${data.fileId}`, data, {responseType: 'blob'}
	).then(res => downloadBlob(res, data.fileName)), 
	downloadCashBoxFile: (data) => axios.get(`/api/v2/lead/${data.leadId}/file/${data.fileId}`, {data: {}, headers: { "accept": "application/octet-stream" }, responseType: 'blob'},
	).then(res => downloadBlob(res, data.fileName)), 
	

	personalData: (data) => axios.post(`/lead/request_personal_data`, data).then(r => r.data),
	closeLead: (data) => axios.post(`/lead/close`, data).then(r => r.data),
	sendNewComment: (data) => axios.post(`/lead/comment`, data).then(r => r.data),

	updateResponsibleUser: (data) => axios.post(`/lead/updateResponsibleUser`, data).then(r => r.data)
}