
import waitersList from './waitersList/saga';   
import couriersList from './couriersList/saga';   
import courierTransactions from './courierTransactions/saga';   
 
export default [  
  ...waitersList,    
  ...couriersList,    
  ...courierTransactions,    
]
 