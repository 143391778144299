import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import API from '../../../../api/tct/points';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetTcpSelect, requestGetTcpSelect),   
	takeLatest(actions.requestGetOnePoint, requestGetOnePoint),   
	takeLatest(actions.requestCreateUpdatePointTct, requestCreateUpdatePointTct),   
	takeLatest(actions.requestGetAllowedMerchantBanks, requestGetAllowedMerchantBanks),
	takeEvery(actions.requestUploadFileTst, requestUploadFileTst),
]

//
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
}

//
function* requestGetTcpSelect(action) {
	try {  
		const data = yield call(API.getTcpSelect, action.payload);  
		yield put(actions.successGeTcptSelect(data))
	} catch (error) {   
		yield put(actions.successGeTcptSelect([]))
	}
}

//
function* requestGetOnePoint(action) {
	try {  
		const data = yield call(API.getGetOnePoint, action.payload);  
		yield put(actions.successGetOnePoint(data))
	} catch (error) {   
		yield put(actions.successGetOnePoint({}))
	}
}

//
function* requestCreateUpdatePointTct(action) {
	try {  
		const data = yield call(API.сreateUpdatePointTct, action.payload.request);  
		yield put(actions.successCreateUpdatePointTct(data))
		action.payload.callback(data)
	} catch (error) {   
		console.log(error);
		yield put(actions.successCreateUpdatePointTct({}))
		action.payload.callback()
	}
}

//
function* requestGetAllowedMerchantBanks(action) {
	try {
		const data = yield call(API.getAllowedMerchantBanks, action.payload);
		yield put(actions.finalGetAllowedMerchantBanks(data))
	} catch (error) {
		yield put(actions.finalGetAllowedMerchantBanks({}))
	}
}

//
function* requestUploadFileTst(action) {
	try { 
		const data = yield call(API.uploadFileTst, action.payload.request);
		if(data) action.payload.callback(action.payload.request.tstList[0])
		else action.payload.callback(false)
	} catch (error) { 
		action.payload.callback(false)
	}
}
