import { createAction } from 'redux-act';
 
// Login
export const requestLogin = createAction('[auth] requestLogin') 
export const successLogin = createAction('[auth] successLogin') 
export const errorLogin = createAction('[auth] errorLogin')
// check HashRecoveryPass 
export const requestCheckHashRecoveryPass = createAction('[auth] requestCheckHashRecoveryPass') 
// ChangePass 
export const requestChangePass = createAction('[auth] requestChangePass') 
export const successChangePass = createAction('[auth] successChangePass') 
export const errorChangePass = createAction('[auth] errorChangePass')
// recoveryPass 
export const requestRecoveryPass = createAction('[auth] requestRecoveryPass') 
export const successRecoveryPass = createAction('[auth] successRecoveryPass') 
export const errorRecoveryPass = createAction('[auth] errorRecoveryPass')
// get code again
export const requestGetCodeAgain = createAction('[auth] requestGetCodeAgain') 
export const finalGetCodeAgain  = createAction('[auth] finalGetCodeAgain')  

export const firstTimeChangePassword = createAction("[auth] firstTimeChangePassword")
export const serviceResetPassword = createAction('[auth] serviceResetPassword')

export const savePathname = createAction('[auth] savedPathname')