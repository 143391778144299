import React from 'react'
import { Button, Tag } from 'antd';
import { EyeTwoTone } from '@ant-design/icons';
import customHistory from '../../history';
import { generatePhoneWithCode } from '../shares/helpers/generatePhoneWithCode';
import moment from 'moment';

const ViewDetails = ({ record }) => {
    const handleEdit = () => {
        const { pathname } = customHistory.location
            if (record.id) {
                customHistory.push(`${pathname}/${record.id}`)
            }
    }

    return <Button type="text" icon={ <EyeTwoTone /> } onClick={ handleEdit } />
}

const columns = [
    {
        title: 'Статус',
        key: 'enable',
        align: 'center',
        sorter: (a, b) => {
            if (a.enable > b.enable) {
              return 1;
            }
            if (a.enable < b.enable) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        render: (_, render) => {
            return <span>
                <Tag color={ render.enable ? "green" : "red" }>{ render.enable ? "Активен" : "Неактивен" }</Tag>
            </span>
        }
    },
    {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        align: 'center',
        width: "110px",
        sorter: (a, b) => {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
                return -1;
            }
            return 0;
        },
    },
    {
        title: 'ИНН',
        dataIndex: 'TIN',
        key: 'TIN',
    },
    {
        title: 'Клиент',
        dataIndex: 'businessName',
        key: 'businessName',
        sorter: (a, b) => {
            if (a.businessName > b.businessName) {
                return 1;
            }
            if (a.businessName < b.businessName) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
    },
    {
        title: 'Город',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => {
            if (a.city > b.city) {
                return 1;
            }
            if (a.city < b.city) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        
    },
    {
        title: 'Контакт',
        dataIndex: 'contact',
        key: 'contact',
        sorter: (a, b) => {
            if (a.contact > b.contact) {
                return 1;
            }
            if (a.contact < b.contact) {
                return -1;
            }
            // a должно быть равным b
            return 0;
        },
        // optional: true
    },
    {
        title: 'Партнер',
        dataIndex: 'partnerName',
        key: 'partnerName',
		hidden: true,
    },
    {
        title: 'Телефон',
        key: 'businessPhone',
        render: (data) => generatePhoneWithCode(data.businessPhone, data.businessPhoneCode)
    },
    {
        title: "Дата регистрации мерчанта",
        dataIndex: "cdat",
        key: "cdat",
        sorter: (a, b) => {
            const first = +moment(a.cdat, "lll")
            const second = +moment(b.cdat, "lll")
            if (first > second) {
                return 1
            }
            if (first < second) {
                return -1
            }
            return 0
        },
    },
    {
        title: 'Действия',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
            <ViewDetails record={ record } />
        )
    },
];

export default columns