import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = {  
  data: [],
  loading: true,
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  { 
    // --- * GetFileList
    [actions.requestGetFileList]: (state, action) => ({
      ...state,  data: initialState.data, loading: true
    }),   
    [actions.finalGetFileList]: (state, action) => ({
      ...state,  data: action, loading: false
    }),     

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
