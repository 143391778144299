import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import merchantsAPI from '../../api/v2/customersAPI'
import posesAPI from '../../api/v2/posesAPI';
import reportsAPI from '../../api/v2/reportsAPI';
import applicationsChangeDataAPI from '../../api/v2/applicationsChangeDataAPI';
import dictsAPI from '../../api/v2/dictsAPI';

export const requiredAddressFields = {
    postCode: true,
    region: true,
    // settlementType: true,
    // settlementName: true,
    // streetType: true,
    // streetName: true,
    // plot: true
}

export const getApplicationData = createAsyncThunk(
    "application-change-data/applicationData/get",
    async (applicationId) => {
        const response = await applicationsChangeDataAPI.getApplicationData(applicationId)
        return response
    }
)

export const getMerchantData = createAsyncThunk(
    "application-change-data/merchantData/get",
    async (merchantId) => {
        const response = await merchantsAPI.getOneForRecord(merchantId)
        return response
    }
)

export const getMerchantPointOptions = createAsyncThunk(
    "application-change-data/merchantPoints/get",
    async (data) => {
        const response = await applicationsChangeDataAPI.getMerchantPointsData(data)
        return response
    }
)


export const getAddressSplitData = createAsyncThunk(
    "application-change-data/addressSplitData/get",
    async (data) => {
        const response = await reportsAPI.getAddressSplitData(data)
        return response
    }
)

export const getBanksForMerchant = createAsyncThunk(
    "application-change-data/getBanksForMerchant/get",
    async (merchantId) => {
        const response = await applicationsChangeDataAPI.getBanksForMerchant(merchantId)
        return response
    }
)

const createFinalDataToServer = (data, merchantPointAddress, id = '') => {
    const dataTypesToChange = data.dataTypesToChange.reduce((newObj, key) => ({...newObj, [key]: true}), {})
    const conditionallyRequiredAddressFields = {
        ...requiredAddressFields,
        streetName: !!merchantPointAddress['streetType'],
        plot: !!merchantPointAddress['houseType'],
        building:!!merchantPointAddress['blockType'],
        unit: !!merchantPointAddress['flatType']
    }

    console.log(conditionallyRequiredAddressFields);

    if (dataTypesToChange.changePointAddress) {
        for (let key in conditionallyRequiredAddressFields) {
            if (conditionallyRequiredAddressFields[key] && !merchantPointAddress[key]) {
                throw new Error('Не все поля в адресе заполнены')
            }
        }
    }
    return {...data, ...dataTypesToChange, merchantPointAddress, id}
}

export const createChangeDataApplication = createAsyncThunk(
    "application-change-data/createChangeDataApplication/post",
    async (data, thunkAPI) => {
        try {
            const finalData = createFinalDataToServer(data, thunkAPI.getState().applicationChangeData.merchantPointAddress.address)
            const response = await applicationsChangeDataAPI.createChangeDataApplication(finalData)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateChangeDataApplication = createAsyncThunk(
    "application-change-data/updateChangeDataApplication/post",
    async (data, thunkAPI) => {
        const finalData = createFinalDataToServer(data, thunkAPI.getState().applicationChangeData.merchantPointAddress.address, thunkAPI.getState().applicationChangeData.applicationData.id)
        const response = await applicationsChangeDataAPI.updateChangeDataApplication(finalData)
        return response
    }
)

export const sendTspChangeDataApplication = createAsyncThunk(
    "application-change-data/sendTspChangeDataApplication/post",
    async (data, thunkAPI) => {
        try {
            const finalData = createFinalDataToServer(data, thunkAPI.getState().applicationChangeData.merchantPointAddress.address, thunkAPI.getState().applicationChangeData.applicationData.id)
            const response = await applicationsChangeDataAPI.sendTspChangeDataApplication(finalData)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const sendPrepareTerminalsChangeDataApplication = createAsyncThunk(
    "application-change-data/sendPrepareTerminalsChangeDataApplication/post",
    async (data, thunkAPI) => {
        try {
            const finalData = createFinalDataToServer(data, thunkAPI.getState().applicationChangeData.merchantPointAddress.address, thunkAPI.getState().applicationChangeData.applicationData.id)
            const response = await applicationsChangeDataAPI.prepareTerminals(finalData)
            return response
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const confirmChangeDataApplication = createAsyncThunk(
    "application-change-data/confirmTspChangeDataApplication/post",
    async (applicationId) => {
        const response = await applicationsChangeDataAPI.confirmTspChangeDataApplication(applicationId)
        return response
    }
)

export const refuseChangeDataApplication = createAsyncThunk(
    "application-change-data/refuseTspChangeDataApplication/post",
    async (data) => {
        const response = await applicationsChangeDataAPI.refuseTspChangeDataApplication(data)
        return response
    }
)

export const getMerchantsOptions = createAsyncThunk(
    "application-change-data/get-merchants",
    async (data) => {
        const response = await dictsAPI.getMerchantsDict(data)
        return response
    }
);

const initialState = {
    options: {
        merchantLoading: false,
        merchantSuccess: false,
        merchantOptions: [],
        merchantPointOptions: [],
        optionsLoading: true
    },
    merchantData: {
        bic: '',
        bankName: '',
        currentAccount: '',
        kpp: '',
        corrAccount: '',
    },
	merchantPoints: [],
	currentMerchantPoint: {},
    banksForMerchant: [],
    merchantPointAddress: {
        addressOptions: [],
        address: {},
        loading: false
    },
    applicationData: {},
    loading: false,
}


const ApplicationChangeDataSlice = createSlice({
    name: "application-change-data",
    initialState,
    reducers: {
        addActualAddress: (state, { payload }) => {
            state.merchantPointAddress.address = payload
        },
        setCurrentMerchantPoint: (state, { payload }) => {
            state.currentMerchantPoint = payload
        },
        refreshSearchAddress: (state) => {
            state.merchantPointAddress.address = initialState.merchantPointAddress.address
        },
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
		[getMerchantsOptions.pending]: (state, { payload }) => {
            state.options.merchantLoading = true
			state.options.merchantSuccess = false
        },
		[getMerchantsOptions.rejected]: (state, { payload }) => {
            state.options.merchantLoading = false
        },
		[getMerchantsOptions.fulfilled]: (state, { payload }) => {
            state.options.merchantOptions = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.merchantLoading = false
			state.options.merchantSuccess = true
        },
        [getMerchantPointOptions.pending]: (state) => {
            state.options.optionsLoading = true
        },
        [getMerchantPointOptions.fulfilled]: (state, { payload }) => {
            const data = payload.data 
				? payload.data
					.map(item => {
						const addressString = item?.addressConfigMap?.params?.total || item?.addressText
						return {value: +item.id, label: `${item.name} ${addressString ? ` (${addressString})` : '' }`}
					}) 
				: []
            state.options.merchantPointOptions = data
            state.merchantPoints = payload.data.map(el => {
				const address = el?.addressConfigMap?.params || {}
				return {
					...el,
					addressConfigMap: {
						params: {
							postCode: address?.postalCode,
							region:address?.region,
							area:address?.area,
							city: address?.city,
							settlementName:address?.locality,
							settlementType:address?.cityType,
							streetType:address?.streetType,
							streetName:address?.street,
							plot:address?.house,
							houseType:address?.houseType,
							building:address?.block,
							blockType:address?.blockType,
							flatType:address?.flatType,
							unit:address?.office,
							country:address?.country,
							total:address?.total,
						}
					}
				}
			})
            state.options.optionsLoading = false
			if(state.applicationData?.id){
				state.currentMerchantPoint = payload?.data?.find(el => el.id === state.applicationData?.merchantPointId) || {}
			}
        },
        [getMerchantPointOptions.rejected]: (state) => {
            state.options.optionsLoading = false
        },
        [getMerchantData.pending]: (state) => {
            state.loading = true
        },
        [getMerchantData.fulfilled]: (state, { payload }) => {
            state.merchantData = payload
            state.loading = false
        },
        [getMerchantData.rejected]: (state) => {
            state.loading = false
        },
        [getAddressSplitData.pending]: (state) => {
            state.merchantPointAddress.loading = true
        },
        [getAddressSplitData.fulfilled]: (state, { payload = [] }) => {
            state.merchantPointAddress.addressOptions = payload.map(item => ({value: item.total}))
            state.merchantPointAddress.address = payload[0] ? payload[0] : state.merchantPointAddress.address
            state.merchantPointAddress.loading = false
        },
        [getAddressSplitData.rejected]: (state) => {
            state.merchantPointAddress.loading = false
        },
        [createChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [createChangeDataApplication.fulfilled]: (state) => {
            state.loading = false
        },
        [createChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
        [getBanksForMerchant.pending]: (state) => {
            state.loading = true
        },
        [getBanksForMerchant.fulfilled]: (state, { payload }) => {
            // Ограничение по банкам, с которыми есть интеграция
            state.banksForMerchant = payload ? payload.filter(item => ['TINKOFF', 'VTB', 'VTBNew', 'PSB'].includes(item.beanName)).map(bank => ({value: bank.id, label: bank.name})) : []
            state.loading = false
        },
        [getBanksForMerchant.rejected]: (state) => {
            state.loading = false
        },
        [getApplicationData.pending]: (state) => {
            state.loading = true
        },
        [getApplicationData.fulfilled]: (state, { payload }) => {
            state.applicationData = payload
            state.merchantPointAddress.address = payload.merchantPointAddress ? payload.merchantPointAddress : {}
            state.loading = false
        },
        [getApplicationData.rejected]: (state) => {
            state.loading = false
        },
        [updateChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [updateChangeDataApplication.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [updateChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
        [sendTspChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [sendTspChangeDataApplication.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [sendTspChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
        [sendPrepareTerminalsChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [sendPrepareTerminalsChangeDataApplication.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [sendPrepareTerminalsChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
        [confirmChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [confirmChangeDataApplication.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [confirmChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
        [refuseChangeDataApplication.pending]: (state) => {
            state.loading = true
        },
        [refuseChangeDataApplication.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [refuseChangeDataApplication.rejected]: (state) => {
            state.loading = false
        },
    }
});

export const {
    addActualAddress,
    refreshSearchAddress,
    setCurrentMerchantPoint,
    clearFormData,
} = ApplicationChangeDataSlice.actions
export default ApplicationChangeDataSlice.reducer