import React, { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Layout } from 'antd'
import { useQuery } from 'react-query'
import Menu from './Menu'
import CustomHeader from './CustomHeader'
import Banners from '../banners/Banners'
import LoadingPage from '../shares/components/LoadingPage'
import routes from '../../routes'
import auth from '../../api/auth/auth'
import { requestLogout } from '../../store/auth/logout/actions'
import { finalrequestGetAllRoles, userSettings } from '../../store/auth/user/actions'
import { successGetAllDataForTmsConfigs,
        successGetBanks,
        successGetDictBanks,
        successGetPayments,
} from '../../store/core/dict/actions'
import { changeIsStartAppNimation } from '../../store/core/connection/actions'
import dictsAPI from '../../api/core/dict'
import { selectPermissions } from '../shares/helpers/commonReduxSelectors'
import applicationsAPI from '../../api/v2/applicationsAPI'
import rolesAPI from '../../api/v2/rolesAPI'
import { useState } from 'react'
import styles from "./Layout.module.scss";
import classNames from 'classnames'

/** Проверка ролей юзера. Если массив - то проверяется каждое право в массиве у данного юза */
/** bannedPermission - Если право есть, раздел\заявка недоступны */
export const checkPermissions = (route, userPerm) => {

	const { bannedPermission , permission } = route;

    const isArray = Array.isArray(permission)
    if (!userPerm[permission] && !isArray) {
        return false
    }  
    if (bannedPermission && userPerm[bannedPermission]) {
        return false
    }  
	if (isArray) {
        return permission.some((el) => userPerm[el])
    } 
    return true
    
}

const DefaultLayout = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isCollapsed, setIsCollapsed] = useState(false)

	const toggleSiderCollapsed = () => {
		setIsCollapsed(prev => !prev)
	};

    const {adminId: userId, partner_id} = useSelector((state) => state.auth.user.userToken)

    const partnerPermission = useSelector(selectPermissions)

    const isStartAppNimation = useSelector(
        (state) => state.core.connection.isStartAppNimation
    )

    const hasOriginalAdminId = useSelector(
        (state) => state.auth.user.userToken.originalAdminId
    )

    useQuery('permissions', auth.getAllRoles, {
        suspense: true,
        enabled: Boolean(userId),
        retry: 0,
        onSuccess: (data) => {
            dispatch(finalrequestGetAllRoles(data))
        },
    })

    useQuery('getAllDataForTmsConfigs', dictsAPI.getAllDataForTmsConfigs, {
        suspense: true,
        enabled: Boolean(userId),
        retry: 0,
        onSuccess: (data) => {
            dispatch(successGetAllDataForTmsConfigs(data))
        },
    })

    useQuery('getAllPaymentSystems', dictsAPI.getPayments, {
        suspense: true,
        enabled: Boolean(userId),
        retry: 0,
        onSuccess: (data) => {
            dispatch(successGetPayments(data))
        },
    })

	useQuery('getUserSettings', () => rolesAPI.getUserSettings(), {
		suspense: true,
        retry: 0,
        onSuccess: (data) => {
			if(data?.data) dispatch(userSettings(data.data))
        },
    })

	/** 
		applicationsAPI.getBanksForApplication - список банков для заявок
		dictsAPI.getBanksDict - список всех банков
	*/
    useQuery('getBanksForApp', applicationsAPI.getBanksForApplication, {
        suspense: true,
        enabled: Boolean(userId),
        retry: 0,
        onSuccess: (data) => {
            dispatch(successGetBanks(data))
        },
    })
    useQuery('getAllBanks', dictsAPI.getBanksDict, {
        suspense: true,
        enabled: Boolean(userId),
        retry: 0,
        onSuccess: (data) => {
            dispatch(successGetDictBanks(data))
        },
    })

    const logout = () => {
        dispatch(requestLogout())
    }

    const settings = () => {
        history.push(`/users/view/${userId}`)
    }

    const partnerTemplatesEditor = () => {
        history.push(`/templates`)
    }

    const goToPartnersUpdate = () => {
        history.push(`/partners/update/${partner_id}`)
    }

    const move = (e, uri) => {
        history.push(uri)
    }

    useEffect(() => {
        if (localStorage.getItem('user')) {
            let data = JSON.parse(localStorage.getItem('user'))
            if (data.needPasswordReset) {
                history.push(`/changePassword`)
            }
        }
    }, [history])

    useEffect(() => {
        if (Object.keys(partnerPermission).length) {
            dispatch(changeIsStartAppNimation(false))
        }
    }, [dispatch, partnerPermission])

    if (isStartAppNimation) {
        return <LoadingPage />
    }

    return (
        <Layout className='h-full'>
            <Suspense fallback={null}>
                <Menu 
					isCollapsed={isCollapsed} 
					setIsCollapsed={setIsCollapsed} 
					toggleSiderCollapsed={toggleSiderCollapsed}
				/>
            </Suspense>

            {partnerPermission['graphics'] && <Banners />}

            <Suspense fallback={<LoadingPage />}>
                <Layout >
                    <CustomHeader
                        onSettings={settings}
                        goToPartnersUpdate={goToPartnersUpdate}
                        onMove={move}
                        onLogout={logout}
                        onPartnerTemplatesEditor={partnerTemplatesEditor}
                        toggleSiderCollapsed={toggleSiderCollapsed}
						isCollapsed={isCollapsed}
                    />

                    {hasOriginalAdminId && (
                        <Alert
                            message="Вы вошли в интерфейс под другим пользователем. Не забудьте выйти, когда выполните действия от данного пользователя!"
                            banner
                        />
                    )}
                    <Layout.Content className={classNames("h-full p-2 overflow-auto", styles.mainLayout)}>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component &&
                                    checkPermissions(
                                        route,
                                        partnerPermission
                                    ) ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact
                                        name={route.name}
                                        render={(props) => (
                                            <route.component
                                                {...props}
                                                adminRoles={partnerPermission}
                                                permissionPrefix={
                                                    route.permission
                                                }
                                            />
                                        )}
                                    />
                                ) : null
                            })}

                            {/* if page not found */}
                            <Redirect 
								from="*"  
								to={{
									pathname: "/login",
									state: { doLogout: true }
								}}
							/>
                        </Switch>
                    </Layout.Content>
                </Layout>
            </Suspense>
        </Layout>
    )
}

export default DefaultLayout
