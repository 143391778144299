import * as actions from "./actions";
import { createReducer } from "redux-act"; 


const initialState = {  
  errors: []
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer(
  {
    [actions.setErrors]: (state, action) => {
        console.log(action)
    },   
    [actions.clearErrors]: (state, action) => ({
      ...initialState
    }), 

    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
