import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dictsAPI from '../../api/v2/dictsAPI'
import usersAPI from '../../api/v2/usersAPI'
import rolesAPI from '../../api/v2/rolesAPI'
import { gettext } from 'ttag'


export const fetchCreateUser = createAsyncThunk(
    "user-create/create/post",
    async (payload, thunkAPI) => {
        const req = {...payload, ...thunkAPI.getState().userCreate.contactPerson}
        const response = await usersAPI.create(req)
        return response
    }
)

export const getCustomersOptions = createAsyncThunk(
    "user-create/customers/get",
    async (partnerId) => {
        if (partnerId) {
            const response = await dictsAPI.getMerchantsForPartner(partnerId)
            return response
        }
        const response = await dictsAPI.getMerchantsDict()
        return response
    }
)

export const validateUserLogin = createAsyncThunk(
    "user-create/loginValidate/get",
    async (login) => {
        if (!login) {
            return false
        }
        const response = await usersAPI.validateLoginExist(login)
        return response
    }
)

export const getRolesOptions = createAsyncThunk(
    "user-create/roles/get",
    async ({merchantId = null, partnerId = null}) => {
        if (merchantId) {
            const response = await rolesAPI.getMerchantRoles(merchantId)
            return response
        } else if (partnerId) {
            const response = await rolesAPI.getPartnerRoles(partnerId)
            return response
        } else {
            const response = await rolesAPI.getUserRoles()
            return response
        }
    }
)

export const getRolesListOptions = createAsyncThunk(
    "user-create/rolesList/get",
    async () => {
        const response = await rolesAPI.getUserRolesList()
        return response
    }
)

export const getPartnersOptions = createAsyncThunk(
    "user-create/partners/get",
    async () => {
		
        const response = await dictsAPI.getPartners()
        return response
    }
)

export const getUsersOptions = createAsyncThunk(
    "user-create/users/get",
    async (userRole) => {
        const response = await dictsAPI.getUsers(userRole)
        return response
    }
)

const initialState = {
    contactPerson: {},
    options: {
        customers: [],
        roles: [],
        rolesList: [],
        partners: [],
        users: []
    },
    loginCheck: {
        valid: false,
        loading: false
    },
    loading: false
}

const userCreateSlice = createSlice({
    name: "user-create",
    initialState,
    reducers: {
        addContactPersonData: (state, { payload }) => {
            state.contactPerson = payload
        },
        clearFormData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key];
                }
            }
        }
    },
    extraReducers: {
        [fetchCreateUser.pending]: (state) => {
            state.loading = true
        },
        [fetchCreateUser.fulfilled]: (state) => {
            state.loading = false
        },
        [fetchCreateUser.rejected]: (state) => {
            state.loading = false
        },
        [getCustomersOptions.fulfilled]: (state, { payload }) => {
            state.options.customers = Object.entries(payload).map(el => ({ value: el[0], label: el[1] }))
        },
        [getCustomersOptions.reject]: (state) => {
            state.options.customers = []
        },
        [getRolesOptions.fulfilled]: (state, { payload }) => {
            state.options.roles = Object.entries(payload).map(el => ({ value: el[0], label: el[1] }))
        },
        [getRolesOptions.reject]: (state) => {
            state.options.roles = []
        },
        [getRolesListOptions.fulfilled]: (state, { payload }) => {
            state.options.rolesList = Object.entries(payload).map(el => ({ value: el[1], label: gettext(el[1]) }))
        },
        [getRolesListOptions.reject]: (state) => {
            state.options.rolesList = []
        },
        [getPartnersOptions.fulfilled]: (state, { payload }) => {
            state.options.partners = Object.entries(payload).map(el => ({ value: el[0], label: el[1] }))
        },
        [getPartnersOptions.reject]: (state) => {
            state.options.partners = []
        },
        [getUsersOptions.fulfilled]: (state, { payload }) => {
            state.options.users = Object.entries(payload.data).map(el => ({ value: el[0], label: el[1] }))
        },
        [getUsersOptions.reject]: (state) => {
            state.options.users = []
        },
        [validateUserLogin.pending]: (state) => {
            state.loginCheck.loading = true
        },
        [validateUserLogin.fulfilled]: (state, {payload}) => {
            state.loginCheck.valid = !payload
            state.loginCheck.loading = false
        },
        [validateUserLogin.rejected]: (state) => {
            state.loginCheck.loading = false
        },
    }
});

export const {
    addContactPersonData,
    clearFormData,
} = userCreateSlice.actions
export default userCreateSlice.reducer