import newLead from './newLead/reducer';  
import newMerch from './newMerch/reducer';  
import faq from './faq/reducer';  
import news from './news/reducer';  
import additionalvalidation from './additionalvalidation/reducer';
import tmsValidation from './tmsValidation/reducer';


export default (state={}, action) => {  
	return {
		newLead: newLead(state.newLead, action),  
		newMerch: newMerch(state.newMerch, action),   
		faq: faq(state.faq, action),  
		news: news(state.news, action), 
		additional_validation:  additionalvalidation(state.additional_validation, action),
		tmsValidation: tmsValidation(state.tmsValidation, action)
	} 
} 