export const changeFalsyValuesToNull = (obj) => {
	if(!obj || typeof obj !== 'object') return obj;

	return Object.keys(obj).reduce((acc, key) => {
		if (obj[key] === undefined || obj[key] === '') {
			acc[key] = null
		} else {
			acc[key] = obj[key]
		}
  
	 	return acc
	}, {})
}