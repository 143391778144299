import * as actions from "./actions";
import { createReducer } from "redux-act";
// import { FD_REPORTS_fieldsForSelect, FD_REPORTS_subPartnersSelect } from "../../../../core/utils/filters/FormatConverter";


const initialState = {
  // -- widgets -->
  cashFlow: {
    loading: false,
    data: {}
  }, 
  purchasesCount: {
    loading: false,
    data: false
  }, 
  refundsCount: {
    loading: false,
    data: false
  }, 
  reversalsCount: {
    loading: false,
    data: false
  }, 
  // <---
  topWidgets: {
    loading: true,
    data: {}
  },
  countTransactions: {
    loading: false,
    data: []
  },
  countShifts: {
    loading: false,
    data: []
  },
  paramsForFilters: {
    loading: false,
    data: {}
  },
  tspIntensity: {
    loading: false,
    data: {
      money: [],
      count: []
    }
  },
  tspRejected: {
    loading: false,
    data: {
      completed: [],
      rejected: []
    }
  },
  totalErrors: {
    loading: false,
    data: []
  },
  totalAmount: {
    loading: false,
    data: {
      successfully: [],
      unsuccessfully: [],
      averageCheck: [],
    }
  },
};

/** action - тут хранится прямой ответ из экшена как он и был где либо записан */
export default createReducer({

    // -- widgets -->
    // reversalsCount
    [actions.requestGetReversalsCount]: (state, action) => ({
      ...state, reversalsCount: {...initialState.reversalsCount, loading: true}
    }),
    [actions.finalGetReversalsCount]: (state, action) => {
      return { ...state, reversalsCount: {...state.reversalsCount, loading: false, data: action}}
    },
    // cashFlow
    [actions.requestGetCashFlow]: (state, action) => ({
      ...state, cashFlow: {...initialState.cashFlow, loading: true}
    }),
    [actions.finalGetCashFlow]: (state, action) => {
      return { ...state, cashFlow: {...state.cashFlow, loading: false, data: action}}
    },
    // refundsCount
    [actions.requestGetRefundsCount]: (state, action) => ({
      ...state, refundsCount: {...initialState.refundsCount, loading: true}
    }),
    [actions.finalGetRefundsCount]: (state, action) => {
      return { ...state, refundsCount: {...state.refundsCount, loading: false, data: action}}
    },
    //  purchasesCount
    [actions.requestGetPurchasesCount]: (state, action) => ({
      ...state, purchasesCount: {...initialState.purchasesCount, loading: true}
    }),
    [actions.finalGetPurchasesCount]: (state, action) => {
      return { ...state, purchasesCount: {...state.purchasesCount, loading: false, data: action}}
    },
    // <---



    // --- * get  TopWidgets ->>
    [actions.requestGetTopWidgets]: (state, action) => ({
      ...state, topWidgets: {...initialState.topWidgets, loading: true}
    }),
    [actions.finalGetTopWidgets]: (state, action) => {
      return { ...state, topWidgets: {...state.topWidgets, loading: false, data: action},
        'cashFlow': {...state.cashFlow, data: action.totalTurnover},
        'purchasesCount': {...state.purchasesCount, data: action.purchasesCount},
        'refundsCount': {...state.refundsCount, data: action.refundsCount},
        'reversalsCount': {...state.reversalsCount, data: action.reversalsCount},
      }
    },
  
    // --- * get   CountTransactions ->>
    [actions.requestGetCountTransactions]: (state, action) => ({
      ...state, countTransactions: {...initialState.countTransactions, loading: true}
    }),
    [actions.finalGetCountTransactions]: (state, action) => {
      return { ...state, countTransactions: {...state.countTransactions, loading: false, data: action}}
    },
  
    // --- * get CountShifts ->>
    [actions.requestGetCountShifts]: (state, action) => ({
      ...state, countShifts: {...initialState.countShifts, loading: true}
    }),
    [actions.finalGetCountShifts]: (state, action) => {
      return { ...state, countShifts: {...state.countShifts, loading: false, data: action}}
    },
  
    // --- * get tspIntensity ->>
    [actions.requestGetTspIntensity]: (state, action) => ({
      ...state, tspIntensity: {...initialState.tspIntensity, loading: true}
    }),
    [actions.finalGetTspIntensity]: (state, action) => {
      return { ...state, tspIntensity: {...state.tspIntensity, loading: false, data: action}}
    },
  
    // --- * get tspIntensity ->>
    [actions.requestGetTspRejected]: (state, action) => ({
      ...state, tspRejected: {...initialState.tspRejected, loading: true}
    }),
    [actions.finalGetTspRejected]: (state, action) => {
      return { ...state, tspRejected: {...state.tspRejected, loading: false, data: action}}
    },
  
    // --- * get totalErrors ->>
    [actions.requestGetTotalErrors]: (state, action) => ({
      ...state, totalErrors: {...initialState.totalErrors, loading: true}
    }),
    [actions.finalGetTotalErrors]: (state, action) => {
      return { ...state, totalErrors: {...state.totalErrors, loading: false, data: action}}
    },
  
    // --- * get totalAmount ->>
    [actions.requestTotalAmount]: (state, action) => ({
      ...state, totalAmount: {...initialState.totalAmount, loading: true}
    }),
    [actions.finalTotalAmount]: (state, action) => {
      return { ...state, totalAmount: {...state.totalAmount, loading: false, data: action}}
    },
  
    // --- * get ParamsForFilters ->>
    [actions.requestGetParamsForFilters]: (state, action) => ({
      ...state, paramsForFilters: {...initialState.paramsForFilters, loading: true}
    }),
    [actions.finalGetParamsForFilters]: (state, action) => {
      return { ...state, paramsForFilters: {...state.paramsForFilters, loading: false, data: action}}
    },
    
    LOGOUT_FROM_SYSTEM: (state, action) => initialState
  },
  initialState
);
