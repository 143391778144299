import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"
import {
    keysWithDateValues,
    keysWithDateTimeValues,
} from "./objectKeysWithDateValue"
import isVoidObject from "../shares/helpers/isVoidObject"
import formatingObjectToView from "../shares/helpers/formatingObjectForView"
import terminalsAPI from "../../api/v2/terminalsAPI"
import dict from "../../api/core/dict"
import dictsAPI from "../../api/v2/dictsAPI"

export const fetchTerminals = createAsyncThunk(
    "terminals/list",
    async (_, { getState }) => {

		const isSuperUser = getState().auth.user.userToken.adminRoles["partners_create:fromPortal"]
        const { advancedSearchData, pagination  } = getState().terminals;
		if(isSuperUser && isVoidObject(advancedSearchData)) return;

		const response = await terminalsAPI.getDataByAdvancedSearch({
			...advancedSearchData,
			page: pagination?.page,
			size: pagination?.size,
		})
		return response
    }
)

export const getTerminalsOptions = createAsyncThunk(
    "terminals/terminals-options",
    async (_, thunkAPI) => {
        const { models, vendors } =
            thunkAPI.getState().core.dict.allDataForTmsConfigs.data
        const options = {}
        options.models = models
            ? models.map((el) => ({ value: el.id, label: el.name }))
            : []
        options.vendors = vendors
            ? Object.entries(vendors).map((el) => ({
                  value: el[0],
                  label: el[1],
              }))
            : []
        return options
    }
)
export const getMerchantsOptions = createAsyncThunk(
    "terminals/get-merchants",
    async (data) => {
        const response = await dictsAPI.getMerchantsDict(data)
        return response
    }
);

export const initialState = {
    listData: [],
    options: {
        vendors: [],
        models: [],
        merchants: [],
		merchantsSuccess: false, 
    },
    advancedSearchData: {
        clientId: "",
        clientIdLabel: "",
		partnerId: null,
        serialNumber: "",
        anyTid: "",
        point: "",
        contact: "",
        qrTid: "",
        bankId: null,
       
        vendorId: null,
        modelId: null,
        installationDate: "",
        enable: "",
    },
    loading: false,
	pagination: {
        page: 1,
        size: 10,
		total: 0,
    },
}

const terminalsSlice = createSlice({
    name: "terminals",
    initialState,
    reducers: {
        advancedSearchTerminalsData: (state, { payload }) => {
            Object.assign(state.advancedSearchData, payload)
        },
		changePagination: (state, { payload }) => {
            state.pagination = {...state.pagination, ...payload}
        },
		setInitialSearchData: (state, { payload }) => {
            state.pagination = initialState.pagination
            state.advancedSearchData = initialState.advancedSearchData
        },
        clearTerminalsData: (state) => {
            for (const key in initialState) {
                if (Object.hasOwnProperty.call(initialState, key)) {
                    state[key] = initialState[key]
                }
            }
        },
    },
    extraReducers: {
        [fetchTerminals.pending]: (state) => {
            state.loading = true
        },
        [fetchTerminals.fulfilled]: (state, { payload }) => {
            state.loading = false
			if(payload){
				state.listData = payload?.list?.map((terminal) => {
					const formatedTerminal = formatingObjectToView(
						terminal,
						keysWithDateValues,
						keysWithDateTimeValues
					)
					return { ...formatedTerminal, key: nanoid() }
				})
				state.pagination = {...state.pagination, current: +payload?.page, total: +payload?.countElements}
			}
        },
        [fetchTerminals.rejected]: (state) => {
            state.loading = false
        },
        [getTerminalsOptions.fulfilled]: (state, { payload }) => {
            Object.assign(state.options, payload)
        },
		[getMerchantsOptions.pending]: (state, { payload }) => {
            state.options.merchantsSuccess = false
        },
		[getMerchantsOptions.fulfilled]: (state, { payload }) => {
            state.options.merchants = Object.entries(payload).map((el) => ({
                key: el[0],
                value: el[1],
            }))
			state.options.merchantsSuccess = true
        },
    },
})

export const {
    advancedSearchTerminalsData,
    clearTerminalsData,
    changePagination,
    setInitialSearchData,
} = terminalsSlice.actions
export default terminalsSlice.reducer
