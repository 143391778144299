import { createAction } from 'redux-act';


// get
export const requestGetDataPage = createAction('[tcp] requestGetDataPage')
export const getDataPageSuccess = createAction('[tcp] getDataPageSuccess')
export const getDataPageError = createAction('[tcp] getDataPageError')

// get one
export const requestGetOne = createAction('[tcp] requestGetOne')
export const finalGetOne = createAction('[tcp] finalGetOne')
// get one
export const requestGetTableCommissions = createAction('[tcp] requestGetTableCommissions')
export const clearGetTableCommissions = createAction('[tcp] clearGetTableCommissions')
export const finalGetTableCommissions = createAction('[tcp] finalGetTableCommissions')