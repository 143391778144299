import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './core/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
// redux
import { Provider } from 'react-redux'
import store from './store/store'

import AxiosDefault from './core/utils/axios/AxiosDefault'
// antd
import './index.css'
// moment
import 'moment/locale/ru'

if (process.env.NODE_ENV !== 'production') {
    AxiosDefault.setSettings(process.env.REACT_APP_DEV_URL)
} else {
	// replace console.* for disable log on production
	console.log = () => {}
	console.error = () => {}
	console.debug = () => {}
	
    AxiosDefault.setSettings(process.env.REACT_APP_PROD_URL)
}

if (localStorage.user) {
    try {
        let token = JSON.parse(localStorage.user).token
        AxiosDefault.setAuthAndGetInitialData(token)
    } catch (error) {}
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)

serviceWorker.register()
