import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/merchant/terminals';  
import * as actions from './actions'  

export default [ 
	takeLatest(actions.requestGetDataPage, requestGetData),   
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestСloseShift, requestСloseShift),   
	takeLatest(actions.requestGetShifts, requestGetShifts),   
	takeLatest(actions.requestCreate, requestCreate),
]

// 
function* requestGetData(action) {
	try {  
		const data = yield call(API.getData, action.payload.request);  
		action.payload.callback(data) // записываем локальные вещи обратно в стейт: пагинация, фильтры 
		yield put(actions.getDataPageSuccess(data))
	} catch (error) {   
		yield put(actions.getDataPageError([]))
	}
} 

// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload);  
		yield put(actions.successGetOne(data))
	} catch (error) {   
		yield put(actions.successGetOne({}))
	}
} 

// 
function* requestСloseShift(action) {
	try {  
		const data = yield call(API.closeShift, action.payload.request); 
		action.payload.callback(true) 
		yield put(actions.successСloseShift(data))
	} catch (error) {   
		action.payload.callback(false) 
		yield put(actions.successСloseShift({}))
	}
} 

// 
function* requestGetShifts(action) {
	try {  
		const data = yield call(API.getShifts, action.payload); 
		yield put(actions.successGetShifts(data))
	} catch (error) {   
		yield put(actions.successGetShifts([]))
	}
}

//
function* requestCreate(action) {
	try {
		const data = yield call(API.create, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalCreate(data))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalCreate())
	}
}
