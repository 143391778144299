import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partnersAPI from '../../api/v2/partnersAPI'


export const fetchCreatePartner = createAsyncThunk(
    "partner-create/partnerData/post",
    async (payload) => {
        const response = await partnersAPI.create(payload)
        return response
    }
)

export const validateTin = createAsyncThunk(
    "partner-create/tinValidate/get",
    async (tin) => {
        if (!tin) {
            return false
        }
        const response = await partnersAPI.validateTinExist(tin)
        return response
    }
)

const initialState = {
    tinCheck: {
        valid: false,
        loading: false
    },
    loading: false
}
 
const partnerCreateSlice = createSlice({
    name: "partner-create",
    initialState,
    reducers: {
        
    },
    extraReducers: {
        [fetchCreatePartner.pending]: (state) => {
            state.loading = true
        },
        [fetchCreatePartner.fulfilled]: (state) => {
            state.loading = false
        },
        [fetchCreatePartner.rejected]: (state) => {
            state.loading = false
        },
        [validateTin.pending]: (state) => {
            state.tinCheck.loading = true
        },
        [validateTin.fulfilled]: (state, {payload}) => {
            state.tinCheck.valid = !payload
            state.tinCheck.loading = false
        },
        [validateTin.rejected]: (state) => {
            state.tinCheck.loading = false
        },
    }
});

export default partnerCreateSlice.reducer