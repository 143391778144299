import {
    BarChartOutlined,
    BuildOutlined,
    AppstoreOutlined,
    ToolOutlined,
    CoffeeOutlined,
    InfoCircleOutlined,
    BulbOutlined,
    AuditOutlined,
    UserOutlined,
    MobileOutlined,
    DollarCircleOutlined,
} from '@ant-design/icons'

const nav = {
    items: [
        {
            name: 'Statistics',
            icon: BarChartOutlined,
            children: [
                {
                    name: 'General',
                    url: '/dashboard',
                    permission: 'graphics',
                },
                // // аналитика
                // {
                //     name: 'Reports',
                //     url: '/reports',
                //     // icon: 'cui-list icons',
                //     permission: 'transactions reports',
                //     crud: true,
                // },
                // новый раздел
                {
                    name: 'Reports',
                    url: '/reports',
                    permission: 'report jobs',
                    crud: true,
                },
                {
                    name: 'Scoring',
                    url: '/scoring',
                    permission: ['merchant applications_kub_application',"partners_create:fromPortal",'merchant applications_scoring_files'],
                    crud: true,
                },
                {
                    name: 'Transactions',
                    url: '/transactions',
                    permission: 'transactions list',
                    childPermissions: ['details']
                },
                {
                    name: 'Temperature map',
                    url: '/temperatureMap',
                    permission: 'terminals_terminalsMap',
                    crud: true,
                },
            ],
        },
        {
            name: 'Applications - Acquiring',
            icon: BuildOutlined,
            children: [
                {
                    name: 'Create',
                    url: '/creating_apps',
                    permission: 'applicationleads',
                },
                {
                    name: 'All applications',
                    url: '/merchant_applications',
                    subPath: ['/merchant_applications/:id', '/quick_merchant_applications/:id'],
                    permission: 'merchant applications',
                    crud: true,
                    childPermissions: ["confirm", "signRight", 'uploadSignAndPrint']
                },
                {
                    name: 'PSB application queue',
                    url: '/app-queue',
                    permission: 'partners_create:fromPortal',
                },
                {
                    name: 'New customer requests',
                    url: '/leads',
                    permission: 'applicationleads',
                },
            ],
        },
        {
            name: 'Applications - Cashboxs',
            icon: MobileOutlined,
            children: [
                {
                    name: 'Create',
                    url: '/kkt-creating-app',
                    permission: ['kkt:crud', 'kkt:create'],
                },
                {
                    name: 'All applications',
                    url: '/kep-list',
                    permission: ['kkt:crud', 'kkt:update']
				}
		]},
        {
			name: 'Merchant invoices',
            icon: DollarCircleOutlined,
            children: [
                {
                    name: 'Invoices',
                    url: '/merchant_invoices',
                    // icon: 'cui-list icons',
                    permission: ['merchantInvoices:invoices', 'merchantInvoices:invoices:update', 'merchantInvoices:invoices:downloadReceipt', 'merchantInvoices:invoices:create'],
                },
                {
                    name: 'Payments',
                    url: '/merchant_payment',
                    // icon: 'cui-list icons',
                    permission: ['merchantInvoices:payments:create', 'merchantInvoices:payments'],
                },
				{
                    name: 'Subscriptions',
                    url: '/recurrent',
                    permission: ['recurringSchedulers:view', 'recurringSchedulers:create', 'recurringSchedulers:update', 'recurringSchedulers:delete', 'recurringSchedulers:crud'],
                },
            ],
        },
        {
            name: 'Clients',
            icon: AppstoreOutlined,
            children: [
                {
                    name: 'Merchants',
                    url: '/merchants',
                    permission: 'merchants',
                    crud: true
                },
                {
                    name: 'Merchant groups',
                    url: '/merchant_groups',
                    permission: 'merchant groups',
                    crud: true,
                },
                {
                    name: 'Terminals',
                    url: '/terminals',
                    permission: 'terminals',
                    crud: false,
                    childPermissions: ["delete", "forceCloseShift", "addLimit"]
                },
                {
                    name: 'Terminal groups',
                    url: '/terminals/groups',
                    permission: 'terminal groups',
                    crud: true,
                },
                {
                    name: 'Terminals shifts',
                    url: '/terminals/shifts',
                    permission: 'terminal shifts',
                },
                {
                    name: 'Merchant points list',
                    url: '/merchant_points',
                    permission: 'merchantpoints',
                    crud: true
                },
            ],
        },
        {
            name: 'Сustomer service',
            icon: BulbOutlined,
            children: [
                {
                    name: 'Clients',
                    url: '/customers/customer',
                    subPath: ['/customers/customer/:id'],
                    permission: ['clientsSection:all', 'clientsSection:merchants'],
                },
                {
                    name: 'Terminals',
                    url: '/customers/terminal',
                    subPath: ['/customers/terminal/:id'],
                    permission: ['clientsSection:all', 'clientsSection:terminals'],
                },

                {
                    name: 'Transactions',
                    url: '/customers/transactions',
                    permission: ['clientsSection:transactions:allFilters', 'clientsSection:transactions:engineer'],
                    childPermissions: []
                },
        
            ],
        },
        {
            name: 'Service',
            icon: AuditOutlined,
            children: [
                {
                    name: 'Service Tickets List',
                    url: '/service/tickets',
                    permission: ['service:serviceTickets'],
                },
            ]
        },
        // {
        //     name: 'Партнеры',
        //     icon: UserOutlined,
        //     children: [
        //         {
        //             name: 'Регистрация',
        //             url: '/partners/create',
        //             permission: 'partners',
        //             crud: false,
        //         },
        //         {
        //             name: 'Список партнеров',
        //             url: '/partners/list',
        //             permission: 'partners',
        //             crud: false,
        //         }
        //     ]
        // },
        {
            name: 'Sub-partners',
            icon: UserOutlined,
            children: [
                {
                    name: 'Sub-partners list',
                    url: '/subpartners',
                    // icon: 'cui-list icons',
                    permission: 'partners',
                    crud: true,
                    childPermissions: ['emailTmpls']
                }
            ]
        },
        // {
        //     name: 'CRM',
        //     icon: 'layout',
        //     children: [
        //     ],
        // },
        {
            name: 'Administration',
            icon: ToolOutlined,
            children: [
                {
                    name: 'Users',
                    url: '/users',
                    permission: 'users',
                    crud: true,
                },
                {
                    name: 'Partners',
                    url: '/partners',
                    // icon: 'cui-list icons',
                    permission: 'partners_create:fromPortal',
                    crud: true,
                },
                {
                    name: 'Role groups',
                    url: '/users/role_groups',
                    permission: 'roles groups',
                    crud: true,
                },
                // {
                //   name: 'Аудит',
                //   url: '/audit',
                //   permission: 'users',
                //   crud: false,
                // },
            ]
        },
        {
            name: 'Services', // будут продукты
            icon: CoffeeOutlined,
            children: [
                {
                    name: 'Waiters list',
                    url: '/waiters',
                    permission: 'waiters',
                },
                {
                    name: 'Courier list',
                    url: '/couriers',
                    permission: 'couriers',
                },
                {
                    name: 'Courier transactions',
                    url: '/courier_transactions',
                    permission: 'transactions list_couriers_read',
                },
            ],
        },
        {
            name: 'FAQ',
            icon: InfoCircleOutlined,
            children: [
                // {
                //     name: 'News',
                //     url: '/news',
                //     permission: 'news',
                // },
                {
                    name: 'Answers on questions',
                    url: '/help',
                    permission: 'news',
                },
            ],
        },

    ],
};

export default nav