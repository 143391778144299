import { List, message, notification } from "antd";
import CustomNotification from "../core/ui/CustomNotification";

/**
 * Обрабатывает ошибки переданной функции (data) => axios.post(`/path`, data).then(r => catchError(r)), 
 * @param {Primise} res промис для обработки
 */
export const catchError = res => {
	if (res.status === 200) {   
		return res.data
	} else { 
		// console.error('catchError: !! :', res.response.data);
		throw(res.response.data ? res.response.data : res)
	} 
} 
export const createFile = (theBlob, fileName) => {
	 return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: "text/xml" })
};

/** Создает окно для скачивания файлов */
export const downloadBlob = (r, fileName) => {
	// 2. Create blob link to download 
	let url = window.URL.createObjectURL(new Blob([r.data])); 
	
	let link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	// 3. Append to html page
	document.body.appendChild(link);
	// 4. Force download
	link.click();
	// 5. Clean up and remove the link
	link.parentNode.removeChild(link);
	window.URL.revokeObjectURL(url)
}

/** Создает окно для скачивания файлов */
export const optionsForAxiosBlob = () => {
	return {responseType: 'blob', headers: { 'Accept': 'application/octet-stream'}}
}
export const optionsXMLJSON = () => {
	return {responseType: 'xml', headers: { 'Accept': 'application/octet-stream'}}
}

/** Вырезает название файла из Content-disposition */
export const getFileNameFromContentDisposition = (contentDisposition = '') => {
	return contentDisposition.split('filename="')[1].split('"')[0]
}
/** Вырезает название файла из Content-disposition (кодированные в utf-8)*/
export const getFileNameFromContentDispositionUTF8 = (contentDisposition = '') => {

	const utf8FileName = contentDisposition.split(/filename\*=UTF-8''/)[1].split('"')[0]
	return decodeURI(utf8FileName)
}

export const createRequestQuery = (object = {}) => {
	return Object.keys(object).reduce((prev, curr, index) => {
		if(object[curr]){
			if(prev === ''){
				return `?${curr}=${object[curr]}`
			}
			return prev+`&${curr}=${object[curr]}`
		}
		return prev
	},'')
};

export const mockRequest = ({mock, resolve}) => {
	return new Promise((res, rej) => {
		setTimeout(() => {
			resolve ? res(mock) : rej(mock)
	   },1000)
	})
};

const getNotificationMessage = (errorType, title) => {
	if(serverErrorTypeEnum[errorType]) return serverErrorTypeEnum[errorType]
	if(title) return title
	return 'Произошла ошибка'
};

const getNotificationDescription = (message) => {
	if(message) return message
	return null
};

export const serverResponseNotification = ({
	errorType,
	errorMessage = null, 
	title = null, 
    type = 'error', 
    duration = 7,
}) => {

	notification[type]({
		message: getNotificationMessage(errorType, title),
		description: getNotificationDescription(errorMessage),
		duration,
	});
};

export const serverArrayResponseNotification = ({
	dataSource = [], 
	getDescription = null,
	errorTypeKey = 'errorType', 
	errorMessageKey = 'errorMessage', 
	type = 'warning', 
	duration = 10,
	showErrorTitle = true,
}) => {

	notification[type]({
		duration,
		description:
		<List
			itemLayout="horizontal"
			dataSource={dataSource}
			renderItem={(item) => (
			<List.Item>
				<List.Item.Meta
					title={showErrorTitle ? getNotificationMessage(item[errorTypeKey]) : null}
					description={
						<span style={{color: '#44403C'}}>
							{getDescription && getDescription(item) 
							? `${getDescription(item)} ${getNotificationDescription(item[errorMessageKey])}` 
							: getNotificationDescription(item[errorMessageKey])}
						</span>
					}
				/>
			</List.Item>
			)}
		/>,
	})
};
/** type - success || error */
export const getServerResponse = (response, getDescription, type) => {

	if(type === 'success'){
		if(response?.errors?.length){
			return serverArrayResponseNotification({
				getDescription, 
				errorTypeKey: 'errorType', 
				errorMessageKey: 'errorMessage', 
				dataSource: response?.errors, 
				type: 'warning',
			})
		};
	
		if(response?.success === false){
			return serverResponseNotification({
				errorType:response?.errorType,
				errorMessage:response?.errorMessage,
			})
		};
	
		return response?.success && message.success('Данные успешно обновлены')
	}
	if(type === 'error'){
		if(response?.errors?.length){
			return serverArrayResponseNotification({
				getDescription, 
				errorTypeKey: 'errorType', 
				errorMessageKey: 'errorMessage', 
				dataSource: response?.errors, 
				type: 'warning',
			})
		};
	
		if(response?.success === false){
			return serverResponseNotification({
				errorType:response?.errorType,
				errorMessage:response?.errorMessage,
			})
		};
		
	}
};

export const serverErrorTypeEnum = {
	USER_NOT_FOUND:'Пользователь не обнаружен, проверьте адресную строку',
	USER_NOT_ACTIVE:'Пользователь не активен',
	INVALID_PASSWORD:'Неверный пароль',
	PASSWORD_NOT_SECURE:'Пароль небезопасен',
	MAX_LOGIN_ENTER_FAIL:'Превышен лимит входов',
	ACCESS_DENIED:'Нет доступа',
	EMPTY_SERVER_RESPONSE:'Пустой ответ от сервера',
	EMPTY_LOGIN:'Пустой логин',
	LOGIN_EXISTS:'Логин занят',
	SDM_ERROR_RESPONSE:'Ошибки в SDM',
	TERMINAL_NOT_FOUND:'Терминал не найден или удален',
	TRANSACTION_NOT_FOUND:'Транзакция не найдена',
	TICKET_NOT_FOUND:'Заявки не найдены',
	LEAD_NOT_FOUND:'Лид не найден',
	LEAD_VPP_ONLY:'Лид может конвертировать только ВПП',
	KEP_NOT_FOUND:'КЕП не найден',
	KKT_NOT_FOUND:'ККТ не найден',
	MERCHANT_APPLICATION_NOT_FOUND:'Заявка не найдена',
	TOO_MUCH_ELEMENTS:'Слишком много данных',
	TOO_MUCH_REQUESTS:'Слишком много запросов',
	DUPLICATE_REQUEST:'Запрос уже отправлен',
	TERMINALS_FOUND_WITH_TID:'Найдены другие терминалы с этим тидом',
	EMAIL_NOT_VALID:'Невалидный email',
	OTP_NOT_VALID:'Неверный код подтверждения',
	PERMISSIONS_NOT_VALID:'Ошибка выбора прав',
	ROLE_NOT_VALID:'Ошибка выбора роли',
	BAD_CONFIG:'Невалидная конфига',
	BAD_REQUEST:'Объект не передан',
	BAD_FORMAT:'Формат не доступен',
	BANK_NOT_ALLOWED:'Банк не разрешен',
	FEE_NOT_ALLOWED:'Профиль комиссии не разрешен',
	EMAIL_SEND_ERROR:'Ошибка отправки email',
	OTP_TYPE_NOT_VALID:'Неверный тип кода подтверждения',
	MERCHANT_EXISTS:'Клиент уже существует в системе',
	MERCHANT_NOT_FOUND:'Клиент не найден в системе',
	WAITER_NOT_FOUND:'Официант не найден в системе',
	TOKEN_NOT_VALID:'Невалидный токен',
	CURRENCY_NOT_FOUND:'Валюта не найдена',
	CARD_NOT_VALID:'Невалидная карта',
	CARD_EXPIRED:'Просроченная карта',
	KEY_NOT_SET:'Ключ не задан',
	FISCAL_NOT_SET:'Фискальный признак не задан',
	NAME_NOT_SET:'Имя не задано',
	COURIER_NOT_FOUND:'Курьер не найден в системе',
	PARTNER_NOT_FOUND:'Партнер не найден в системе',
	FILE_NOT_FOUND:'Файл не найден',
	PARTNER_EXISTS:'Партнер уже присутствует в системе',
	SERVICE_NOT_AVAILABLE:'Сервис не доступен',
	MERCHANT_POINT_NOT_FOUND:'Торговая точка не найдена в системе',
	EMPTY_SERIAL_NUMBER:'Пустой серийный номер',
	SERIAL_NUMBER_NOT_VALID:'Невалидный серийный номер',
	SERIAL_NUMBER_ALREADY_EXISTS:'Серийный номер уже существует',
	ERROR_SERVER_CONNECTION:'Сервер не доступен',
	ERROR_TIMEOUT_15:'Вы заблокированы на 15 минут',
	ERROR_IN_SENDING:'Ошибка при отправке ключа',
	ERROR_IN_CHECKING:'Неверный ключ',
	TMS_ERROR:'Ошибка ТМС',
	UNKNOWN_ERROR:'Неизвестная ошибка',
	WRONG_AMOUNT:'Ошибка ввода суммы',
	MID_NOT_VALID:'Невалидный мид',
	UNFILLED_COLUMNS:'Не все поля заполнены',
	NEED_UPLOAD_FILE:'Необходимо загрузить файл(ы)',
	TMS_CONFIG_NOT_FOUND:'Не найден шаблон ТМС конфигурации',
	LOGIN_NOT_FOUND:'Не найден login',
	PASSWORD_NOT_FOUND:'Не найден password',
	IMAGE_URL_NOT_FOUND:'Не найден url для обновления картинок',
	INVALID_ACCOUNT:'Неверный номер счёта',
	BANK_NOT_FOUND:'Банк не найден',
	DATA_NOT_FOUND:'Данные не найдены',
	FILE_TOO_LARGE:'Файл слишком огромный',
	FILE_NOT_VALID:'Проверьте документ',
	REPORT_EXISTS:'Отчет уже создан',
	CONTRACT_NOT_FOUND:'Договор не найден в системе',
	PERSON_NOT_FOUND:'Сотрудник не найден в системе',
	TERMINALS_FOUND_WITH_QR_TID:'Найден другой терминал с этим QR TID',
	MCC_EXISTS:'Выберете "Вид деятельности" из списка',
};
