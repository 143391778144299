import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit"
import moment from "moment"
import merchantPaymentAPI from "../../../api/v2/merchantPaymentAPI"


export const getMerchantPayments = createAsyncThunk(
    "merchantPayment/list/get",
    async (data ,thunkAPI) => {
		const { page, size } = data
		const { merchant_id } = thunkAPI.getState().auth.user.userToken
        const response = await merchantPaymentAPI.getMerchantPayments({page, size,merchantId:merchant_id,})
        return response
    }
)

const initialState = {
    listData: [],
    loading: false,
    currentPage: 1,
    countElement: null,
    merchantRepeatData: {},
}

const merchantPaymentSlice = createSlice({
    name: "merchantPayment",
    initialState,
    reducers: {
        changeCurrentPage: (state, { payload }) => {
            state.currentPage = payload
        },
		resetRepeatPaymentData: (state) => {
			state.merchantRepeatData = {}
        },
        setRepeatPaymentData: (state, { payload }) => {

			const getSendCurrParam = (payload) => {
				if(payload?.email) return 'email'
				if(payload?.phone) return 'phone'
				return null
			};

            state.merchantRepeatData = {...payload, 
				expirationDate: payload?.expirationDate ? moment(payload.expirationDate) : null,
				sendCurrency: getSendCurrParam(payload),
				email: payload?.email ? payload.email : null,
				phone: payload?.phone ? payload.phone : null,
				phoneCode: payload?.phoneCode ? payload.phoneCode : null,
				currency: payload?.currency || null
			}
        },
    },
    extraReducers: {
        [getMerchantPayments.pending]: (state) => {
            state.loading = true
        },
        [getMerchantPayments.fulfilled]: (state, { payload }) => {
            const data = payload?.payments?.map((item) => {
                return { ...item, key: nanoid() }
            })

            state.currentPage = payload?.currentPage
            state.countElement = payload?.countElements
			state.listData = data
            state.loading = false
        },
        [getMerchantPayments.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const {
    refreshSelectedColumns,
    resetRepeatPaymentData,setRepeatPaymentData,
    changeCurrentPage,
} = merchantPaymentSlice.actions
export default merchantPaymentSlice.reducer