import { t } from 'ttag'

export const bankListBeanName = {
	TINKOFF: 'Тинькофф',
	VTBNew: 'ВТБ',
	VTB: 'ВТБ',
	ALFA: 'Альфа-банк',
	OPEN: 'Открытие',
	PSB: 'ПСБ',
	RNKO: 'РНКО',
	GEN: 'ГенБанк',
	KUB: 'ПростоБанк',
}

/** Новый формат для react-select */
export const availableRegion = [
    { value: 'MOSCOW', label: 'Москва (Город)' },
    { value: 'ST_PET', label: 'Санкт-Петербург (Город)' },
    { value: 'ADIGEA', label: 'Адыгея (Республика)' },
    { value: 'ALTAI', label: 'Алтай (Республика)' },
    { value: 'ALTA', label: 'Алтайский (Край)' },
    { value: 'AMYRSK', label: 'Амурская (Область)' },
    { value: 'ARHANG', label: 'Архангельская (Область)' },
    { value: 'ASTRAX', label: 'Астраханская (Область)' },
    { value: 'BAI', label: 'Байконур (Город)' },
    { value: 'BASHKOR', label: 'Башкортостан (Республика)' },
    { value: 'BELGOROD', label: 'Белгородская (Область)' },
    { value: 'BRANSK', label: 'Брянская (Область)' },
    { value: 'BYRAT', label: 'Бурятия (Республика)' },
    { value: 'VLADIM', label: 'Владимирская (Область)' },
    { value: 'VOLGOGR', label: 'Волгоградская (Область)' },
    { value: 'VOLOGODSK', label: 'Вологодская (Область)' },
    { value: 'VORON', label: 'Воронежская (Область)' },
    { value: 'DAGESTAN', label: 'Дагестан (Республика)' },
    { value: 'DNR', label: 'Донецкая Народная Республика' },
    { value: 'EVR', label: 'Еврейская (Автономная область)' },
    { value: 'ZABAIKAL', label: 'Забайкальский (Край)' },
    { value: 'ZAPOR', label: 'Запорожская (Область)' },
    { value: 'IVAN', label: 'Ивановская (Область)' },
    { value: 'INGUSHET', label: 'Ингушетия (Республика)' },
    { value: 'IRKYTSK', label: 'Иркутская (Область)' },
    { value: 'KABARDINO', label: 'Кабардино-Балкарская (Республика)' },
    { value: 'KALININGR', label: 'Калининградская (Область)' },
    { value: 'KALMIK', label: 'Калмыкия (Республика)' },
    { value: 'KALY', label: 'Калужская (Область)' },
    { value: 'KAMCHATSK', label: 'Камчатский (Край)' },
    { value: 'KARACHAEV', label: 'Карачаево-Черкесская (Республика)' },
    { value: 'KARELIA', label: 'Карелия (Республика)' },
    { value: 'KEMEROV', label: 'Кемеровская (Область)' },
    { value: 'KIROV', label: 'Кировская (Область)' },
    { value: 'KOMI', label: 'Коми (Республика)' },
    { value: 'COSTR', label: 'Костромская (Область)' },
    { value: 'KRASHODAR', label: 'Краснодарский (Край)' },
    { value: 'KRASNOYAR', label: 'Красноярский (Край)' },
    { value: 'KRIM', label: 'Крым (Республика)' },
    { value: 'KYRGAN', label: 'Курганская (Область)' },
    { value: 'KYRSK', label: 'Курская (Область)' },
    { value: 'LENIN', label: 'Ленинградская (Область)' },
    { value: 'LIPET', label: 'Липецкая (Область)' },
    { value: 'LNR', label: 'Луганская Народная Республика' },
    { value: 'MAGAD', label: 'Магаданская (Область)' },
    { value: 'MARI', label: 'Марий Эл (Республика)' },
    { value: 'MORDOVIA', label: 'Мордовия (Республика)' },
    { value: 'MO', label: 'Московская (Область)' },
    { value: 'MYRMAN', label: 'Мурманская (Область)' },
    { value: 'NEN', label: 'Ненецкий (Автономный округ)' },
    { value: 'NIJEGOROD', label: 'Нижегородская (Область)' },
    { value: 'NOVGOROD', label: 'Новгородская (Область)' },
    { value: 'NOVOSIB', label: 'Новосибирская (Область)' },
    { value: 'OMSK', label: 'Омская (Область)' },
    { value: 'ORENB', label: 'Оренбургская (Область)' },
    { value: 'ORL', label: 'Орловская (Область)' },
    { value: 'PENZENSK', label: 'Пензенская (Область)' },
    { value: 'PERM', label: 'Пермский (Край)' },
    { value: 'PRIMORSK', label: 'Приморский (Край)' },
    { value: 'PSKOVSK', label: 'Псковская (Область)' },
    { value: 'ROSTOV', label: 'Ростовская (Область)' },
    { value: 'RAZAN', label: 'Рязанская (Область)' },
    { value: 'SAMAR', label: 'Самарская (Область)' },
    { value: 'SARATOV', label: 'Саратовская (Область)' },
    { value: 'SAXA', label: 'Саха/Якутия (Республика)' },
    { value: 'SAHALINSK', label: 'Сахалинская (Область)' },
    { value: 'SEVASTOP', label: 'Севастополь (Город)' },
    { value: 'SVERDLOVSK', label: 'Свердловская (Область)' },
    { value: 'SEV_OS', label: 'Северная Осетия-Алания (Республика)' },
    { value: 'SMOLENSK', label: 'Смоленская (Область)' },
    { value: 'STAVR', label: 'Ставропольский (Край)' },
    { value: 'TAMBOV', label: 'Тамбовская (Область)' },
    { value: 'TATAR', label: 'Татарстан (Республика)' },
    { value: 'TVERSK', label: 'Тверская (Область)' },
    { value: 'TOMSK', label: 'Томская (Область)' },
    { value: 'TYLSK', label: 'Тульская (Область)' },
    { value: 'TIVA', label: 'Тыва (Республика)' },
    { value: 'TYMEN', label: 'Тюменская (Область)' },
    { value: 'YDMYR', label: 'Удмуртская (Республика)' },
    { value: 'YLANOV', label: 'Ульяновская (Область)' },
    { value: 'XABAROV', label: 'Хабаровский (Край)' },
    { value: 'XAKASIA', label: 'Хакасия (Республика)' },
    { value: 'HERSON', label: 'Херсонская (Область)' },
    {
        value: 'XAN',
        label: 'Ханты-Мансийский Автономный округ-Югра (Автономный округ)',
    },
    { value: 'CHELAB', label: 'Челябинская (Область)' },
    { value: 'CHECHEN', label: 'Чеченская (Республика)' },
    { value: 'CHYKOT', label: 'Чукотский (Автономный округ)' },
    { value: 'CHYVA', label: 'Чувашская (Республика)' },
    { value: 'YAM_NEN', label: 'Ямало-Ненецкий (Автономный округ)' },
    { value: 'YAROSLAV', label: 'Ярославская (Область)' },
]

export const availableCityType = [
	{ value: 'MICRODISTRICT', label: `микрорайон` },
    { value: 'VILLAGE', label: `деревня` },
    { value: 'POCELOK', label: `поселок` },
	{ value: 'COLONY', label: `поселение` },
    { value: 'SUBURBAN_VILLAGE', label: `дачный поселок` },
    { value: 'SETTLEMENT', label: `поселок городского типа` },
    { value: 'WORKING_VILLAGE', label: `рабочий поселок` },
    { value: 'STANICA', label: `станица` },
    { value: 'HYTOR', label: `хутор` },
    { value: 'SELO', label: `село` },
    { value: 'AUL', label: `аул` },
    { value: "TERRITORY", label: `территория` },
    { value: "QUARTER", label: `квартал` },
]
export const availableCityTypeForKUB = [
    { value: 'CITY', label: `город` },
    ...availableCityType
]

export const availableCityTypeForKkt = [
    { value: 'MICRODISTRICT', label: `микрорайон` },
    { value: 'VILLAGE', label: `деревня` },
    { value: 'POCELOK', label: `поселок` },
    { value: 'COLONY', label: `поселение` },
    { value: 'SUBURBAN_VILLAGE', label: `дачный поселок` },
    { value: 'SETTLEMENT', label: `поселок городского типа` },
    { value: 'WORKING_VILLAGE', label: `рабочий поселок` },
    { value: 'STANICA', label: `станица` },
    { value: 'HYTOR', label: `хутор` },
    { value: 'SELO', label: `село` },
    { value: 'AUL', label: `аул` },
	{ value: "TERRITORY", label: `территория` },
]

export const availableMunicapalAreas = [
    { value: 'MUNICIPAL_DISTRICT', label: t`Муниципальный район` },
    { value: 'CITY_DISTRICT', label: t`Городской округ` },
    { value: 'INTRACITY_TERRITORY', label: t`Внутригородская территория города федерального значения` },
    { value: 'MUNUCIPAL_AREA', label: t`Муниципальный округ` },
]

export const availableStreetType = [
    { value: 'STREET', label: `улица` },
    { value: 'ALLEY', label: `аллея` },
    { value: 'BOULEVARD', label: `бульвар` },
    { value: 'QUAY', label: `набережная` },
    { value: 'LANE', label: `переулок` },
    { value: 'PASSAGE', label: `проезд` },
    { value: 'AVENUE', label: `проспект` },
    { value: 'DEADLOCK', label: `тупик` },
    { value: 'HIGHWAY', label: `шоссе` },
    { value: 'PATROL', label: `разьезд` },
    { value: 'MICRODISTRICT', label: `микрорайон` },
    { value: 'QUARTER', label: `квартал` },
    { value: 'SQUARE', label: `площадь` },
    { value: 'TRACT', label: `тракт` },
    { value: 'LINE', label: `линия` },
]

export const availableKUBStreetType = [
    { value: 'STREET', label: `улица` },
    { value: 'ALLEY', label: `аллея` },
    { value: 'BOULEVARD', label: `бульвар` },
    { value: 'QUAY', label: `набережная` },
    { value: 'LANE', label: `переулок` },
    { value: 'PASSAGE', label: `проезд` },
    { value: 'AVENUE', label: `проспект` },
    { value: 'DEADLOCK', label: `тупик` },
    { value: 'HIGHWAY', label: `шоссе` },
    { value: 'PATROL', label: `разьезд` },
	{ value: 'MICRODISTRICT', label: `микрорайон` },
    { value: 'QUARTER', label: `квартал` },
    { value: 'SQUARE', label: `площадь` },
    { value: 'TRACT', label: `тракт` },
    { value: 'LINE', label: `линия` },
    { value: 'OTHER', label: `другое` },
]

export const availableBlockTypes = [
    {
         label:"корпус",
         value:"HOUSING"
     },
    {
        value:"STRUCTURE",
        label:"строение"
    },
   {
        label:"сооружение",
        value:"BUILDING"
    } ,
   {
        label:"литера",
        value:"LITERA"
    } ,
   {
        label:"блок",
        value:"BLOCK"
    } ,
    
]
export const availableFlatTypes = [
    {
        value:"ROOM",
        label:"комната"
    },
    {
        value:"OFFICE",
        label:"офис"
    },
    {
        value:"PLACEMENT",
        label:"помещение"
    },
    {
        value:"FLAT",
        label:"квартира"
    },
    
]

export const availableHouseTypes = [
    {
        value:"HOUSE",
        label:"дом"
    },
    {
        label:"здание",
        value:"EDIFICE"
    } ,
   {
        label:"владение",
        value:"OWNERSHIP"
    } ,
]

const availableBFROOcode = [
    { value: '0072', label: 'Москва' },
    { value: '1039', label: 'Архангельски' },
    { value: '1020', label: 'Астрахански' },
    { value: '1014', label: 'Барнау' },
    { value: '1016', label: 'Белгоро' },
    { value: '0956', label: 'Благовещенски' },
    { value: '2013', label: 'Братски' },
    { value: '1066', label: 'Брянск' },
    { value: '1054', label: 'Владивостокски' },
    { value: '1655', label: 'Владикавказски' },
    { value: '0851', label: 'Владими' },
    { value: '1008', label: 'Волгоградски' },
    { value: '0051', label: 'Вороне' },
    { value: '0002', label: 'Екатеринбур' },
    { value: '0751', label: 'Иванов' },
    { value: '1057', label: 'Ижевски' },
    { value: '1009', label: 'Йошкар-Ол' },
    { value: '2011', label: 'Иркутс' },
    { value: '1038', label: 'Калининградски' },
    { value: '0651', label: 'Калуг' },
    { value: '1156', label: 'Камчатски' },
    { value: '1018', label: 'Киро' },
    { value: '2551', label: 'Костром' },
    { value: '0055', label: 'Краснода' },
    { value: '2046', label: 'Красноярс' },
    { value: '2007', label: 'Кузбас' },
    // {value: "2302", label: "Кургански"},
    { value: '1751', label: 'Курс' },
    { value: '1051', label: 'Липец' },
    { value: '1956', label: 'Магадански' },
    { value: '4506', label: 'Мурмански' },
    { value: '1755', label: 'Нальчи' },
    { value: '2302', label: 'Нижний Новгород' },
    { value: '0926', label: 'Новгородски' },
    { value: '1045', label: 'Новомосковс' },
    { value: '0040', label: 'Новосибирс' },
    { value: '1043', label: 'Омс' },
    { value: '2751', label: 'Орё' },
    { value: '1061', label: 'Оренбур' },
    { value: '1218', label: 'Пенз' },
    { value: '2042', label: 'Перм' },
    { value: '1065', label: 'Петрозаводски' },
    { value: '2006', label: 'Псковски' },
    { value: '2005', label: 'Ростовски' },
    { value: '1251', label: 'Рязан' },
    { value: '0018', label: 'Самар' },
    { value: '0006', label: 'Санкт-Петербур' },
    { value: '0818', label: 'Саранс' },
    { value: '1052', label: 'Сарато' },
    { value: '0856', label: 'Сахалински' },
    { value: '1044', label: 'Смоленс' },
    { value: '1059', label: 'Ставропольски' },
    { value: '1004', label: 'Сыктывкарски' },
    { value: '0951', label: 'Тамбо' },
    { value: '1351', label: 'Твер' },
    { value: '1019', label: 'Тольятт' },
    { value: '1440', label: 'Томс' },
    { value: '1015', label: 'Тюменски' },
    { value: '1071', label: 'Улан-Уд' },
    { value: '1031', label: 'Ульяновс' },
    { value: '1062', label: 'Уф' },
    { value: '0056', label: 'Хабаровс' },
    { value: '1053', label: 'Чебоксар' },
    { value: '2049', label: 'Челябински' },
    { value: '3060', label: 'Череповецки' },
    { value: '0540', label: 'Чит' },
    { value: '1056', label: 'Якутски' },
    { value: '2064', label: 'Набережные челны' },
]



const rentDataConfigMap = [
    { value: 'OWN', label: t`Свидетельство о праве собственности` },
    { value: 'RENT', label: t`Договор аренды` },
    { value: 'EGRP', label: t`Выписки из ЕГРП` },
    { value: 'SUBRENT', label: t`Договор субаренды` },
    { value: 'ASSIGNMENT', label: t`Договор переуступки права` },
    { value: 'OTHER', label: t`Другое` },
]

const classificatorAPOS = [
    { value: 'POS', label: t`POS-эквайринг` },
    { value: 'ECOM', label: t`E-COMM` },
    { value: 'POS_RJD', label: t`POS РЖД` },
    { value: 'ECOM_RJD', label: t`E-comm РЖД` },
    { value: 'ALIPAY', label: t`Alipay` },
]

const classificatorBPOS = [
    { value: 'POS', label: t`POS` },
    { value: 'MPOS', label: t`mPOS` },
    { value: 'CAT', label: t`CAT-устройства` },
    { value: 'TURNSTILES', label: t`Турникеты` },
    { value: 'ECOMINT', label: t`E-comm через интеграторы` },
    { value: 'ECOMCONTRACT', label: t`E-comm прямой договор` },
    { value: 'IPT_RJD', label: t`ИПТ РЖД` },
    { value: 'TTC_RJD', label: t`ТТС РЖД` },
    { value: 'BPA', label: t`BPA` },
    { value: 'INTERNER_RJD', label: t`Интернет-эквайринг РЖД` },
    { value: 'ALIPAY', label: t`Alipay` },
]

const activityForm = [
    { value: 'RETAIL', label: t`Розница` },
    { value: 'WHOLESALE', label: t`Оптовая` },
    { value: 'RETAIL_WHOLESALE', label: t`Оптом и розницу` },
]
const activityFormVTBNew = [
    { value: 'RETAIL', label: t`Розница` },
]

export const periodDashboard = [
    // {value: "ALL_TIME", label: t`For all the time`},
    { value: 'THIS_DAY', label: t`Текущий день` },
    { value: 'THIS_WEEK', label: t`This week` },
    { value: 'THIS_MONTH', label: t`Текущая неделя` },
    // {value: "THIS_YEAR", label: t`This year`},
    { value: 'YESTERDAY', label: t`Вчера`},
    { value: 'LAST_WEEK', label: t`Прошлая неделя` },
    { value: 'LAST_MONTH', label: t`Прошлый месяц` },
    // {value: "LAST_YEAR", label: t`Last year`},
]

export const secondPartAuth = [
    { value: 'EMAIL', label: t`email.` },
    { value: 'PHONE', label: t`phone.` },
]

export const appTypeInChangeTerminal = [
    { value: 'NEWEQUIPMENT', label: t`Новое оборудование` },
    { value: 'CHANGEEQUIPMENT', label: t`Замена оборудования` },
    { value: 'REMOVEEQUIPMENT', label: t`Снятие оборудования` },
    { value: 'SERVICEEQUIPMENT', label: t`Обслуживание оборудование` },
]
export const bankTermServiceType = [
    { value: 'CONSULTATION', label: 'Консультация по работе терминала' },
    { value: 'TRAINING', label: 'Обучение по работе терминала' },
    //{ value: 'DISCONNECTION_NO_COINS', label: 'Отключение НетМонет' },
   // { value: 'CONFIGURE_NO_COINS', label: 'Подключение безналичных чаевых' },
    //{ value: 'CONNECTION_NO_COINS', label: 'Подключение НетМонет' },
    //{ value: 'CONNECTION_SBP', label: 'Подключение СБП' },
    { value: 'TERMINAL_BREAK', label: 'Поломка терминала' },
    { value: 'CHANGE_COMMUNICATION', label: 'Смена типа соединения (коммуникаций)' },
    { value: 'WORK_UNSTABLE', label: 'Терминал работает нестабильно' },
]

export const appServiceRequestTypeTypeInChangeTerminal = [
    { value: 'SERVICE', label: 'Сервис' },
    { value: 'DISABLE_SBP', label: 'Отключение от СБП' },
]

export const roomType = [
    { value: 'SHOP', label: 'Магазин/выставочный зал' },
    { value: 'CATERING_POINT', label: 'Пункт общепита' },
    { value: 'DELIVERY_POINT', label: 'Пункт выдачи товара' },
    { value: 'OFFICE', label: 'Офис' },
    { value: 'WAREHOUSE', label: 'Склад' },
    { value: 'PRODUCTION', label: 'Производственное помещение' },
    { value: 'DWELLING', label: 'Жилое помещение' },
    { value: 'CAR_SERVICE', label: 'Автосервис' },
    { value: 'BEAUTY', label: 'Салон красоты' },
    { value: 'SPORT', label: 'Спортивный центр' },
    { value: 'HOTEL', label: 'Гостиница/Хостел' },
    { value: 'MEDICINE', label: 'Медицинский центр' },
    { value: 'OTHER', label: 'Другое' },
]

export const fileReportFormats = [
    {
        value: "TXT",
        label: "TXT",
    },
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "XLSX",
        label: "XLSX",
    },
]

export const staticDataForFields = {
    roomType,
    availableRegion,
    availableCityType,
    availableStreetType,
    availableKUBStreetType,
	availableCityTypeForKkt,
    availableCityTypeForKUB,
    availableBFROOcode,
    rentDataConfigMap,
    classificatorAPOS,
    classificatorBPOS,
    activityForm,
    bankListBeanName,
    activityFormVTBNew,
}
