import axios from "axios"

const ROOT_URL = "/api/v2"

const partnersAPI = {
    // read
    getAllPartnersData: (pagination) =>
        axios.get(`${ROOT_URL}/partners?page=${pagination.current}&elementsPerPage=${pagination.elementsPerPage}`)
            .then((r) => r.data),
    getPartnerTemplates: () => axios.get(`${ROOT_URL}/partnerTemplates`).then((r) => r.data),
    getPartnerUsers: (id) => axios.get(`${ROOT_URL}/partner/${id}/users`).then((r) => r.data),
    getGetNewLeadReceivers: (id) => axios.get(`${ROOT_URL}/partner/${id}/newLeadNotificationUsers`).then((r) => r.data),
    getOnePartnerData: (partnerId) =>
        axios.get(`${ROOT_URL}/partner/${partnerId}`)
            .then((r) => r.data),
    getBanksManagers: (partnerId) =>
        axios.get(`${ROOT_URL}/partner/${partnerId}/appBankManagers`)
            .then((r) => r.data),
    getSigns: (partnerId, bankId) =>
        axios.get(`${ROOT_URL}/partner/${partnerId}/bank/${bankId}/sign`)
            .then((r) => r.data),
    validateTinExist: (tin) =>
        axios.get(`${ROOT_URL}/partner/check/${tin}`).then((r) => r.data),

    // update
    updatePartner: (data) =>
        axios.put(`${ROOT_URL}/updatePartner`, data)
            .then((r) => r.data),
    updatePartnerSDMData: (data) =>
        axios.post(`${ROOT_URL}/updatePartnerSdmConfig`, data)
            .then((r) => r.data),
    updatePartnerBankManagers: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankManagers`, data)
            .then((r) => r.data),
    updatePartnerBankData: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankData`, data)
            .then((r) => r.data),      
    updatePartnerBankLogo: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankLogo`, data)
            .then((r) => r.data),
    updatePartnerBankPrint: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankPrint`, data)
            .then((r) => r.data),
    updatePartnerBankSign: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankSign`, data)
            .then((r) => r.data),
    updatePartnerBankSignAndPrintFiles: (data) =>
        axios.put(`${ROOT_URL}/updatePartnerBankSignAndPrintFiles`, data)
            .then((r) => r.data),
    updatePartnerTemplates: (data) => axios.put(`${ROOT_URL}/updatePartnerTemplates`, data).then((r) => r.data),
    updateNewLeadReceivers: ({partnerId, data}) => axios.post(`${ROOT_URL}/partner/${partnerId}/newLeadNotificationUsers`, data).then((r) => r.data),
        
    // create
    create: (data) =>
        axios.post(`${ROOT_URL}/createPartner`, data)
            .then((r) => r.data),
}

export default partnersAPI
