import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Grid, Input, message, Popover, Row, Switch, Tooltip, Typography } from 'antd'
import { t } from 'ttag'
import html2canvas from 'html2canvas'
import Axios from 'axios'
import { useGetSupportText } from './queries'
import { selectUserTokenBankBean } from '../shares/helpers/commonReduxSelectors'
import { CommentOutlined } from '@ant-design/icons'
import styles from "./ServiceDesk.module.scss";

const requestIssue = async (payload) => {
    const res = await Axios.post('/service_desk', payload)
    return res.data
}

const overlayStyle = {
    width: 360,
}

const IssueForm = ({ form, handleFinish, bankBean }) => {

    const {data} = useGetSupportText()
    
    return (
        <Form form={form} layout="vertical" onFinish={handleFinish}>
            {bankBean === 'OPEN' ? (
                <Typography.Paragraph>
                    Сообщите нам о проблеме через форму ниже
                </Typography.Paragraph>
            ) : (
                <Typography.Paragraph>
                    {data?.data ? data.data :  'Сообщите нам о проблеме через форму ниже'}
                </Typography.Paragraph>
            )}
            <Form.Item name="description" label="" rules={[{ required: true }]}>
                <Input placeholder="Заголовок" />
            </Form.Item>
            <Form.Item name="summary" label="" rules={[{ required: true }]}>
                <Input.TextArea rows={3} placeholder="Описание" />
            </Form.Item>
            <Form.Item name="withScreen" hidden  valuePropName="checked">
                <Switch />
            </Form.Item>
            <Row justify="space-between" align="middle">
                <Button onClick={form.submit}>{t`Submit`}</Button>
                <Button
                    onClick={() => {
                        form.setFieldsValue({ withScreen: true })
                        form.submit()
                    }}
                >{t`Send with screenshot`}</Button>
            </Row>
        </Form>
    )
}

const ServiceDesk = ({ className }) => {
    const bankBean = useSelector(selectUserTokenBankBean)
    const [formOpen, setFormOpen] = useState(false)
    const [form] = Form.useForm()

    const handleOpen = () => {
        setFormOpen(true)
    }

    const handleCancel = () => {
        form.resetFields()
        setFormOpen(false)
    }

    const handleSubmit = async (values) => {
        try {
            await requestIssue(values)
            handleCancel()
        } catch (e) {
            return await message.error('При отправке произошла ошибка.')
        }
    }

    const handleSubmitWithScreenshot = (values) => {
        let issueData = { ...values }

        html2canvas(document.body, {
            useCORS: true,
            foreignObjectRendering: true,
        })
            .then((canvas) => {
                try {
                    issueData.attachment = canvas.toDataURL()
                } catch (e) {
                    console.error('error', e)
                    delete issueData.attachment
                }
                return requestIssue(issueData)
            })
            .then(() => {
                handleCancel()
            })
            .catch(() => message.error('При отправке произошла ошибка.'))
    }

    const handleFinish = ({ withScreen, ...values }) => {
        withScreen ? handleSubmitWithScreenshot(values) : handleSubmit(values)
    }

	return (
        <Popover
            title={t`Contact support`}
            visible={formOpen}
            overlayStyle={overlayStyle}
            content={
                <IssueForm
                    form={form}
                    bankBean={bankBean}
                    handleFinish={handleFinish}
                />
            }
			
        >
            <Button type="link" onClick={formOpen ? handleCancel : handleOpen} className={className}>
                <span className={styles.btnText}>{t`Support`}</span>
                
				<Tooltip title={t`Support`} placement='bottomRight'>
					<CommentOutlined className={styles.btnIcon}/>
				</Tooltip>
				
				
            </Button>
        </Popover>
    )
}

export default ServiceDesk
