import axios from 'axios'

const ROOT_URL = "/api/v2/clients"

const customersAPI = {
    // read
	getInfoByInnAlfa: (data)=> axios.get(`${ROOT_URL}/checkExists`, { params: data }).then(r => r.data),
    getData: () => axios.get(ROOT_URL).then(r => r.data),
    getDataByTin: (TIN) => axios.get(`${ROOT_URL}?TIN=${TIN}`).then(r => r.data),
    getDataByAdvancedSearch: (data) => axios.post(`${ROOT_URL}/advanced-search`, data).then(r => r.data),
    getOne: (id) => axios.get(`${ROOT_URL}/${id}/main`).then(r => r.data),
    getOneForRecord: (id) => axios.get(`${ROOT_URL}/${id}/record`).then(r => r.data),
    getMerchantBankData: (id, bankId) => axios.get(`${ROOT_URL}/${id}/merchantBankData?bankId=${bankId}`).then(r => r.data),
    getTableCommissions: (data) => axios.post(`/api/v2/merchant/commission/details`, data).then(r => r.data),  
    getRelatedCustomers: (id) => axios.get(`/api/v2/client/${id}/lk`).then(r => r?.data),
    getNspkData: (id) => axios.get(`${ROOT_URL}/${id}/mpFastPaymentProviderData`).then(r => r.data),
    // update
    updateOne: ({ id, payload }) => axios.put(`${ROOT_URL}/${id}/record`, payload)
        .then(r => r.data),
    updateOneDeactivate: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/deactivate`, payload)
        .then(r => r.data),
    updateOneBlock: (id) => axios.put(`/api/v2/terminals/${id}/block`, {})
        .then(r => r.data),
    updateOneUnblock: (id) => axios.put(`/api/v2/terminals/${id}/unblock`, {})
        .then(r => r.data),
    updateOneKcv: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/kcv`, payload)
        .then(r => r.data),
    updateOneKeys: (id) => axios.put(`/api/v2/terminals/${id}/keys`, {})
        .then(r => r.data),
    updateOneSn: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/serial-number`, payload)
        .then(r => r.data),
    updateOneReceipt: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/receipt`, payload)
        .then(r => r.data),
    updateOneConnectionType: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/connection-type`, payload)
        .then(r => r.data),
    updateOneTemplate: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/template`, payload)
        .then(r => r.data),
    updateOneKeyloader: (id) => axios.put(`/api/v2/terminals/${id}/keyloader`)
        .then(r => r.data),
    updateOneSoftware: ({ id, payload }) => axios.put(`/api/v2/terminals/${id}/software`, payload)
        .then(r => r.data),
    updateReportingPeriods: ({ id, payload }) => axios.put(`${ROOT_URL}/${id}/report`, payload)
        .then(r => r.data),
    transferToAnotherPartner: (data) => axios.put(`/api/v2/migrateMerchantToPartner`, data)
        .then(r => r.data),
    updateOfdInfo: ({id, ...data}) => axios.put(`/api/v2/clients/${id}/fiscalData`, data)
		.then(r => r.data),
    getCustomerCommissions: (data) => axios.post(`/api/v2/dict/commissionsForNewPartner`, data)
        .then(r => r.data),
    updateFastPaymentProviderData: ({id, payload}) => axios.put(`/api/v2/clients/${id}/mapFastPaymentProviderToMerchantPoints`, payload)
		.then(r => r.data),
    terminateContract: (payload) => axios.put(`/api/v2/terminateContract`, payload)
		.then(r => r.data),
    // create
    createOne: (payload) => axios.post(ROOT_URL, payload)
        .then(r => r.data),
    createOneServiceComments: ({ id, payload }) => axios.post(`/api/v2/terminals/${id}/service-comments`, payload)
        .then(r => r.data),
    relateCustomers: ({ id, payload }) => axios.put(`/api/v2/client/${id}/lkUnion`, payload),
    putSwitchBank: ({ id, payload }) => axios.put(`/api/v2/clients/${id}/switchTerminalsBank`, payload)
	.then(r => r.data),
}

export default customersAPI