import { call, put, takeLatest } from 'redux-saga/effects';
import API from '../../../../api/merchant/functionality';
import * as actions from './actions'  

export default [
	takeLatest(actions.requestGetOne, requestGetOne),   
	takeLatest(actions.requestGetTerminals, requestGetTerminals),
	takeLatest(actions.requestGetLoyaltyProgram, requestGetLoyaltyProgram),
	takeLatest(actions.requestGetTstList, requestGetTstList),
	takeLatest(actions.requestGetDynamicTerminals, requestGetDynamicTerminals),
	takeLatest(actions.requestCreateUpdate, requestCreateUpdate),
]


// 
function* requestGetOne(action) {
	try {  
		const data = yield call(API.getOne, action.payload);  
		yield put(actions.successGetOne(data))
	} catch (error) {   
		yield put(actions.successGetOne({}))
	}
}

//
function* requestGetTerminals(action) {
	try {
		const data = yield call(API.getTerminals, action.payload);
		yield put(actions.finalGetTerminals(data))
	} catch (error) {
		yield put(actions.finalGetTerminals([]))
	}
}

//
function* requestGetLoyaltyProgram(action) {
	try {
		const data = yield call(API.getLoyaltyProgram, action.payload);
		yield put(actions.finalGetLoyaltyProgram(data))
	} catch (error) {
		yield put(actions.finalGetLoyaltyProgram([]))
	}
}

//
function* requestGetTstList(action) {
	try {
		const data = yield call(API.getTstList, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalGetTstList(data))
	} catch (error) {
		action.payload.callback([])
		yield put(actions.finalGetTstList([]))
	}
}

//
function* requestGetDynamicTerminals(action) {
	try {
		const data = yield call(API.getDynamicTerminals, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalGetDynamicTerminals(data))
	} catch (error) {
		action.payload.callback([])
		yield put(actions.finalGetDynamicTerminals([]))
	}
}

//
function* requestCreateUpdate(action) {
	try {
		const data = yield call(API.createUpdate, action.payload.request);
		action.payload.callback(data)
		yield put(actions.finalCreateUpdate(data))
	} catch (error) {
		action.payload.callback(false)
		yield put(actions.finalCreateUpdate({}))
	}
}
