import { createAction } from 'redux-act';

// get banks
export const requestGetBanks = createAction('[core - dict] requestGetBanks')
export const successGetBanks = createAction('[core - dict] successGetBanks') 
export const successGetDictBanks = createAction('[core - dict] successGetDictBanks') 
// get Payments
export const requestGetPayments = createAction('[core - dict] requestGetPayments')
export const successGetPayments = createAction('[core - dict] successGetPayments') 
// get partners
export const requestGetPartners = createAction('[core - dict] requestGetPartners')
export const finalGetPartners = createAction('[core - dict] finalGetPartners')
// get merchants
export const requestGetMerchants = createAction('[leads] requestGetMerchants')
export const finalGetMerchants = createAction('[leads] finalGetMerchants')
// get merchant points
export const requestGetMerchantPoints = createAction('[leads] requestGetMerchantPoints')
export const finalGetMerchantsPoints  = createAction('[leads] finalGetMerchantsPoints')
//  Get merchant points by params
export const requestGetMerchantPointsByParams = createAction('[merchant] requestGetMerchantPointsByParams') 
export const finallGetMerchantPointsByParams = createAction('[merchant] finallGetMerchantPointsByParams') 
export const clearGetMerchantPointsByParams = createAction('[merchant] clearGetMerchantPointsByParams') 
// get UsersForApp
export const requestUsersForApp = createAction('[leads] requestUsersForApp')
export const finalUsersForApp  = createAction('[leads] finalUsersForApp')
// --- cards
// get cards
export const requestGetWaitersCards = createAction('[leads] requestGetWaitersCards')
export const finalGetWaitersCards  = createAction('[leads] finalGetWaitersCards')
// get currencies
// get pinpads
export const requestGetPinpads = createAction('[Pinpads] requestGetPinpads')
export const successGetPinpads = createAction('[Pinpads] successGetPinpads')
// get allAllDataForTmsConfigs
export const requestGetAllDataForTmsConfigs = createAction('[allTmsData] requestGetAllDataForTmsConfigs')
export const successGetAllDataForTmsConfigs = createAction('[allTmsData] successGetAllDataForTmsConfigs')

// состояние открыто\закрыто расширенного поиска 

//таблица лидов
export const changeLeadSearchOpen = createAction('[leads] changeLeadSearchOpen')
