import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons'
import { Grid, Image, Layout, Menu, Spin } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { gettext } from 'ttag'
import navigation from '../../_nav'
import history from '../../history'
import { selectPermissions } from '../shares/helpers/commonReduxSelectors'
import useCheckDomainName from '../shares/helpers/useCheckDomainName'
import { checkPermissions } from './DefaultLayout'
import './LoadingAnimation/index.sass'
import { useSetPortalLogo } from './useSetPortalLogo'
import styles from './Layout.module.scss'

const { Sider } = Layout

const reducedNavigation = (userRoles) => ({
    items: navigation.items.filter(
        (item) =>
            item.children &&
            item.children.some((child) =>
                checkPermissions(child, userRoles)
            )
    ),
})

function getItem(label, key, icon, children, type) {
	return {
	  key,
	  icon,
	  children,
	  label,
	  type,
	};
};

const renderItems = (adminRoles) => {
    const items = reducedNavigation(adminRoles).items

    const result = items.map((el) => {
        if (el.children.length) {
			const icon = el.icon ? <el.icon /> : null;

			const children = el.children.map((subElem) => {
					// проверка доступа
					if (!checkPermissions(subElem, adminRoles)) {
						return null
					}
					return getItem(gettext(subElem.name),subElem.url)

				});

			return (
					getItem(gettext(el.name),el.name,icon, children)				
			) 
		} else {
				if (!adminRoles[el.permission]) return null
				return (
					getItem(gettext(el.name),el.name,<InboxOutlined />)
				)
		}
	})   
    
    return result
}

/** Меню постоянно получает путь реакт роутера и при каждой смене - ищет данный урл в nav
 * Или дополнительный url в subPath, такой как /:id на этот же компонент
 **/
const SideBar = ({ 
	isCollapsed, 
	setIsCollapsed,
	toggleSiderCollapsed,
}) => {

	const adminRoles = useSelector(selectPermissions)
    const { pathname } = useLocation()

    const [openKeys, setOpenKeys] = useState([])

 	const { isAtol } = useCheckDomainName();
	const { fistLogoLoading, isLogoLoading, logo } = useSetPortalLogo({ fetchIcon: true })
	const {md, sm} = Grid.useBreakpoint()
	
    useEffect(() => {
        const list = pathname.split('/').splice(1)
        setOpenKeys(
            list.map((_, index) => `/${list.slice(0, index + 1).join('/')}`)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectedKeys = useMemo(() => {
        const list = pathname.split('/').splice(1)
        return list.map((_, index) => `/${list.slice(0, index + 1).join('/')}`)
    }, [pathname])

    const onOpenChange = (keys) => {
        setOpenKeys(keys)
    }

	const getCollapsedWidth = () => {
		if(!md) return '0'
		return '80'
	};

	const getSiderWidth = () => {
		if(sm === undefined || md === undefined) return '210'
		if(!sm) return '100%'
		if(!md) return '310'
		return '210'
	};

	return (
        <Sider
			className={ classNames(`overflow-auto scrollbar-none ${styles.sider}`,{
				'sider-atol': isAtol,
			})}
			breakpoint="lg"
			collapsedWidth={getCollapsedWidth()}
            collapsible={true}
			collapsed={isCollapsed}
            width={getSiderWidth()}
			onBreakpoint={(broken) => {
				setIsCollapsed(broken)
			}}
			onCollapse={(collapsed, type) => {
				if(type === 'clickTrigger') setIsCollapsed(collapsed)
			}}

        >
            <Spin spinning={isLogoLoading || !logo || fistLogoLoading}>
                <h1
					className={ classNames('menu_head',styles.menuHead,{
						'menu-head-atol': isAtol,
					})}
				 >
                    <Image 
						src={logo}  
						preview={false} 
						className={styles.headerLogo}
					/>
					<CloseCircleOutlined   
						onClick={toggleSiderCollapsed} 
						className={classNames(styles.siderMenuToggle,{
							[styles.menuToggleHidden]: md || isCollapsed,
						})}
					/> 
                </h1>
            </Spin>
			
            <Menu
                mode="inline"
                theme="dark"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                selectedKeys={selectedKeys}
                inlineIndent={12}
                onClick={(e) => {
                    if (history.location.pathname !== e.key) history.push(e.key)
					if (!md) toggleSiderCollapsed()
                }}
				items={renderItems(adminRoles)}
				className={ classNames('menu_item_nav_flex',{
					'menu-sider-atol': isAtol,
				})}
            />
        </Sider>
    )
}

export default SideBar
