import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { keysWithDateValues, keysWithDateTimeValues } from './objectKeysWithDateValue';
import terminalsAPI from '../../api/v2/terminalsAPI';
import serviceAPI from '../../api/v2/serviceAPI';
import generalAPI from '../../api/v2/generalAPI';
import formatingObjectToView from '../shares/helpers/formatingObjectForView';
import posesAPI from '../../api/v2/posesAPI';

// read
export const fetchTerminalData = createAsyncThunk(
    "terminal/data/fetch",
    async (terminalId) => {
        const response = await terminalsAPI.getOneData({ id: terminalId })
        return response
    }
)

export const fetchTerminalCompany = createAsyncThunk(
    "terminal/company/fetch",
    async (terminalId) => {
        const response = await terminalsAPI.getOneCompany({ id: terminalId })
        return response
    }
)

export const fetchTerminalParameters = createAsyncThunk(
    "terminal/parameters/fetch",
    async (terminalId) => {
        const response = await terminalsAPI.getOneParameters({ id: terminalId })
        return response
    }
)

export const fetchTerminalTmsDict = createAsyncThunk(
    "terminal/tms-dict/fetch",
    async (terminalId) => {
        const response = await terminalsAPI.getOneTmsDict({ id: terminalId })
        return response
    }
)

export const fetchTerminalSdm = createAsyncThunk(
    "terminal/sdm/fetch",
    async (terminalId) => {
        const response = await terminalsAPI.getOneSdm({ id: terminalId })
        return response
    }
)

export const fetchCheckSerialNumber = createAsyncThunk(
    "terminal/check-sn/fetch",
    async (payload) => {
        const response = await generalAPI.checkSn(payload)
        return response
    }
)

// update
export const fetchTerminalDeactivate = createAsyncThunk(
    "terminal/update-deactivate/fetch",
    async ({ id, payload }, { rejectWithValue }) => {
        try {
            const response = await terminalsAPI.updateOneDeactivate({ id, payload })
            return response
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const fetchTerminalBlock = createAsyncThunk(
    "terminal/update-block/fetch",
    async (id) => {
        const response = await terminalsAPI.updateOneBlock(id)
        return response
    }
)

export const fetchTerminalUnblock = createAsyncThunk(
    "terminal/update-unblock/fetch",
    async (id) => {
        const response = await terminalsAPI.updateOneUnblock(id)
        return response
    }
)

export const fetchTerminalKeys = createAsyncThunk(
    "terminal/update-keys/fetch",
    async (id) => {
        const response = await terminalsAPI.updateOneKeys(id)
        return response
    }
)

export const fetchTerminalSn = createAsyncThunk(
    "terminal/update-sn/fetch",
    async ({ id, payload }) => {
        const response = await terminalsAPI.updateOneSn({ id, payload })
        return response
    }
)

export const fetchTerminalReceipt = createAsyncThunk(
    "terminal/update-receipt/fetch",
    async ({ id, payload }, thunkAPI) => {
        const { receiptLogo, installationAddress } = thunkAPI.getState().terminal.terminalActionType.payload
        const requestData = { id, payload: { ...payload, receiptLogo, installationAddress } }
        const response = await terminalsAPI.updateOneReceipt(requestData)
        return response
    }
)

export const updateScreenSavers = createAsyncThunk(
    "terminal/update-screenSavers/put",
    async ({ id }, thunkAPI) => {
        const { mainSplashScreenInBase64, splashScreenInBase64 } = thunkAPI.getState().terminal.screenSavers
        const requestData = { id, payload: { mainSplashScreenInBase64, splashScreenInBase64 } }
        const response = await terminalsAPI.updateScreenSavers(requestData)
        return response
    }
)

export const getTerminalReceipt = createAsyncThunk(
    "terminal/get-receipt/fetch",
    async (id) => {
        const response = await terminalsAPI.getOneReceiptData(id)
        return response
    }
)

export const fetchTerminalTemplate = createAsyncThunk(
    "terminal/update-template/fetch",
    async ({ id, payload }) => {
        const response = await terminalsAPI.updateOneTemplate({ id, payload })
        return response
    }
)

export const assignTerminalTemplate = createAsyncThunk(
    "terminal/assign-template/put",
    async ({ id, payload }) => {
        const response = await terminalsAPI.assingOneTemplate({ id, payload })
        return response
    }
)

export const updateConfigSettings = createAsyncThunk(
    "terminal/update-config-settings/put",
	async ({ id, payload }, { rejectWithValue }) => {
        try {
            const response = await terminalsAPI.updateConfigSettings({ id, payload })
       	 	return response
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const fetchTerminalKeyloader = createAsyncThunk(
    "terminal/update-keyloader/fetch",
    async (id) => {
        const response = await terminalsAPI.updateOneKeyloader(id)
        return response
    }
)


export const fetchTerminalSoftware = createAsyncThunk(
    "terminal/update-software/fetch",
    async ({ id, payload }) => {
        const response = await terminalsAPI.updateOneSoftware({ id, payload })
        return response
    }
)

export const fetchTerminalServiceTickets = createAsyncThunk(
    "terminal/create-ticket/fetch",
    async ({ id, payload }) => {
        const response = await serviceAPI.createOneServiceTickets({ terminalId: id, ...payload })
        return response
    }
)

export const fetchTerminalServiceTicketComment = createAsyncThunk(
    "terminal/create-ticket-comment/fetch",
    async ({ id, payload }) => {
        const response = await serviceAPI.createOneServiceTicketsComment({ terminalId: id, ...payload })
        return response
    }
)

export const getTemplateOptions = createAsyncThunk(
    "terminal/options/template",
    async (vendorId, thunkAPI) => {
        const { tmsConfigs } = thunkAPI.getState().core.dict.allDataForTmsConfigs.data

        const permissions = thunkAPI.getState().auth.user.userToken.adminRoles
        const isSuperUser = permissions["partners_create:fromPortal"]
        
        if (isSuperUser) {
            return tmsConfigs.map(el => ({ value: el.id, label: el.name, ...el  }))
        } else {
            return tmsConfigs
                        .filter(el => el.vendorId === +vendorId)
                        .map(el => ({ value: el.id, label: el.name, ...el }))
        }
    }
)

export const getPoses = createAsyncThunk(
    "terminal/poses/get",
    async (clientId) => {
        const response = await posesAPI.getDataByClientId(clientId)
        return response
    }
)

export const bindTerminalToTst = createAsyncThunk(
    "terminal/bindToTst/post",
    async (data) => {
        const response = await terminalsAPI.bindTerminalToTst(data)
        return response
    }
)

const initialState = {
    main: {
        data: {},
        loading: false,
    },
    company: {
        data: {},
        loading: false,
    },
    parameters: {
        data: {},
        loading: false,
    },
    processing: {
        data: {},
        loading: false,
    },
    tms: {
        data: {},
        loading: false,
    },
    sdm: {
        data: {},
        loading: false,
    },
    receiptData: {
        data: {},
        loadingReceiptData: false,
        error: false,
    },
    screenSavers: {
        mainSplashScreenInBase64: '',
        splashScreenInBase64 : '',
        loading: false
    },
    actionOptions: {
        software: [],
        template: [],
    },
    vendorOptions: [],
    modelOptions: [],
    posesOptions: [],
    terminalActionType: {
        actionName: '',
        payload: {},
        currentStep: 0,
        loading: false
    },
    validSerialNumber: {
        loading: false,
        data: {},
    },
    loading: false
}

const terminalSlice = createSlice({
    name: "terminal",
    initialState,
    reducers: {
        //общий метод для всех модалок
        closeAction: (state) => {
            state.terminalActionType = initialState.terminalActionType
            state.receiptData = initialState.receiptData
        },
        // инициация действия для терминала
        actionInit: (state, { payload }) => {
            // исключение для коментария к заявке
            if (payload.currentTicketId) {
                state.terminalActionType.actionName = payload.actionType
                state.terminalActionType.payload.currentTicketId = payload.currentTicketId
                return
            }
            state.terminalActionType.actionName = payload
        },
        changeStep: (state, { payload }) => {
            if (payload === 'next') {
                state.terminalActionType.currentStep += 1
            }
            if (payload === 'back') {
                state.terminalActionType.currentStep -= 1
            }
        },
        resetSnValidation: (state) => {
            state.validSerialNumber = initialState.validSerialNumber
        },
        addBase64ReceiptLogo: (state, { payload }) => {
            state.terminalActionType.payload.receiptLogo = payload
        },
        addBase64MainScreenSaver: (state, { payload }) => {
            state.screenSavers.mainSplashScreenInBase64 = payload
        },
        addBase64KktScreenSaver: (state, { payload }) => {
            state.screenSavers.splashScreenInBase64 = payload
        },
        addInstallationAddress: (state, { payload }) => {
            state.terminalActionType.payload.installationAddress = payload
        },
        clearTerminalData: (state) => {
            state.main = initialState.main
            state.company = initialState.company
            state.parameters = initialState.parameters
            state.processing = initialState.processing
            state.tms = initialState.tms
            state.sdm = initialState.sdm
            state.terminalActionType = initialState.terminalActionType
            state.receiptData = initialState.receiptData
            state.validSerialNumber = initialState.validSerialNumber
        },
    },

    extraReducers: {
        // общие данные (НОВЫЕ)
        [fetchTerminalData.pending]: (state) => {
            state.main.loading = true
        },
        [fetchTerminalData.fulfilled]: (state, { payload }) => {
            state.main.data = formatingObjectToView(payload, keysWithDateValues, keysWithDateTimeValues)
            state.main.loading = false
        },
        [fetchTerminalData.rejected]: (state, { payload }) => {
            state.main = initialState.main
            // state.main.loading = false
        },
        // данные о компании
        [fetchTerminalCompany.pending]: (state) => {
            state.company.loading = true
        },
        [fetchTerminalCompany.fulfilled]: (state, { payload }) => {
            state.company.data = formatingObjectToView(payload, keysWithDateValues, keysWithDateTimeValues)
            state.company.loading = false
        },
        [fetchTerminalCompany.rejected]: (state, { payload }) => {
            state.company = initialState.company
            // state.processing.loading = false
        },
        // данные для Параметров терминала
        [fetchTerminalParameters.pending]: (state) => {
            state.parameters.loading = true
        },
        [fetchTerminalParameters.fulfilled]: (state, { payload }) => {
            state.parameters.data = formatingObjectToView(payload, keysWithDateValues, keysWithDateTimeValues)
            state.parameters.loading = false
        },
        [fetchTerminalParameters.rejected]: (state, { payload }) => {
            state.parameters = initialState.parameters
            state.parameters.loading = false
        },
        // словари для выбранного терминала
        [fetchTerminalTmsDict.fulfilled]: (state, { payload }) => {
            if (payload.software) {
                state.actionOptions.software = Object.entries(payload.software).map(el => ({ value: el[0], label: el[1] }))
            }
        },
        // данные для СДМ
        [fetchTerminalSdm.pending]: (state) => {
            state.sdm.loading = true
        },
        [fetchTerminalSdm.fulfilled]: (state, { payload }) => {
            const formatedPayload = payload.tickets?.map?.((ticket) => {
                ticket.comments = ticket?.comments?.map(comment => {
                    return formatingObjectToView(comment, keysWithDateValues, keysWithDateTimeValues)
                }) || []
                return Object.assign(formatingObjectToView(ticket, keysWithDateValues, keysWithDateTimeValues), { key: nanoid() })
            })
            state.sdm.data = { ...payload, tickets: formatedPayload }
            state.sdm.loading = false
        },
        [fetchTerminalSdm.rejected]: (state, { payload }) => {
            state.sdm = initialState.sdm
        },
        // запрос на деактивацию терминала
        [fetchTerminalDeactivate.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalDeactivate.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalDeactivate.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос блокировки терминала
        [fetchTerminalBlock.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalBlock.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalBlock.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос разблокировки терминала
        [fetchTerminalUnblock.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalUnblock.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalUnblock.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос на выставление ключей
        [fetchTerminalKeys.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalKeys.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalKeys.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос обновления Serial number
        [fetchTerminalSn.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalSn.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalSn.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос проверки Serial number
        [fetchCheckSerialNumber.pending]: (state) => {
            state.validSerialNumber.loading = true
        },
        [fetchCheckSerialNumber.fulfilled]: (state, { payload }) => {
            state.validSerialNumber.data = payload
            state.validSerialNumber.loading = false
        },
        [fetchCheckSerialNumber.rejected]: (state, { payload }) => {
            state.validSerialNumber.loading = false
        },
        // запрос изменения чека
        [getTerminalReceipt.pending]: (state) => {
            state.receiptData.loadingReceiptData = true
        },
        [getTerminalReceipt.fulfilled]: (state, { payload }) => {
            state.receiptData.loadingReceiptData = false
            state.terminalActionType.payload.installationAddress = payload.installationAddress || {}
            state.terminalActionType.payload.receiptLogo = payload.receiptLogo || ""
        },
        [getTerminalReceipt.rejected]: (state, { payload }) => {
            state.receiptData.loadingReceiptData = false
        },
        // запрос изменения чека
        [fetchTerminalReceipt.pending]: (state) => {
            state.screenSavers.loading = true
        },
        [fetchTerminalReceipt.fulfilled]: (state, { payload }) => {
            state.screenSavers.loading = false
        },
        [fetchTerminalReceipt.rejected]: (state, { payload }) => {
            state.screenSavers.loading = false
        },
        // запрос изменения заставок
        [updateScreenSavers.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [updateScreenSavers.fulfilled]: (state) => {
            state.screenSavers.mainSplashScreenInBase64 = ''
            state.screenSavers.splashScreenInBase64 = ''
            state.terminalActionType.loading = false
        },
        [updateScreenSavers.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос изменения шаблона
        [fetchTerminalTemplate.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalTemplate.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalTemplate.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос изменения шаблона 2
        [assignTerminalTemplate.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [assignTerminalTemplate.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [assignTerminalTemplate.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос выставления KeyLoader
        [fetchTerminalKeyloader.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalKeyloader.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalKeyloader.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос выставления KeyLoader
        [fetchTerminalSoftware.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalSoftware.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalSoftware.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос на обслуживание терминала
        [fetchTerminalServiceTickets.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalServiceTickets.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalServiceTickets.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
		// запрос на подкл\откл сбп
        [updateConfigSettings.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [updateConfigSettings.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [updateConfigSettings.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // запрос на создание комментария к тикету
        [fetchTerminalServiceTicketComment.pending]: (state) => {
            state.terminalActionType.loading = true
        },
        [fetchTerminalServiceTicketComment.fulfilled]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        [fetchTerminalServiceTicketComment.rejected]: (state, { payload }) => {
            state.terminalActionType.loading = false
        },
        // получение опций для темплейтов
        [getTemplateOptions.fulfilled]: (state, { payload }) => {
            state.actionOptions.template = payload
        },
        [getPoses.fulfilled]: (state, { payload }) => {
            state.posesOptions = payload.poses.map(pos => ({ value: pos.id, label: pos.posName }))
        },
        [bindTerminalToTst.pending]: (state) => {
            state.loading = true
        },
        [bindTerminalToTst.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
    },

});

export const {
    actionInit,
    changeStep,
    closeAction,
    resetSnValidation,
    addBase64ReceiptLogo,
    addBase64MainScreenSaver,
    addBase64KktScreenSaver,
    addInstallationAddress,
    clearTerminalData,
} = terminalSlice.actions
export default terminalSlice.reducer