import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Favicon_OPEN from '../../core/assets/Favicon_OPEN.png'
import DefaultLogo from '../../core/assets/Logo2.svg'
import Logo_OPEN from '../../core/assets/Logo_OPEN.png'
import AtolLogo from '../../core/assets/Logo_atol.png'
import Favicon_ATOL from '../../core/assets/atol-favicon.ico'
import { selectUserToken } from '../shares/helpers/commonReduxSelectors'
import useCheckDomainName from '../shares/helpers/useCheckDomainName'
import './LoadingAnimation/index.sass'
import { useGetLogo } from './hooks/useLayoutQueries'


export const useSetPortalLogo = ({ fetchIcon = true }) => {
	const { bankBean = '', merchant_id = '' } = useSelector(selectUserToken)
    const { data: {logoInBase64: serverLogo = ''} = {}, isLoading: isLogoLoading, refetch: refetchLogo } = useGetLogo()

	const [logo, setLogo] = useState('')
	const [fistLogoLoading, setFistLogoLoading] = useState(true)
    
	const { isAtol } = useCheckDomainName();

	useEffect(() => {
		if(isAtol){
			setLogo(AtolLogo)
			setFistLogoLoading(false)
			return;
		}
		
        if (bankBean === 'OPEN') {
            const favicon = document.getElementById('favicon')
            favicon.href = Favicon_OPEN
        }

        if (fistLogoLoading && fetchIcon) {
            refetchLogo()
        }

        if (merchant_id && bankBean === 'OPEN') {
            setLogo(Logo_OPEN)
        } else if (serverLogo) {
            setLogo(serverLogo)
        } 
        if (!isLogoLoading && !logo && !fistLogoLoading) {
            setLogo(DefaultLogo)
        }
        setFistLogoLoading(false)
    }, [bankBean,isAtol, fistLogoLoading, isLogoLoading, logo, merchant_id, serverLogo, refetchLogo])

	return { 
		fistLogoLoading,
		isLogoLoading,
		logo, 
	}

};
