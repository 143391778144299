export const generateOptionsFromObject = (obj = {}, valueType = 'string') => {
    return Object.entries(obj).map(item => ({value: valueType === 'number' ? +item[0] : item[0], label: item[1]}))
}
export const getOptionsByKey = (data, valueType = 'string') => {
	if(typeof data !== 'object' || !Object.keys(data).length) return []
	return Object.keys(data).map(key => {return {value:valueType === 'number' ? +key : key, label: data[key]}})
}
export const generateNumbersOptionsFromObject = (obj = {}) => {
    return Object.entries(obj).map(item => ({value: +item[0], label: item[1]}))
}
export const generateOptionsByNameId = (data) => {
    if (data && data?.length) {
		return data.map(el => ({value:+el.id, label: el.name}))
	}
	return []
}
export const getOptionValue = (options = [], item) => {
    return options.find(option => option.value === item)?.label
}
