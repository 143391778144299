import React from "react"
import { Modal, Button, Form, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getNewAuthToken } from "./loginAsSlice"
import openNotificationWithIcon from "../shares/openNotificationWithIcon"
import { unwrapResult } from "@reduxjs/toolkit"
import { gettext } from "ttag"


const OtpModalForm = ({show, setShow, toUserId}) => {
    const dispatch = useDispatch()
    const { loading } = useSelector(
        (state) => state.loginAs
    )

    const onFinish = ({otp}) => {
        dispatch(getNewAuthToken({toUserId, otp}))
            .then(unwrapResult)
            .then(() => {
                handleClose()
        })
        .catch((err) => {
            console.log(err)
            openNotificationWithIcon({
                type: "error",
                message: `При попытке входа на портал под другим пользователем произошла ошибка!`,
                description: `${gettext(err.errorType)}`,
            })
        })
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <Modal
                title="Подтверждение управления пользователем"
                footer={null}
                visible={show}
                onCancel={handleClose}
                maskClosable={false}
                destroyOnClose

            >
                <Form onFinish={onFinish}>
                    <Form.Item
                        label="Введите OTP"
                        name="otp"
                        rules={[{ required: true, message: 'Пожалуйста укажите OTP отправленный на почту пользователю' }]}
                    >
                        <Input placeholder="OTP" style={{width: 350}} />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" loading={loading} style={{display: 'block', margin: '0 auto'}}>
                            Перейти к управлению пользователем
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OtpModalForm