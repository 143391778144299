import axios from 'axios'

const ROOT_URL = "/api/v2"

export default {  
	getData: (data) => axios.get(`/merchants?page=${data.page}&elementsPerPage=${data.elementsPerPage}`, data).then(r => r.data),  
	getOne: (data) => axios.get(`/merchant/${data.id}`).then(r => r.data),  
	//getTableCommissions: (data) => axios.post(`/merchant/commission/details`, data).then(r => r.data),  
	getBankList: (id) => axios.get(`/dict/merchant/${id}/banks`).then(r => r.data),  
	//getTableCommissionsPoints: ({merchantId, agreementId}) => axios.get(`${ROOT_URL}/merchant/${merchantId}/agreement/${agreementId}/points`).then(r => r.data),  
	getTableCommissionsPoints: (data) => {
		const contarctId = data.contract_id ? `&contract_id=${data.contract_id}` : ''
		return axios.get(`/api/v2/dict/merchant_points?merchant_id=${data.merchant_id}&bank_id=${data.bank_id}${contarctId}`).then(r => r.data)
	},
	getTableCommissionsRates: ({merchantId, agreementId, pointId}) => axios.get(`${ROOT_URL}/merchant/${merchantId}/agreement/${agreementId}/points/${pointId}/rates`).then(r => r.data),  
}